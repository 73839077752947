import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: 20,
    '&:first-child': {
      marginTop: 20,
    },
  },
  title: {
    marginTop: theme.spacing(8),
  },
  appBar: {
    width: '100%',
    background: 'inherit',
    boxShadow: 'none',
    marginBottom: 30,
  },
  tabs: {
    background: 'inherit',
    border: 0,
    borderRadius: 3,
    height: 48,
    padding: '0 30px',
    color: 'white',
    paddingLeft: 0,
  },
  button: {
    '&:hover': {
      border: 'none',
    },
  },
  buttonWithMargin: {
    marginTop: 10,
    '&:hover': {
      border: 'none',
    },
  },
  tab: {
    '&:hover': {
      border: 0,
      outline: 'none',
    },
    '&:focus': {
      border: 0,
      outline: 'none',
    },
  },

  formControl: {
    width: 200,
  },
  medicalRecordHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  medicalRecordH5: {
    marginBottom: 12,
    fontWeight: 700,
  },
  signosVitalesAltGrid: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  subSectionHeader: {
    fontWeight: 600,
  },
  usefulText: {
    fontSize: 18,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  uploadButtonContainer: {
    marginLeft: 16,
  },
}));

export default useStyles;
