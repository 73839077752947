import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import store from './store';

import App from './App';
import './index.css';

// console.warn = () => {}
// console.error = () => {}
ReactDOM.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
  document.getElementById('root'),
);
