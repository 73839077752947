import { Box, IconButton, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { CaseListItem } from 'pages/User/PatientCases/types';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { convertDate } from 'utils/formatters';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { ThunkType } from 'store/type';
import { Link, useParams } from 'react-router-dom';

interface CovidItemProps {
  covidInfo: CaseListItem['covidInfo'][0];
}

const CovidItem: FC<CovidItemProps> = ({ covidInfo }) => {
  const { caseId, patient } = useParams<any>();
  const { type } = useStoreState(({ user }) => user);
  const isRemoveAllowed = useMemo(() => ['admin', 'staff', 'doctor'].includes(type), [type]);

  const removeCovid = useStoreActions(({ removeCovidThunk }) => removeCovidThunk as ThunkType);

  const [loading, setLoading] = useState({
    delete: false,
  });

  const createdAtFormatted = useMemo(() => convertDate(covidInfo.createdAt, 'dd.MM.yyyy HH:mm'), [covidInfo.createdAt]);

  const onDeleteClick = useCallback(async () => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, delete: true }));
      await removeCovid({ caseId, covidId: covidInfo.id });
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, delete: false }));
    }
  }, [covidInfo, setLoading, removeCovid]);

  return (
    <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
      <Box>
        <Link to={`/user/patient_records/${patient}/cases/${caseId}/covid19/${covidInfo.id}`}>{createdAtFormatted}</Link>
      </Box>

      <Box>
        {isRemoveAllowed && (
          <IconButton disabled={loading.delete} onClick={onDeleteClick} color="secondary">
            <Delete />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default CovidItem;
