import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import SignIn from '../pages/Auth/SignIn';

import SetPassword from '../pages/Auth/SetPassword';
import ForgotPassword from '../pages/Auth/ForgotPassword';

export default function AuthRouter() {
  const { push } = useHistory();
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      push('/user');
    }
  }, []);
  return (
    <>
      <Route exact path="/auth/sign_in" component={SignIn} />
      <Route exact path="/auth/set_password" component={SetPassword} />
      <Route exact path="/auth/forgot_password" component={ForgotPassword} />
    </>
  );
}
