/* eslint-disable no-useless-catch */
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_HOST;
// 'http://dev.hospitalprivadopeten-api.geniusee.space'

export default {
  async getCatalogue(token, params) {
    try {
      const { data } = await axios.get('/catalog', {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async editCatalogue(token, formData) {
    try {
      const { data } = await axios.patch('/catalog', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(data);
      return data;
    } catch (err) {
      throw err;
    }
  },

  async removeCatalogue(token, catalogId) {
    try {
      const { data } = await axios.delete(`/catalog/${catalogId}`, { headers: { Authorization: `Bearer ${token}` } });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async addCatalogue(token, formData) {
    try {
      const data = await axios.post('/catalog', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async assignCatalogue(token, formData) {
    try {
      const { data } = await axios.post('/assigned_catalog/assign', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async changeStatus(token, formData) {
    try {
      const { data } = await axios.post('/assigned_catalog/change_status', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async changePaymentStatus(token, formData) {
    try {
      const { data } = await axios.post('/assigned_catalog/change_payment_status', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getUnresolvedCatalogue(token) {
    try {
      const { data } = await axios.get('/assigned_catalog/unresolved', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getVatPercent(token) {
    try {
      const { data } = await axios.get('/vat', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getAllAssigned(token, params) {
    try {
      const { data } = await axios.get('/assigned_catalog', {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async editVatPercent(token, formData) {
    try {
      const { data } = await axios.post('/vat', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async uploadImage(formData, catalogId) {
    try {
      const { data } = await axios.post(`catalog/${catalogId}/image`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
};
