import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from 'components/Autocomplete';
import { ChartsApi } from 'api/Charts';
import { Box, Switch, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Loader from 'components/Loader';
import { useTranslations } from 'utils/useTranslations';
import { roundTo2 } from 'utils/roundTo2';
import { useStyles } from './styles';
import { baseOptions, chartTypeOptions, ChartTypeOptionsEnum } from './constants';
import { getFillColor } from './utils';

Highcharts.setOptions({
  lang: {
    loading: 'Cargando...',
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    rangeSelectorFrom: 'Desde',
    rangeSelectorTo: 'Hasta',
    rangeSelectorZoom: 'Período',
    downloadPNG: 'Descargar imagen PNG',
    downloadJPEG: 'Descargar imagen JPEG',
    downloadPDF: 'Descargar imagen PDF',
    downloadSVG: 'Descargar imagen SVG',
    printChart: 'Imprimir',
    resetZoom: 'Reiniciar zoom',
    resetZoomTitle: 'Reiniciar zoom',
    thousandsSep: ',',
    decimalPoint: '.',
  },
});

const Chart = () => {
  const classes = useStyles();
  const intl = useTranslations();
  const [charts, setCharts] = useState([]);
  const [type, setType] = useState('totalPrice');
  const [loading, setLoading] = useState(false);

  const onTypeChange = useCallback(() => {
    setType((oldType) => (oldType === 'totalPrice' ? 'count' : 'totalPrice'));
  }, []);

  const [chartType, setChartType] = useState(chartTypeOptions[0]);

  const onChartTypeChange = useCallback((_, value) => {
    setChartType(value);
    setCharts([]);
  }, []);

  const [catalog, setCatalog] = useState({});
  const [patients, setPatients] = useState({});

  const pArray = useMemo(() => Object.values(patients), [patients]);
  const cArray = useMemo(() => Object.values(catalog), [catalog]);

  const [selectedPatients, setSelectedPatients] = useState([]);

  const onPatientsChange = useCallback((_, value) => setSelectedPatients(value), []);

  const [selectedCatalog, setSelectedCatalog] = useState([]);

  const onCatalogChange = useCallback((_, value) => setSelectedCatalog(value), []);

  const [dates, setDates] = useState({ from: new Date(new Date().setMonth(new Date().getMonth() - 3)), to: new Date() });

  const onDateFromChange = useCallback((from) => setDates((prev) => ({ ...prev, from })), []);
  const onDateToChange = useCallback((to) => setDates((prev) => ({ ...prev, to })), []);

  useEffect(() => {
    let params;
    setCharts([]);
    if (chartType.value === ChartTypeOptionsEnum.USERS) {
      params = {
        patients: selectedPatients.map(({ _id }) => _id),
      };

      if (!params.patients.length) {
        return;
      }
    }

    if (chartType.value === ChartTypeOptionsEnum.SKU) {
      params = {
        codigo: selectedCatalog.map(({ codigo, categoria }) => ({ codigo, categoria })),
      };
      if (!params.codigo.length) {
        return;
      }
    }

    setLoading(true);
    ChartsApi.getCharts({ ...params, dateFrom: dates.from, dateTo: dates.to })
      .then(({ data }) => setCharts(data))
      .finally(() => setLoading(false));
  }, [selectedPatients, selectedCatalog, chartType, dates]);

  useEffect(() => {
    ChartsApi.getPatients().then(({ data }) =>
      setPatients(
        data.reduce((acum, item) => {
          acum[item._id] = item;
          return acum;
        }, {}),
      ),
    );
    ChartsApi.getCatalog().then(({ data }) =>
      setCatalog(
        data.reduce((acum, item) => {
          acum[`${item.codigo} ${item.categoria}`] = item;
          return acum;
        }, {}),
      ),
    );
  }, []);

  const data = useMemo(() => {
    let chartData: any = [
      {
        type: 'area',
        name: type === 'totalPrice' ? 'Total Price' : 'Count',
        data: charts.map(({ _id: { date }, ...rest }) => [+new Date(date), roundTo2(rest[type])]),
        ...getFillColor(),
      },
    ];

    if (loading) {
      return [];
    }

    if (chartType.value === ChartTypeOptionsEnum.USERS) {
      const users = Object.keys(
        charts.reduce((acum, item) => {
          acum[item._id.patientId] = '';
          return acum;
        }, {}),
      );

      chartData = users.map((userId) => ({
        data: charts
          .filter(({ _id: { patientId } }) => userId === patientId)
          .map(({ _id: { date }, ...rest }) => [+new Date(date), roundTo2(rest[type])]),
        type: 'area',
        name: `${patients[userId]?.firstName || ''} ${patients[userId]?.lastName || ''}`,
        ...getFillColor(),
      }));
    }

    if (chartType.value === ChartTypeOptionsEnum.SKU) {
      const skus = Object.values(
        charts.reduce((acum, item) => {
          acum[`${item._id.codigo} ${item._id.categoria}`] = item;
          return acum;
        }, {}),
      );

      chartData = skus.map(({ _id: { codigo, categoria } }) => ({
        data: charts
          .filter(({ _id: { codigo: lCodigo, categoria: lCategoria } }) => codigo === lCodigo && categoria === lCategoria)
          .map(({ _id: { date }, ...rest }) => [+new Date(date), roundTo2(rest[type])]),
        type: 'area',
        name: `${catalog[`${codigo} ${categoria}`]?.descripcion}. ${intl('catalogue.price_category')}: ${
          catalog[`${codigo} ${categoria}`]?.categoria
        }}`,
        ...getFillColor(),
      }));
    }

    console.log(chartData);
    return chartData;
  }, [charts, type, chartType, loading]);

  const opts = useMemo(
    () => ({
      ...baseOptions,
      yAxis: {
        title: {
          text: type === 'totalPrice' ? intl('charts.totalPrice') : intl('charts.count'),
        },
      },
      series: data,
    }),
    [data, type],
  );

  return (
    <div>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
        <Box width="400px" maxWidth="100%">
          <Autocomplete
            className={classes.input}
            label={intl('charts.view')}
            value={chartType}
            disableClearable
            onChange={onChartTypeChange}
            getOptionLabel={({ name }) => intl(name)}
            options={chartTypeOptions}
          />

          {chartType.value === ChartTypeOptionsEnum.USERS && (
            <Autocomplete
              value={selectedPatients}
              className={classes.input}
              multiple
              freeSolo={selectedPatients.length > 4}
              getOptionDisabled={() => selectedPatients.length > 4}
              label={intl('charts.users')}
              onChange={onPatientsChange}
              getOptionSelected={(option: any) => selectedPatients.map(({ _id }) => _id).includes(option._id)}
              getOptionLabel={({ firstName, lastName }) => `${firstName || ''}${lastName || ''}`}
              options={pArray}
            />
          )}

          {chartType.value === ChartTypeOptionsEnum.SKU && (
            <Autocomplete
              value={selectedCatalog}
              className={classes.input}
              multiple
              freeSolo={selectedCatalog.length > 4}
              getOptionDisabled={() => selectedCatalog.length > 4}
              label={intl('charts.skus')}
              onChange={onCatalogChange}
              getOptionSelected={(option: any) => selectedCatalog.map(({ _id }) => _id).includes(option._id)}
              getOptionLabel={({ descripcion, categoria }) => `${descripcion}. Price category: ${categoria}`}
              options={cArray}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>{intl('charts.totalPrice')}</Typography>
          <Switch checked={type === 'count'} onChange={onTypeChange} />
          <Typography>{intl('charts.count')}</Typography>
        </Box>

        <Box width="400px" maxWidth="100%">
          <KeyboardDatePicker
            fullWidth
            inputVariant="outlined"
            margin="normal"
            format="dd.MM.yyyy"
            label={intl('calendar.from')}
            onChange={onDateFromChange}
            value={dates.from}
          />
          <KeyboardDatePicker
            fullWidth
            inputVariant="outlined"
            margin="normal"
            format="dd.MM.yyyy"
            label={intl('calendar.to')}
            onChange={onDateToChange}
            value={dates.to}
          />
        </Box>
      </Box>

      {loading && <Loader height="300px" />}
      {!loading && charts.length ? <HighchartsReact highcharts={Highcharts} options={opts} /> : null}
      {!loading && !charts.length && (
        <Box height="300px" display="flex" alignItems="center" justifyContent="center">
          <Typography>{intl('charts.noData')}</Typography>
        </Box>
      )}
    </div>
  );
};
export default memo(Chart);
