export const chartsEn = {
  'charts.users': 'Users',
  'charts.view': 'View',
  'charts.totalPrice': 'Total Price',
  'charts.count': 'Count',
  'charts.skus': 'SKUs',
  'charts.allTime': 'All Time',
  'charts.chart': 'Chart',
  'charts.noData': 'No data with such parameters',
};

export const chartsEs = {
  'charts.users': 'Pacientes',
  'charts.view': 'Vista',
  'charts.totalPrice': 'Precio total',
  'charts.count': 'Contar',
  'charts.skus': 'SKUs',
  'charts.allTime': 'Todo el tiempo',
  'charts.chart': 'Gráfico',
  'charts.noData': 'No hay datos con tales parámetros',
};
