import { Button } from '@material-ui/core';
import { FileApi } from 'api/File';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { checkFileExtension } from 'utils/checkExtension';
import { showErrorToast } from 'utils/toast';
import { useTranslations } from 'utils/useTranslations';

interface Props {
  fieldName: string;
  className: string;
  user: { [key: string]: string };
  medicalRecord: { [key: string]: any };
}

const UploadFile: FC<Props> = ({ fieldName, user, medicalRecord, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { update } = useMedicalRecordFetch();
  const { recordId } = useParams<{ recordId: string }>();
  const intl = useTranslations();
  const onFileChange = useCallback(
    async (e) => {
      if (!e.target.files[0]) {
        return showErrorToast(intl('case.no_file_err'));
      }

      if (!checkFileExtension(e.target.files[0].name)) {
        return showErrorToast(intl('case.file_format_err'));
      }
      try {
        setIsLoading(true);
        const uploadedFile = await FileApi.uploadPublicMedicalRecordFile(recordId, e.target.files[0]);

        const newItem = {
          ...uploadedFile?.data,
          createdBy: `${user.firstName} ${user.lastName}`,
          userType: user.type,
          createdAt: new Date(),
          userId: user._id,
        };

        await update(recordId, {
          [fieldName]: [newItem, ...(Array.isArray(medicalRecord[fieldName]) ? medicalRecord[fieldName] : [])],
        });
      } catch (err) {
        const errorMessage = typeof err?.response?.data === 'string' ? err?.response?.data : err?.message || 'Server Error: Something went wrong';
        showErrorToast(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, update, user],
  );

  return (
    <Button disabled={isLoading} variant="contained" color="primary" component="label" {...rest}>
      {intl('catalogue.uploadFile')}
      <input accept="image/png, image/jpg, image/jpeg, application/pdf" type="file" onChange={onFileChange} hidden />
    </Button>
  );
};

export default UploadFile;
