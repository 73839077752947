import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_HOST;
// 'http://dev.hospitalprivadopeten-api.geniusee.space'

export default {
  async setBusinessHours(token, formData) {
    try {
      const { data } = await axios.post('/calendar/add_business_hours', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async addEvent(token, formData) {
    try {
      const { data } = await axios.post('/calendar/add_event', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async editEvent(token, formData) {
    try {
      const { data } = await axios.put('/calendar/edit', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async getEvents(token, formData) {
    try {
      if (formData) {
        const { data } = await axios.get('/calendar/events', {
          params: {
            ...formData,
          },
          headers: { Authorization: `Bearer ${token}` },
        });
        return data;
      }
      const { data } = await axios.get('/calendar/events', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async deleteEvent(token, formData) {
    try {
      const { data } = await axios.post('/calendar/delete_event', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getBusinessHours(token, formData) {
    try {
      if (formData) {
        const { data } = await axios.get('/calendar/business_hours', {
          params: {
            ...formData,
          },
          headers: { Authorization: `Bearer ${token}` },
        });
        return data && data.businessHours;
      }
      const { data } = await axios.get('/calendar/business_hours', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data?.businessHours;
    } catch (err) {
      throw err;
    }
  },
};
