import React, { FC } from 'react';
import { TableRow, TableCell, Hidden } from '@material-ui/core';
import { useTranslations } from 'utils/useTranslations';
import { useStyles } from '../styles';

interface PaymentTableTotalRowProps {
  total: number | string;
}

const PaymentTableTotalRow: FC<PaymentTableTotalRowProps> = ({ total }) => {
  const styles = useStyles();
  const intl = useTranslations();

  return (
    <TableRow style={{ border: 'none' }}>
      <TableCell className={styles.column}>{intl('case.total_payment')}</TableCell>
      <TableCell className={styles.column} />
      <TableCell className={styles.column}>{total}</TableCell>
      <TableCell className={styles.column} />
      <Hidden smDown>
        <TableCell className={styles.column} />
      </Hidden>
      <TableCell className={styles.column} />
    </TableRow>
  );
};

export default PaymentTableTotalRow;
