export const defaultFields = [
  {
    nameInModel: 'codigo',
    translationKey: 'catalogue.codigo',
  },
  {
    nameInModel: 'descripcion',
    translationKey: 'catalogue.descripcion',
  },
  {
    nameInModel: 'disponible',
    translationKey: 'catalogue.disponible',
  },
  {
    nameInModel: 'nombreBodega',
    translationKey: 'catalogue.nombreBodega',
  },
  {
    nameInModel: 'price',
    translationKey: 'catalogue.price',
  },
  {
    nameInModel: 'medida',
    translationKey: 'catalogue.medida',
  },
  {
    nameInModel: 'category',
    translationKey: 'catalogue.category',
  },
  {
    nameInModel: 'subcategory',
    translationKey: 'catalogue.subcategory',
  },
  {
    nameInModel: 'categoria',
    translationKey: 'catalogue.price_category',
  },
  {
    nameInModel: 'status',
    translationKey: 'catalogue.status',
  },
  {
    nameInModel: 'uso',
    translationKey: 'catalogue.uso',
  },
  {
    nameInModel: 'principioActivo',
    translationKey: 'catalogue.principioActivo',
  },
];

export const restrictedFieldNames = ['price', 'status'];
