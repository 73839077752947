import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Button, Box, Divider, Hidden } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { format } from 'date-fns';
import usePermissions from 'utils/usePermissions';
import { useTranslations } from 'utils/useTranslations';
import { convertDate } from 'utils/formatters';
import { useStyles } from './styles';
import { PatientCaseListItemProps } from './types';

interface ParamType {
  patient: string;
}

const PatientCaseListItem = ({ caseItem, onDelete, deleteIsLoading }: PatientCaseListItemProps) => {
  const intl = useTranslations();
  const styles = useStyles();
  const { patient } = useParams<ParamType>();
  const { canCrudCasesAndRecords } = usePermissions();

  return (
    <Box>
      <Box>
        <Typography className={styles.caseHeader}>
          {intl('patient.case')} {convertDate(caseItem.name, 'dd.MM.yyyy HH:mm')}
        </Typography>
      </Box>
      <Divider />
      <Box className={styles.caseBoxItem}>
        <Box className={styles.caseInnerItem}>
          <Typography className={styles.contentText}>{intl('case.admission_date')}</Typography>
          <Typography className={styles.contentText}>{format(new Date(caseItem.createdAt), 'dd.MM.yyyy')}</Typography>
        </Box>
        <Box className={styles.caseInnerItem}>
          <Typography className={styles.contentText}>{intl('case.discharge_date')}</Typography>
          <Typography className={styles.contentText}>{(caseItem.checkOut && format(new Date(caseItem.checkOut), 'dd.MM.yyyy')) || '-'}</Typography>
        </Box>
        <Box className={styles.caseInnerItem}>
          <Typography className={styles.contentText}>{intl('case.estimate')}</Typography>
          <Typography className={styles.contentText}>{caseItem.budget ? intl(`case.budgetOption.${caseItem.budget}`) : '-'}</Typography>
        </Box>

        <Hidden xsDown>
          {/* eslint-disable-next-line */}
          {onDelete ? (
            canCrudCasesAndRecords(patient) ? (
              <Button disabled={deleteIsLoading} variant="contained" className={styles.resetLinkDecoration} color="secondary" onClick={onDelete}>
                {intl('shared.delete')}
              </Button>
            ) : null
          ) : (
            <Link to={`/user/patient_records/${patient}/cases/${caseItem._id}`} className={styles.resetLinkDecoration}>
              <Button variant="contained" color="primary">
                {intl('view.case')}
              </Button>
            </Link>
          )}
        </Hidden>
        <Hidden smUp>
          {onDelete ? (
            canCrudCasesAndRecords(patient) && (
              <Button disabled={deleteIsLoading} variant="contained" color="secondary" className={styles.resetLinkDecoration} onClick={onDelete}>
                <Delete />
              </Button>
            )
          ) : (
            <Link to={`/user/patient_records/${patient}/cases/${caseItem._id}`} className={styles.resetLinkDecoration}>
              <Button variant="contained" color="primary">
                {intl('view.case')}
              </Button>
            </Link>
          )}
        </Hidden>
      </Box>
    </Box>
  );
};

export default PatientCaseListItem;
