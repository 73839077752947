import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_HOST;
// 'http://dev.hospitalprivadopeten-api.geniusee.space'

export default {
  async addMedicalRecord(token, formData) {
    try {
      const { data } = await axios.post('/medical_record', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async editMedicalRecord(token, formData) {
    try {
      const { data } = await axios.put('/medical_record', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async addCommentToRecord(token, formData) {
    try {
      const { data } = await axios.put('/medical_record/comment', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async setPayStatus(token, formData) {
    try {
      const { data } = await axios.put('/medical_record/set_pay_status', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async closeExam(token, formData) {
    try {
      const { data } = await axios.put('/medical_record/close_exam', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async deleteRecord(token, formData) {
    try {
      const { data } = await axios.delete('/medical_record', {
        data: formData,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getByPatientId(token, patientId) {
    try {
      const { data } = await axios.get(`/medical_record?patientId=${patientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
};
