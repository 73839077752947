import { Box, Button, Step, StepLabel, Stepper } from '@material-ui/core';
import Axios from 'axios';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { useStoreActions } from 'easy-peasy';
import { showErrorToast } from 'utils/toast';
import _ from 'lodash';
import { FormController } from './FormController';
import parseQuestionnaireForm from './parse';
import { questioneer } from './questioneer';

const QUESTIONEER_LS_KEY = 'questioneer';
const QUESTIONEER_PAGE_LS_KEY = 'questioneer_page';

const Covid19 = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const history = useHistory();
  const addCovidThunk: any = useStoreActions((actions) => actions.addCovidThunk);
  const questioneerLsKeyWithCase = `${QUESTIONEER_LS_KEY}_${caseId}`;
  const questioneerLsPageKeyWithCase = `${QUESTIONEER_PAGE_LS_KEY}_${caseId}`;

  const [activeStep, setActiveStep] = useState(+localStorage.getItem(questioneerLsPageKeyWithCase) || 0);

  const onScrollToError = useCallback((name: string) => {
    const section = document.querySelector(`#${name}`);
    const y = section.getBoundingClientRect().top + window.pageYOffset - 80;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }, []);

  const { initValues, validationSchema } = useMemo(
    () =>
      questioneer.reduce(
        (acum, curValue) => {
          curValue.questions.forEach(({ name, control, validation }: any) => {
            if (control === 'checkbox') {
              acum.initValues[name] = [];
            } else if (control === 'date') {
              acum.initValues[name] = new Date();
              acum.validationSchema[name] = validation || string().required();
            } else {
              acum.initValues[name] = undefined;
              acum.validationSchema[name] = validation || string().required();
            }
          });
          return acum;
        },
        { initValues: {}, validationSchema: {} },
      ),
    [],
  );

  const { values, handleChange, errors, touched, setErrors, handleSubmit, validateForm, setTouched } = useFormik({
    initialValues: localStorage.getItem(questioneerLsKeyWithCase) ? JSON.parse(localStorage.getItem(questioneerLsKeyWithCase)) : initValues,
    validationSchema: object().shape(validationSchema),

    async onSubmit(formValues) {
      try {
        await addCovidThunk({ caseId, covidInfo: parseQuestionnaireForm(formValues) });
      } catch (err) {
        showErrorToast(err);
      } finally {
        localStorage.removeItem(questioneerLsKeyWithCase);
        localStorage.removeItem(questioneerLsPageKeyWithCase);
        history.goBack();
      }
      // .finally(() => {
      //   localStorage.removeItem(questioneerLsKeyWithCase);
      //   localStorage.removeItem(questioneerLsPageKeyWithCase);
      // });
    },
  });

  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);

  // useEffect(() => {
  //   Axios.get('https://api.testforcov19.org/api/v1/country').then(({ data }) =>
  //     setCountries(data.countries?.map(({ id, name }) => ({ label: name, value: name, id }))),
  //   );
  // }, []);

  // useEffect(() => {
  //   if (values.country) {
  //     setFieldValue('region', '');
  //     const country = countries.find((c) => c.value === values.country);
  //     Axios.get(`https://api.testforcov19.org/api/v1/region?country_id=${country.id}`).then(({ data }) =>
  //       setRegions(data.region?.map(({ name }) => ({ label: name, value: name }))),
  //     );
  //   }
  // }, [values.country, countries]);

  const validatePage = useCallback(async () => {
    const resultOfValidation = await validateForm();
    let isError = false;
    const questionNames = questioneer[activeStep].questions.map(({ name }) => name);

    for (const key in resultOfValidation) {
      if (questionNames.includes(key) && !isError) {
        isError = true;
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          onScrollToError(key);
        }
      }
    }

    return !isError;
  }, [validateForm, activeStep]);

  const handlePageSubmit = useCallback(() => {
    validatePage().then((success) => {
      if (success && activeStep !== questioneer.length - 1) {
        localStorage.setItem(questioneerLsKeyWithCase, JSON.stringify(values));
        localStorage.setItem(questioneerLsPageKeyWithCase, `${activeStep + 1}`);
        setActiveStep((step) => step + 1);
        setErrors({});
      } else if (!success) {
        setTouched(_.assign({}, ...questioneer[activeStep].questions.map(({ name }) => ({ [name]: true }))));
      } else {
        handleSubmit();
      }
    });
  }, [validatePage, values]);

  const handlePageBack = useCallback(() => setActiveStep((step) => step - 1), [setActiveStep]);

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {questioneer.map((pageInfo, i) => (
          <Step id={`${i}`} key={pageInfo.pageName}>
            <StepLabel>{pageInfo.pageName}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box display="flex" flexDirection="column">
        {questioneer[activeStep].questions.map((question) => (
          <Box mb="2em" key={question.name + question.control}>
            <FormController
              {...question}
              // eslint-disable-next-line no-nested-ternary
              options={question.name === 'region' ? regions : question.name === 'country' ? countries : question.options}
              loadOptions={question.loadOptions}
              dynamicOptionsParams={values[question.dynamicParams]}
              onChange={handleChange}
              touched={touched[question.name]}
              error={errors[question.name]}
              value={values[question.name]}
            />
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="center" mb="2em">
        {activeStep !== 0 && (
          <Box mr="2em">
            <Button color="primary" variant="contained" onClick={handlePageBack}>
              Prev
            </Button>
          </Box>
        )}
        <Button color="primary" variant="contained" onClick={handlePageSubmit}>
          Next
        </Button>
      </Box>
    </div>
  );
};
export default Covid19;
