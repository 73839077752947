import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Hidden } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormattedMessage } from 'react-intl';
import DateFnsUtils from '@date-io/date-fns';
import { parseForDatePickers, parseForSend } from '../../../utils/parseTimeForCalendar';

export default function BusinessTimePicker({ businessHours, onApply }) {
  const [businessTimeIsLoading, setBusinessTimeIsLoading] = useState(false);
  const [daysSchedule, setDaysSchedule] = useState([
    {
      daysOfWeek: 'Lunes',
      startTime: 0,
      endTime: 0,
    },
    {
      daysOfWeek: 'Martes',
      startTime: 0,
      endTime: 0,
    },
    {
      daysOfWeek: 'Miercoles',
      startTime: 0,
      endTime: 0,
    },
    {
      daysOfWeek: 'Jueves',
      startTime: 0,
      endTime: 0,
    },
    {
      daysOfWeek: 'Viernes',
      startTime: 0,
      endTime: 0,
    },
    {
      daysOfWeek: 'Sabado',
      startTime: 0,
      endTime: 0,
    },
    {
      daysOfWeek: 'Domingo',
      startTime: 0,
      endTime: 0,
    },
  ]);

  useEffect(() => {
    if (businessHours && businessHours.length) {
      setDaysSchedule(parseForDatePickers(businessHours));
    }
  }, [businessHours]);

  const handleDaysScheduleChange = (i, startOrEnd, date) => {
    const daysScheduleTemp = [...daysSchedule];
    daysScheduleTemp[i][startOrEnd] = date;
    setDaysSchedule(daysScheduleTemp);
  };

  const onSend = async () => {
    try {
      setBusinessTimeIsLoading(true);
      await onApply(parseForSend(daysSchedule));
      setBusinessTimeIsLoading(false);
    } catch (err) {
      setBusinessTimeIsLoading(false);
    }
  };

  return (
    <Box marginBottom={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Hidden xsDown>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            {daysSchedule.map((day, i) => (
              <Box key={day.daysOfWeek} display="flex" flexDirection="column">
                <Typography>
                  <FormattedMessage id={`calendar.${day.daysOfWeek}`} />
                </Typography>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label={<FormattedMessage id="calendar.from" description="From" />}
                  value={day.startTime}
                  onChange={(date) => handleDaysScheduleChange(i, 'startTime', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label={<FormattedMessage id="calendar.to" description="To" />}
                  value={day.endTime}
                  onChange={(date) => handleDaysScheduleChange(i, 'endTime', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Hidden>
        <Hidden smUp>
          <Box display="flex" justifyContent="space-between" flexDirection="column" width="90%" paddingLeft="10px">
            {daysSchedule.map((day, i) => (
              <Box key={day.daysOfWeek} display="flex" flexDirection="column">
                <Typography>
                  <FormattedMessage id={`calendar.${day.daysOfWeek}`} />
                </Typography>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label={<FormattedMessage id="calendar.from" description="From" />}
                  value={day.startTime}
                  onChange={(date) => handleDaysScheduleChange(i, 'startTime', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label={<FormattedMessage id="calendar.to" description="To" />}
                  value={day.endTime}
                  onChange={(date) => handleDaysScheduleChange(i, 'endTime', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Hidden>
      </MuiPickersUtilsProvider>
      <Button disabled={businessTimeIsLoading} onClick={onSend} color="primary" variant="contained">
        <FormattedMessage id="calendar.apply" description="Apply" />
      </Button>
    </Box>
  );
}
