import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Button, Box, Divider } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import { MedicalRecordType } from 'types/medicalRecord';
import { useTranslations } from 'utils/useTranslations';
import { format, isValid } from 'date-fns';
import { ParamType } from './types';
import { useStyles } from './styles';
import { CaseListItem } from '../PatientCases/types';

const MedicalRecordListItem = ({ medicalRecord, inCase = true }: { medicalRecord: MedicalRecordType; inCase?: boolean }) => {
  const intl = useTranslations();
  const styles = useStyles();
  const { patient, caseId } = useParams<ParamType>();

  const caseItem: CaseListItem = useStoreState((state) => state.cases?.getById(caseId));

  return (
    <>
      <Box className={styles.caseBoxItem}>
        <Box className={styles.caseBoxIndent}>
          <Typography className={styles.contentText}>{intl('medical_record.date_name')}</Typography>
          <Typography className={styles.contentText}>
            {isValid(new Date(medicalRecord?.name)) ? format(new Date(medicalRecord?.name), 'dd.MM.yyyy') : medicalRecord?.name}
          </Typography>
        </Box>
        <Box className={styles.caseBoxIndent}>
          <Typography className={styles.contentText}>{intl('medicalRecord.author')}</Typography>
          <Typography className={styles.contentText}>
            {medicalRecord?.createdBy?.firstName} {medicalRecord?.createdBy?.lastName}
          </Typography>
        </Box>
        <Link
          to={`/user/patient_records/${patient}/${inCase ? 'cases' : 'accounts'}/${caseItem?._id}/medical_records/${medicalRecord?._id}`}
          className={styles.resetLinkDecorationRecords}
        >
          <Button className={styles.caseBtnText} variant="contained" color="primary">
            {intl('medicalRecord.viewMedRecord')}
          </Button>
        </Link>
      </Box>
      <Divider />
    </>
  );
};

export default MedicalRecordListItem;
