import { DiscountItem } from './discountTable/discount.type';

export const countTotals = (total: number = 0, paidTotal: number = 0, discounts: DiscountItem[]) => {
  const percentageDiscount = discounts?.find(({ discountType }) => discountType === 'percentage');

  let discountNumberTotal = discounts?.filter(({ discountType }) => discountType === 'number').reduce((acum, { amount }) => acum + amount, 0);

  let totalLeftToPay = total;

  const totalToPay = total;

  if (percentageDiscount) {
    discountNumberTotal += (totalToPay * percentageDiscount.amount) / 100;
  }

  totalLeftToPay = totalToPay - paidTotal - discountNumberTotal;

  return {
    totalLeftToPay,
    totalToPay,
    discountNumberTotal,
  };
};
