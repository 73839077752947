import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useCallback, useState } from 'react';
import { PaymentType } from 'types/paymentTypes';
import PaymentTypeEditModal from './PaymentTypeEditModal';

interface PaymentTypeItemProps {
  paymentType: PaymentType;
}

const PaymentTypeItem: FC<PaymentTypeItemProps> = ({ paymentType }) => {
  const [open, setIsOpen] = useState(false);

  const onOpenModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onCloseModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <>
      <TableRow>
        <TableCell>{paymentType.name}</TableCell>
        <TableCell>{paymentType.value}</TableCell>
        <TableCell>
          <IconButton onClick={onOpenModal}>
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
      <PaymentTypeEditModal initialValues={paymentType} onClose={onCloseModal} open={open} />
    </>
  );
};

export default PaymentTypeItem;
