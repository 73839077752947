import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStoreState } from 'easy-peasy';

import authFetch from 'api/Authorization';
import { useTranslations } from 'utils/useTranslations';
import MaterialSelect from './Select';

const AddPatientModal = ({ open = false, handleClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const intl = useTranslations();

  const paymentTypes = useStoreState((state) => state.paymentTypes);

  const submitValues = async (values, formContext) => {
    try {
      setLoading(true);
      const response = await authFetch.signUp(localStorage.getItem('accessToken'), values);
      setLoading(false);
      if (!response.error) {
        onSuccess(response);
        formContext.resetForm();
        handleClose();
      } else if (response.error.data === 'User already exists') {
        formContext.setFieldError('email', intl('error.user_already_exists'));
      }
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, resetForm, errors, touched } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      type: 'patient',
      paymentType: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      email: Yup.string().email(),
      phone: Yup.string().required(),
      type: Yup.string().equals(['patient']),
      paymentType: Yup.string().required(),
    }),
    onSubmit: submitValues,
  });

  const clearAndClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={clearAndClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{intl('patient_modal.add_new_patient')}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <TextField
              onChange={handleChange}
              fullWidth
              name="firstName"
              error={touched.firstName && errors.firstName}
              value={values.firstName}
              label={intl('user.first_name')}
              variant="outlined"
            />
            <TextField
              fullWidth
              style={{ marginTop: '20px' }}
              onChange={handleChange}
              value={values.lastName}
              error={touched.lastName && errors.lastName}
              name="lastName"
              variant="outlined"
              label={intl('user.last_name')}
            />
          </Box>
          <Box marginY="20px">
            <TextField
              onChange={handleChange}
              fullWidth
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
              name="email"
              value={values.email}
              variant="outlined"
              label={intl('user.email_adress')}
            />
            <TextField
              style={{ marginTop: '20px' }}
              onChange={handleChange}
              fullWidth
              type="number"
              name="phone"
              error={touched.phone && errors.phone}
              value={values.phone}
              variant="outlined"
              label={intl('user.phone')}
            />
          </Box>
          <MaterialSelect
            style={{ marginBottom: '20px' }}
            options={paymentTypes}
            onChange={handleChange}
            value={values.paymentType}
            error={touched.paymentType && errors.paymentType}
            name="paymentType"
            variant="outlined"
            title={intl('user.payment_type')}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={clearAndClose} color="secondary">
            {intl('services.cancel')}
          </Button>
          <Button disabled={loading} color="primary" type="submit">
            {intl('services.add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPatientModal;
