import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import { useTranslations } from 'utils/useTranslations';
import { useParams } from 'react-router-dom';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import { roundTo2 } from 'utils/roundTo2';
import * as yup from 'yup';
import { valiadateFloatWithTwoDigits } from 'utils/floatWithTwoDigits';

interface Props {
  open: boolean;
  onClose: () => void;
  skuItem: any;
}
interface ParamType {
  caseId: string;
  recordId: string;
}

interface INewCaseForm {
  precio: number;
}

export const ChangePriceModal = ({ open, skuItem, onClose }: Props) => {
  const { updateAssignedCatalogItemPrice } = useMedicalRecordFetch();
  const { recordId } = useParams<ParamType>();
  const [loading, setLoading] = useState(false);
  const intl = useTranslations();
  const { values, errors, touched, handleSubmit, handleChange } = useFormik<INewCaseForm>({
    initialValues: {
      precio: roundTo2(skuItem.precio),
    },
    validationSchema: yup.object().shape({
      precio: yup
        .number()
        .test('float', intl('error.incorrect_symbols'), function () {
          // eslint-disable-next-line react/no-this-in-sfc
          return valiadateFloatWithTwoDigits(+this.parent.precio);
        })
        .required(),
    }),
    enableReinitialize: true,
    async onSubmit(formValues, formContext) {
      try {
        setLoading(true);
        const response = await updateAssignedCatalogItemPrice({
          precio: formValues.precio,
          medicalRecordId: recordId,
          id: skuItem._id,
        });
        console.log(response);
        formContext.resetForm();
        onClose();
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{intl('medicalRecord.addNewRecord')}</DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          helperText={touched.precio && errors.precio}
          error={touched.precio && Boolean(errors.precio)}
          value={values.precio}
          onChange={handleChange}
          name="precio"
          label={intl('shared.price')}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="secondary">
          {intl('shared.cancel')}
        </Button>
        <Button disabled={loading} color="primary" onClick={() => handleSubmit()}>
          {intl('shared.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
