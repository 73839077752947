import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import React from 'react';
import { PaymentType } from 'types/paymentTypes';
import { useTranslations } from 'utils/useTranslations';
import PaymentTypeItem from './PaymentTypeItem';

const PaymentTypeTable = () => {
  const paymentTypes = useStoreState((state) => state.paymentTypes) as PaymentType[];
  const intl = useTranslations();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{intl('paymentTypes.name')}</TableCell>
            <TableCell>{intl('paymentTypes.value')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentTypes.map((pt) => (
            <PaymentTypeItem key={pt._id} paymentType={pt} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentTypeTable;
