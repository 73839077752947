export const baseOptions = {
  chart: {
    zoomType: 'x',
  },

  title: { text: 'SKU Gráfico' },
  xAxis: {
    type: 'datetime',
  },
  yAxis: {
    title: {
      text: 'Precio',
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    area: {
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },
};

export enum ChartTypeOptionsEnum {
  ALL_TIME = 'All Time',
  USERS = 'Users',
  SKU = 'SKUs',
}

export const chartTypeOptions = [
  { value: ChartTypeOptionsEnum.ALL_TIME, name: 'charts.allTime' },
  { value: ChartTypeOptionsEnum.USERS, name: 'charts.users' },
  { value: ChartTypeOptionsEnum.SKU, name: 'charts.skus' },
];
