import React, { useState } from 'react';
import {
  Container,
  Tabs,
  Tab,
  AppBar,
  TextField,
  Box,
  Button,
  Menu,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTranslations } from 'utils/useTranslations';
import UniTable from 'components/UniTable';
import usePermissions from 'utils/usePermissions';

import useStyles from './style';
import MaterialDialog from '../../../components/Dialog';
import getPermissions from '../../../utils/getPermissions';
import authFetch from '../../../api/Authorization';
import AddDoctorsModal from '../../../components/AddDoctorsModal';
import AddAdminModal from '../../../components/AddAdminModal';
import userFetch from '../../../api/User';

export default function MedicalProfessionals() {
  const style = useStyles();
  const accessToken = localStorage.getItem('accessToken');
  const [value, setValue] = useState('doctor');

  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAddBtn, setAnchorAddBtn] = useState(null);
  const [medicalProfessional, setMedicalProfessional] = useState({});
  const [dialog, setDialog] = useState(false);
  const [deleteUserIsLoading, setDeleteUserIsLoading] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const theme = useTheme();
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const intl = useTranslations();
  const history = useHistory();
  const { canCrudSpecialist, canViewCalendar, canHaveCalendar } = usePermissions();
  const closeDialog = () => {
    setDialog(false);
  };

  const openDialog = () => {
    setDialog(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorAddBtn(null);
  };
  const openModal = (modal) => {
    setModalOpened(modal);
    handleClose();
  };
  const closeModal = () => setModalOpened(false);

  const handleClick = (event, user) => {
    setMedicalProfessional(user);
    setAnchorEl(event.currentTarget);
  };

  const usersData = useStoreState((state) => state.users);
  const userData = useStoreState((state) => state.user);
  const setUsersData = useStoreActions((actions) => actions.setUsersData);
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const onDeleteProfilePress = (professional) => {
    openDialog();
    setMedicalProfessional(professional);
  };

  const onDeleteActionPress = async () => {
    try {
      setDeleteUserIsLoading(true);
      await userFetch.deleteProfile(localStorage.getItem('accessToken'), {
        userId: medicalProfessional._id,
      });
      setDeleteUserIsLoading(false);
      const tempUsers = usersData.filter((user) => user._id !== medicalProfessional._id);
      setUsersData(tempUsers);
      setAnchorEl(null);
      closeDialog();
      setMedicalProfessional({});
    } catch (err) {
      setDeleteUserIsLoading(false);
    }
  };

  const registerUser = async (data) => {
    try {
      const response = await authFetch.signUp(accessToken, data);
      setUsersData([...usersData, response]);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  const dialogActions = [
    {
      name: <FormattedMessage id="calendar.cancel" />,
      onClick: closeDialog,
      color: 'secondary',
      disabled: deleteUserIsLoading,
    },
    {
      name: <FormattedMessage id="users.confirm_removing" />,
      onClick: onDeleteActionPress,
      color: 'primary',
      disabled: deleteUserIsLoading,
    },
  ];

  const columns = [
    { name: 'firstName', title: intl('user.first_name') },
    { name: 'lastName', title: intl('user.last_name') },
    { name: 'email', title: intl('user.email_adress') },
    { name: 'phone', title: intl('user.phone') },
    { name: 'role', title: intl('doctor.role') },
    { name: 'collegiateNumber', title: intl('doctor.collegiate_number') },
    { name: 'title', title: intl('doctor.title'), valueGetter: (title) => title && intl(`doctor.title.${title}`) },
    { name: 'skills', title: intl('doctor.skills') },
  ];

  const smColumns = [
    {
      name: '',
      title: intl('user.name'),
      valueGetter: ({ lastName, firstName }) => `${firstName || ''} ${lastName || ''}`,
      headerClassName: style.nameCell,
      bodyClassName: style.nameCell,
    },
    { name: 'email', title: intl('user.email_adress'), headerClassName: style.emailCell, bodyClassName: style.emailCell },
  ];

  const tabs = [
    { name: 'doctor', title: intl('position.doctor') },
    { name: 'pharmacist', title: intl('position.pharmacist') },
    { name: 'nurse', title: intl('position.nurse') },
    { name: 'technician_radiology', title: intl('position.technician_radiology') },
    { name: 'technician_labs', title: intl('position.technician_labs') },
    { name: 'admin', title: intl('position.admin') },
    { name: 'staff', title: intl('position.staff') },
  ];

  if (canCrudSpecialist || canViewCalendar) {
    const popoverTableItem = {
      name: '',
      title: '',
      headerClassName: style.horizIconCell,
      bodyClassName: style.horizIconCell,
      valueGetter: (user) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e, user);
          }}
          className={style.smHorizIconButton}
        >
          <MoreVertIcon />
        </Button>
      ),
    };
    columns.push(popoverTableItem);
    smColumns.push(popoverTableItem);
  }

  const rows = usersData
    ? usersData.filter((user) => {
        if (searchValue.length > 1) {
          return Object.values(user).some((item) => item.toString().toLowerCase().includes(searchValue.toLowerCase()));
        }
        return user.type === value;
      })
    : [];

  const onClickAction = ({ _id }) => {
    history.push(`/user/medical_professionals/profile/${_id}`);
  };

  return (
    <Container component="main">
      <Box className={style.buttonCont} display="flex" flexWrap="wrap">
        <FormattedMessage id="position.find_specialist" description="Search">
          {(msg) => (
            <TextField
              className={style.search}
              margin="normal"
              label={<FormattedMessage id="user.search" description="Search" />}
              placeholder={msg}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}
        </FormattedMessage>
        {canCrudSpecialist && (
          <Button
            className={style.button}
            variant="contained"
            color="primary"
            onClick={(e) => {
              setAnchorAddBtn(e.currentTarget);
            }}
          >
            <FormattedMessage id="auth.add_admin" description="Add new user" />
          </Button>
        )}

        <Popover
          anchorEl={anchorAddBtn}
          style={{ width: '100%' }}
          open={Boolean(anchorAddBtn)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => {
            setAnchorAddBtn(null);
          }}
        >
          <Box display="flex" style={{ width: '100%' }} flexDirection="column">
            {getPermissions(userData.type, ['admin', 'staff']) && (
              <>
                <Button fullWidth onClick={() => openModal('doctor')}>
                  <FormattedMessage id="position.doctor" />
                </Button>
                <Button fullWidth onClick={() => openModal('admin')}>
                  <FormattedMessage id="position.admin" />
                </Button>
              </>
            )}
          </Box>
        </Popover>
      </Box>

      <AppBar className={style.appBar} position="static" color="primary">
        <Tabs
          className={style.tabs}
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#3f51b5',
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.name} className={style.tab} value={tab.name} label={tab.title} />
          ))}
        </Tabs>
      </AppBar>

      <UniTable className={style.table} columns={matchesSmDown ? smColumns : columns} rows={rows} onRowClick={onClickAction} keyMapper="_id" />

      <AddDoctorsModal open={modalOpened === 'doctor'} handleClose={closeModal} registerUser={registerUser} />
      <AddAdminModal open={modalOpened === 'admin'} registerUser={registerUser} handleClose={closeModal} />

      <MaterialDialog
        title={
          medicalProfessional && (
            <FormattedMessage
              id="remove_user_popup.remove_confirm_msg"
              values={{
                name: `${medicalProfessional.firstName} ${medicalProfessional.lastName}`,
              }}
            />
          )
        }
        actions={dialogActions}
        opened={dialog}
        onClose={closeDialog}
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {canViewCalendar && canHaveCalendar(medicalProfessional.type) && (
          <MenuItem onClick={handleClose}>
            <Link to={`/user/medical_professionals/calendar/${medicalProfessional._id}`}>
              <FormattedMessage id="calendar" description="Calendar" />
            </Link>
          </MenuItem>
        )}
        {canCrudSpecialist && (
          <MenuItem onClick={() => onDeleteProfilePress(medicalProfessional)}>
            <Typography color="secondary">
              <FormattedMessage id="user.remove" description="Remove" />
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Container>
  );
}
