import React from 'react';
import { Table, TableBody } from '@material-ui/core';
import { Payment } from 'pages/User/PatientCases/types';
import PaymentTableHead from './PaymentTableHead';
import { useStyles } from '../styles';
import PaymentTableRow from './PaymentTableRow';
import PaymentTableTotalRow from './PaymentTableTotalRow';

type PaymentTableProps = {
  payments: Payment[];
  total: string | number;
};

const PaymentTable = ({ payments = [], total }: PaymentTableProps) => {
  const styles = useStyles();
  return (
    <Table className={styles.table}>
      <PaymentTableHead />
      <TableBody>
        {payments.map((paymentItem) => (
          <PaymentTableRow {...paymentItem} key={paymentItem._id} />
        ))}
        <PaymentTableTotalRow total={total} />
      </TableBody>
    </Table>
  );
};

export default PaymentTable;
