import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    minWidth: 400,
  },
  title: {
    marginTop: theme.spacing(8),
  },
  appBar: {
    width: '100%',
    background: 'inherit',
    boxShadow: 'none',
    marginBottom: 30,
  },
  tabs: {
    background: 'inherit',
    border: 0,
    borderRadius: 3,
    height: 48,
    padding: '0 30px',
    color: 'white',
    paddingLeft: 0,
  },
  button: {
    '&:hover': {
      border: 'none',
    },
  },
  tab: {
    '&:hover': {
      border: 0,
      outline: 'none',
    },
    '&:focus': {
      border: 0,
      outline: 'none',
    },
  },

  formControl: {
    width: 200,
  },
}))

export default useStyles
