import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { priceCategoryTopaymentMethodMap } from 'constants/paymentMethods';
import { useTranslations } from 'utils/useTranslations';
import { valiadateFloatWithTwoDigits } from 'utils/floatWithTwoDigits';
import { roundTo2 } from 'utils/roundTo2';
import cataloguesFetch from 'api/Catalogue';
import MaterialSelect from 'components/Select';
import { Delete } from '@material-ui/icons';
import _ from 'lodash';

const useStyles = makeStyles({
  fileUpload: {
    width: '100%',
    height: 200,
    borderRadius: 8,
    border: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 18,
    marginBottom: 18,
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '100%',
    },
  },
  deleteButton: {
    position: 'absolute',
    top: 18,
    right: 18,
  },
});

export const CatalogueAddModal = ({ open, currentCatalogItem: currentCatalogItemProp, handleClose, toggle, onCatalogUpdateAction }) => {
  const currentCatalogItem = currentCatalogItemProp || {};
  const intl = useTranslations();
  const classes = useStyles();

  const [imageUploading, setImageUploading] = useState(false);
  const [imageRnd, setImageRnd] = useState(Math.random());

  const createOrUpdate = async (formValues, formCtx) => {
    try {
      if (!currentCatalogItemProp) {
        const response = await cataloguesFetch.addCatalogue(localStorage.getItem('accessToken'), { ...formValues, image: '' });
        const imageUrl = typeof formValues.image === 'string' ? formValues.image : await uploadImage(formValues.image, response.data._id);
        const updateResponse = await cataloguesFetch.editCatalogue(
          localStorage.getItem('accessToken'),
          _.omit(
            {
              ...response.data,
              catalogItemId: response.data._id,
              image: imageUrl,
            },
            ['createdAt', 'status', '_id'],
          ),
        );
        onCatalogUpdateAction(updateResponse);
      } else {
        const imageUrl = typeof formValues.image === 'string' ? formValues.image : await uploadImage(formValues.image, currentCatalogItem._id);
        const response = await cataloguesFetch.editCatalogue(localStorage.getItem('accessToken'), {
          ...formValues,
          catalogItemId: currentCatalogItem._id,
          image: imageUrl,
        });
        onCatalogUpdateAction(response);
      }

      formCtx.resetForm();
      toggle();
    } catch (err) {
      console.log(err);
    }
  };

  const { values, handleChange, setFieldValue, errors, touched, handleSubmit, resetForm } = useFormik({
    initialValues: {
      compania: currentCatalogItem.compania,
      codigo: currentCatalogItem.codigo,
      descripcion: currentCatalogItem.descripcion,
      disponible: currentCatalogItem.disponible,
      bodega: currentCatalogItem.bodega,
      nombreBodega: currentCatalogItem.nombreBodega,
      precio: roundTo2(currentCatalogItem.precio),
      medida: currentCatalogItem.medida,
      category: currentCatalogItem.category,
      subcategory: currentCatalogItem.subcategory,
      categoria: currentCatalogItem.categoria,
      uso: currentCatalogItem.uso,
      principioActivo: currentCatalogItem.principioActivo,
      image: currentCatalogItem.image || '',
    },
    validationSchema: Yup.object({
      codigo: Yup.number().required(),
      descripcion: Yup.string().required(),
      disponible: Yup.number().required(),
      nombreBodega: Yup.string().required(),
      precio: Yup.number()
        .test('float', intl('error.incorrect_symbols'), function () {
          // eslint-disable-next-line react/no-this-in-sfc
          return valiadateFloatWithTwoDigits(+this.parent.precio);
        })
        .required(),
      medida: Yup.string().required(),
      category: Yup.string().required(),
      subcategory: Yup.string().required(),
      categoria: Yup.number().required(),
      bodega: Yup.number().required(),
      compania: Yup.number().required(),
      uso: Yup.string(),
      principioActivo: Yup.string(),
      image: Yup.mixed(),
    }),
    onSubmit: createOrUpdate,
    enableReinitialize: true,
  });

  const uploadImage = async (imageFile, catalogId) => {
    try {
      setImageUploading(true);
      const fd = new FormData();
      fd.append('file', imageFile);
      const response = await cataloguesFetch.uploadImage(fd, catalogId);
      return response;
    } finally {
      setImageUploading(false);
    }
  };

  const addImage = async (e) => {
    try {
      setFieldValue('image', e.target.files[0]);
      setImageRnd(Math.random());
    } finally {
      setImageUploading(false);
    }
  };

  const deleteImage = async () => {
    setFieldValue('image', '');
  };

  const toggleWithReset = () => {
    resetForm();
    handleClose();
  };

  const imageUrl = useMemo(() => {
    if (values?.image) {
      return typeof values.image === 'string' ? `${values?.image}?${imageRnd}` : URL.createObjectURL(values?.image);
    }
  }, [values.image, imageRnd]);

  return (
    <Dialog open={open} onClose={toggleWithReset} style={{ width: '100%' }}>
      <DialogTitle>{intl('catalogue.add_new_item')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl('catalogue.provide_info')}</DialogContentText>

        <TextField
          margin="dense"
          id="codigo"
          name="codigo"
          label={intl('catalogue.codigo')}
          type="number"
          error={touched.codigo && errors.codigo}
          onChange={handleChange}
          value={values.codigo}
          fullWidth
        />
        <TextField
          margin="dense"
          name="descripcion"
          id="descripcion"
          onChange={handleChange}
          error={touched.descripcion && errors.descripcion}
          label={intl('catalogue.descripcion')}
          value={values.descripcion}
          type="text"
          fullWidth
        />
        <TextField
          name="disponible"
          margin="dense"
          value={values.disponible}
          error={touched.disponible && errors.disponible}
          onChange={handleChange}
          label={intl('catalogue.disponible')}
          id="disponible"
          type="number"
          fullWidth
        />
        <TextField
          name="nombreBodega"
          margin="dense"
          value={values.nombreBodega}
          error={touched.nombreBodega && errors.nombreBodega}
          onChange={handleChange}
          label={intl('catalogue.nombreBodega')}
          id="nombreBodega"
          type="text"
          fullWidth
        />
        <TextField
          name="precio"
          margin="dense"
          value={values.precio}
          error={touched.precio && errors.precio}
          min="0"
          onChange={handleChange}
          label={intl('catalogue.precio')}
          id="precio"
          type="number"
          fullWidth
        />
        <TextField
          name="medida"
          margin="dense"
          value={values.medida}
          error={touched.medida && errors.medida}
          onChange={handleChange}
          id="medida"
          label={intl('catalogue.medida')}
          type="text"
          fullWidth
        />

        <TextField
          name="bodega"
          margin="dense"
          value={values.bodega}
          error={touched.bodega && errors.bodega}
          onChange={handleChange}
          label={intl('catalogue.bodega')}
          id="bodega"
          type="number"
          fullWidth
        />

        <TextField
          name="compania"
          margin="dense"
          value={values.compania}
          error={touched.compania && errors.compania}
          onChange={handleChange}
          label={intl('catalogue.companyNumber')}
          id="compania"
          type="number"
          fullWidth
        />

        <MaterialSelect
          options={[
            {
              value: 'inventory',
              name: intl('inventory_type.inventory'),
            },
            {
              value: 'services',
              name: intl('inventory_type.services'),
            },
          ]}
          value={values.category}
          error={touched.category && errors.category}
          onChange={handleChange}
          name="category"
          title={intl('catalogue.category')}
          style={{ marginBottom: 10 }}
        />
        <MaterialSelect
          options={
            values.category === 'inventory'
              ? [
                  {
                    value: 'medicine',
                    name: intl('inventory_type.medicine'),
                  },
                  {
                    value: 'supply',
                    name: intl('inventory_type.supply'),
                  },
                ]
              : [
                  {
                    value: 'service',
                    name: intl('inventory_type.service'),
                  },
                  {
                    value: 'exam',
                    name: intl('inventory_type.exam'),
                  },
                ]
          }
          value={values.subcategory}
          error={touched.subcategory && errors.subcategory}
          onChange={handleChange}
          name="subcategory"
          title={intl('catalogue.subcategory')}
        />
        <TextField
          select
          name="categoria"
          value={values.categoria}
          label={intl('catalogue.price_category')}
          fullWidth
          onChange={handleChange}
          error={touched.categoria && errors.categoria}
        >
          {Object.entries(priceCategoryTopaymentMethodMap).map(([priceCategory, paymentMethod]) => (
            <MenuItem value={+priceCategory}>{intl(`payment_type.${paymentMethod}`)}</MenuItem>
          ))}
        </TextField>

        <TextField
          name="uso"
          margin="dense"
          value={values.uso}
          error={touched.uso && errors.uso}
          onChange={handleChange}
          id="uso"
          label={intl('catalogue.uso')}
          fullWidth
        />
        <TextField
          name="principioActivo"
          margin="dense"
          value={values.principioActivo}
          error={touched.principioActivo && errors.principioActivo}
          onChange={handleChange}
          id="principioActivo"
          label={intl('catalogue.principioActivo')}
          fullWidth
        />

        <div className={classes.fileUpload}>
          {imageUrl ? (
            <>
              <img src={`${imageUrl}`} alt="SKU" />
              <IconButton onClick={deleteImage} className={classes.deleteButton} color="secondary">
                <Delete />
              </IconButton>
            </>
          ) : (
            <Button disabled={imageUploading} variant="contained" color="primary" component="label">
              {intl('catalogue.uploadFile')}
              <input accept="image/png, image/jpg, image/jpeg" type="file" onChange={addImage} hidden />
            </Button>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={toggleWithReset} color="secondary">
          {intl('catalogue.cancel')}
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          {intl('catalogue.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
