import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  billsContainer: {
    marginBottom: 24,
  },
  billsSubContainer: {
    marginBottom: 12,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  boxForButtons: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
  },
  invoiceNumber: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  boxForButton: {
    marginLeft: 12,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: { marginBottom: 22 },
  },
  billsHeader: {
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: { fontSize: 24 },
  },
  billsBoxItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      alignItems: 'flex-start',
      marginTop: 8,
    },
  },
  contentText: {
    wordBreak: 'break-all',
    // textAlign: 'center',
    // fontSize: 16,
    // marginTop: 8,
    // [theme.breakpoints.down('sm')]: { textAlign: 'left' },
  },
  resetLinkDecoration: {
    textDecoration: 'none',
    fontSize: '0.875rem',
    [theme.breakpoints.down('sm')]: { marginTop: 20 },
  },
  caseBtnText: {
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  container: {
    '&:first-child': {
      marginTop: -24,
    },
  },
  resetLinkDecorationRecords: {
    textDecoration: 'none',
    fontSize: '6px',
    [theme.breakpoints.down('sm')]: { marginTop: 20 },
  },
  buttonPositioning: {
    justifyContent: 'flex-end',
  },
  responseFromService: {
    fontSize: 14,
    marginLeft: 24,
  },
  caseInnerItem: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
}));
