import React, { useState, useEffect } from 'react';
import { useParams, Route } from 'react-router-dom';
import useCaseFetch from 'api/Case';
import { Box, CircularProgress } from '@material-ui/core';
import PatientAccount from '../PatientAccount';
import PatientAccountRecord from '../PatientAccountRecord';
import PatientBill from '../PatientBill';

const PatientAccountContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { caseId, patient } = useParams<{ caseId: string; patient: string }>();
  const { getCase } = useCaseFetch();

  const loadCase = async () => {
    setLoading(true);
    await getCase(caseId, patient);
    setLoading(false);
  };
  useEffect(() => {
    loadCase();
  }, []);

  return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="500px" width="100%">
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Route exact path="/user/patient_records/:patient/accounts/:caseId/medical_records/:recordId" component={PatientAccountRecord} />
      <Route exact path="/user/patient_records/:patient/accounts/:caseId/payments" component={PatientBill} />
      <Route exact path="/user/patient_records/:patient/accounts/:caseId" component={PatientAccount} />
    </>
  );
};
export default PatientAccountContainer;
