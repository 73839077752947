const businessHoursInit = [
  {
    daysOfWeek: [0],
    startTime: '9:00',
    endTime: '17:00',
  },

  {
    daysOfWeek: [1],
    startTime: '10:00',
    endTime: '19:00',
  },
  {
    daysOfWeek: [2],
    startTime: '07:00',
    endTime: '18:00',
  },
  {
    daysOfWeek: [3],
    startTime: '7:00',
    endTime: '18:00',
  },
  {
    daysOfWeek: [4],
    startTime: '7:00',
    endTime: '18:00',
  },
  {
    daysOfWeek: [5],
    startTime: '7:00',
    endTime: '18:00',
  },
  {
    daysOfWeek: [6],
    startTime: '7:00',
    endTime: '18:00',
  },
]

export default businessHoursInit
