import React, { useState } from 'react';

import { Avatar, Button, TextField, Grid, Box, Typography, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { useStoreActions } from 'easy-peasy';
import { useTranslations } from 'utils/useTranslations';

import authFetch from 'api/Authorization';
import userFetch from 'api/User';

import useStyles from './style';

const SignIn = () => {
  const style = useStyles();
  const intl = useTranslations();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const setUserData: any = useStoreActions((actions) => actions.setUserData);
  const setTokenData: any = useStoreActions((actions) => actions.setTokenData);

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (formValues, formContext) => {
      try {
        setLoading(true);
        const trimmedEmail = formValues.email.trim();
        const response = await authFetch.signIn({
          email: trimmedEmail,
          password: formValues.password,
        });

        if (!response.error) {
          localStorage.setItem('AUTH', JSON.stringify(response));
          localStorage.setItem('accessToken', response.accessToken);
          setTokenData(response.accessToken);
          const userProfile = await userFetch.getUserProfile(response.accessToken);
          setLoading(false);

          setUserData(userProfile);
          push('/user/profile');
        } else if (response.error.data === 'User not found') {
          formContext.setFieldError('email', 'error.user_not_found');
        } else if (response.error.data === 'Not valid credentials') {
          formContext.setFieldError('password', 'error.not_valid_credentials');
        } else if (response.error.data === 'User not confirmed') {
          formContext.setFieldError('email', 'error.user_not_confirmed');
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box className={style.paper}>
        <Avatar className={style.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <FormattedMessage id="auth.signin" description="Sign In" />
        </Typography>

        <form className={style.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={touched.email && !!errors.email}
            fullWidth
            id="email"
            label={intl('user.email_adress')}
            name="email"
            value={values.email}
            onChange={handleChange}
            helperText={touched.email && errors.email && intl(errors.email)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password && intl(errors.password)}
            name="password"
            label={intl('auth.password')}
            type="password"
            id="password"
            value={values.password}
            onChange={handleChange}
          />

          <Button disabled={loading} fullWidth variant="contained" color="primary" className={style.submit} type="submit">
            {intl('auth.signin')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/auth/forgot_password">{intl('auth.forgot_password')}</Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default SignIn;
