import { makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { isImageUrl } from 'utils/isImageUrl';

type ImagePreviewProps = {
  imageUrl: string;
};

const useStyles = makeStyles({
  image: {
    maxWidth: '100%',
    maxHeight: 300,
  },
});

const ImagePreview: FC<ImagePreviewProps> = ({ imageUrl }) => {
  const classes = useStyles();
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    isImageUrl(imageUrl).then((isImg: boolean) => setIsImage(isImg));
  }, [setIsImage, imageUrl]);

  return isImage ? (
    <a className={classes.image} target="_blank" href={imageUrl} rel="noreferrer">
      <img className={classes.image} src={imageUrl} alt={imageUrl} />
    </a>
  ) : null;
};

export default ImagePreview;
