import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, TextField, Typography } from '@material-ui/core';
import { useTranslations } from 'utils/useTranslations';
import { CaseListItem } from 'pages/User/PatientCases/types';
import { format, isValid } from 'date-fns';
import clsx from 'clsx';
import useCaseFetch from 'api/Case';
import usePermissions from 'utils/usePermissions';
import { Edit } from '@material-ui/icons';
import { roundTo2 } from 'utils/roundTo2';
import { flatten } from 'lodash';
import { countTotals } from 'pages/User/PatientBill/PatientBill.utils';
import { convertNumber } from 'utils/formatters';
import { useStyles } from './styles';

interface ParamType {
  patient: string;
  caseId: string;
}

const PatientAccountItem = ({ caseItem, withButton = false }: { caseItem: CaseListItem; withButton?: boolean }) => {
  const intl = useTranslations();
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const { patient, caseId } = useParams<ParamType>();
  const { checkOut, updateCaseInvoice, updateCaseDocument } = useCaseFetch();
  const { canCheckoutAddAndViewPaymentsInBills } = usePermissions();
  const isAllResolved = () => {
    const allSkus = flatten(caseItem?.medicalRecords?.map((mRecord) => mRecord.catalog));
    const isAllRes = allSkus?.length ? allSkus.every((item) => item?.resolved) : false;
    return isAllRes;
  };

  const handleCheckout = async () => {
    try {
      setLoading(true);
      await checkOut({ caseId });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const { totalLeftToPay, totalToPay, discountNumberTotal } = useMemo(
    () => countTotals(caseItem?.total, caseItem?.paidTotal, caseItem?.discounts),
    [caseItem],
  );

  const formattedTotals = useMemo(
    () => ({
      totalToPay: convertNumber(roundTo2(totalToPay)),
      totalLeftToPay: convertNumber(roundTo2(totalLeftToPay)),
      paidTotal: convertNumber(roundTo2(caseItem?.paidTotal)),
      discountNumberTotal: convertNumber(roundTo2(discountNumberTotal)),
    }),
    [totalLeftToPay, totalToPay, caseItem?.paidTotal],
  );

  const checkOutAvailable = useMemo(() => {
    const caseIsUpdated = (caseItem?.checkOut || 0) < caseItem?.updatedAt;
    const caseSkusAreResolved = isAllResolved();
    const documentsAreApproved = caseItem.documentStatus;
    const paidTotalMoreOrEqualThanNeeded = totalLeftToPay <= 0;

    return caseIsUpdated && caseSkusAreResolved && documentsAreApproved && paidTotalMoreOrEqualThanNeeded;
  }, [caseItem, isAllResolved, totalLeftToPay, totalToPay]);

  const [invoiceNumber, setInvoiceNumber] = useState<{ value: string; disabled: boolean }>({ value: '', disabled: true });

  const toggleInput = async () => {
    if (!invoiceNumber.disabled) {
      await updateCaseInvoice({ caseId, invoiceNumber: invoiceNumber.value });
    }
    setInvoiceNumber((prevState) => ({ ...prevState, disabled: !prevState.disabled }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.persist();
    setInvoiceNumber((prevState) => ({ ...prevState, value: e.target.value }));
  };

  const [documentStatus, setDocumentStatus] = useState(false);

  useEffect(() => {
    setInvoiceNumber((prevState) => ({ ...prevState, value: caseItem?.invoiceNumber }));
    setDocumentStatus(caseItem.documentStatus);
  }, [caseItem]);

  const handleDocumentStatusChange = async (e) => {
    try {
      setDocumentStatus(e.target.checked);
      setDocumentLoading(true);
      await updateCaseDocument({ caseId, documentStatus: e.target.checked });
    } finally {
      setDocumentLoading(false);
    }
  };

  return (
    <Box className={styles.billsContainer}>
      <Box className={styles.billsSubContainer}>
        <Typography className={styles.billsHeader}>
          {intl('patient.case')} {isValid(new Date(caseItem?.name)) ? format(new Date(caseItem?.name), 'dd.MM.yyyy HH:mm') : caseItem?.name}
          {caseItem?.responseFromService && (
            <Typography className={styles.responseFromService} component="span">
              ({caseItem?.responseFromService?.mensaje})
            </Typography>
          )}
          {loading && <CircularProgress size={16} />}
        </Typography>

        <Box className={styles.boxForButtons} display="flex" alignItems="center">
          <Box className={styles.boxForButton}>
            {withButton && (
              <TextField
                disabled={!canCheckoutAddAndViewPaymentsInBills || invoiceNumber.disabled}
                value={invoiceNumber.value}
                onChange={handleChange}
                className={styles.invoiceNumber}
                InputProps={{
                  endAdornment: canCheckoutAddAndViewPaymentsInBills && (
                    <IconButton onClick={() => toggleInput()}>
                      <Edit />
                    </IconButton>
                  ),
                }}
                variant="outlined"
                label={intl('case.invoiceNumber')}
              />
            )}
          </Box>
          {canCheckoutAddAndViewPaymentsInBills && (
            <>
              <Box className={styles.boxForButton}>
                {withButton && (
                  <Link
                    to={`/user/patient_records/${patient}/accounts/${caseItem?._id}/payments`}
                    className={clsx(styles.resetLinkDecorationRecords, styles.buttonPositioning)}
                  >
                    <Button className={styles.caseBtnText} color="primary">
                      {intl('patient.bills')}
                    </Button>
                  </Link>
                )}
              </Box>
              <Box className={styles.boxForButton}>
                {withButton && (
                  <Button
                    onClick={handleCheckout}
                    disabled={!checkOutAvailable || loading}
                    className={styles.caseBtnText}
                    variant="contained"
                    color="primary"
                  >
                    {intl('patient.checkout')}
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Divider />
      {withButton && (
        <Box>
          <FormControlLabel
            color="primary"
            disabled={documentLoading}
            onChange={handleDocumentStatusChange}
            checked={documentStatus}
            control={<Checkbox color="primary" checked={documentStatus} name="secondDoc" />}
            label={intl('case.secondDocument')}
          />
        </Box>
      )}
      <Box className={styles.billsBoxItem}>
        <Box className={styles.caseInnerItem}>
          <Typography className={styles.contentText}>{intl('case.admission_date')}</Typography>
          <Typography className={styles.contentText}>{format(new Date(caseItem?.createdAt), 'dd.MM.yyyy')}</Typography>
        </Box>
        <Box className={styles.caseInnerItem}>
          <Typography className={styles.contentText}>{intl('case.discharge_date')}</Typography>
          <Typography className={styles.contentText}>{(caseItem?.checkOut && format(new Date(caseItem?.checkOut), 'dd.MM.yyyy')) || '-'}</Typography>
        </Box>
        <Box className={styles.caseInnerItem}>
          <Typography className={styles.contentText}>{intl('case.estimate')}</Typography>
          <Typography className={styles.contentText}>{caseItem?.budget ? intl(`case.budgetOption.${caseItem?.budget}`) : '-'}</Typography>
        </Box>
        {canCheckoutAddAndViewPaymentsInBills && (
          <>
            <Box className={styles.caseInnerItem}>
              <Typography className={styles.contentText}>{intl('case.totalAccount')}</Typography>
              <Typography className={styles.contentText}>{formattedTotals.totalToPay}</Typography>
            </Box>
            <Box className={styles.caseInnerItem}>
              <Typography className={styles.contentText}>{intl('case.totalDiscounts')}</Typography>
              <Typography className={styles.contentText}>{formattedTotals.discountNumberTotal}</Typography>
            </Box>
            <Box className={styles.caseInnerItem}>
              <Typography className={styles.contentText}>{intl('case.totalAccountPaid')}</Typography>
              <Typography className={styles.contentText}>{formattedTotals.paidTotal}</Typography>
            </Box>
            <Box className={styles.caseInnerItem}>
              <Typography className={styles.contentText}>{intl('case.amountToPay')}</Typography>
              <Typography className={styles.contentText}>{formattedTotals.totalLeftToPay}</Typography>
            </Box>
          </>
        )}
        {!withButton && (
          <Link to={`/user/patient_records/${patient}/accounts/${caseItem?._id}`} className={styles.resetLinkDecorationRecords}>
            <Button className={styles.caseBtnText} variant="contained" color="primary">
              {intl('view.case')}
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default PatientAccountItem;
