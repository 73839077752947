import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import MaterialSelect from './Select';

const doctorTitles = ['lic', 'ing', 'ph_d', 'dr', 'mtro', 'mtr', 'sr_sra'].map((title) => ({
  value: title,
  name: <FormattedMessage id={`doctor.title.${title}`} />,
}));

const medicalType = ['doctor', 'pharmacist', 'nurse', 'technician_radiology', 'technician_labs'].map((type) => ({
  value: type,
  name: <FormattedMessage id={`position.${type}`} />,
}));

const INITIAL_USER = {
  role: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  type: '',
  title: '',
  collegiateNumber: '',
  skills: '',
};

const AddDoctorsModal = ({ open = false, handleClose, registerUser }) => {
  const [user, setUser] = useState(INITIAL_USER);
  const [errors, setErrors] = useState({
    role: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    type: false,
    title: false,
    collegiateNumber: false,
    skills: false,
  });
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);

  const validate = useCallback(() => {
    const err = {};
    Object.keys(user).forEach((key) => {
      if (!user[key]) {
        err[key] = true;
      } else {
        err[key] = false;
      }
    });
    if (user.type === 'doctor' && !user.collegiateNumber) {
      err.collegiateNumber = true;
    } else {
      err.collegiateNumber = false;
    }
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
      err.email = false;
    } else {
      err.email = true;
    }
    setErrors(err);
    return !Object.values(err).filter((elem) => elem).length;
  }, [user]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setErrors((err) => ({ ...err, [name]: false }));
    setUser((u) => ({ ...u, [name]: value }));
  }, []);

  const onClose = async () => {
    if (validate()) {
      try {
        setLoading(true);
        const response = await registerUser(user);
        setLoading(false);
        if (!response.error) {
          setUser(INITIAL_USER);
          handleClose();
        } else {
          if (response.error.data === 'User already exists') {
            setServerError('error.user_already_exists');
            setErrors((err) => ({ ...err, email: true }));
          }
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const clearAndClose = () => {
    setUser(INITIAL_USER);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={clearAndClose}>
      <DialogTitle>
        <FormattedMessage id="doctor_modal.add_new_medical" />
      </DialogTitle>
      <DialogContent>
        <MaterialSelect
          style={{ marginBottom: '20px' }}
          options={medicalType}
          onChange={handleChange}
          value={user.type}
          error={errors.type}
          name="type"
          variant="outlined"
          title={<FormattedMessage id="user.role" />}
        />
        <Box display="flex" justifyContent="space-between">
          <TextField
            onChange={handleChange}
            style={{ width: '48%' }}
            name="firstName"
            error={errors.firstName}
            value={user.firstName}
            label={<FormattedMessage id="user.first_name" />}
            variant="outlined"
          />

          <TextField
            style={{ width: '48%' }}
            onChange={handleChange}
            value={user.lastName}
            error={errors.lastName}
            name="lastName"
            variant="outlined"
            label={<FormattedMessage id="user.last_name" />}
          />
        </Box>
        <Box marginY="20px">
          <TextField
            onChange={handleChange}
            fullWidth
            error={errors.email}
            helperText={serverError && <FormattedMessage id={serverError} />}
            name="email"
            value={user.email}
            variant="outlined"
            label={<FormattedMessage id="user.email_adress" />}
          />
          <TextField
            style={{ marginTop: '20px' }}
            onChange={handleChange}
            fullWidth
            type="number"
            name="phone"
            error={errors.phone}
            value={user.phone}
            variant="outlined"
            label={<FormattedMessage id="user.phone" />}
          />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <MaterialSelect
            style={{ width: '48%' }}
            options={doctorTitles}
            onChange={handleChange}
            name="title"
            error={errors.title}
            value={user.title}
            title={<FormattedMessage id="doctor.title" />}
            variant="outlined"
          />
          <TextField
            style={{ width: '48%' }}
            variant="outlined"
            name="role"
            onChange={handleChange}
            value={user.role}
            error={errors.role}
            label={<FormattedMessage id="doctor.role" />}
          />
        </Box>
        <Box>
          <TextField
            style={{ marginTop: '20px' }}
            onChange={handleChange}
            fullWidth
            type="text"
            name="skills"
            error={errors.skills}
            value={user.skills}
            variant="outlined"
            label={<FormattedMessage id="doctor.skills" />}
          />
        </Box>
        {user.type === 'doctor' && (
          <Box marginY="20px">
            <TextField
              onChange={handleChange}
              fullWidth
              error={errors.collegiateNumber}
              name="collegiateNumber"
              value={user.collegiateNumber}
              variant="outlined"
              label={<FormattedMessage id="doctor.collegiate_number" />}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={clearAndClose} color="secondary">
          <FormattedMessage id="services.cancel" />
        </Button>
        <Button disabled={loading} color="primary" onClick={onClose}>
          <FormattedMessage id="services.add" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDoctorsModal;
