import React from 'react';
import { Route } from 'react-router-dom';
import MedicalRecord from '../MedicalRecord/MedicalRecord';
import MedicalRecordAlt from '../MedicalRecord/MedicalRecordAlt';

const MedicalRecordContainer = () => (
  <>
    <Route exact path="/user/patient_records/:patient/cases/:caseId/medical_records/:recordId" component={MedicalRecordAlt} />
    <Route exact path="/user/patient_records/:patient/cases/:caseId/medical_records/:recordId/old" component={MedicalRecord} />
  </>
);

export default MedicalRecordContainer;
