import { UserTypeEnum } from 'types';

import { NavBarLinks } from './types';

export const PERMISSION_TABS: NavBarLinks[] = [
  {
    name: 'navbar.profile',
    link: '/user/profile',
    permissions: [
      UserTypeEnum.doctor,
      UserTypeEnum.technician_labs,
      UserTypeEnum.technician_radiology,
      UserTypeEnum.nurse,
      UserTypeEnum.pharmacist,
      UserTypeEnum.staff,
      UserTypeEnum.admin,
    ],
  },
  {
    name: 'navbar.medicals',
    link: '/user/medical_professionals',
    permissions: [
      UserTypeEnum.doctor,
      UserTypeEnum.technician_labs,
      UserTypeEnum.technician_radiology,
      UserTypeEnum.nurse,
      UserTypeEnum.pharmacist,
      UserTypeEnum.staff,
      UserTypeEnum.admin,
    ],
  },
  {
    name: 'navbar.patient_record',
    link: '/user/patient_records',
    permissions: [
      UserTypeEnum.doctor,
      UserTypeEnum.technician_labs,
      UserTypeEnum.technician_radiology,
      UserTypeEnum.nurse,
      UserTypeEnum.pharmacist,
      UserTypeEnum.staff,
      UserTypeEnum.admin,
    ],
  },
  {
    name: 'catalog.inventory',
    link: '/user/catalogue',
    permissions: [
      UserTypeEnum.doctor,
      UserTypeEnum.technician_labs,
      UserTypeEnum.technician_radiology,
      UserTypeEnum.nurse,
      UserTypeEnum.pharmacist,
      UserTypeEnum.staff,
      UserTypeEnum.admin,
    ],
  },
  {
    name: 'navbar.calendar',
    link: '/user/calendar',
    permissions: [UserTypeEnum.doctor, UserTypeEnum.nurse],
  },
  {
    name: 'navbar.admin_accounts',
    link: '/user/admins_accounts',
    permissions: [UserTypeEnum.staff, UserTypeEnum.admin],
  },
  {
    name: 'navbar.paymentType',
    link: '/user/payment_type',
    permissions: [UserTypeEnum.staff, UserTypeEnum.admin],
  },
];
