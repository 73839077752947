import axiosClient from 'api';

export class FileApi {
  static getDownloadLink(id: string) {
    return axiosClient.get(`file/${id}`);
  }

  static downloadSingleFile(id: string) {
    return axiosClient.get(`file/download/${id}`, { responseType: 'blob' });
  }

  static downloadCaseZip(caseId: string) {
    return axiosClient.get(`file/case/${caseId}`, { responseType: 'blob' });
  }

  static remove(id: string) {
    return axiosClient.delete(`file/${id}`);
  }

  static upload(caseId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.post(`file/upload/${caseId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static uploadPublicMedicalRecordFile(medicalRecord: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.post(`medical_record/${medicalRecord}/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
