import React, { useState, useEffect, useMemo } from 'react';
import { Container, Box, AppBar, Tabs, Tab, Breadcrumbs } from '@material-ui/core';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslations } from 'utils/useTranslations';
import { Link, Route, useLocation, useParams } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import userFetch from 'api/User';
import { format, isValid } from 'date-fns';

import Loader from 'components/Loader';
import usePermissions from 'utils/usePermissions';

import useStyles from './style';
import PatientAccounts from '../PatientAccounts';
import { PatientProfile as PProfile } from './PatientProfile';
import PatientCases from '../PatientCases';
import PatientCaseContainer from '../PatientCaseContainer';
import PatientAccountContainer from '../PatientAccountContainer';
import PatientBill from '../PatientBill';

const PatientProfile = ({ match, history }) => {
  const style = useStyles();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [breadCrumbsData, setBreadCrumbsData] = useState({ patient: null, medicalRecord: null, case: null });
  const { patient: patientId } = useParams();
  const getCase = useStoreState((state) => state.cases.getById);
  const getRecord = useStoreState((state) => state.medicalRecords.getById);
  const patients = useStoreState((state) => state.patientsMap);
  const getPaymentTypes = useStoreActions((actions) => actions.getPaymentTypesThunk);

  const { canViewBills, canViewCases } = usePermissions();
  const loadNewPatient = useStoreActions((actions) => actions.loadNewPatient);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    history.push(`/user/patient_records/${match.params.patient}${newValue}`);
  };

  const location = useLocation();

  useEffect(() => {
    const locations = location.pathname.split('/');

    const pId = locations[3];
    const cId = locations[5];
    const rId = locations[7];

    const breadCrumbs = {};
    if (patients[pId]) {
      breadCrumbs.patient = patients[pId];
    }

    if (getCase(cId)) {
      breadCrumbs.case = getCase(cId);
    }

    const record = getRecord(rId);
    if (record && record._id) {
      breadCrumbs.medicalRecord = record;
    }

    setBreadCrumbsData(breadCrumbs);
  }, [location.pathname, getCase, getRecord, patients]);

  const loadPatient = async () => {
    try {
      setLoading(true);
      const patient = await userFetch.getPatient(patientId);
      loadNewPatient({ patient, patientId: patient._id });
      await getPaymentTypes();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const splittedLink = location.pathname.split('/');
    const tab = splittedLink[4];
    if (tab === 'records' || tab === 'accounts' || tab === 'cases') setValue(`/${tab}`);
    else {
      loadPatient();
      setValue('');
    }
  }, [location.pathname]);

  useEffect(() => {
    loadPatient();
  }, []);

  const intl = useTranslations();

  const casesOrAccount = useMemo(() => location.pathname.split('/')[4], [location]);

  if (loading) {
    return <Loader height="300px" />;
  }

  return (
    <Container component="main" style={{ paddingTop: 30 }}>
      <Box className={style.title}>
        <AppBar className={style.appBar} position="static" color="primary">
          <Tabs
            className={style.tabs}
            value={value}
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#3f51b5',
              },
            }}
          >
            <Tab className={style.tab} value="" label={<FormattedMessage id="user.profile" description="Profile" />} />
            {canViewCases && <Tab className={style.tab} value="/cases" label={intl('user.cases')} />}
            {canViewBills && <Tab className={style.tab} value="/accounts" label={<FormattedMessage id="user.accounts" description="Accounts" />} />}
          </Tabs>
        </AppBar>
      </Box>

      {breadCrumbsData.patient && (
        <Box mb="20px">
          <Breadcrumbs separator="›">
            {breadCrumbsData.patient && (
              <Link className={style.link} color="inherit" to={`/user/patient_records/${breadCrumbsData.patient._id}`}>
                {intl('position.patient')}: {breadCrumbsData.patient.firstName} {breadCrumbsData.patient.lastName}
              </Link>
            )}
            {breadCrumbsData.patient && breadCrumbsData.case && (
              <Link
                className={style.link}
                color="inherit"
                to={`/user/patient_records/${breadCrumbsData.patient._id}/${casesOrAccount}/${breadCrumbsData.case._id}`}
              >
                {intl('patient.case')}:{' '}
                {isValid(new Date(breadCrumbsData.case.name))
                  ? format(new Date(breadCrumbsData.case.name), 'dd.MM.yyyy HH:mm')
                  : breadCrumbsData.case.name}
              </Link>
            )}
            {breadCrumbsData.patient && breadCrumbsData.case && breadCrumbsData.medicalRecord && (
              <Link
                className={style.link}
                color="inherit"
                // eslint-disable-next-line
                to={`/user/patient_records/${breadCrumbsData.patient._id}/${casesOrAccount}/${breadCrumbsData.case._id}/medical_records/${breadCrumbsData.medicalRecord._id}`}
              >
                {intl('user.medical_record')}:{' '}
                {isValid(new Date(breadCrumbsData.medicalRecord.name))
                  ? format(new Date(breadCrumbsData.medicalRecord.name), 'dd.MM.yyyy')
                  : breadCrumbsData.medicalRecord.name}
              </Link>
            )}
          </Breadcrumbs>
        </Box>
      )}

      <>
        <Route exact path="/user/patient_records/:patient" component={PProfile} />
        <Route path="/user/patient_records/:patient/cases/:caseId" component={PatientCaseContainer} />
        <Route exact path="/user/patient_records/:patient/cases" component={PatientCases} />
        {canViewBills && (
          <>
            <Route path="/user/patient_records/:patient/accounts/:caseId" component={PatientAccountContainer} />
            <Route exact path="/user/patient_records/:patient/accounts" component={PatientAccounts} />
            <Route exact path="/user/patient_records/:patient/accounts/budget" component={PatientBill} />
          </>
        )}
      </>
    </Container>
  );
};

export default PatientProfile;
