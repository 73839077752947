import { format, isValid } from 'date-fns';

export const convertDate = (date: any, formatString: string = 'dd.MM.yyyy'): string => {
  if (!isValid(new Date(date))) {
    return 'n/a';
  }

  return format(new Date(date), formatString);
};

export const convertNumber = (number: any): string => {
  if (typeof number !== 'number' || typeof +number !== 'number') {
    return 'n/a';
  }

  return new Intl.NumberFormat('en-US').format(number);
};
