import { useStoreState } from 'easy-peasy';
import { UserTypeEnum } from 'types';
import getPermissions from './getPermissions';

const usePermissions = () => {
  const { type, _id } = useStoreState((state) => state.user);
  const patientsMap = useStoreState((state) => state.patientsMap);
  const canCrudCasesAndRecords = (patientId: string) => {
    if (getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff])) return true;

    return type === UserTypeEnum.doctor && patientsMap?.[patientId]?.doctors?.map((d) => d._id)?.includes(_id);
  };

  const canAddObservations = (patientId: string) => {
    if (
      getPermissions(type, [
        UserTypeEnum.admin,
        UserTypeEnum.staff,
        UserTypeEnum.pharmacist,
        UserTypeEnum.technician_labs,
        UserTypeEnum.technician_radiology,
      ])
    ) {
      return true;
    }

    return type === UserTypeEnum.doctor && patientsMap?.[patientId]?.doctors?.map((d) => d._id)?.includes(_id);
  };

  const canDoctorEditDetailedMedicalRecord = (patientId) =>
    type === UserTypeEnum.doctor && patientsMap?.[patientId]?.doctors?.map((d) => d._id)?.includes(_id);

  const canAddSkusInMedicalRecord = (patientId: string) => {
    if (getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.pharmacist])) return true;

    return type === UserTypeEnum.doctor && patientsMap?.[patientId]?.doctors?.map((d) => d._id)?.includes(_id);
  };

  const canDeleteSkusInMedicalRecord = (patientId: string) => {
    if (getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff])) return true;

    return type === UserTypeEnum.doctor && patientsMap?.[patientId]?.doctors?.map((d) => d._id)?.includes(_id);
  };

  const canHaveCalendar = (medUserRole) => getPermissions(medUserRole, [UserTypeEnum.doctor, UserTypeEnum.nurse]);

  const permissions = {
    // Bills
    canAddSkuInBills: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.pharmacist]),
    canAddSkuInMedicalRecord: canAddSkusInMedicalRecord,
    canDeleteSkusInMedicalRecord,
    canCheckmarkSkuInBills: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.pharmacist]),
    canViewBills: getPermissions(type, [
      UserTypeEnum.admin,
      UserTypeEnum.staff,
      UserTypeEnum.pharmacist,
      UserTypeEnum.technician_labs,
      UserTypeEnum.technician_radiology,
    ]),
    canViewCases: getPermissions(type, [
      UserTypeEnum.doctor,
      UserTypeEnum.nurse,
      UserTypeEnum.pharmacist,
      UserTypeEnum.staff,
      UserTypeEnum.admin,
      UserTypeEnum.technician_labs,
      UserTypeEnum.technician_radiology,
    ]),
    canCheckmarkExamSkuInBills: getPermissions(type, [UserTypeEnum.technician_labs, UserTypeEnum.technician_radiology]),
    canCheckoutAddAndViewPaymentsInBills: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.pharmacist]),
    // Cases&Records
    canAddObservation: getPermissions(type, [UserTypeEnum.pharmacist, UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.doctor]),
    canCrudCasesAndRecords,
    canAddObservations,
    canAddNurseObservation: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.nurse]),
    canEditPatientsInfo: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff]),
    canDeletePatient: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff]),
    canAssignDoctor: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff]),

    // Medical specialists
    canCrudCatalogAndVat: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.pharmacist]),
    canCrudSpecialist: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff]),
    canViewCalendar: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.nurse]),
    canHaveCalendar,

    canViewPrices: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff]),

    canCloseRequests: getPermissions(type, [UserTypeEnum.technician_labs, UserTypeEnum.technician_radiology]),

    canSignMedicalRecord: getPermissions(type, [UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.doctor]),
    canEditSignedMedicalRecord: getPermissions(type, [UserTypeEnum.admin]),
    canDoctorEditDetailedMedicalRecord,
  };

  return permissions;
};

export default usePermissions;
