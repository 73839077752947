import React from 'react';
import { Table, TableRow, TableCell } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';

import { useStoreState } from 'easy-peasy';
import MaterialDialog from './Dialog';
import getPermissions from '../utils/getPermissions';

const fieldsToShowAdmin = [
  // { name: 'compania', translationKey: 'company' },
  { name: 'codigo', translationKey: 'codigo' },
  { name: 'descripcion', translationKey: 'descripcion' },
  // { name: 'disponible', translationKey: 'disponiblecompany' },
  { name: 'amount', translationKey: 'quantity' },
  // { name: 'bodega', translationKey: 'bodega' },
  // { name: 'nombreBodega', translationKey: 'nombreBodega' },
  // { name: 'precio', translationKey: 'precio' },
  // { name: 'categoria', translationKey: 'priceCategory' },
  // { name: 'medida', translationKey: 'medida' },
  // { name: 'expiryDate', translationKey: 'expiryDate' },
  // { name: 'paid', translationKey: 'paid' },
  // { name: 'resolved', translationKey: 'resolved' },
  // { name: 'paymentType', translationKey: 'paymentType' },
  // { name: 'category', translationKey: 'category' },
  // { name: 'subcategory', translationKey: 'subcategory' },
  { name: 'documentLink', translationKey: 'documentLink' },
  { name: 'doctorJudgement', translationKey: 'doctorJudgement' },
];

const fieldsToShowOthers = [
  // { name: 'compania', translationKey: 'company' },
  { name: 'codigo', translationKey: 'codigo' },
  { name: 'descripcion', translationKey: 'descripcion' },
  // { name: 'disponible', translationKey: 'disponiblecompany' },
  { name: 'amount', translationKey: 'quantity' },
  // { name: 'bodega', translationKey: 'bodega' },
  // { name: 'nombreBodega', translationKey: 'nombreBodega' },
  // { name: 'categoria', translationKey: 'priceCategory' },
  // { name: 'medida', translationKey: 'medida' },
  // { name: 'expiryDate', translationKey: 'expiryDate' },
  // { name: 'paid', translationKey: 'paid' },
  // { name: 'resolved', translationKey: 'resolved' },
  // { name: 'paymentType', translationKey: 'paymentType' },
  // { name: 'category', translationKey: 'category' },
  // { name: 'subcategory', translationKey: 'subcategory' },
  { name: 'documentLink', translationKey: 'documentLink' },
  { name: 'doctorJudgement', translationKey: 'doctorJudgement' },
];

export const DetailAssignedInfo = ({ toggle, open, catalogItem = {} }) => {
  const user = useStoreState((state) => state.user);

  const realFields = getPermissions(user.type, ['admin', 'staff']) ? fieldsToShowAdmin : fieldsToShowOthers;

  return (
    <MaterialDialog
      opened={open}
      title={<FormattedMessage id="inventory.details" />}
      onClose={toggle}
      actions={[
        {
          name: <FormattedMessage id="calendar.close" />,
          onClick: toggle,
          color: 'primary',
        },
      ]}
    >
      <Table>
        {realFields.map((field) => (
          <TableRow key={field.translationKey}>
            <TableCell>
              <FormattedMessage id={`detailed.${field.translationKey}`} />
            </TableCell>
            {field.name === 'precio' ? (
              <TableCell>{Math.round(catalogItem[field.name] * 100) / 100 || '---'}</TableCell>
            ) : (
              <TableCell>
                {catalogItem[field.name] || '---'}
                {/* eslint-disable-next-line no-nested-ternary */}
                {typeof catalogItem[field.name] === 'boolean' ? (catalogItem[field.name] ? 'Yes' : 'No') : ''}
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
    </MaterialDialog>
  );
};
