import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_HOST;
// 'http://dev.hospitalprivadopeten-api.geniusee.space'

export default {
  async getUserProfile(token) {
    const { data } = await axios.get('/user/profile', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  },

  async getUsersData(token) {
    try {
      const { data } = await axios.get('/user/profiles', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async editUserProfile(token, formData) {
    try {
      const data = await axios.put('/user/edit_profile', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getPatientsData(token) {
    try {
      const { data } = await axios.get('/user/profiles?filters[]=patient', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async addComment(token, formData) {
    try {
      const { data } = await axios.put('/user/add_comment', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async assignDoctor(token, formData) {
    try {
      const { data } = await axios.put('/user/assign_doctor', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async unassignDoctor(token, formData) {
    try {
      const { data } = await axios.put('/user/unassign_doctor', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async getCurrentDoctorPatientsData(token) {
    try {
      const { data } = await axios.get('/user/patients', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async deleteProfile(token, formData) {
    try {
      const { data } = await axios.put('/user/delete_profile', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getAssignedServices(token) {
    try {
      const { data } = await axios.get('/user/assigned_services', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async getPatient(id) {
    const { data } = await axios.get(`/user/patients/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });
    return data;
  },
  async getPatients(params) {
    const { data } = await axios.get('/user/patients', {
      params,
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });
    return data;
  },
};
