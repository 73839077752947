const mapEnumRadios = (v: string): number => {
  const mapped = parseInt(v, 10);
  return Number.isNaN(mapped) ? undefined : mapped;
};

const mapCheckboxValues = (values: string[]): { [key: string]: boolean } => {
  const reduceCheckboxArray = (obj: any, val: string) => {
    obj[val] = true;
    return obj;
  };
  return values.reduce(reduceCheckboxArray, {});
};

const mapBooleanRadios = (boolString: string): boolean => {
  if (boolString === 'true') {
    return true;
  }

  if (boolString === 'false') {
    return false;
  }

  return undefined;
};

const mapText = (v: string): string => v || undefined;

// Returns Epoch time in millis.
const mapDate = (dateIsoString: string) => (dateIsoString ? Date.parse(dateIsoString) : undefined);

const mapNumberInput = (v: number | string): number => (typeof +v === 'number' ? +v : undefined);

const mapImage = (v: string) => v || undefined;

const parseQuestionnaireForm = (formValues) => ({
  generalInformation: {
    sex: mapEnumRadios(formValues.sex),
    age: mapText(formValues.age),
    health: mapEnumRadios(formValues.health),
    ...mapCheckboxValues(formValues.healthProblems),
    fluVaccine: mapBooleanRadios(formValues.flu),
  },
  locationAndTravel: {
    // country: mapText(formValues.country),
    // region: mapText(formValues.region),
    // postcode: mapText(formValues.postcode),
    country: 'United States',
    region: 'fl',
    postcode: '331',
    travelledToOtherCountries: mapEnumRadios(formValues.travelledToOtherCountries),
  },
  travelContinued: {
    highestRiskCountry: mapText(formValues.highestRiskCountry),
    dateEnteredCountry: mapDate(formValues.dateEnteredCountry),
    dateLeftCountry: mapDate(formValues.dateLeftCountry),
  },
  exposureRisk: {
    didWork: mapEnumRadios(formValues.didWork),
    ...mapCheckboxValues(formValues.avoidCatching),
    contactWithCovid19: mapEnumRadios(formValues.contactWithCovid19),
    amountPeopleLiveWith: mapNumberInput(formValues.amountPeopleLiveWith),
    householdIllness: mapEnumRadios(formValues.householdIllness),
  },
  illHousemates: {
    numberOfIllInHousehold: mapNumberInput(formValues.numberOfIllInHousehold),
    firstHouseholdIllnessDate: mapDate(formValues.firstHouseholdIllnessDate),
  },
  wereYouIll: {
    hasFeltIll: mapEnumRadios(formValues.hasFeltIll),
  },
  yourSymptoms: {
    ...mapCheckboxValues(formValues.whichSymptoms),
    howShortOfBreath: mapEnumRadios(formValues.howShortOfBreath),
    howBadDidTheyFeel: mapEnumRadios(formValues.howBadDidTheyFeel),
    howAnxious: mapEnumRadios(formValues.howAnxious),
    firstSymptoms: mapDate(formValues.firstSymptoms),
    lengthOfTimeOfSymptoms: mapEnumRadios(formValues.lengthOfTimeOfSymptoms),
    wereTheyInfected: mapEnumRadios(formValues.wereTheyInfected),
  },
  covidTesting: {
    antibodyTest: mapEnumRadios(formValues.antibodyTest),
    dateOfTest: mapDate(formValues.dateOfTest),
    testType: mapEnumRadios(formValues.testType),
    testOutcome: mapEnumRadios(formValues.testOutcome),
  },
  antibodyTest: {
    // testOutcome: mapEnumRadios(formValues.testOutcome),
    testImageSrc: mapImage(formValues.testImageSrc),
  },
  otherTests: {
    labTest: mapEnumRadios(formValues.labTest),
    swabTest: mapEnumRadios(formValues.swabTest),
    antigenTest: mapEnumRadios(formValues.antigenTest),
    xrayTest: mapEnumRadios(formValues.xrayTest),
    diagnosedByDoctor: mapEnumRadios(formValues.diagnosedByDoctor),
  },
  emailConsent: {
    emailConsent: true,
    // email: mapText(state.emailConsent.values.email),
  },
});

export default parseQuestionnaireForm;
