import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Typography,
  Box,
  TextField,
  Input,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import { useStoreState } from 'easy-peasy';
import { FormattedMessage } from 'react-intl';
import { Close, ExpandMoreOutlined } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isValid } from 'date-fns';
import { useFormik } from 'formik';
import { PDFDownloadLink } from '@react-pdf/renderer';
import usePermissions from 'utils/usePermissions';
import { MedicalRecordPdf } from 'components/MedicalRecordPdf/MedicalRecordPdf';

import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { Link, useHistory, useParams } from 'react-router-dom';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import { useTranslations } from 'utils/useTranslations';
import { RestoreMedicalRecord } from 'components/Modals/RestoreFieldMedicalRecord';
import { convertDate } from 'utils/formatters';
import MedicalRecordSKUTable from 'components/MedicalRecordSKUTable';
import useStyles from './style';
import MaterialSelect from '../../../components/Select';
import { vitalSigns, laboratoryExams, radiologicalExams, diagnosis, clinicalTreatment, surgicalTreatment } from './constants';
import Comments from '../../../components/Comments';

const localeMap = {
  es: esLocale,
  en: enLocale,
};

const MedicalRecord = () => {
  const style = useStyles();
  const lang = useStoreState((state) => state.lang);
  const intl = useTranslations();
  const [editMode, setEditMode] = useState(false);
  const { push } = useHistory();

  const [editOrderSummaryMode, setEditOrderSummaryMode] = useState(false);
  const [loading, setLoading] = useState({ editOrDeleteAction: false, newCommentAction: false, fieldRemoveAction: false });

  const [comment, setComment] = useState('');
  const [nurseComment, setNurseComment] = useState('');
  const [modalOpened, setModalOpened] = useState(false);

  const toggleModal = () => setModalOpened((old) => !old);

  const { canCrudCasesAndRecords, canAddObservations, canAddNurseObservation, canSignMedicalRecord, canEditSignedMedicalRecord } = usePermissions();

  const { recordId, caseId, patient } = useParams<{ recordId: string; caseId: string; patient: string }>();
  const medicalRecord = useStoreState((state) => state.medicalRecords.getById(recordId));
  const { deleteRecord, update, addComment, toggleField, sign } = useMedicalRecordFetch();

  const patients = useStoreState((state) => state.patientsMap);
  const patientRec = patients[patient];

  const onRemoveClick = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: true }));
      await deleteRecord(recordId, caseId, patient);
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
    }
  };

  const handleCommentChange = useCallback((e) => {
    setComment(e.target.value);
  }, []);

  const handleNurseCommentChange = useCallback((e) => {
    setNurseComment(e.target.value);
  }, []);

  const onAddComment = async (commentValue: string, isNurse: boolean) => {
    try {
      setLoading((prevState) => ({ ...prevState, newCommentAction: true }));
      await addComment(recordId, commentValue, isNurse);
      setLoading((prevState) => ({ ...prevState, newCommentAction: false }));
      if (isNurse) {
        setNurseComment('');
      } else {
        setComment('');
      }
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, newCommentAction: false }));
    }
  };

  const onEditPress = async (values) => {
    try {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: true }));

      await update(recordId, values);
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
      setEditMode(false);
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
    }
  };

  const onEditOrderPress = async (values) => {
    try {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: true }));

      await update(recordId, values);
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
      setEditOrderSummaryMode(false);
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
    }
  };

  const { handleSubmit, values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      caseId,
      recordId,
      name: medicalRecord.name || '',
      diagnosis: medicalRecord.diagnosis || '',
      personalMedicalHistory: medicalRecord.personalMedicalHistory || '',
      familiarMedicalHistory: medicalRecord.familiarMedicalHistory || '',
      symptoms: medicalRecord.symptoms || '',
      signs: medicalRecord.signs || '',
      sex: medicalRecord.sex || '',
      age: medicalRecord.age || '',
      homeAdress: medicalRecord.homeAdress || '',
      recentTrips: medicalRecord.recentTrips || '',
      weight: medicalRecord.weight || '',
      height: medicalRecord.height || '',
      temperatura: medicalRecord.temperatura || '',
      pulse: medicalRecord.pulse || '',
      breathesPerMinute: medicalRecord.breathesPerMinute || '',
      pressure: medicalRecord.pressure || '',
      oxygenSaturation: medicalRecord.oxygenSaturation || '',
      Na: medicalRecord.Na || '',
      K: medicalRecord.K || '',
      Cl: medicalRecord.Cl || '',
      WBC: medicalRecord.WBC || '',
      HCT: medicalRecord.HCT || '',
      bakingSoda: medicalRecord.bakingSoda || '',
      BUN: medicalRecord.BUN || '',
      glukose: medicalRecord.glukose || '',
      platelets: medicalRecord.platelets || '',
      creatinine: medicalRecord.creatinine || '',
      hepaticFunctionality: medicalRecord.hepaticFunctionality || '',
      renalFunctionality: medicalRecord.renalFunctionality || '',
      specials: medicalRecord.specials || '',
      ultrasound: medicalRecord.ultrasound || '',
      xRays: medicalRecord.xRays || '',
      tomography: medicalRecord.tomography || '',
      resonancia: medicalRecord.resonancia || '',
      petCt: medicalRecord.petCt || '',
      treatmentDays: medicalRecord.treatmentDays || '',
      treatmentMedications: medicalRecord.treatmentMedications || '',
      treatmentProcedures: medicalRecord.treatmentProcedures || '',
      surgicalTreatmentDays: medicalRecord.surgicalTreatmentDays || '',
      comments: medicalRecord.comments || [],
      catalog: medicalRecord.catalog || [],
      orderSummary: medicalRecord.orderSummary || '',
    },
    onSubmit: onEditPress,
    enableReinitialize: true,
  });

  const [pdfLoading, setPdfLoading] = useState(true);

  useEffect(() => {
    setPdfLoading(true);
    // if (medicalRecord) {
    setTimeout(() => {
      setPdfLoading(false);
    }, 500);
    // }
  }, [medicalRecord]);

  const isEditable = useMemo(() => {
    if (medicalRecord.signed && !canEditSignedMedicalRecord) {
      return false;
    }

    return true;
  }, [medicalRecord, canEditSignedMedicalRecord]);

  const comments = useMemo(() => {
    if (!medicalRecord?.comments) {
      return [];
    }
    return medicalRecord.comments
      .filter((comment) => !comment.isNurse)
      .slice()
      .reverse();
  }, [medicalRecord]);

  const nurseComments = useMemo(() => {
    if (!medicalRecord?.comments) {
      return [];
    }
    return medicalRecord.comments
      .filter((comment) => comment.isNurse)
      .slice()
      .reverse();
  }, [medicalRecord]);

  return (
    <Box width="100%">
      <Box className={style.medicalRecordHeader}>
        <Box display="flex" flexDirection="column">
          {isValid(new Date(medicalRecord.name)) ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[lang]}>
              <DatePicker
                // fullWidth
                format="dd.MM.yyyy"
                style={{ fontSize: 32 }}
                disabled={!editMode}
                margin="normal"
                id="time-picker"
                label={<FormattedMessage id="record.name" description="Name" />}
                value={values.name}
                onChange={(date) => {
                  setFieldValue('name', date);
                }}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <Input
              style={{ fontSize: 32 }}
              value={values.name}
              disabled={!editMode}
              disableUnderline={!editMode}
              onChange={(e) => setFieldValue('name', e.target.value)}
            />
          )}
          <Button
            onClick={() => push(`/user/patient_records/${patient}/cases/${caseId}/medical_records/${recordId}`)}
            color="primary"
            variant="contained"
          >
            {intl('medical_record.detailed_info')}
          </Button>
        </Box>
        <Box>
          {canCrudCasesAndRecords(patient) && isEditable && (
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  className={style.buttonWithMargin}
                  onClick={!editMode ? () => setEditMode(true) : () => handleSubmit()}
                  variant="contained"
                  color="primary"
                  disabled={loading.editOrDeleteAction}
                >
                  {!editMode ? <Edit /> : <Save />}
                </Button>
                <Button
                  disabled={loading.editOrDeleteAction}
                  className={style.buttonWithMargin}
                  onClick={onRemoveClick}
                  variant="contained"
                  color="secondary"
                >
                  <Delete />
                </Button>
              </Box>
              <Box mt={2} mb={2}>
                <Button color="primary" variant="contained" onClick={toggleModal}>
                  {intl('medical_record.restore_fields')}
                </Button>
              </Box>
              {canSignMedicalRecord && (
                <Box mt={2} mb={2}>
                  <Button fullWidth color="primary" variant="contained" onClick={() => sign(recordId)}>
                    {intl('medical_record.sign')}
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {medicalRecord.signed && (
            <Box>
              <Typography>
                {intl('medical_record.signed_by')}:{' '}
                <Link to={`/user/medical_professionals/profile/${medicalRecord.signedBy?._id}`}>
                  {medicalRecord.signedBy?.firstName} {medicalRecord.signedBy?.lastName}
                </Link>
              </Typography>
              <Typography>{convertDate(medicalRecord.signedAt)}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={style.paper}>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
            <h4>
              <FormattedMessage id="medical_record.vital_signs" />
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box className={style.paper}>
              <MaterialSelect
                className={style.input}
                title={<FormattedMessage id="medical_record.gender" description="Sex" />}
                disabled={!editMode}
                options={[
                  {
                    value: 'man',
                    name: <FormattedMessage id="medical_record.man" />,
                  },
                  {
                    value: 'woman',
                    name: <FormattedMessage id="medical_record.woman" />,
                  },
                ]}
                value={values.sex}
                name="sex"
                onChange={handleChange as any}
              />
              {vitalSigns.map(
                (field) =>
                  medicalRecord.fields?.find((f) => f.name === field.name && f.enabled) && (
                    <TextField
                      key={field.name}
                      variant="outlined"
                      name={field.name}
                      label={intl(field.translationKey)}
                      className={style.input}
                      value={values[field.name]}
                      disabled={!editMode}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: canCrudCasesAndRecords(patient) && (
                          <IconButton disabled={!isEditable} onClick={() => toggleField(recordId, field.name, false)}>
                            <Close />
                          </IconButton>
                        ),
                      }}
                      multiline={field.multiline}
                    />
                  ),
              )}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
            <h4>
              <FormattedMessage id="medical_record.laboratory_exams" />
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box className={style.paper}>
              {laboratoryExams.map(
                (field) =>
                  medicalRecord.fields?.find((f) => f.name === field.name && f.enabled) && (
                    <TextField
                      key={field.name}
                      variant="outlined"
                      name={field.name}
                      label={intl(field.translationKey)}
                      className={style.input}
                      value={values[field.name]}
                      disabled={!editMode}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: canCrudCasesAndRecords(patient) && (
                          <IconButton disabled={!isEditable} onClick={() => toggleField(recordId, field.name, false)}>
                            <Close />
                          </IconButton>
                        ),
                      }}
                      multiline={field.multiline}
                    />
                  ),
              )}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
            <h4>
              <FormattedMessage id="medical_record.radiological_exams" />
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box className={style.paper}>
              {radiologicalExams.map(
                (field) =>
                  medicalRecord.fields?.find((f) => f.name === field.name && f.enabled) && (
                    <TextField
                      key={field.name}
                      variant="outlined"
                      name={field.name}
                      label={intl(field.translationKey)}
                      className={style.input}
                      value={values[field.name]}
                      disabled={!editMode}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: canCrudCasesAndRecords(patient) && (
                          <IconButton disabled={!isEditable} onClick={() => toggleField(recordId, field.name, false)}>
                            <Close />
                          </IconButton>
                        ),
                      }}
                      multiline={field.multiline}
                    />
                  ),
              )}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
            <h4>
              <FormattedMessage id="medical_record.diagnostic" />
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box className={style.paper}>
              {diagnosis.map(
                (field) =>
                  medicalRecord.fields?.find((f) => f.name === field.name && f.enabled) && (
                    <TextField
                      key={field.name}
                      variant="outlined"
                      name={field.name}
                      label={intl(field.translationKey)}
                      className={style.input}
                      value={values[field.name]}
                      disabled={!editMode}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: canCrudCasesAndRecords(patient) && (
                          <IconButton disabled={!isEditable} onClick={() => toggleField(recordId, field.name, false)}>
                            <Close />
                          </IconButton>
                        ),
                      }}
                      multiline={field.multiline}
                    />
                  ),
              )}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
            <h4>
              <FormattedMessage id="medical_record.clinical_treatment" />
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box className={style.paper}>
              {clinicalTreatment.map(
                (field) =>
                  medicalRecord.fields?.find((f) => f.name === field.name && f.enabled) && (
                    <TextField
                      key={field.name}
                      variant="outlined"
                      name={field.name}
                      label={intl(field.translationKey)}
                      className={style.input}
                      value={values[field.name]}
                      disabled={!editMode}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: canCrudCasesAndRecords(patient) && (
                          <IconButton disabled={!isEditable} onClick={() => toggleField(recordId, field.name, false)}>
                            <Close />
                          </IconButton>
                        ),
                      }}
                      multiline={field.multiline}
                    />
                  ),
              )}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
            <h4>
              <FormattedMessage id="medical_record.surgical_treatment" />
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box className={style.paper}>
              {surgicalTreatment.map(
                (field) =>
                  medicalRecord.fields?.find((f) => f.name === field.name && f.enabled) && (
                    <TextField
                      key={field.name}
                      variant="outlined"
                      name={field.name}
                      label={intl(field.translationKey)}
                      className={style.input}
                      value={values[field.name]}
                      disabled={!editMode}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: canCrudCasesAndRecords(patient) && (
                          <IconButton disabled={!isEditable} onClick={() => toggleField(recordId, field.name, false)}>
                            <Close />
                          </IconButton>
                        ),
                      }}
                      multiline={field.multiline}
                    />
                  ),
              )}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {canAddObservations(patient) && (
          <Box mt={10}>
            <TextField
              multiline
              fullWidth
              value={comment}
              disabled={!isEditable}
              onChange={handleCommentChange}
              variant="outlined"
              label={<FormattedMessage id="user.observation" description="Add comment" />}
            />
            <Box mt={1}>
              <Button
                disabled={loading.newCommentAction || !isEditable}
                variant="contained"
                color="primary"
                onClick={() => onAddComment(comment, false)}
              >
                <FormattedMessage id="services.add" description="Add comment" />
              </Button>
            </Box>
          </Box>
        )}
        <Box mt={5} />
        <Comments comments={comments} />
      </Box>
      <RestoreMedicalRecord open={modalOpened} onClose={toggleModal} fields={medicalRecord.fields} />
    </Box>
  );
};

export default MedicalRecord;
