import { Divider, Typography } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import React from 'react';
import { Link } from 'react-router-dom';
import { convertDate } from 'utils/formatters';

const Creator = ({ item }) => {
  const user = useStoreState((state) => state.user);
  return (
    <>
      <Divider />
      <Typography>{convertDate(item.createdAt)}</Typography>
      <Link to={user._id === item.userId ? '/user/profile' : `/user/medical_professionals/profile/${item.userId}`}>
        <Typography style={{ fontWeight: 'bold' }}>{item.createdBy}</Typography>
      </Link>
      <small>{item.userType}</small>
    </>
  );
};

export default Creator;
