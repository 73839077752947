import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Hidden,
  Divider,
} from '@material-ui/core';

import { useStoreState, useStoreActions } from 'easy-peasy';
import { FormattedMessage } from 'react-intl';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { format, isValid } from 'date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { useParams } from 'react-router-dom';

import useStyles from './style';

import medicalRecordsFetch from '../../../api/MedicalRecord';
import getPermissions from '../../../utils/getPermissions';

const localeMap = {
  es: esLocale,
  en: enLocale,
};

const MedicalRecords = () => {
  const accessToken = localStorage.getItem('accessToken');
  const style = useStyles();
  const user = useStoreState((state) => state.user);
  const lang = useStoreState((state) => state.lang);
  const patients = useStoreState((state) => state.patients);
  const setPatientsData = useStoreActions((actions) => actions.setPatientsData);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [newMedicalRecordName, setNewMedicalRecordName] = useState(new Date());
  const [dialog, setDialog] = useState(false);

  const { patient: patientId } = useParams();

  const currentPatient = patients.find((p) => p._id === patientId) || {};

  const openDialog = () => {
    setDialog(true);
  };
  const closeDialog = () => {
    setDialog(false);
  };

  const addNewMedicalRecord = async () => {
    const formData = { name: newMedicalRecordName, patientId };

    try {
      const newMedicalRecord = await medicalRecordsFetch.addMedicalRecord(accessToken, formData);
      newMedicalRecord.catalog = [];
      if (!medicalRecords.length) {
        setMedicalRecords([newMedicalRecord]);
      } else {
        setMedicalRecords([newMedicalRecord, ...medicalRecords]);
      }
      const tempPatients = [...patients];
      const patientIndex = patients.findIndex((p) => p._id === patientId);
      if (tempPatients[patientIndex].medicalRecords && tempPatients[patientIndex].medicalRecords.length) {
        tempPatients[patientIndex].medicalRecords = [newMedicalRecord, ...tempPatients[patientIndex].medicalRecords];
      } else tempPatients[patientIndex].medicalRecords = [newMedicalRecord];

      setPatientsData(tempPatients);

      closeDialog();
      setNewMedicalRecordName(new Date());
    } catch (err) {
      console.log(err);
    }
  };

  const [expandedId, setExpandedId] = useState(false);

  const onExpand = (id) => (_, isExpanded) => {
    setExpandedId(isExpanded ? id : false);
  };

  return (
    <Box display="block">
      <Hidden xsDown>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography component="h4" variant="h4">
            {currentPatient.firstName} {currentPatient.lastName} <FormattedMessage id="medical_records.medical_records" />
          </Typography>

          {getPermissions(user.type, ['admin', 'staff', 'doctor']) && (
            <Button className={style.button} onClick={openDialog} variant="contained" color="primary">
              <FormattedMessage id="record.add_new" description="Add new record" />
            </Button>
          )}
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box display="flex" flexDirection="column" alignItems="left" justifyContent="space-between" marginTop={3} marginBottom={4}>
          <Typography component="h4" variant="h4">
            {currentPatient.firstName} {currentPatient.lastName} <FormattedMessage id="medical_records.medical_records" />
          </Typography>

          {getPermissions(user.type, ['admin', 'staff', 'doctor']) && (
            <Button className={style.button} onClick={openDialog} variant="contained" color="primary" paddingBottom="8px">
              <FormattedMessage id="record.add_new" description="Add new record" />
            </Button>
          )}
        </Box>
      </Hidden>
      <Divider />

      <Box style={{ marginTop: 30, paddingLeft: 20 }} display="flex">
        <Typography style={{ width: '30%' }}>
          <FormattedMessage id="record.name" description="Name" />
        </Typography>
        <Typography style={{ width: '25%' }}>
          <FormattedMessage id="record.author" description="Author" />
        </Typography>
        <Typography align="right" />
      </Box>
      {currentPatient.medicalRecords &&
        currentPatient.medicalRecords.map((record) => (
          <ExpansionPanel
            onChange={onExpand(record._id)}
            expanded={record._id === expandedId}
            style={{
              margin: 0,
              paddingLeft: 20,
            }}
          >
            <ExpansionPanelSummary
              style={{
                background: 'inherit',
                padding: 0,
                margin: 0,
              }}
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box style={{ width: '100%' }} display="flex" alignItems="center">
                <Typography style={{ width: '30%' }}>
                  {isValid(new Date(record.name)) ? format(new Date(record.name), 'dd.MM.yyyy') : record.name}
                </Typography>
                <Typography style={{ width: '25%' }}>{record.createdBy && `${record.createdBy.firstName} ${record.createdBy.lastName}`}</Typography>
                <Typography align="right" />
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {/* <MedicalRecord
                patient={{
                  firstName: currentPatient.firstName,
                  lastName: currentPatient.lastName,
                }}
                expanded={record._id === expandedId}
                match={{ params: { record: record._id, patient: patientId } }}
                medicalRecord={record}
              /> */}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      <Dialog open={dialog} onClose={closeDialog} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="medical_records.dialog_title" />
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[lang]}>
            <DatePicker
              fullWidth
              margin="normal"
              format="dd.MM.yyyy"
              autoFocus
              id="time-picker"
              name="name"
              label={<FormattedMessage id="medical_records.record_name" />}
              value={newMedicalRecordName}
              onChange={(date) => {
                setNewMedicalRecordName(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            <FormattedMessage id="calendar.cancel" description="Cancel" />
          </Button>
          <Button color="primary" onClick={addNewMedicalRecord}>
            <FormattedMessage id="calendar.add" description="Add" />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MedicalRecords;
