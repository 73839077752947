import React, { useState } from 'react';

import { Avatar, Button, CssBaseline, TextField, Grid, Typography, Container, Popover, Box, FormControl } from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import fetch from '../../../api/Authorization';

import useStyles from './style';

const SetPassword = (props) => {
  const style = useStyles();

  const [formValue, setFormValue] = useState({
    password: '',
    confirmPassword: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [error, setErrorMessage] = useState('');

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormValue({ password: '', confirmPassword: '' });
  };

  const parseTicketFromUrl = (ticket) => {
    return ticket.replace('?ticket=', '');
  };

  const handleClick = async (event) => {
    try {
      const { password, confirmPassword } = formValue;
      if (password.length === 0 && confirmPassword.length === 0) {
        setPopUpMessage('Rellene los campos obligatorios por favor.');
        setAnchorEl(event.currentTarget);
        return;
      }

      if (password !== confirmPassword) {
        setPopUpMessage('La contraseña en el primer y segundo campo no coincide.');
        resetForm();
        setAnchorEl(event.currentTarget);
        return;
      }
      setLoading(true);

      const ticket = parseTicketFromUrl(props.location.search);
      const response = await fetch.setPassword({ password, ticket });
      setLoading(false);

      if (response === 'Password successfully confirmed') {
        props.history.push('/auth/sign_in');
      }
    } catch (err) {
      setLoading(false);

      switch (err.response.data) {
        case 'Ticket not found':
          setErrorMessage('Boleto no encontrado. Intenta enviar el boleto correcto');
          break;
        case 'Ticket has been expired':
          setErrorMessage('El boleto ha expirado. Intenta registrarte de nuevo');
          break;
        default:
          break;
      }
    }
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={style.paper}>
        <Avatar className={style.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Configurar la clave
        </Typography>
        <FormControl className={style.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Contraseña"
            name="password"
            value={formValue.password}
            onChange={(e) => onChange(e)}
            type="password"
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            name="confirmPassword"
            label="Repite la contraseña"
            id="confirmPassword"
            value={formValue.confirmPassword}
            onChange={(e) => onChange(e)}
            type="password"
          />
          <Button
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            className={style.submit}
            onClick={(e) => handleClick(e)}
            aria-describedby={id}
          >
            Confirmar contraseña
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopUp}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography className={style.popUp}>{popUpMessage}</Typography>
          </Popover>
          <Grid container>
            <Grid item />
          </Grid>
        </FormControl>
      </Box>
      {error.length > 0 && (
        <Box className={style.errorMessage}>
          <Typography>{error}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default SetPassword;
