import React, { useState } from 'react';

import { Avatar, Button, CssBaseline, TextField, Grid, Box, Typography, Container, FormControl } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { CheckCircleOutline } from '@material-ui/icons';
import authFetch from '../../../api/Authorization';

import useStyles from './style';

const ForgotPassword = () => {
  const style = useStyles();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetIsCompleted, setResetIsCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const resetPassword = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const response = await authFetch.resetPassword({ email });
      setErrorMessage('');
      setResetIsCompleted(false);
      setLoading(false);

      if (response && !response.error) setResetIsCompleted(true);
      else if (response.error.data === 'User not found') {
        setErrorMessage(<FormattedMessage id="forgot_password.user_not_found" />);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage('');
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={style.paper}>
        <Avatar className={style.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <FormattedMessage id="auth.forgot_password" description="Forgot password" />
        </Typography>
        <form className={style.form} onSubmit={resetPassword}>
          <FormControl className={style.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={<FormattedMessage id="user.email_adress" description="Email adress" />}
              name="email"
              value={email}
              helperText={errorMessage}
              error={Boolean(errorMessage)}
              onChange={handleChange}
            />

            <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" className={style.submit}>
              <FormattedMessage id="auth.reset" description="Reset" />
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/auth/sign_in" variant="body2">
                  <FormattedMessage id="auth.back_to_login" description="Don't have an account? Sign Up" />
                </Link>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </Box>
      <Box mt={2} />
      {resetIsCompleted && (
        <Box className={style.signup_completed}>
          <CheckCircleOutline className={style.signup_completed_icon} />
          <Typography component="p" variant="p">
            <FormattedMessage id="auth.restore_link_sent" description="Don't have an account? Sign Up" />
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default ForgotPassword;
