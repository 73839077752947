import React, { useState } from 'react';
import { TableCell, Hidden, TableRow, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useStoreActions } from 'easy-peasy';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslations } from 'utils/useTranslations';
import { RemoveConfirmModal } from 'components/Modals/RemoveConfirmationModal';
import { convertNumber } from 'utils/formatters';
import { DiscountItem } from './discount.type';
import { useStyles } from '../styles';

const DiscountTableRow = ({ amount, _id, discountType, creator, createdAt }: DiscountItem) => {
  const removeDiscountThunk: any = useStoreActions((actions) => actions.removeDiscountThunk);
  const { caseId } = useParams<{ caseId: string }>();
  const intl = useTranslations();
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const onRemove = () => {
    setIsLoading(true);
    removeDiscountThunk({ caseId, discountId: _id }).finally(() => setIsLoading(false));
  };

  const onModalOpen = () => setIsOpen(true);
  const onModalClose = () => setIsOpen(false);

  const symbol = discountType === 'percentage' ? '%' : '';

  const formattedAmount = convertNumber(amount);

  return (
    <TableRow>
      <TableCell className={styles.column}>{format(createdAt, 'dd.MM.yyyy')}</TableCell>
      <TableCell className={styles.column}>
        {formattedAmount} {symbol}
      </TableCell>
      <Hidden smDown>
        <TableCell className={styles.column}>
          <Link to={`/user/medical_professionals/profile/${creator._id}`}>
            {creator.firstName} {creator.lastName}
          </Link>
        </TableCell>
      </Hidden>
      <TableCell className={styles.column}>
        <IconButton disabled={isLoading} onClick={onModalOpen} color="secondary">
          <Delete />
        </IconButton>
      </TableCell>

      <RemoveConfirmModal
        label={intl('remove.label')}
        text={intl('remove.text')}
        open={isOpen}
        onSubmit={onRemove}
        loading={isLoading}
        onClose={onModalClose}
      />
    </TableRow>
  );
};

export default DiscountTableRow;
