import { Page, Document, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import React, { useCallback, useMemo } from 'react';
import productionLogo from 'assets/images/hospitalprivado.png';
import stagingLogo from 'assets/images/managemed-logo-2.png';
import { convertDate } from 'utils/formatters';
import _ from 'lodash';
import { countTotals } from 'pages/User/PatientBill/PatientBill.utils';

const styles = StyleSheet.create({
  container: {
    marginLeft: 40,
    marginRight: 40,
  },
  logo: {
    width: 200,
    marginLeft: 40,
    marginTop: 40,
    marginBottom: 40,
  },
  name: {
    fontSize: 24,
  },
  case: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 24,
  },
  tableCell: {
    flex: 1,
    borderRight: 1,
    borderColor: 'black',
    padding: 12,
  },
  tableCellLast: {
    borderRight: 0,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: 'black',
  },
  tableRowLast: {
    borderBottom: 0,
  },
  table: {
    borderColor: 'black',
    borderWidth: 1,
  },
  total: {
    fontSize: 24,
    marginTop: 24,
  },
  medicalRecord: {
    fontSize: 18,
    marginTop: 12,
    marginBottom: 12,
  },
});

const subcategoriesMap = {
  medicine: 'Medicina',
  exam: 'Examen',
  service: 'Servicio',
  supply: 'Suministro',
};
const baseData = {
  medicine: [],
  exam: [],
  service: [],
  supply: [],
};

const formatNumber = (number: number) => Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number);

const countTotal = (catalogList) => formatNumber(_.sumBy(catalogList, (item: any) => (item.resolved ? item.amount * item.precio : 0)));

const BillBySubCategoryPdf = ({ patient = {}, caseItem, withMedicalRecords }: any) => {
  const isMasterEnv = process.env.REACT_APP_ENV === 'master';
  const patientFullName = `${patient.firstName || ''} ${patient?.lastName || ''}`;
  const caseName = `${convertDate(caseItem?.name)} - ${convertDate(caseItem?.checkOut)}`;

  const { totalLeftToPay, totalToPay, discountNumberTotal } = useMemo(
    () => countTotals(caseItem?.total, caseItem?.paidTotal, caseItem?.discounts),
    [caseItem],
  );

  const data = useMemo(
    () => ({
      ...baseData,
      ..._.groupBy(
        caseItem.medicalRecords.reduce((acum, curValue) => {
          // eslint-disable-next-line no-param-reassign
          acum = [...acum, ...(curValue?.catalog || [])];
          return acum;
        }, []),
        'subcategory',
      ),
    }),
    [caseItem],
  );

  const getMedicalRecordData = useCallback(
    (medRecord) => ({
      ...baseData,
      ..._.groupBy(medRecord.catalog, 'subcategory'),
    }),
    [],
  );

  return (
    <Document>
      <Page size="A4">
        {isMasterEnv ? <Image style={styles.logo} src={productionLogo} /> : <Image style={styles.logo} src={stagingLogo} />}
        <View style={styles.container}>
          <Text style={styles.name}>Paciente: {patientFullName}</Text>
          <Text style={styles.case}>Caso: {caseName}</Text>

          {withMedicalRecords ? (
            <>
              {caseItem.medicalRecords.map((record) => (
                <>
                  <Text style={styles.medicalRecord}>Record medico: {convertDate(record.name)}</Text>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCell}>
                        <Text>Subcategoria</Text>
                      </View>
                      <View style={[styles.tableCell, styles.tableCellLast]}>
                        <Text>Total partial</Text>
                      </View>
                    </View>
                    {Object.entries(getMedicalRecordData(record)).map(([key, value], i, array) => (
                      <View key={key} style={[styles.tableRow, i === array.length - 1 && styles.tableRowLast]}>
                        <View style={styles.tableCell}>
                          <Text>{subcategoriesMap[key]}</Text>
                        </View>
                        <View style={[styles.tableCell, styles.tableCellLast]}>
                          <Text>{countTotal(value)}</Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </>
              ))}
            </>
          ) : (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Subcategoria</Text>
                </View>
                <View style={[styles.tableCell, styles.tableCellLast]}>
                  <Text>Total partial</Text>
                </View>
              </View>
              {Object.entries(data).map(([key, value], i, array) => (
                <View key={key} style={[styles.tableRow, i === array.length - 1 && styles.tableRowLast]}>
                  <View style={styles.tableCell}>
                    <Text>{subcategoriesMap[key]}</Text>
                  </View>
                  <View style={[styles.tableCell, styles.tableCellLast]}>
                    <Text>{countTotal(value)}</Text>
                  </View>
                </View>
              ))}
            </View>
          )}

          <View wrap={false}>
            <Text style={styles.total}>
              Total de Cuenta: {formatNumber(totalToPay)} {'\n'}
              Total de cuenta descuentos: {formatNumber(discountNumberTotal)} {'\n'}
              Pago total: {formatNumber(caseItem?.paidTotal)} {'\n'}
              Total de Cuenta Pendiente: {formatNumber(totalLeftToPay)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BillBySubCategoryPdf;
