import React from 'react';
import {
  Box,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

export interface ITableColumn {
  name: string;
  title: string;
  valueGetter: any;
  headerClassName?: string;
  bodyClassName?: string;
}
interface Props {
  columns: ITableColumn[];
  rows: any[];
  onRowClick?: (param?: string) => {};
  keyMapper: string;
  className?: string;
  paginationProps?: {
    count: number;
    rowsPerPage: number;
    page: number;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };
  loading?: boolean;
}

const useUniTableStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
  },

  selectRoot: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const UniTable = ({ rows, columns, onRowClick, keyMapper, className, paginationProps, loading }: Props) => {
  const s = useUniTableStyles();
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box>
      <TableContainer>
        <Table className={className}>
          <TableHead>
            {columns.map(({ title, headerClassName }) => (
              <TableCell className={headerClassName}>{title}</TableCell>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row[keyMapper]}
                className={onRowClick ? s.clickable : null}
                hover={Boolean(onRowClick)}
                onClick={() => (onRowClick ? onRowClick(row) : null)}
              >
                {columns.map(({ name, valueGetter, bodyClassName }) => (
                  <TableCell className={bodyClassName} key={name}>
                    {valueGetter ? valueGetter(name ? row[name] : row) : row[name]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Box height="200px" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {paginationProps && (
        <TablePagination
          classes={{
            selectRoot: s.selectRoot,
          }}
          labelRowsPerPage={isDownXs ? '' : 'Filas por página'}
          rowsPerPageOptions={[10, 15, 20]}
          count={paginationProps.count}
          rowsPerPage={paginationProps.rowsPerPage}
          page={paginationProps.page}
          onChangePage={paginationProps.handleChangePage}
          onChangeRowsPerPage={paginationProps.handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default UniTable;
