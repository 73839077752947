import React from 'react';
import { TableHead, TableCell, Hidden } from '@material-ui/core';
import { useTranslations } from 'utils/useTranslations';
import { useStyles } from '../styles';

const DiscountTableHead = () => {
  const styles = useStyles();
  const intl = useTranslations();

  return (
    <TableHead>
      <TableCell className={styles.column}>{intl('shared.date')}</TableCell>
      <TableCell className={styles.column}>{intl('patient.amount')}</TableCell>
      <Hidden smDown>
        <TableCell className={styles.column}>{intl('patient.registered')}</TableCell>
      </Hidden>
      <TableCell className={styles.column} />
    </TableHead>
  );
};

export default DiscountTableHead;
