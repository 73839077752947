import { UserTypeEnum } from 'types';

import PaymentType from 'pages/User/PaymentType';
import Profile from 'pages/User/Profile';
import PatientRecords from 'pages/User/PatientRecords';
import MedicalProfessionals from 'pages/User/MedicalProfessionals';
import PatientProfile from 'pages/User/Patient';

import Calendar from 'pages/User/Calendar';
import Catalogue from 'pages/User/Catalogue';

import MedicalProfessionalProfile from 'pages/User/MedicalProfessionalProfile';
import MedicalRecords from 'pages/User/MedicalRecords';
import AdminsAccounts from 'pages/User/AdminsAccounts';

const ALL_PERMITTED = [
  UserTypeEnum.doctor,
  UserTypeEnum.technician_labs,
  UserTypeEnum.technician_radiology,
  UserTypeEnum.nurse,
  UserTypeEnum.pharmacist,
  UserTypeEnum.staff,
  UserTypeEnum.admin,
];

type TRoute = {
  path: string | string[];
  component: any;
  permissions: UserTypeEnum[];
  notExact?: boolean;
};

export const USER_ROUTES: TRoute[] = [
  {
    path: ['/user/profile', '/user'],
    component: Profile,
    permissions: ALL_PERMITTED,
  },
  {
    path: '/user/medical_records',
    component: MedicalRecords,
    permissions: ALL_PERMITTED,
  },
  {
    path: '/user/medical_records/:record',
    component: MedicalRecords,
    permissions: ALL_PERMITTED,
  },
  {
    path: '/user/patient_records',
    component: PatientRecords,
    permissions: ALL_PERMITTED,
  },
  {
    path: '/user/medical_professionals',
    component: MedicalProfessionals,
    permissions: ALL_PERMITTED,
  },
  {
    path: '/user/patient_records/:patient',
    component: PatientProfile,
    permissions: ALL_PERMITTED,
    notExact: true,
  },
  {
    path: ['/user/calendar', '/user/medical_professionals/calendar/:doctorId'],
    component: Calendar,
    permissions: [UserTypeEnum.doctor, UserTypeEnum.nurse, UserTypeEnum.staff, UserTypeEnum.admin],
  },
  {
    path: '/user/medical_professionals/profile/:medicalSpecialist',
    component: MedicalProfessionalProfile,
    permissions: ALL_PERMITTED,
  },
  {
    path: '/user/catalogue',
    component: Catalogue,
    permissions: ALL_PERMITTED,
  },
  {
    path: '/user/admins_accounts',
    component: AdminsAccounts,
    permissions: [UserTypeEnum.admin, UserTypeEnum.staff],
  },
  {
    path: '/user/payment_type',
    component: PaymentType,
    permissions: [UserTypeEnum.admin, UserTypeEnum.staff],
  },
];
