import { HashRouter as Router } from 'react-router-dom';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useStoreState } from 'easy-peasy';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { ToastContainer } from 'react-toastify';
import messages from './localization';
import Routes from './routes';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const localeMap = {
  es: esLocale,
  en: enLocale,
};

const App = () => {
  const lang = useStoreState((state) => state.lang);
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[lang]}>
        <Router>
          <Routes />
        </Router>
      </MuiPickersUtilsProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        theme="colored"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </IntlProvider>
  );
};

export default App;
