import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: '80px',
    '& img': {
      height: 20,
    },
  },
  burger: {
    color: 'white',
  },
  mobileLogout: {
    [theme.breakpoints.down('sm')]: { padding: '30px' },
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 0,
    backgroundColor: '#4DA638',
    marginBottom: 30,
  },
  tabIndicarorStyle: {
    backgroundColor: '#3f51b5',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  hospitalPrivado: {
    height: 40,
  },
}));
