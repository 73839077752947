import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Box, CircularProgress } from '@material-ui/core';
import { UserTypeEnum } from 'types';
import Footer from 'components/Footer';

import NavBar from '../components/NavBar';

import userFetch from '../api/User';
import catalogueFetch from '../api/Catalogue';
import calendarFetch from '../api/Calendar';
import getPermissions from '../utils/getPermissions';
import { USER_ROUTES } from './constants';

export default function UserRouter() {
  const accessToken = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();
  const { setUserData, setUsersData, setBusinessHours, setEvents, setVat, setRequests, setTokenData }: any = useStoreActions((actions) => actions);

  const user = useStoreState((state) => state.user);

  const loadData = async () => {
    try {
      const currentUser = await userFetch.getUserProfile(accessToken);

      setUserData(currentUser);

      // eslint-disable-next-line no-underscore-dangle
      if (!currentUser._id) {
        setLoading(false);
        localStorage.removeItem('accessToken');
        push('/');
      } else {
        setTokenData(accessToken);
      }

      const usersList = await userFetch.getUsersData(accessToken);
      setUsersData(usersList);

      if (getPermissions(currentUser.type, [UserTypeEnum.doctor, UserTypeEnum.nurse])) {
        const businessHours = await calendarFetch.getBusinessHours(accessToken);
        if (businessHours) {
          setBusinessHours(businessHours);
        }

        const events = await calendarFetch.getEvents(accessToken);
        setEvents(events.map(({ _id, ...rest }) => ({ ...rest, id: _id })));
      }

      if (
        getPermissions(currentUser.type, [
          UserTypeEnum.admin,
          UserTypeEnum.staff,
          UserTypeEnum.technician_labs,
          UserTypeEnum.technician_radiology,
          UserTypeEnum.doctor,
          UserTypeEnum.nurse,
          UserTypeEnum.pharmacist,
        ])
      ) {
        // const requestsResponse = await catalogueFetch.getUnresolvedCatalogue(accessToken);
        // setRequests(requestsResponse);
        const vatPercent = await catalogueFetch.getVatPercent(accessToken);
        setVat(vatPercent.percent);
      }

      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401 || err.response?.data === 'User credentials not found') {
        setLoading(false);
        localStorage.removeItem('accessToken');
        push('/');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return user &&
    // eslint-disable-next-line no-underscore-dangle
    user._id ? (
    <>
      {loading ? (
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box minHeight="100vh" display="flex" flexDirection="column">
          <NavBar />
          <Box flex="1">
            {USER_ROUTES.map(
              (route) =>
                route.permissions.includes(user.type) && (
                  <Route
                    key={Array.isArray(route.path) ? route.path[0] : route.path}
                    exact={!route.notExact}
                    path={route.path}
                    component={route.component}
                  />
                ),
            )}
          </Box>
          <Footer />
        </Box>
      )}
    </>
  ) : (
    <></>
  );
}
