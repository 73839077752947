import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import MaterialSelect from './Select';

const adminType = ['admin', 'staff'].map((type) => ({
  value: type,
  name: <FormattedMessage id={`position.${type}`} />,
}));

const INITIAL_USER = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  type: '',
};

const AddAdminModal = ({ open = false, handleClose, registerUser }) => {
  const [user, setUser] = useState(INITIAL_USER);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    type: false,
  });

  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState('');

  const validate = useCallback(() => {
    const err = {};
    Object.keys(user).forEach((key) => {
      if (!user[key]) {
        err[key] = true;
      } else {
        err[key] = false;
      }
    });

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
      err.email = false;
    } else {
      err.email = true;
    }
    setErrors(err);
    return !Object.values(err).filter((elem) => elem).length;
  }, [user]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setErrors((err) => ({ ...err, [name]: false }));
    setUser((u) => ({ ...u, [name]: value }));
  }, []);

  const onClose = async () => {
    if (validate()) {
      try {
        setLoading(true);
        const response = await registerUser(user);
        setLoading(false);
        if (!response.error) {
          setUser(INITIAL_USER);
          handleClose();
        } else {
          setServerError(response.error.data);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const clearAndClose = () => {
    setUser(INITIAL_USER);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={clearAndClose}>
      <DialogTitle>
        <FormattedMessage id="doctor_modal.add_new_admin" />
      </DialogTitle>
      <DialogContent>
        <MaterialSelect
          style={{ marginBottom: '20px' }}
          options={adminType}
          onChange={handleChange}
          value={user.type}
          error={errors.type}
          name="type"
          variant="outlined"
          title={<FormattedMessage id="user.role" />}
        />
        <Box display="flex" justifyContent="space-between">
          <TextField
            onChange={handleChange}
            style={{ width: '45%' }}
            name="firstName"
            error={errors.firstName}
            value={user.firstName}
            label={<FormattedMessage id="user.first_name" />}
            variant="outlined"
          />
          <TextField
            style={{ width: '45%' }}
            onChange={handleChange}
            value={user.lastName}
            error={errors.lastName}
            name="lastName"
            variant="outlined"
            label={<FormattedMessage id="user.last_name" />}
          />
        </Box>
        <Box marginY="20px">
          <TextField
            onChange={handleChange}
            fullWidth
            error={errors.email}
            name="email"
            value={user.email}
            variant="outlined"
            label={<FormattedMessage id="user.email_adress" />}
          />
          <TextField
            style={{ marginTop: '20px' }}
            onChange={handleChange}
            fullWidth
            type="number"
            name="phone"
            error={errors.phone}
            value={user.phone}
            variant="outlined"
            label={<FormattedMessage id="user.phone" />}
          />
          <Box style={{ color: 'red' }}>{serverError}</Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={clearAndClose} color="secondary">
          <FormattedMessage id="services.cancel" />
        </Button>
        <Button disabled={loading} color="primary" onClick={onClose}>
          <FormattedMessage id="services.add" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAdminModal;
