export const discountEn = {
  'discount.number': 'Number',
  'discount.percentage': 'Percentage',
  'discount.discountType': 'Discount Type',
  'discount.addDiscount': 'Add Discount',
  'discount.discounts': 'Discounts',
};

export const discountEs = {
  'discount.number': 'Número',
  'discount.percentage': 'Porcentaje',
  'discount.discountType': 'Tipo de descuento',
  'discount.addDiscount': 'Agregar descuento',
  'discount.discounts': 'Descuentos',
};
