import React from 'react';
import { Table, TableContainer, TableHead, TableCell, Toolbar, TablePagination, TableBody, Box, TableRow, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const thCells2 = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.talent' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget' },
  { name: 'OPPORTUNITIES.status', align: 'right' },
];

export const TableWithPagination = ({ children, thCells = thCells2, paginationProps = {}, loading }) => {
  //   const styles = useStyles({})
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            {thCells.map(({ name, align }) => (
              <TableCell key={name} align={align}>
                <FormattedMessage id={name} />
              </TableCell>
            ))}
          </TableHead>
          {!loading && <TableBody>{children}</TableBody>}
        </Table>
      </TableContainer>
      {loading && (
        <Box height="200px" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={paginationProps.count}
        rowsPerPage={paginationProps.rowsPerPage}
        page={paginationProps.page}
        onChangePage={paginationProps.handleChangePage}
        onChangeRowsPerPage={paginationProps.handleChangeRowsPerPage}
      />
    </Box>
  );
};
