import React from 'react';
import { Table, TableBody } from '@material-ui/core';
import DiscountTableHead from './DiscountTableHead';
import { DiscountItem } from './discount.type';
import { useStyles } from '../styles';
import DiscountTableRow from './DiscountTableRow';

type DiscountTableProps = {
  discounts: DiscountItem[];
};

const DiscountTable = ({ discounts = [] }: DiscountTableProps) => {
  const styles = useStyles();
  return (
    <Table className={styles.table}>
      <DiscountTableHead />
      <TableBody>
        {discounts.map((discountItem) => (
          <DiscountTableRow {...discountItem} key={discountItem._id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default DiscountTable;
