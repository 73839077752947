import React, { useCallback, useMemo, useState } from 'react';
import { TableCell, Hidden, TableRow, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Payment } from 'pages/User/PatientCases/types';
import { Edit } from '@material-ui/icons';
import { convertNumber } from 'utils/formatters';
import { AddPaymentModal, INewPaymentModal } from 'components/Modals/AddPaymentModal';
import { roundTo2 } from 'utils/roundTo2';
import { useStyles } from '../styles';

const PaymentTableRow = (payment: Payment) => {
  const { amount, trsType, paymentNumber, createdAt, createdBy } = payment;

  const styles = useStyles();

  const [addNewPaymentModal, setAddNewPaymentModal] = useState<INewPaymentModal>({
    open: false,
    payment: null,
  });

  const onModalClose = useCallback(() => setAddNewPaymentModal((oldPaymentModal) => ({ ...oldPaymentModal, open: false })), [setAddNewPaymentModal]);

  const onModalOpen = useCallback(
    () => setAddNewPaymentModal((oldPaymentModal) => ({ ...oldPaymentModal, open: true, payment })),
    [payment, setAddNewPaymentModal],
  );

  const formattedAmount = useMemo(() => convertNumber(roundTo2(amount)), [amount]);

  const initialModalValues = useMemo(
    () =>
      addNewPaymentModal.payment
        ? {
            paymentNumber: addNewPaymentModal.payment.paymentNumber,
            trsType: addNewPaymentModal.payment.trsType,
            amount: addNewPaymentModal.payment.amount,
          }
        : undefined,
    [addNewPaymentModal],
  );

  return (
    <TableRow>
      <TableCell className={styles.column}>{format(createdAt, 'dd.MM.yyyy')}</TableCell>
      <TableCell className={styles.column}>{paymentNumber}</TableCell>
      <TableCell className={styles.column}>{formattedAmount}</TableCell>
      <TableCell className={styles.column}>{trsType}</TableCell>
      <Hidden smDown>
        <TableCell className={styles.column}>
          <Link to={`/user/medical_professionals/profile/${createdBy._id}`}>
            {createdBy?.firstName} {createdBy?.lastName}
          </Link>
        </TableCell>
      </Hidden>
      <TableCell>
        <IconButton onClick={onModalOpen} color="primary">
          <Edit />
        </IconButton>
      </TableCell>

      <AddPaymentModal
        initialValues={initialModalValues}
        paymentId={addNewPaymentModal.payment ? addNewPaymentModal.payment._id : undefined}
        open={addNewPaymentModal.open}
        onClose={onModalClose}
      />
    </TableRow>
  );
};

export default PaymentTableRow;
