import React, { FC } from 'react';
import { Typography, AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';
import { CaseListItem } from 'pages/User/PatientCases/types';

import CovidItem from './CovidItem';

interface CovidProps {
  covidInfo: CaseListItem['covidInfo'];
}

const accordionStyles: React.CSSProperties = { flexDirection: 'column' };

const Covid: FC<CovidProps> = ({ covidInfo = [] }) => (
  <Accordion elevation={1}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography variant="h6">Covid 19</Typography>
    </AccordionSummary>
    <AccordionDetails style={accordionStyles}>
      {covidInfo.map((covidInfoItem) => (
        <CovidItem covidInfo={covidInfoItem} key={covidInfoItem.id} />
      ))}
    </AccordionDetails>
  </Accordion>
);

export default Covid;
