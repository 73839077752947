import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Typography, Box, TextField, Input, Grid, GridSize, Paper, IconButton } from '@material-ui/core';

import Delete from '@material-ui/icons/Delete';
import { useStoreState } from 'easy-peasy';
import { FormattedMessage } from 'react-intl';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isValid } from 'date-fns';
import { useFormik } from 'formik';
import usePermissions from 'utils/usePermissions';
import Linkify from 'react-linkify';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { Link, useHistory, useParams } from 'react-router-dom';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import Comments from 'components/Comments';
import { useTranslations } from 'utils/useTranslations';
import { convertDate } from 'utils/formatters';
import Creator from 'components/Creator';
import { UserTypeEnum } from 'types';
import { MedicalRecordPdfAlt } from 'components/MedicalRecordPdf/MedicalRecordAltPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Edit } from '@material-ui/icons';
import EditMedicalRecordAltModal from 'components/Modals/EditMedicalRecordAltModal';

import MedicalRecordSKUTable from 'components/MedicalRecordSKUTable';
import ImagePreview from 'components/ImagePreview';
import useStyles from './style';
import { controlDeMedicamentos, signosVitalesAlt } from './constants';
import UploadFile from './UploadFile';

const localeMap = {
  es: esLocale,
  en: enLocale,
};

const MedicalRecordAlt = () => {
  const style = useStyles();
  const lang = useStoreState((state) => state.lang);
  const intl = useTranslations();
  const [editMode, setEditMode] = useState(false);
  const { push } = useHistory();
  const [editRecordModal, setEditRecordModal] = useState({
    open: false,
    data: null,
    category: null,
  });

  // const [editOrderSummaryMode, setEditOrderSummaryMode] = useState(false);
  const [loading, setLoading] = useState({
    editOrDeleteAction: false,
    newCommentAction: false,
    fieldRemoveAction: false,
    medicalEvolutionNote: false,
    orderSummary: false,
    signosVitalesAlt: false,
    controlDeMedicamentosAlt: false,
    radiologyExamLink: false,
    laboratoryExamLink: false,
  });
  const [nurseComment, setNurseComment] = useState('');

  const { canCrudCasesAndRecords, canSignMedicalRecord, canEditSignedMedicalRecord, canAddNurseObservation, canDoctorEditDetailedMedicalRecord } =
    usePermissions();

  const { recordId, caseId, patient } = useParams<{ recordId: string; caseId: string; patient: string }>();
  const medicalRecord = useStoreState((state) => state.medicalRecords.getById(recordId));
  const user = useStoreState((state) => state.user);
  const { deleteRecord, update, sign, addComment, removeComment, updateComment } = useMedicalRecordFetch();

  const patients = useStoreState((state) => state.patientsMap);
  const patientRec = patients[patient];

  const isEditable = useMemo(() => {
    if (medicalRecord.signed && !canEditSignedMedicalRecord) {
      return false;
    }

    if (user.type === 'doctor') {
      return canDoctorEditDetailedMedicalRecord(patient);
    }

    return true;
  }, [medicalRecord, canEditSignedMedicalRecord]);

  const onRemoveClick = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: true }));
      await deleteRecord(recordId, caseId, patient);
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
    }
  };

  const onEditPress = async (values) => {
    try {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: true }));

      await update(recordId, values);
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
      setEditMode(false);
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, editOrDeleteAction: false }));
    }
  };

  const [pdfLoading, setPdfLoading] = useState(true);

  useEffect(() => {
    setPdfLoading(true);
    setTimeout(() => {
      setPdfLoading(false);
    }, 500);
  }, [medicalRecord]);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      caseId,
      recordId,
      name: medicalRecord.name || '',
      nurseComment: '',
      orderSummaryAlt: {
        text: '',
      },
      laboratoryExamLink: {
        text: '',
      },
      radiologyExamLink: {
        text: '',
      },
      medicalEvolutionNote: {
        text: '',
      },
      signosVitalesAlt: {
        date: '',
        hour: '',
        'p/a': '',
        'f/c': '',
        breathing: '',
        temperature: '',
        observations: '',
        signature: '',
      },
      controlDeMedicamentosAlt: {
        initialDate: '',
        medicine: '',
        hour: '',
        day: '',
        bed: '',
        room: '',
      },
    },
    onSubmit: onEditPress,
    enableReinitialize: true,
  });

  const checkIfArrayWithData = (array) => {
    if (Array.isArray(array)) {
      return array;
    }
    return [];
  };

  const buildBeforeSending = useCallback(
    (valuesObject) => ({
      ...valuesObject,
      createdBy: `${user.firstName} ${user.lastName}`,
      userType: user.type,
      createdAt: new Date(),
      userId: user._id,
    }),
    [user],
  );

  const handleSavePress = useCallback(
    async (savedBlock: string) => {
      switch (savedBlock) {
        case 'medicalEvolutionNote': {
          setLoading((prevState) => ({ ...prevState, medicalEvolutionNote: true }));
          await update(recordId, {
            medicalEvolutionNote: [buildBeforeSending(values.medicalEvolutionNote), ...checkIfArrayWithData(medicalRecord.medicalEvolutionNote)],
          });
          setFieldValue('medicalEvolutionNote', { text: '' });
          setLoading((prevState) => ({ ...prevState, medicalEvolutionNote: false }));
          break;
        }
        case 'orderSummary': {
          setLoading((prevState) => ({ ...prevState, orderSummary: true }));
          await update(recordId, {
            orderSummaryAlt: [buildBeforeSending(values.orderSummaryAlt), ...checkIfArrayWithData(medicalRecord.orderSummaryAlt)],
          });
          setFieldValue('orderSummaryAlt', { text: '' });
          setLoading((prevState) => ({ ...prevState, orderSummary: false }));
          break;
        }
        case 'signosVitalesAlt': {
          setLoading((prevState) => ({ ...prevState, signosVitalesAlt: true }));
          await update(recordId, {
            signosVitalesAlt: [buildBeforeSending(values.signosVitalesAlt), ...checkIfArrayWithData(medicalRecord.signosVitalesAlt)],
          });
          setFieldValue('signosVitalesAlt', {
            date: '',
            hour: '',
            'p/a': '',
            'f/c': '',
            breathing: '',
            temperature: '',
            observations: '',
            signature: '',
          });
          setLoading((prevState) => ({ ...prevState, signosVitalesAlt: false }));
          break;
        }
        case 'controlDeMedicamentosAlt': {
          setLoading((prevState) => ({ ...prevState, controlDeMedicamentosAlt: true }));
          await update(recordId, {
            controlDeMedicamentosAlt: [
              buildBeforeSending(values.controlDeMedicamentosAlt),
              ...checkIfArrayWithData(medicalRecord.controlDeMedicamentosAlt),
            ],
          });
          setFieldValue('controlDeMedicamentosAlt', {
            initialDate: '',
            medicine: '',
            hour: '',
            day: '',
            bed: '',
            room: '',
          });
          setLoading((prevState) => ({ ...prevState, controlDeMedicamentosAlt: false }));
          break;
        }
        case 'radiologyExamLink': {
          setLoading((prevState) => ({ ...prevState, radiologyExamLink: true }));
          await update(recordId, {
            radiologyExamLink: [buildBeforeSending(values.radiologyExamLink), ...checkIfArrayWithData(medicalRecord.radiologyExamLink)],
          });
          setFieldValue('radiologyExamLink', { text: '' });
          setLoading((prevState) => ({ ...prevState, radiologyExamLink: false }));
          break;
        }
        case 'laboratoryExamLink': {
          setLoading((prevState) => ({ ...prevState, laboratoryExamLink: true }));
          await update(recordId, {
            laboratoryExamLink: [buildBeforeSending(values.laboratoryExamLink), ...checkIfArrayWithData(medicalRecord.laboratoryExamLink)],
          });
          setFieldValue('laboratoryExamLink', { text: '' });
          setLoading((prevState) => ({ ...prevState, laboratoryExamLink: false }));
          break;
        }
        default:
          break;
      }
    },
    [values, update, setLoading, recordId],
  );

  const onEditModalClose = () => {
    setEditRecordModal({ open: false, data: null, category: null });
  };

  const handleUpdatePress = useCallback(
    async (savedBlock: string, data: any) => {
      switch (savedBlock) {
        case 'medicalEvolutionNote': {
          await update(recordId, {
            medicalEvolutionNote: checkIfArrayWithData(medicalRecord.medicalEvolutionNote).map((item) =>
              item.createdAt === data.createdAt ? data : item,
            ),
          });
          break;
        }
        case 'orderSummary': {
          await update(recordId, {
            orderSummaryAlt: checkIfArrayWithData(medicalRecord.orderSummaryAlt).map((item) => (item.createdAt === data.createdAt ? data : item)),
          });

          break;
        }
        case 'signosVitalesAlt': {
          await update(recordId, {
            signosVitalesAlt: checkIfArrayWithData(medicalRecord.signosVitalesAlt).map((item) => (item.createdAt === data.createdAt ? data : item)),
          });

          break;
        }
        case 'controlDeMedicamentosAlt': {
          await update(recordId, {
            controlDeMedicamentosAlt: checkIfArrayWithData(medicalRecord.controlDeMedicamentosAlt).map((item) =>
              item.createdAt === data.createdAt ? data : item,
            ),
          });

          break;
        }
        case 'radiologyExamLink': {
          await update(recordId, {
            radiologyExamLink: checkIfArrayWithData(medicalRecord.radiologyExamLink).map((item) => (item.createdAt === data.createdAt ? data : item)),
          });
          break;
        }
        case 'laboratoryExamLink': {
          await update(recordId, {
            laboratoryExamLink: checkIfArrayWithData(medicalRecord.laboratoryExamLink).map((item) =>
              item.createdAt === data.createdAt ? data : item,
            ),
          });
          break;
        }
        case 'comment': {
          await updateComment(recordId, data);
          break;
        }
        default:
          break;
      }

      onEditModalClose();
    },
    [values, update, updateComment, setLoading, recordId, onEditModalClose, medicalRecord],
  );

  const onEditModalOpen = (category, prevData) => {
    setEditRecordModal({ open: true, data: prevData, category });
  };

  const handleRemovePress = useCallback(
    (savedBlock: string) => async (e: any) => {
      switch (savedBlock) {
        case 'medicalEvolutionNote': {
          setLoading((prevState) => ({ ...prevState, medicalEvolutionNote: true }));
          await update(recordId, {
            medicalEvolutionNote: checkIfArrayWithData(medicalRecord.medicalEvolutionNote).filter((item) => item.createdAt !== e.currentTarget.id),
          });
          setLoading((prevState) => ({ ...prevState, medicalEvolutionNote: false }));
          break;
        }
        case 'orderSummary': {
          setLoading((prevState) => ({ ...prevState, orderSummary: true }));
          await update(recordId, {
            orderSummaryAlt: checkIfArrayWithData(medicalRecord.orderSummaryAlt).filter((item) => item.createdAt !== e.currentTarget.id),
          });
          setLoading((prevState) => ({ ...prevState, orderSummary: false }));
          break;
        }
        case 'signosVitalesAlt': {
          setLoading((prevState) => ({ ...prevState, signosVitalesAlt: true }));
          await update(recordId, {
            signosVitalesAlt: checkIfArrayWithData(medicalRecord.signosVitalesAlt).filter((item) => item.createdAt !== e.currentTarget.id),
          });

          setLoading((prevState) => ({ ...prevState, signosVitalesAlt: false }));
          break;
        }
        case 'controlDeMedicamentosAlt': {
          setLoading((prevState) => ({ ...prevState, controlDeMedicamentosAlt: true }));
          await update(recordId, {
            controlDeMedicamentosAlt: checkIfArrayWithData(medicalRecord.controlDeMedicamentosAlt).filter(
              (item) => item.createdAt !== e.currentTarget.id,
            ),
          });

          setLoading((prevState) => ({ ...prevState, controlDeMedicamentosAlt: false }));
          break;
        }
        case 'radiologyExamLink': {
          setLoading((prevState) => ({ ...prevState, radiologyExamLink: true }));
          await update(recordId, {
            radiologyExamLink: checkIfArrayWithData(medicalRecord.radiologyExamLink).filter((item) => item.createdAt !== e.currentTarget.id),
          });
          setLoading((prevState) => ({ ...prevState, radiologyExamLink: false }));
          break;
        }
        case 'laboratoryExamLink': {
          setLoading((prevState) => ({ ...prevState, laboratoryExamLink: true }));
          await update(recordId, {
            laboratoryExamLink: checkIfArrayWithData(medicalRecord.laboratoryExamLink).filter((item) => item.createdAt !== e.currentTarget.id),
          });
          setLoading((prevState) => ({ ...prevState, laboratoryExamLink: false }));
          break;
        }
        default:
          break;
      }
    },
    [values, update, setLoading, recordId],
  );

  const nurseComments = useMemo(() => {
    if (!medicalRecord?.comments) {
      return [];
    }
    return medicalRecord.comments
      .filter((comment) => comment.isNurse)
      .slice()
      .reverse();
  }, [medicalRecord]);

  const handleNurseCommentChange = useCallback((e) => {
    setNurseComment(e.target.value);
  }, []);

  const onAddComment = async (commentValue: string, isNurse: boolean) => {
    try {
      setLoading((prevState) => ({ ...prevState, newCommentAction: true }));
      await addComment(recordId, commentValue, isNurse);
      setLoading((prevState) => ({ ...prevState, newCommentAction: false }));
      setNurseComment('');
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, newCommentAction: false }));
    }
  };

  const onRemoveComment = async (createdAt) => {
    try {
      setLoading((prevState) => ({ ...prevState, newCommentAction: true }));
      await removeComment(recordId, createdAt);
      setLoading((prevState) => ({ ...prevState, newCommentAction: false }));
    } catch (err) {
      setLoading((prevState) => ({ ...prevState, newCommentAction: false }));
    }
  };

  const validateSignosVitales = useMemo(() => Object.values(values.signosVitalesAlt).some((item: any) => item?.trim()), [values.signosVitalesAlt]);

  const validateControlDeMedicamentos = useMemo(
    () => Object.values(values.controlDeMedicamentosAlt).some((item: any) => item?.trim()),
    [values.controlDeMedicamentosAlt],
  );

  return (
    <Box width="100%">
      <Box className={style.medicalRecordHeader}>
        <Box display="flex" flexDirection="column">
          {isValid(new Date(medicalRecord.name)) ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[lang]}>
              <DatePicker
                // fullWidth
                format="dd.MM.yyyy"
                style={{ fontSize: 32 }}
                disabled={!editMode}
                margin="normal"
                id="time-picker"
                label={<FormattedMessage id="record.name" description="Name" />}
                value={values.name}
                onChange={(date) => {
                  setFieldValue('name', date);
                }}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <Input
              style={{ fontSize: 32 }}
              value={values.name}
              disabled={!editMode}
              disableUnderline={!editMode}
              onChange={(e) => setFieldValue('name', e.target.value)}
            />
          )}
          <Button
            onClick={() => push(`/user/patient_records/${patient}/cases/${caseId}/medical_records/${recordId}/old`)}
            color="primary"
            variant="contained"
          >
            {intl('medical_record.general_info')}
          </Button>
        </Box>
        {!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && canCrudCasesAndRecords(patient) ? (
          pdfLoading ? (
            <>Cargando documento ...</>
          ) : (
            <PDFDownloadLink fileName={medicalRecord._id} document={<MedicalRecordPdfAlt patient={patientRec} medicalRecord={medicalRecord} />}>
              {({ loading }) => (
                <Button variant="contained" color="primary">
                  {loading ? 'Cargando documento ...' : '¡Descargar ahora!'}
                </Button>
              )}
            </PDFDownloadLink>
          )
        ) : null}

        <Box>
          {canCrudCasesAndRecords(patient) && isEditable && (
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  disabled={loading.editOrDeleteAction || !isEditable}
                  className={style.buttonWithMargin}
                  onClick={onRemoveClick}
                  variant="contained"
                  color="secondary"
                >
                  <Delete />
                </Button>
              </Box>
              {canSignMedicalRecord && (
                <Box mt={2} mb={2}>
                  <Button fullWidth color="primary" variant="contained" onClick={() => sign(recordId)}>
                    {intl('medical_record.sign')}
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {medicalRecord.signed && (
            <Box>
              <Typography>
                {intl('medical_record.signed_by')}:{' '}
                <Link to={`/user/medical_professionals/profile/${medicalRecord.signedBy?._id}`}>
                  {medicalRecord.signedBy?.firstName} {medicalRecord.signedBy?.lastName}
                </Link>
              </Typography>
              <Typography>{convertDate(medicalRecord.signedAt)}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={style.paper}>
        <Box mt={3}>
          {[UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.doctor].includes(user.type) && (
            <>
              <Typography className={style.medicalRecordH5} variant="h5">
                {intl('medical_records.medicalEvolutionNote')}
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                name="medicalEvolutionNote.text"
                label={intl('medical_records.medicalEvolutionNote')}
                value={values.medicalEvolutionNote.text}
                onChange={handleChange}
              />
              <Box mt={1}>
                <Button
                  onClick={() => handleSavePress('medicalEvolutionNote')}
                  disabled={loading.medicalEvolutionNote || !isEditable || !values.medicalEvolutionNote.text.trim()}
                  variant="contained"
                  color="primary"
                >
                  {intl('services.add')}
                </Button>
              </Box>
            </>
          )}
        </Box>

        {medicalRecord.medicalEvolutionNote?.map((medicalEvolutionNoteItem) => (
          <Box key={medicalEvolutionNoteItem.createdAt} mt="16px" p="16px" component={Paper}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={style.usefulText}>{medicalEvolutionNoteItem.text}</Typography>
              {user.type === 'admin' && (
                <Box>
                  <IconButton
                    id={medicalEvolutionNoteItem.createdAt}
                    onClick={() => onEditModalOpen('medicalEvolutionNote', medicalEvolutionNoteItem)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" id={medicalEvolutionNoteItem.createdAt} onClick={handleRemovePress('medicalEvolutionNote')}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Creator item={medicalEvolutionNoteItem} />
          </Box>
        ))}

        <Box mt={3}>
          {[UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.doctor].includes(user.type) && (
            <>
              <Typography className={style.medicalRecordH5} variant="h5">
                {intl('medical_records.orderSummary')}
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="orderSummaryAlt.text"
                label={intl('medical_records.orderSummary')}
                value={values.orderSummaryAlt.text}
                onChange={handleChange}
              />
              <Box mt={1}>
                <Button
                  onClick={() => handleSavePress('orderSummary')}
                  disabled={loading.orderSummary || !isEditable || !values.orderSummaryAlt.text.trim()}
                  variant="contained"
                  color="primary"
                >
                  {intl('services.add')}
                </Button>
              </Box>
            </>
          )}
        </Box>

        {medicalRecord.orderSummaryAlt?.map((orderSummaryAltItem) => (
          <Box key={orderSummaryAltItem.createdAt} mt="16px" p="16px" component={Paper}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={style.usefulText}>{orderSummaryAltItem.text}</Typography>
              {user.type === 'admin' && (
                <Box>
                  <IconButton id={orderSummaryAltItem.createdAt} onClick={() => onEditModalOpen('orderSummary', orderSummaryAltItem)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" id={orderSummaryAltItem.createdAt} onClick={handleRemovePress('orderSummary')}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Creator item={orderSummaryAltItem} />
          </Box>
        ))}

        <Box mt={3}>
          <Typography className={style.medicalRecordH5} variant="h5">
            {intl('user.nurse_observation')}
          </Typography>

          {canAddNurseObservation && (
            <Box>
              <TextField
                fullWidth
                multiline
                rows={4}
                disabled={!isEditable}
                value={nurseComment}
                onChange={handleNurseCommentChange}
                variant="outlined"
                label={<FormattedMessage id="user.nurse_observation" description="Add comment" />}
              />
              <Box mt={1}>
                <Button
                  disabled={loading.newCommentAction || !isEditable || !nurseComment.trim()}
                  variant="contained"
                  color="primary"
                  onClick={() => onAddComment(nurseComment, true)}
                >
                  <FormattedMessage id="services.add" description="Add comment" />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box mt={3}>
          <Comments onEditComment={onEditModalOpen} onRemoveComment={onRemoveComment} comments={nurseComments} />
        </Box>

        <Box mt={3}>
          <Typography className={style.medicalRecordH5} variant="h5">
            {intl('medical_record.vital_signs')}
          </Typography>

          {[UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.doctor].includes(user.type) && (
            <>
              <Grid container>
                {signosVitalesAlt.map((item) => (
                  <Grid
                    className={style.signosVitalesAltGrid}
                    spacing={2}
                    key={item.name}
                    xs={item.xs as GridSize}
                    sm={item.sm as GridSize}
                    md={item.md as GridSize}
                  >
                    <TextField
                      value={values.signosVitalesAlt[item.name]}
                      onChange={handleChange}
                      name={`signosVitalesAlt.${item.name}`}
                      style={{ width: '95%' }}
                      variant="outlined"
                      label={intl(item.translationKey)}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box mt={1}>
                <Button
                  onClick={() => handleSavePress('signosVitalesAlt')}
                  disabled={loading.newCommentAction || !isEditable || !validateSignosVitales}
                  variant="contained"
                  color="primary"
                >
                  {intl('services.add')}
                </Button>
              </Box>
            </>
          )}
        </Box>

        {medicalRecord.signosVitalesAlt?.map((signosVitalesItem) => (
          <Box key={signosVitalesItem.createdAt} mt="16px" p="16px" component={Paper}>
            {user.type === 'admin' && (
              <Box display="flex" justifyContent="flex-end">
                <IconButton id={signosVitalesItem.createdAt} onClick={() => onEditModalOpen('signosVitalesAlt', signosVitalesItem)}>
                  <Edit />
                </IconButton>
                <IconButton color="secondary" id={signosVitalesItem.createdAt} onClick={handleRemovePress('signosVitalesAlt')}>
                  <Delete />
                </IconButton>
              </Box>
            )}
            <Grid container>
              {signosVitalesAlt.map((item) => (
                <Grid
                  className={style.signosVitalesAltGrid}
                  spacing={2}
                  key={item.name}
                  xs={item.xs as GridSize}
                  sm={item.sm as GridSize}
                  md={item.md as GridSize}
                >
                  <Typography className={style.subSectionHeader}>{intl(item.translationKey)}</Typography>
                  <Typography>{signosVitalesItem[item.name]}</Typography>
                </Grid>
              ))}
            </Grid>
            <Creator item={signosVitalesItem} />
          </Box>
        ))}

        <Box mt={3}>
          <Typography className={style.medicalRecordH5} variant="h5">
            {intl('medical_record.medicaments_control')}
          </Typography>

          {[UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.nurse].includes(user.type) && (
            <>
              <Grid container>
                {controlDeMedicamentos.map((item) => (
                  <Grid
                    className={style.signosVitalesAltGrid}
                    spacing={2}
                    key={item.name}
                    xs={item.xs as GridSize}
                    sm={item.sm as GridSize}
                    md={item.md as GridSize}
                  >
                    <TextField
                      value={values.controlDeMedicamentosAlt[item.name]}
                      onChange={handleChange}
                      name={`controlDeMedicamentosAlt.${item.name}`}
                      style={{ width: '95%' }}
                      variant="outlined"
                      label={intl(item.translationKey)}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box mt={1}>
                <Button
                  onClick={() => handleSavePress('controlDeMedicamentosAlt')}
                  disabled={loading.newCommentAction || !isEditable || !validateControlDeMedicamentos}
                  variant="contained"
                  color="primary"
                >
                  {intl('services.add')}
                </Button>
              </Box>
            </>
          )}
        </Box>

        {medicalRecord.controlDeMedicamentosAlt?.map((controlDeMedicamentosItem) => (
          <Box key={controlDeMedicamentosItem.createdAt} mt="16px" p="16px" component={Paper}>
            {user.type === 'admin' && (
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  id={controlDeMedicamentosItem.createdAt}
                  onClick={() => onEditModalOpen('controlDeMedicamentosAlt', controlDeMedicamentosItem)}
                >
                  <Edit />
                </IconButton>
                <IconButton color="secondary" id={controlDeMedicamentosItem.createdAt} onClick={handleRemovePress('controlDeMedicamentosAlt')}>
                  <Delete />
                </IconButton>
              </Box>
            )}
            <Grid container>
              {controlDeMedicamentos.map((item) => (
                <Grid
                  className={style.signosVitalesAltGrid}
                  spacing={2}
                  key={item.name}
                  xs={item.xs as GridSize}
                  sm={item.sm as GridSize}
                  md={item.md as GridSize}
                >
                  <Typography className={style.subSectionHeader}>{intl(item.translationKey)}</Typography>
                  <Typography>{controlDeMedicamentosItem[item.name]}</Typography>
                </Grid>
              ))}
            </Grid>
            <Creator item={controlDeMedicamentosItem} />
          </Box>
        ))}

        <Box mt={3}>
          <Typography className={style.medicalRecordH5} variant="h5">
            {intl('medical_record.laboratoryExamLink')}
          </Typography>
          {[UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.technician_labs].includes(user.type) && (
            <>
              <TextField
                onChange={handleChange}
                fullWidth
                name="laboratoryExamLink.text"
                value={values.laboratoryExamLink.text}
                placeholder="https://"
                variant="outlined"
                label={intl('medical_record.laboratoryExamLink')}
              />
              <Box mt={1}>
                <Button
                  onClick={() => handleSavePress('laboratoryExamLink')}
                  disabled={loading.newCommentAction || !isEditable || !values.laboratoryExamLink.text.trim()}
                  variant="contained"
                  color="primary"
                >
                  {intl('services.add')}
                </Button>
                <UploadFile className={style.uploadButtonContainer} fieldName="laboratoryExamLink" medicalRecord={medicalRecord} user={user} />
              </Box>
            </>
          )}
        </Box>

        {medicalRecord.laboratoryExamLink?.map((laboratoryExamLinkItem) => (
          <Box key={laboratoryExamLinkItem.createdAt} mt="16px" p="16px" component={Paper}>
            <Box display="flex" justifyContent="space-between">
              <Box width="calc(100% - 40px)">
                <ImagePreview imageUrl={laboratoryExamLinkItem.text} />
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  <Typography className={style.usefulText}>{laboratoryExamLinkItem.text}</Typography>
                </Linkify>
              </Box>

              {user.type === 'admin' && (
                <Box>
                  <IconButton id={laboratoryExamLinkItem.createdAt} onClick={() => onEditModalOpen('laboratoryExamLink', laboratoryExamLinkItem)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" id={laboratoryExamLinkItem.createdAt} onClick={handleRemovePress('laboratoryExamLink')}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Creator item={laboratoryExamLinkItem} />
          </Box>
        ))}

        <Box mt={3}>
          <Typography className={style.medicalRecordH5} variant="h5">
            {intl('medical_record.radiologyExamLink')}
          </Typography>
          {[UserTypeEnum.admin, UserTypeEnum.staff, UserTypeEnum.technician_radiology].includes(user.type) && (
            <>
              <TextField
                onChange={handleChange}
                value={values.radiologyExamLink.text}
                name="radiologyExamLink.text"
                fullWidth
                placeholder="https://"
                variant="outlined"
                label={intl('medical_record.radiologyExamLink')}
              />
              <Box mt={1}>
                <Button
                  onClick={() => handleSavePress('radiologyExamLink')}
                  disabled={loading.newCommentAction || !isEditable || !values.radiologyExamLink.text.trim()}
                  variant="contained"
                  color="primary"
                >
                  {intl('services.add')}
                </Button>
                <UploadFile className={style.uploadButtonContainer} fieldName="radiologyExamLink" medicalRecord={medicalRecord} user={user} />
              </Box>
            </>
          )}
        </Box>

        {medicalRecord.radiologyExamLink?.map((radoilogyExamLinkItem) => (
          <Box key={radoilogyExamLinkItem.createdAt} mt="16px" p="16px" component={Paper}>
            <Box display="flex" justifyContent="space-between">
              <Box width="calc(100% - 40px)">
                <ImagePreview imageUrl={radoilogyExamLinkItem.text} />
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  <Typography className={style.usefulText}>{radoilogyExamLinkItem.text}</Typography>
                </Linkify>
              </Box>
              {user.type === 'admin' && (
                <Box>
                  <IconButton id={radoilogyExamLinkItem.createdAt} onClick={() => onEditModalOpen('radiologyExamLink', radoilogyExamLinkItem)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" id={radoilogyExamLinkItem.createdAt} onClick={handleRemovePress('radiologyExamLink')}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Creator item={radoilogyExamLinkItem} />
          </Box>
        ))}

        <Box className={style.paper}>
          <MedicalRecordSKUTable isSignedAndEditable={isEditable} catalog={medicalRecord.catalog} />
        </Box>
      </Box>

      <EditMedicalRecordAltModal onSave={handleUpdatePress} {...editRecordModal} onClose={onEditModalClose} />
    </Box>
  );
};

export default MedicalRecordAlt;
