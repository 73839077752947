import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  medRecordsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 44,
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: { flexDirection: 'column', marginTop: 20 },
  },
  medRecordsHeader: {
    marginTop: 44,
  },
  caseHeader: {
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: { fontSize: 24, marginTop: 30 },
  },
  caseBoxItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',

      alignItems: 'flex-start',
      paddingLeft: 16,
      marginTop: 8,
    },
  },
  caseBoxIndent: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,
    },
  },
  caseBtnHeaderText: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  caseBtnText: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  contentText: {
    textAlign: 'left',
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down('xs')]: { textAlign: 'left' },
  },
  resetLinkDecorationRecords: {
    textDecoration: 'none',
    fontSize: '6px',
    [theme.breakpoints.down('xs')]: { marginTop: 20 },
  },
  container: {
    '&:first-child': {
      marginTop: -24,
    },
  },
}));
