import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  medRecordsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 44,
    marginBottom: 6,
    [theme.breakpoints.down('sm')]: { flexDirection: 'column', marginTop: 20 },
  },
  medRecordsHeader: {
    marginTop: 44,
  },
  caseHeader: {
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: { fontSize: 24, marginTop: 30 },
  },
  caseBoxItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      alignItems: 'flex-start',
      paddingLeft: 16,
    },
  },
  caseBoxIndent: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  caseBtnHeaderText: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  caseBtnText: {
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  contentText: {
    // textAlign: 'left',
    // fontSize: 16,
    // marginTop: 8,
    // [theme.breakpoints.down('sm')]: { textAlign: 'left' },
  },
  resetLinkDecorationRecords: {
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: { marginTop: 12 },
  },
}));
