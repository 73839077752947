import React, { useState, useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';

import useCaseFetch from 'api/Case';

import { useStoreActions } from 'easy-peasy';
import { GetPaymentTypes } from 'types/paymentTypes';
import PatientCase from '../PatientCase';
import MedicalRecordContainer from '../MedicalRecordContainer';
import Covid19 from '../Covid19';
import Covid19Result from '../Covid19/Covid19Result';

const PatientCaseContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { caseId, patient } = useParams<{ caseId: string; patient: string }>();

  const { getCase } = useCaseFetch();
  const getPaymentTypes = useStoreActions((actions) => actions.getPaymentTypesThunk) as GetPaymentTypes;

  useEffect(() => {
    setLoading(true);
    getCase(caseId, patient)
      .then(() => getPaymentTypes())
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="500px" width="100%">
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Route path="/user/patient_records/:patient/cases/:caseId/medical_records/:recordId" component={MedicalRecordContainer} />
      <Route exact path="/user/patient_records/:patient/cases/:caseId" component={PatientCase} />
      <Route exact path="/user/patient_records/:patient/cases/:caseId/covid19" component={Covid19} />
      <Route exact path="/user/patient_records/:patient/cases/:caseId/covid19/:covidId" component={Covid19Result} />
    </>
  );
};
export default PatientCaseContainer;
