import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import useCaseFetch from 'api/Case';
import Loader from 'components/Loader';
import { useStoreState } from 'easy-peasy';
import { useTranslations } from 'utils/useTranslations';
import { useParams } from 'react-router-dom';
import { CaseListItem } from '../PatientCases/types';
import PatientAccountItem from './PatientAccountItem';

interface ParamType {
  patient: string;
}

const PatientAccounts = () => {
  // const classes = useStyles({});
  const { getCases } = useCaseFetch();
  const { patient } = useParams<ParamType>();
  const [loading, setLoading] = useState(false);
  const intl = useTranslations();

  const cases: CaseListItem[] = useStoreState((state) => state.cases.getByPatientId(patient));

  const loadCases = async () => {
    try {
      if (!cases.length) {
        setLoading(true);
      }
      await getCases(patient);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCases();
  }, []);

  if (loading) {
    return <Loader height="calc(100vh - 350px)" />;
  }

  if (cases.length === 0) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 350px)">
        <Typography variant="h5" align="center">
          {intl('case.noCases')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {cases.map((caseItem) => (
        <PatientAccountItem caseItem={caseItem} />
      ))}
    </Box>
  );
};
export default PatientAccounts;
