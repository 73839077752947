import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    minWidth: 320,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(8),
  },

  mobileLogout: {
    [theme.breakpoints.down('sm')]: { padding: '30px' },
  },
  appBar: {
    width: '100%',
    background: 'inherit',
    boxShadow: 'none',
    marginBottom: 30,
  },
  tabs: {
    background: 'inherit',
    border: 0,
    borderRadius: 3,
    height: 48,
    padding: '0 30px',
    color: 'white',
    paddingLeft: 0,
  },
  button: {
    '&:hover': {
      border: 'none',
    },
    marginLeft: 20,
    minWidth: 130,
  },
  tab: {
    '&:hover': {
      border: 0,
      outline: 'none',
    },
    '&:focus': {
      border: 0,
      outline: 'none',
    },
  },

  formControl: {
    width: 200,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  input: {
    marginBottom: 20,
    '&:first-child': {
      marginTop: 20,
    },
  },
}));

export default useStyles;
