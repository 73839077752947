import React, { memo, useMemo, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, GridSize, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslations } from 'utils/useTranslations';
import { controlDeMedicamentos, signosVitalesAlt } from 'pages/User/MedicalRecord/constants';

const useStyles = makeStyles({
  linkTextField: {
    width: 400,
    maxWidth: '100%',
  },
});

const EditMedicalRecordAltModal = ({ open, data, category, onClose, onSave }) => {
  const intl = useTranslations();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { values, handleChange, setValues, handleSubmit } = useFormik({
    initialValues: data,
    // enableReinitialize: true,
    onSubmit: async (formValues) => {
      try {
        setLoading(true);
        await onSave(category, formValues);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    setValues(data);
  }, [data, setValues]);

  const isSimpleCat = useMemo(
    () => ['medicalEvolutionNote', 'orderSummary', 'radiologyExamLink', 'laboratoryExamLink'].includes(category),
    [category],
  );

  const isSignosVitalesAlt = useMemo(() => category === 'signosVitalesAlt', [category]);

  const isControlDeMedicamentosAlt = useMemo(() => category === 'controlDeMedicamentosAlt', [category]);

  const isComment = useMemo(() => category === 'comment', [category]);

  return (
    <Dialog open={open} onClose={loading ? () => null : onClose}>
      <DialogTitle>{intl('case.editRecord')}</DialogTitle>

      <DialogContent>
        {isSimpleCat && (
          <TextField
            multiline
            className={classes.linkTextField}
            rows={4}
            fullWidth
            variant="outlined"
            name="text"
            label={intl(`medical_records.${category}`)}
            value={values?.text}
            onChange={handleChange}
          />
        )}
        {isComment && (
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            name="comment"
            label={intl('user.nurse_observation')}
            value={values?.comment}
            onChange={handleChange}
          />
        )}
        {isControlDeMedicamentosAlt && (
          <Grid container>
            {controlDeMedicamentos.map((item) => (
              <Grid
                // className={style.signosVitalesAltGrid}
                spacing={2}
                key={item.name}
                xs={item.xs as GridSize}
                sm={item.sm as GridSize}
              >
                <TextField
                  value={values?.[item.name]}
                  onChange={handleChange}
                  name={item.name}
                  style={{ width: '95%', marginBottom: 12 }}
                  variant="outlined"
                  label={intl(item.translationKey)}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {isSignosVitalesAlt && (
          <Grid container>
            {signosVitalesAlt.map((item) => (
              <Grid
                // className={style.signosVitalesAltGrid}
                spacing={2}
                key={item.name}
                xs={item.xs as GridSize}
                sm={item.sm as GridSize}
              >
                <TextField
                  value={values?.[item.name]}
                  onChange={handleChange}
                  name={item.name}
                  style={{ width: '95%', marginBottom: 12 }}
                  variant="outlined"
                  label={intl(item.translationKey)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="secondary">
          {intl('case.cancel')}
        </Button>
        <Button disabled={loading} color="primary" onClick={() => handleSubmit()}>
          {intl('case.edit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(EditMedicalRecordAltModal);
