/* eslint-disable max-len */
import { array, string } from 'yup';
import { countries } from './countriesList';

export const questioneer = [
  {
    pageName: 1,
    questions: [
      {
        name: 'sex',
        validation: string().required(),
        control: 'radio',
        label: 'covid19.What sex were you assigned at birth?',
        options: [
          {
            label: 'covid19.Male',
            value: '0',
          },
          {
            label: 'covid19.Female',
            value: '1',
          },
          {
            label: 'covid19.Other / Prefer not to say',
            value: '2',
          },
        ],
      },
      {
        name: 'age',
        control: 'radio',
        label: 'covid19.What is your age group?',
        options: [
          {
            label: '0-19',
            value: '0-19',
          },
          {
            label: '20-29',
            value: '20-29',
          },
          {
            label: '30-39',
            value: '30-39',
          },
          {
            label: '40-49',
            value: '40-49',
          },
          {
            label: '50-59',
            value: '50-59',
          },
          {
            label: '60-69',
            value: '60-69',
          },
          {
            label: '70-79',
            value: '70-79',
          },
          {
            label: '80+',
            value: '80+',
          },
          {
            label: 'covid19.I prefer not to say',
            value: 'I prefer not to say',
          },
        ],
      },
      {
        name: 'health',
        control: 'radio',
        label: 'covid19.How is your health?',
        options: [
          {
            label: 'covid19.I am very fit and rarely ill',
            value: '0',
          },
          {
            label: 'covid19.I am fitter and healthier than average',
            value: '1',
          },
          {
            label: 'covid19.I am less fit and healthy than average',
            value: '2',
          },
          {
            label: "covid19.I'm not very healthy at all",
            value: '3',
          },
        ],
      },
      {
        name: 'healthProblems',
        control: 'checkbox',
        validation: array().required(),
        label: 'covid19.Which of the following do you have/apply to you?',
        options: [
          {
            label: 'covid19.None of the following',
            value: '',
          },
          {
            label: 'covid19.Mild heart condition',
            value: 'mildHeartCondition',
          },
          {
            label: 'covid19.Chronic heart condition',
            value: 'chronicHeartCondition',
          },
          {
            label: 'covid19.Mild lung problem like mild asthma',
            value: 'mildLungCondition',
          },
          {
            label: 'covid19.Chronic lung problems',
            value: 'chronicLungCondition',
          },
          {
            label: 'covid19.Mild or borderline Diabetes',
            value: 'mildDiabetes',
          },
          {
            label: 'covid19.Chronic Diabetes',
            value: 'chronicDiabetes',
          },
          {
            label: 'covid19.Recent (last 5 years) Cancer treatment with chemo',
            value: 'recentCancer',
          },
          {
            label: 'covid19.Past Cancer treatment (over 5 years ago) with chemo',
            value: 'pastCancer',
          },
          {
            label: 'covid19.Reduced kidney function',
            value: 'reducedKidneyFunction',
          },
          {
            label: 'covid19.Chronic liver disease',
            value: 'chronicLiverDisease',
          },
          {
            label: 'covid19.I take immunosuppressant drugs',
            value: 'onImmunosuppressant',
          },
          {
            label: 'covid19.I am pregnant',
            value: 'pregnant',
          },
        ],
      },
      {
        name: 'flu',
        control: 'radio',
        label: 'covid19.Did you get a flu shot in the past year?',
        options: [
          {
            label: 'covid19.Yes',
            value: 'true',
          },
          {
            label: "covid19.No / I don't know / I can't remember",
            value: 'false',
          },
          // {
          //   label: "covid19.I don't know / can't remember",
          //   value: 'false',
          // },
        ],
      },
    ],
  },
  {
    pageName: 2,
    questions: [
      // {
      //   name: 'country',
      //   control: 'select',
      //   label: 'covid19.Where are you living right now?',
      // },
      // {
      //   name: 'region',
      //   control: 'select',
      //   dynamicParamsField: 'country',
      //   label: 'covid19.Region?',
      // },
      // {
      //   name: 'postcode',
      //   control: 'input',
      //   label: 'covid19.Please enter only the first three letters or numbers of your postcode/zipcode.',
      // },
      {
        name: 'travelledToOtherCountries',
        control: 'radio',
        label: 'covid19.Have you travelled to any other countries since January 1st 2020?',
        options: [
          {
            label: 'covid19.No',
            value: '0',
          },
          {
            label: 'covid19.Yes, before they were in "lockdown"',
            value: '1',
          },
          {
            label: 'covid19.Yes, while they were in "lockdown"',
            value: '2',
          },
          {
            label: 'covid19.Yes, after they came out of "lockdown"',
            value: '3',
          },
        ],
      },
    ],
  },
  {
    pageName: 3,
    questions: [
      {
        name: 'highestRiskCountry',
        control: 'select',
        hasOwnLabel: true,
        label:
          'covid19.Which countries have you visited since January 1st 2020? (If you went to multiple countries please pick the one where you think you could have caught COVID-19 or had the highest risk of catching it)',
        options: countries,
      },
      {
        name: 'dateEnteredCountry',
        label: 'covid19.What date did you enter that country?',
        control: 'date',
      },
      {
        name: 'dateLeftCountry',
        label: 'covid19.What date did you leave that country?',
        control: 'date',
      },
    ],
  },
  {
    pageName: 4,
    questions: [
      {
        name: 'didWork',
        control: 'radio',
        options: [
          {
            label: 'covid19.No',
            value: '0',
          },
          {
            label: 'covid19.Yes, but I interacted with few people per day',
            value: '1',
          },
          {
            label: 'covid19.Yes, and I interacted with many people per day',
            value: '2',
          },
          {
            label: 'covid19.Yes, and I interacted with many people/patients per day in a healthcare setting.',
            value: '3',
          },
        ],
        label: "covid19.Did you go to work during your country's lockdown?",
      },
      {
        name: 'avoidCatching',
        control: 'checkbox',
        validation: array().required(),

        options: [
          {
            label: 'covid19.I always kept 6 ft/2 m distance from people.',
            value: 'keepDistanceFromOthers',
          },
          {
            label: 'covid19.I always wore a mask/face covering.',
            value: 'mask',
          },
          {
            label: 'covid19.I never spent more than 30 minutes indoors/in vehicles with others.',
            value: 'timeInVehicles',
          },
          {
            label: 'covid19.I frequently washed my hands and rarely touched my face.',
            value: 'washedHands',
          },
        ],
        label: 'covid19.What did you to avoid catching COVID-19 outside your home?',
      },
      {
        name: 'contactWithCovid19',
        control: 'radio',
        options: [
          {
            label: 'covid19.Possible COVID-19 symptoms',
            value: '1',
          },
          {
            label: 'covid19.Confirmed COVID-19 (Positive diagnosis)',
            value: '2',
          },
          {
            label: 'covid19.None of the above',
            value: '0',
          },
        ],
        label: 'covid19.Outside your home did you spend time with anyone who had or soon after developed...?',
      },
      {
        name: 'amountPeopleLiveWith',
        control: 'input',
        hasOwnLabel: true,
        label: 'covid19.How many people do you live with? (Please type a number, e.g. 2)',
      },
      {
        name: 'householdIllness',
        control: 'radio',
        options: [
          {
            label: 'covid19.No',
            value: '0',
          },
          {
            label: 'covid19.Yes, possibly',
            value: '1',
          },
          {
            label: 'covid19.Yes, definitely (Positive test result)',
            value: '2',
          },
        ],
        label: 'covid19.Do you think anyone in your home might have had COVID-19?',
      },
    ],
  },
  {
    pageName: 5,
    questions: [
      {
        name: 'numberOfIllInHousehold',
        control: 'input',
        hasOwnLabel: true,
        label: 'covid19.How many people who you live with have had possible COVID-19 symptoms? Please type a number, e.g. 2',
      },
      {
        name: 'firstHouseholdIllnessDate',
        label: 'covid19.When did the first person in your house become ill?',
        control: 'date',
      },
    ],
  },
  {
    pageName: 6,
    questions: [
      {
        name: 'hasFeltIll',
        control: 'radio',
        options: [
          {
            label: "covid19.No, I'm fine. I haven't felt ill at all since January 2020",
            value: '0',
          },
          {
            label: "covid19.I'm beginning to feel ill",
            value: '1',
          },
          {
            label: "covid19.I'm now feeling quite ill",
            value: '2',
          },
          {
            label: "covid19.I'm feeling very ill",
            value: '3',
          },
          {
            label: "covid19.I'm still ill but feeling better",
            value: '4',
          },
          {
            label: 'covid19.I was recovering, but now feeling worse again',
            value: '5',
          },
          {
            label: 'covid19.Yes, but I feel fully recovered',
            value: '6',
          },
        ],
        label: "covid19.Have you felt ill at all since January 2020? (If you say no, you won't be asked about symptoms)",
      },
    ],
  },
  {
    pageName: 7,
    questions: [
      {
        name: 'whichSymptoms',
        control: 'checkbox',
        validation: array().required(),

        options: [
          {
            label: 'covid19.Achy joints and/or muscles',
            value: 'achyJointsMuscles',
          },
          {
            label: 'covid19.A headache',
            value: 'headaches',
          },
          {
            label: 'covid19.Chills',
            value: 'chills',
          },
          {
            label: 'covid19.Congested nose',
            value: 'congestedNose',
          },
          {
            label: 'covid19.A cough with sputum',
            value: 'coughWithSputum',
          },
          {
            label: 'covid19.A dry cough',
            value: 'dryCough',
          },
          {
            label: 'covid19.Fever',
            value: 'fever',
          },
          {
            label: 'covid19.Loss of sense of taste/smell',
            value: 'lossTasteSmell',
          },
          {
            label: 'covid19.Really tired',
            value: 'fatigue',
          },
          {
            label: 'covid19.Runny nose',
            value: 'runnyNose',
          },
          {
            label: 'covid19.Short of breath',
            value: 'shortnessOfBreath',
          },
          {
            label: 'covid19.Skin rash',
            value: 'skinRash',
          },
          {
            label: 'covid19.Sneezing',
            value: 'sneezing',
          },
          {
            label: 'covid19.A sore throat',
            value: 'soreThroat',
          },
          {
            label: 'covid19.Stomach disturbance/diarrhoea',
            value: 'stomachUpsetDiarrhoea',
          },
          {
            label: 'covid19.Vomiting/nausea',
            value: 'nausea',
          },
        ],
        label: 'covid19.Which of the following symptoms did/do you have?',
      },
      {
        name: 'howShortOfBreath',
        control: 'radio',
        label: 'covid19.How hard did you, or do you find it to breathe? (0 = mild shortness of breath, 4 = severe shortness of breath)',
        options: [
          {
            label: '0',
            value: '0',
          },
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4',
            value: '4',
          },
        ],
      },
      {
        name: 'howBadDidTheyFeel',
        control: 'radio',
        label: 'covid19.How bad did you, or do you feel overall? (0 = only slightly ill, 4 = seriously ill/went to the hospital)',
        options: [
          {
            label: '0',
            value: '0',
          },
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4',
            value: '4',
          },
        ],
      },
      {
        name: 'howAnxious',
        control: 'radio',
        label: 'covid19.How anxious were/are you about COVID19? (0 = a little anxious, 4 = very anxious)',
        options: [
          {
            label: '0',
            value: '0',
          },
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4',
            value: '4',
          },
        ],
      },
      {
        name: 'firstSymptoms',
        label: 'covid19.When did you first experience symptoms?',
        control: 'date',
      },
      {
        name: 'lengthOfTimeOfSymptoms',
        control: 'radio',
        label: 'covid19.How long did you feel ill/have symptoms for?',
        options: [
          {
            label: 'covid19.A few days or less',
            value: '0',
          },
          {
            label: 'covid19.Less than one week',
            value: '1',
          },
          {
            label: 'covid19.One to two weeks',
            value: '2',
          },
          {
            label: 'covid19.Two to three weeks',
            value: '3',
          },
          {
            label: 'covid19.More than three weeks',
            value: '4',
          },
          {
            label: "covid19.I'm still ill!",
            value: '5',
          },
        ],
      },
      {
        name: 'wereTheyInfected',
        control: 'radio',
        label: 'covid19.Did/Do you think you were infected with Coronavirus / COVID-19?',
        options: [
          {
            label: 'covid19.No way',
            value: '0',
          },
          {
            label: 'covid19.A small chance (~25% sure)',
            value: '1',
          },
          {
            label: 'covid19.A reasonable chance (~50% sure)',
            value: '2',
          },
          {
            label: 'covid19.Pretty sure (~75% sure)',
            value: '3',
          },
          {
            label: 'covid19.Absolutely convinced (~90%+ sure)',
            value: '4',
          },
          {
            label: 'covid19.Yes, 100%: I tested positive',
            value: '5',
          },
          {
            label: 'covid19.Yes, 100%: I was treated in hospital',
            value: '6',
          },
          {
            label: 'covid19.Yes, 100%: I was treated in intensive care',
            value: '7',
          },
        ],
      },
    ],
  },
  {
    pageName: 8,
    questions: [
      {
        name: 'antibodyTest',
        options: [
          {
            label: "covid19.No, I've not done a lateral flow antibody test, or any other COVID tests.",
            value: '0',
          },
          {
            label: "covid19.No, but I've done a different COVID test.",
            value: '1',
          },
          {
            label: 'covid19.Yes, and I tested positive',
            value: '2',
          },
          {
            label: 'covid19.Yes, but I tested negative',
            value: '3',
          },
          {
            label: "covid19.Yes, but I don't know the result",
            value: '4',
          },
        ],
        control: 'radio',
        label: 'covid19.Have you done a lateral flow antibody test to check if you have/had COVID-19?',
      },
      {
        name: 'dateOfTest',
        label: 'covid19.What date did you do the test?',
        control: 'date',
      },
      {
        name: 'testOutcome',
        options: [
          {
            label: 'covid19.C only',
            value: '0',
          },
          {
            label: 'covid19.C and IgG',
            value: '1',
          },
          {
            label: 'covid19.C and IgM',
            value: '2',
          },
          {
            label: 'covid19.C, IgM and IgG',
            value: '3',
          },
          {
            label: 'covid19.IgG only (no C)',
            value: '4',
          },
          {
            label: 'covid19.IgM only (no C)',
            value: '5',
          },
          {
            label: 'covid19.IgG and IgM only (no C)',
            value: '6',
          },
          {
            label: 'covid19.None, no lines',
            value: '7',
          },
          {
            label: 'covid19.None of the above',
            value: '8',
          },
        ],
        control: 'radio',
        label: 'covid19.What did your test result look like?',
      },
    ],
  },
  // {
  //   pageName: 9,
  //   questions: [
  //     {
  //       name: 'emailConsent',
  //       control: 'radio',
  //       options: [
  //         {
  //           label: 'covid19.No, not interested',
  //           value: 'false',
  //         },
  //       ],
  //       label:
  //         'covid19.Would you be interested in knowing about any studies, testing programmes, or trials, to help confirm if you have fully recovered, to donate plasma, or if you may be immune to Corona Virus?',
  //     },
  //   ],
  // },
];
