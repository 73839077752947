const removeFromObject = (obj, fieldsToRemove = []) => {
  const clonedObject = { ...obj }

  Object.keys(clonedObject).forEach((key) => {
    if (fieldsToRemove.includes(key)) delete clonedObject[key]
  })

  return clonedObject
}

export default removeFromObject
