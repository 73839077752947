import { IPaymentMethod } from 'types/paymentMethods';

export const paymentMethods: IPaymentMethod[] = ['self_pay', 'social_security', 'insurance', 'coex'];

export const priceCategoryTopaymentMethodMap = {
  1: 'self_pay',
  2: 'insurance',
  3: 'coex',
  4: 'social_security',
};

export const paymentMethodToPriceCategoryMap = {
  self_pay: 1,
  insurance: 2,
  coex: 3,
  social_security: 4,
};
