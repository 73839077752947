import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: { marginBottom: 22 },
  },
  // btnMobile: {},
  caseHeader: {
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: { fontSize: 24 },
  },
  caseBoxItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      alignItems: 'flex-start',
      // paddingLeft: 16,
      marginTop: 8,
    },
  },
  contentText: {
    textAlign: 'center',
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down('sm')]: { textAlign: 'left' },
  },
  resetLinkDecoration: {
    textDecoration: 'none',
    fontSize: '0.875rem',
    [theme.breakpoints.down('sm')]: { marginTop: 20 },
  },
  caseInnerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'row',
    },
  },
}));
