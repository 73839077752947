import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import prodLogo from 'assets/images/hospitalprivado.png';
import logo from 'assets/images/managemed-logo-2.png';
import { roundTo2 } from 'utils/roundTo2';
import { format, isValid } from 'date-fns';
import { convertDate, convertNumber } from 'utils/formatters';
import { DiscountItem } from 'pages/User/PatientBill/discountTable/discount.type';
import { countTotals } from 'pages/User/PatientBill/PatientBill.utils';

const styles = StyleSheet.create({
  name: {
    marginLeft: 40,
    fontSize: 24,
  },
  medRecordTitle: {
    fontSize: 16,
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 10,
  },
  logo: {
    width: 200,
    marginLeft: 40,
    marginTop: 40,
    marginBottom: 40,
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: 'grey',
    display: 'flex',
    flexDirection: 'row',
  },
  tableCell: {
    borderRightColor: 'grey',
    borderRightWidth: 1,
    minHeight: 25,
    width: 71.42,
    padding: 5,
  },
  tableCellText: {
    fontSize: 12,
  },
  table: {
    borderTopColor: 'grey',
    borderLeftColor: 'grey',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    marginLeft: 40,
    width: 500,
  },
  emptyState: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 12,
  },

  total: {
    fontSize: 18,
    marginTop: 15,
    marginLeft: 40,
    fontWeight: 500,
  },

  paymentsTable: {
    width: 125,
  },

  discountsTable: {
    width: 166.666,
  },

  paymentsLabel: {
    marginTop: 25,
    marginBottom: 15,
  },
  description: {
    width: 140,
  },
  other: {
    width: 60,
  },
  date: {
    width: 70,
  },
  last: {
    width: 50,
  },
});

const columns = [
  { name: 'codigo', translationKey: 'Codigo', className: styles.other },
  { name: 'descripcion', translationKey: 'Descripcion', className: styles.description },
  { name: '', translationKey: 'Precio', valueGetter: ({ precio }) => new Intl.NumberFormat().format(+precio), className: styles.other },
  { name: '', translationKey: 'Cantidad', valueGetter: ({ amount }) => new Intl.NumberFormat().format(+amount), className: styles.other },
  {
    name: '',
    translationKey: 'Total parcial',
    valueGetter: ({ amount, precio }) => new Intl.NumberFormat().format(roundTo2(amount * precio)),
    className: styles.other,
  },
  { name: '', translationKey: 'Fecha de creación ', valueGetter: ({ createdAt }) => convertDate(createdAt), className: styles.date },
  { name: '', translationKey: 'Esta resuelto', valueGetter: ({ resolved }) => (resolved ? 'Si' : 'No'), className: styles.last },
];

const paymentColumns = [
  { name: '', translationKey: 'Fecha', valueGetter: ({ createdAt }) => format(createdAt, 'dd.MM.yyyy') },
  { name: 'paymentNumber', translationKey: 'No. Boleta' },
  { name: '', translationKey: 'Monto', valueGetter: ({ amount }) => roundTo2(amount) },
  { name: '', translationKey: 'Registrado Por', valueGetter: ({ createdBy }) => `${createdBy?.firstName} ${createdBy?.lastName}` },
];

const discountColumns = [
  { name: '', translationKey: 'Fecha', valueGetter: ({ createdAt }) => format(createdAt, 'dd.MM.yyyy') },
  {
    name: '',
    translationKey: 'Monto',
    valueGetter: ({ amount, discountType }) => `${roundTo2(amount)} ${discountType === 'percentage' ? '%' : ''} `,
  },
  { name: '', translationKey: 'Registrado Por', valueGetter: ({ creator }) => `${creator?.firstName} ${creator?.lastName}` },
];

const SkuTable = ({ catalogList }: any) => (
  <View style={catalogList.length ? styles.table : {}}>
    {catalogList.length ? (
      <View style={styles.tableRow}>
        {columns.map((field) => (
          <View style={[styles.tableCell, field.className]} key={field.name}>
            <Text style={styles.tableCellText}>{field.translationKey}</Text>
          </View>
        ))}
      </View>
    ) : (
      <View style={styles.emptyState}>
        <Text>No se han agregado skus</Text>
      </View>
    )}

    {catalogList.map((row) => (
      <View style={styles.tableRow} key={row._id}>
        {columns.map(({ name, valueGetter, className }) => (
          <View style={[styles.tableCell, className]} key={name}>
            <Text style={styles.tableCellText}>{valueGetter ? valueGetter(name ? row[name] : row) : row[name]}</Text>
          </View>
        ))}
      </View>
    ))}
  </View>
);

const PaymentsTable = ({ payments }: any) => (
  <View style={payments.length ? styles.table : {}}>
    <View style={styles.tableRow}>
      {paymentColumns.map((field) => (
        <View style={[styles.tableCell, styles.paymentsTable]} key={field.name}>
          <Text style={styles.tableCellText}>{field.translationKey}</Text>
        </View>
      ))}
    </View>

    {payments?.map((row) => (
      <View style={styles.tableRow} key={row._id}>
        {paymentColumns.map(({ name, valueGetter }) => (
          <View style={[styles.tableCell, styles.paymentsTable]} key={name}>
            <Text style={styles.tableCellText}>{valueGetter ? valueGetter(name ? row[name] : row) : row[name]}</Text>
          </View>
        ))}
      </View>
    ))}
  </View>
);

interface DiscountsTableProps {
  discounts: DiscountItem[];
}
export const DiscountsTable = ({ discounts = [] }: DiscountsTableProps) => (
  <View style={discounts.length ? styles.table : {}}>
    <View style={styles.tableRow}>
      {discountColumns.map((field) => (
        <View style={[styles.tableCell, styles.discountsTable]} key={field.name}>
          <Text style={styles.tableCellText}>{field.translationKey}</Text>
        </View>
      ))}
    </View>

    {discounts?.map((row) => (
      <View style={styles.tableRow} key={row._id}>
        {discountColumns.map(({ name, valueGetter }) => (
          <View style={[styles.tableCell, styles.discountsTable]} key={name}>
            <Text style={styles.tableCellText}>{valueGetter ? valueGetter(name ? row[name] : row) : row[name]}</Text>
          </View>
        ))}
      </View>
    ))}
  </View>
);

const MedicalRecordItem = ({ medicalRecord }: any) => (
  <View>
    <Text style={styles.medRecordTitle}>
      Record Medico: {isValid(new Date(medicalRecord.name)) ? format(new Date(medicalRecord.name), 'dd.MM.yyyy') : medicalRecord.name}
    </Text>
    <SkuTable catalogList={medicalRecord.catalog} />
  </View>
);

export const BillingPdf = ({ caseItem, patient }: any) => {
  const { totalLeftToPay, totalToPay, discountNumberTotal } = useMemo(
    () => countTotals(caseItem?.total, caseItem?.paidTotal, caseItem?.discounts),
    [caseItem],
  );

  return (
    <Document>
      <Page size="A4">
        <Image style={styles.logo} src={process.env.REACT_APP_ENV === 'master' ? prodLogo : logo} />

        <View>
          <Text style={styles.name}>
            Paciente: {patient.firstName} {patient.lastName}
          </Text>
        </View>
        <View>
          <Text style={styles.name}>
            Caso: {convertDate(caseItem.name)} - {convertDate(caseItem.checkOut)}
          </Text>
        </View>
        {caseItem.medicalRecords.length ? (
          caseItem.medicalRecords?.map((mr) => <MedicalRecordItem key={mr._id} medicalRecord={mr} />)
        ) : (
          <View style={styles.emptyState}>
            <Text>No se han agregado registros médicos</Text>
          </View>
        )}

        <Text style={[styles.name, styles.paymentsLabel]}>Pagos</Text>
        {caseItem?.payments?.length ? (
          <PaymentsTable payments={caseItem?.payments} />
        ) : (
          <View style={styles.emptyState}>
            <Text>No se han agregado pagos</Text>
          </View>
        )}

        <Text style={[styles.name, styles.paymentsLabel]}>Descuentos</Text>
        {caseItem?.discounts?.length ? (
          <DiscountsTable discounts={caseItem?.discounts} />
        ) : (
          <View style={styles.emptyState}>
            <Text>No se han agregado descuentos</Text>
          </View>
        )}

        <Text style={styles.total}>TOTAL DE CUENTA: {roundTo2(totalToPay)}</Text>
        <Text style={styles.total}>TOTAL DE CUENTA DESCUENTOS: {convertNumber(discountNumberTotal)}</Text>
        <Text style={styles.total}>PAGO TOTAL: {roundTo2(caseItem?.paidTotal)}</Text>
        <Text style={styles.total}>TOTAL DE CUENTA PENDIENTE: {roundTo2(totalLeftToPay)}</Text>
      </Page>
    </Document>
  );
};
