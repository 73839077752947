import React from 'react';
import { useParams } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { CaseListItem } from '../PatientCases/types';
import PatientAccountItem from '../PatientAccounts/PatientAccountItem';
import MedicalRecordListItem from '../MedicalRecordListItem/MedicalRecordListItem';

const PatientAccount = () => {
  const { caseId } = useParams<{ caseId: string; patient: string }>();
  const caseItem: CaseListItem = useStoreState((state) => state.cases.getById(caseId));

  return (
    caseItem && (
      <>
        <PatientAccountItem caseItem={caseItem} withButton />
        {caseItem?.medicalRecords?.map((record) => (
          <MedicalRecordListItem inCase={false} medicalRecord={record} key={record._id} />
        ))}
      </>
    )
  );
};
export default PatientAccount;
