import React, { FC, useCallback, useRef, useState } from 'react';
import { Typography, Button, Divider, AccordionActions, AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { saveAs } from 'file-saver';
import { ExpandMore } from '@material-ui/icons';
import { FileItemType } from 'pages/User/PatientCases/types';
import { FileApi } from 'api/File';
import { useStoreActions } from 'easy-peasy';
import { ThunkType } from 'store/type';
import { showErrorToast } from 'utils/toast';
import { checkFileExtension } from 'utils/checkExtension';
import { useTranslations } from 'utils/useTranslations';
import FileItem from './FileItem';

interface FilesProps {
  files: FileItemType[];
}

const TWENTY_MEGABYTES = 20971520;

const accordionStyles: React.CSSProperties = { flexDirection: 'column' };

const Files: FC<FilesProps> = ({ files = [] }) => {
  const intl = useTranslations();
  const [loading, setLoading] = useState({
    upload: false,
    download: false,
  });
  const { caseId } = useParams<{ caseId: string }>();
  const inputRef = useRef<any>();
  const isEmpty = !files.length;
  const uploadFile = useStoreActions(({ uploadFileThunk }) => uploadFileThunk as ThunkType);

  const onDownloadAll = useCallback(async () => {
    if (caseId) {
      setLoading((prevLoading) => ({ ...prevLoading, download: true }));
      const blob = await FileApi.downloadCaseZip(caseId);
      saveAs(new Blob([blob.data]), `${caseId}.zip`);
    }
    setLoading((prevLoading) => ({ ...prevLoading, download: false }));
  }, [caseId]);

  const onFileUpload = useCallback(
    (e) => {
      if (!e.target.files[0]) {
        return showErrorToast(intl('case.no_file_err'));
      }

      if (!checkFileExtension(e.target.files[0].name)) {
        return showErrorToast(intl('case.file_format_err'));
      }

      if (e.target.files[0].size < TWENTY_MEGABYTES) {
        setLoading((prevLoading) => ({ ...prevLoading, upload: true }));
        uploadFile({ caseId, file: e.target.files[0] }).then(() => {
          if (inputRef.current?.value) {
            inputRef.current.value = '';
          }
        });
        setLoading((prevLoading) => ({ ...prevLoading, upload: false }));
      } else {
        showErrorToast(intl('case.file_size_err'));
      }
    },
    [caseId, uploadFile],
  );

  return (
    <Accordion elevation={1}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{intl('case.files')}</Typography>
      </AccordionSummary>
      <AccordionDetails style={accordionStyles}>
        {files.map((file) => (
          <FileItem file={file} key={file._id} />
        ))}
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {!isEmpty && (
          <Button disabled={loading.download} onClick={onDownloadAll} size="small" color="primary">
            {intl('case.download_all')}
          </Button>
        )}
        <Button disabled={loading.upload} variant="contained" color="primary" component="label">
          {intl('shared.add')}
          <input accept="image/png, image/jpeg, application/pdf" ref={inputRef} onChange={onFileUpload} type="file" hidden />
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default Files;
