import axios from 'axios';
import { showErrorToast } from 'utils/toast';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_HOST,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers = {
    Authorization: `Bearer ${token}`,
  };
  return config;
});

axiosClient.interceptors.response.use(
  (res) => res,
  (error) => {
    const errorMessage = typeof error?.response?.data === 'string' ? error?.response?.data : error?.message || 'Server Error: Something went wrong';
    showErrorToast(errorMessage);
    return Promise.reject(error);
  },
);

export default axiosClient;
