import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '../pages/Home';
import AuthRouter from './auth';
import UserRouter from './user';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route path="/user" component={UserRouter} />
      <AuthRouter />
      <Route>
        <Redirect to="/home" />
      </Route>
    </Switch>
  );
}
