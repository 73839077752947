import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonCont: {
    marginTop: theme.spacing(8),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appBar: {
    width: '100%',
    background: 'inherit',
    boxShadow: 'none',
    marginBottom: 30,
  },
  tabs: {
    background: 'inherit',
    border: 0,
    borderRadius: 3,
    height: 48,
    padding: '0 30px',
    color: 'white',
    paddingLeft: 0,
  },
  button: {
    '&:hover': {
      border: 'none',
    },
  },
  tab: {
    '&:hover': {
      border: 0,
      outline: 'none',
    },
    '&:focus': {
      border: 0,
      outline: 'none',
    },
  },
  link: {
    textDecoration: 'none',
  },
  search: {
    width: '40%',
    minWidth: 200,
  },
  mobileContainer: {
    [theme.breakpoints.down('sm')]: { marginBottom: '24px' },
  },
  nameCell: {
    width: '25%',
    padding: 4,
    wordBreak: 'break-word',
  },
  emailCell: {
    width: '65%',
    padding: 4,
    wordBreak: 'break-word',
  },
  horizIconCell: {
    width: '4%',
    padding: 4,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: 0,
    },
  },
  table: {
    // [theme.breakpoints.down('sm')]: { tableLayout: 'fixed' },
  },
  smHorizIconButton: {
    minWidth: 0,
  },
}));

export default useStyles;
