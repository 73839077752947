import { Box, CircularProgress, Container } from '@material-ui/core';
import { useStoreActions } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { GetPaymentTypes } from 'types/paymentTypes';
import PaymentTypeHeader from './PaymentTypeHeader';
import PaymentTypeTable from './PaymentTypeTable';
import { useStyles } from './style';

const PaymentType = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const getPaymentTypes = useStoreActions((actions) => actions.getPaymentTypesThunk) as GetPaymentTypes;

  useEffect(() => {
    setIsLoading(true);
    getPaymentTypes().finally(() => setIsLoading(false));
  }, [setIsLoading, getPaymentTypes]);

  return (
    <Container className={classes.container} component="main">
      <PaymentTypeHeader />
      {isLoading ? (
        <Box height="200px" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <PaymentTypeTable />
      )}
    </Container>
  );
};

export default PaymentType;
