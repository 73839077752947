import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signup_completed: {
    color: '#5FB811',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  signup_completed_icon: {
    width: '15px',
    height: '15px',
    marginRight: 3,
  },
}))
export default useStyles
