import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sectionHeaderBold: {
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  tableHeader: {
    textAlign: 'center',
    fontWeight: 700,
  },

  tableText: {
    textAlign: 'center',
  },
  smDividerBottom: {
    borderBottom: '2px solid black',
  },
  smDividerTop: {
    borderTop: '2px solid black',
  },
  uploadCsvButton: {
    marginTop: 12,
  },
}));
