import { Box, Typography } from '@material-ui/core';
import React from 'react';

const Footer = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="60px" bgcolor="#eee">
      <Typography>Copyright 2020 ® Silibrain LLC</Typography>
    </Box>
  );
};

export default Footer;
