import Axios, { AxiosResponse } from 'axios';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { omit } from 'lodash';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { useTranslations } from 'utils/useTranslations';

interface ICreateMedicalRecord {
  name: string;
}

type MedicalRecordType = {
  name: string;
  diagnosis: string;
  personalMedicalHistory: string;
  familiarMedicalHistory: string;
  symptoms: string;
  signs: string;
  sex: string;
  age: string;
  homeAdress: string;
  recentTrips: string;
  weight: string;
  height: string;
  temperatura: string;
  pulse: string;
  breathesPerMinute: string;
  pressure: string;
  oxygenSaturation: string;
  Na: string;
  K: string;
  Cl: string;
  WBC: string;
  HCT: string;
  bakingSoda: string;
  BUN: string;
  glukose: string;
  platelets: string;
  creatinine: string;
  hepaticFunctionality: string;
  renalFunctionality: string;
  specials: string;
  ultrasound: string;
  xRays: string;
  tomography: string;
  resonancia: string;
  petCt: string;
  treatmentDays: string;
  treatmentMedications: string;
  treatmentProcedures: string;
  orderSummary: string;
  laboratoryExamLink: string[];
  radiologyExamLink: string[];
  medicalEvolutionNote: string[];
  orderSummaryAlt: {}[];
  signosVitalesAlt: {
    date: string;
    hour: string;
    'p/a': string;
    'f/c': string;
    breathing: string;
    temperature: string;
    observations: string;
    signature: string;
  }[];
  controlDeMedicamentosAlt: {
    initialDate: string;
    medicine: string;
    hour: string;
    day: string;
    bed: string;
    room: string;
  }[];
};

interface ICatalogLightParams {
  subcategory?: string;
  categoria?: string;
  limit?: number;
  skip?: number;
}

interface IUseMedicalRecordFetch {
  getRecords: (caseId: string) => Promise<AxiosResponse<any>>;
  deleteRecord: (medicalRecordId: string, caseId: string, patientId: string) => Promise<AxiosResponse<any>>;

  create: (caseId: string, medicalRecordData: ICreateMedicalRecord) => Promise<AxiosResponse<any>>;
  update: (medicalRecordId: string, medicalRecordData: Partial<MedicalRecordType>) => Promise<AxiosResponse<any>>;
  addComment: (medicalRecordId: string, comment: string, isNurse: boolean) => Promise<AxiosResponse<any>>;
  removeComment: (medicalRecordId: string, createdAt: string) => Promise<AxiosResponse<any>>;
  updateComment: (medicalRecordId: string, comment: any) => Promise<AxiosResponse<any>>;
  // addNurseComment: (medicalRecordId: string, comment: string) => Promise<AxiosResponse<any>>;
  addCatalogItem: (data: any) => Promise<AxiosResponse<any>>;
  deleteCatalogItem: (data: { recordId: string; catalogItem: any }) => Promise<AxiosResponse<any>>;
  updateAssignedCatalogItem: (data: {
    id: string;
    isResolved: boolean;
    medicalRecordId: string;
    documentLink?: string;
    doctorJudgement?: string;
  }) => Promise<AxiosResponse<any>>;
  updateAssignedCatalogItemPrice: (data: { id: string; precio: number; medicalRecordId: string }) => Promise<AxiosResponse<any>>;
  toggleField: (medicalRecordId: string, field: string, newValue: boolean) => Promise<AxiosResponse<any>>;
  getCatalogLight: (params: ICatalogLightParams) => Promise<AxiosResponse<any>>;
  sign: (medicalRecordId: string) => Promise<AxiosResponse<any>>;
  uploadCsvItems: (data: any) => Promise<AxiosResponse<any>>;
}

export const baseUrl = process.env.REACT_APP_HOST;

const useMedicalRecordFetch = (): IUseMedicalRecordFetch => {
  const token = useStoreState((state) => state.token);
  const { push } = useHistory();
  const t = useTranslations();
  const addMedicalRecords: any = useStoreActions((actions) => actions.addMedicalRecords);
  // const addNewCase: any = useStoreActions((actions) => actions.addNewCase);
  const deleteMedicalRecordFromStore: any = useStoreActions((actions) => actions.deleteMedicalRecordFromStore);
  const updateMedicalRecord: any = useStoreActions((actions) => actions.updateMedicalRecord);
  const addCommentToStore: any = useStoreActions((actions) => actions.addComment);
  const addCatalogToMedicalRecord: any = useStoreActions((actions) => actions.addCatalogToMedicalRecord);
  const addMultipleCatalogToMedicalRecord: any = useStoreActions((actions) => actions.addMultipleCatalogToMedicalRecord);

  const changeCatalogItemStatus: any = useStoreActions((actions) => actions.changeCatalogItemStatus);
  const changeCatalogItemPrice: any = useStoreActions((actions) => actions.changeCatalogItemPrice);
  const deleteAssignedCatalogItem: any = useStoreActions((actions) => actions.deleteAssignedCatalogItem);
  const removeFieldFromRecord: any = useStoreActions((actions) => actions.removeFieldFromRecord);

  const create = async (caseId: string, medicalRecordData: ICreateMedicalRecord) => {
    const body = {
      caseId,
      medicalRecordData,
    };
    const newMedicalRecord = await Axios.post(`${baseUrl}/medical_record`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    addMedicalRecords({ medicalRecords: [newMedicalRecord.data] });
    return newMedicalRecord;
  };

  const update = async (medicalRecordId: string, medicalRecordData: Partial<MedicalRecordType>) => {
    const dataToSend = omit(medicalRecordData, ['caseId', 'patientId', 'comments', 'catalog', 'recordId']);

    const updatedMedicalRecord = await Axios.put(`${baseUrl}/medical_record/${medicalRecordId}`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    updateMedicalRecord({ medicalRecord: updatedMedicalRecord.data, recordId: medicalRecordId });
    return updatedMedicalRecord;
  };

  const getRecords = async (caseId: string) => {
    const medicalRecords = await Axios.get(`${baseUrl}/medical_record?caseId=${caseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    addMedicalRecords({ medicalRecords: medicalRecords.data });
    return medicalRecords;
  };

  const addComment = async (medicalRecordId: string, comment: string, isNurse: boolean) => {
    const medicalRecordComment = await Axios.put(
      `${baseUrl}/medical_record/${medicalRecordId}/comment`,
      { comment, isNurse },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // console.log(medicalRecordWithComment.data);
    addCommentToStore({ comment: medicalRecordComment.data, recordId: medicalRecordId });

    return medicalRecordComment;
  };

  const updateComment = async (medicalRecordId: string, comment: any) => {
    const medicalRecord = await Axios.post(
      `${baseUrl}/medical_record/${medicalRecordId}/updateComment`,
      { comment },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // console.log(medicalRecordWithComment.data);
    updateMedicalRecord({ medicalRecord: medicalRecord.data, recordId: medicalRecordId });

    return medicalRecord;
  };

  const removeComment = async (medicalRecordId: string, createdAt: string) => {
    const medicalRecord = await Axios.post(
      `${baseUrl}/medical_record/${medicalRecordId}/deleteComment`,
      { createdAt },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // console.log(medicalRecordWithComment.data);
    updateMedicalRecord({ medicalRecord: medicalRecord.data, recordId: medicalRecordId });

    return medicalRecord;
  };

  // const addNurseComment = async (medicalRecordId: string, comment: string) => {
  //   const medicalRecordComment = await Axios.put(
  //     `${baseUrl}/medical_record/${medicalRecordId}/comment`,
  //     { comment },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     },
  //   );

  //   addCommentToStore({ comment: medicalRecordComment.data, recordId: medicalRecordId });

  //   return medicalRecordComment;
  // };

  const getCatalogLight = async (params: ICatalogLightParams) => {
    const catalog = await Axios.get(`${baseUrl}/catalog/light`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return catalog;
  };

  const addCatalogItem = async (data: any) => {
    const newCatalogItem = await Axios.post(`${baseUrl}/assigned_catalog/assign`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    addCatalogToMedicalRecord({ catalogItem: newCatalogItem.data, recordId: data.medicalRecordId });
    return newCatalogItem;
  };

  const uploadCsvItems = async ({ recordId, file }: any) => {
    try {
      const fd = new FormData();
      fd.append('file', file);

      const newCatalogItems = await Axios.post(`${baseUrl}/assigned_catalog/assign/csv/${recordId}`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const insertedCatalogItems = newCatalogItems.data?.successInsert;
      const failInsertCount = newCatalogItems.data?.failInsertCount;
      const failedSkuList = newCatalogItems.data?.failedSkuList;

      if (insertedCatalogItems?.length && insertedCatalogItems?.length > 0) {
        showSuccessToast(`${t('assigned_catalog.successfullyAssigned')} ${insertedCatalogItems.length} SKU`);
        addMultipleCatalogToMedicalRecord({ catalogItems: newCatalogItems.data.successInsert, recordId });
      }

      if (failInsertCount > 0) {
        showErrorToast(`${t('assigned_catalog.notAssigned')} ${failInsertCount} SKU: \n ${failedSkuList.map(({ codigo }) => codigo).join(', ')}`);
      }

      return newCatalogItems;
    } catch (err) {
      showErrorToast(JSON.stringify(err));
    }
  };

  const deleteCatalogItem = async ({ recordId, catalogItem }: { recordId: string; catalogItem: any }) => {
    const result = await Axios.delete(`${baseUrl}/assigned_catalog/${catalogItem._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    deleteAssignedCatalogItem({ catalogItem, recordId });
    return result;
  };

  const updateAssignedCatalogItem = async (data: {
    id: string;
    isResolved: boolean;
    medicalRecordId: string;
    documentLink?: string;
    doctorJudgement?: string;
  }) => {
    const body: { id: string; documentLink?: string; doctorJudgement?: string } = {
      id: data.id,
    };

    if (data.documentLink) {
      body.documentLink = data.documentLink;
    }

    if (data.doctorJudgement) {
      body.doctorJudgement = data.doctorJudgement;
    }

    let updatedCatalogItem: any = {};
    if (data.isResolved) {
      updatedCatalogItem = await Axios.put(`${baseUrl}/assigned_catalog/${data.id}/resolve`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      updatedCatalogItem = await Axios.put(`${baseUrl}/assigned_catalog/${data.id}/unresolve`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    changeCatalogItemStatus({
      catalogItem: updatedCatalogItem.data,
      recordId: data.medicalRecordId,
      documentLink: data.documentLink,
      doctorJudgement: data.doctorJudgement,
    });
    return updatedCatalogItem;
  };

  const updateAssignedCatalogItemPrice = async (data: { id: string; precio: number; medicalRecordId: string }) => {
    const updatedCatalogItem = await Axios.put(
      `${baseUrl}/assigned_catalog/${data.id}/change_price`,
      { precio: +data.precio },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    changeCatalogItemPrice({ catalogItem: updatedCatalogItem.data, recordId: data.medicalRecordId });
    return updatedCatalogItem;
  };

  const deleteRecord = async (medicalRecordId: string, caseId: string, patientId: string) => {
    const deletedRecordItem = await Axios.delete(`${baseUrl}/medical_record/${medicalRecordId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setTimeout(() => push(`/user/patient_records/${patientId}/cases/${caseId}`), 0);
    deleteMedicalRecordFromStore({ id: medicalRecordId });
    return deletedRecordItem;
  };

  const toggleField = async (medicalRecordId: string, field: string, newValue: boolean) => {
    const medicalRecordWithComment = await Axios.post(
      `${baseUrl}/medical_record/${medicalRecordId}/update_field_visibility`,
      {
        field,
        value: newValue,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    // setTimeout(() => push(`/user/patient_records/${patientId}/cases/${caseId}`), 0);
    removeFieldFromRecord({ recordId: medicalRecordId, field, value: newValue });

    return medicalRecordWithComment;
  };

  const sign = async (medicalRecordId: string) => {
    const medicalRecordWithComment = await Axios.put(`${baseUrl}/medical_record/${medicalRecordId}/sign`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // setTimeout(() => push(`/user/patient_records/${patientId}/cases/${caseId}`), 0);
    updateMedicalRecord({ medicalRecord: medicalRecordWithComment.data, recordId: medicalRecordId });

    return medicalRecordWithComment;
  };
  return {
    getRecords,
    create,
    deleteRecord,
    update,
    addComment,
    addCatalogItem,
    updateAssignedCatalogItem,
    deleteCatalogItem,
    toggleField,
    updateAssignedCatalogItemPrice,
    getCatalogLight,
    sign,
    removeComment,
    uploadCsvItems,
    updateComment,
  };
};

export default useMedicalRecordFetch;
