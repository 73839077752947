import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslations } from 'utils/useTranslations';

const PaymentTypeHeader = () => {
  const intl = useTranslations();
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          backgroundColor: '#3f51b5',
        },
      }}
      value={0}
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab label={intl('paymentTypes')} />
    </Tabs>
  );
};

export default PaymentTypeHeader;
