import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { DatePicker } from '@material-ui/pickers';
import { FormattedMessage, useIntl } from 'react-intl';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { paymentMethodToPriceCategoryMap } from 'constants/paymentMethods';
import { allFields } from 'components/Modals/RestoreFieldMedicalRecord';
import MaterialDialog from './Dialog';
import MaterialSelect from './Select';
import Virtualize from './VirtualizedAutocomplete';

export const AssignCatalogItemModal = ({ open, toggle, selectedSubcategory }) => {
  const [catalog, setCatalog] = useState([]);
  const pMap = useStoreState((state) => state.patientsMap);
  const intl = useIntl();
  const { patient: patientId, recordId } = useParams();
  const patient = pMap[patientId];
  const { addCatalogItem, getCatalogLight } = useMedicalRecordFetch();
  const [loading, setLoading] = useState(false);

  const paymentTypes = useStoreState((state) => state.paymentTypes);

  const [catalogLoading, setCatalogLoading] = useState(false);

  const formSubmit = async (values, formContext) => {
    try {
      setLoading(true);
      await addCatalogItem({ ...values, patientId, medicalRecordId: recordId, expiry_date: +new Date(values.expiry_date) });
      formContext.resetForm();
      toggle();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const { values, setFieldValue, handleChange, handleSubmit, errors, touched, resetForm } = useFormik({
    initialValues: {
      expiry_date: new Date(),
      catalogItemId: '',
      paymentType: patient?.paymentType,
      comment: '',
      amount: '',
      fieldToSaveLink: '',
    },
    validationSchema: Yup.object({
      catalogItemId: Yup.string().required(),
      paymentType: Yup.string().required(),
      expiry_date: Yup.date().required(),
      comment: Yup.string(),
      amount: Yup.number().integer().positive().required(),
      fieldToSaveLink: Yup.string(),
    }),
    onSubmit: formSubmit,
  });

  const loadCatalog = async (params) => {
    try {
      setCatalogLoading(true);
      const catalogRes = await getCatalogLight(params);
      setCatalogLoading(false);
      setCatalog(catalogRes.data);
      setFieldValue('catalogItemId', null);
    } catch (err) {
      setCatalogLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setCatalog([]);
      loadCatalog({
        limit: 10000,
        skip: 0,
        categoria: values.paymentType || paymentMethodToPriceCategoryMap[values.paymentType],
        subcategory: selectedSubcategory,
      });
    }
  }, [open, values.paymentType]);

  useEffect(() => {
    resetForm();
  }, [open]);

  return (
    <MaterialDialog
      opened={open}
      title={<FormattedMessage id="inventory.add_new_item" />}
      onClose={toggle}
      actions={[
        {
          name: <FormattedMessage id="calendar.add" />,
          onClick: handleSubmit,
          color: 'primary',
          disabled: loading,
        },
        {
          name: <FormattedMessage id="calendar.cancel" />,
          onClick: toggle,
          color: 'secondary',
          disabled: loading,
        },
      ]}
    >
      <Virtualize
        disabled={catalogLoading}
        options={catalog.map(({ _id, descripcion, categoria, codigo }) => ({
          value: _id,
          name: `${codigo}, ${descripcion} (${intl.formatMessage({
            id: 'catalogue.price_category',
          })}: ${categoria || 0})`,
        }))}
        onChange={(_, value) => {
          setFieldValue('catalogItemId', value.value);
        }}
        error={touched.catalogItemId && errors.catalogItemId}
        value={values.catalogItemId}
        label={<FormattedMessage id="catalogue.name" />}
      />

      <MaterialSelect
        title={<FormattedMessage id="user.payment_type" />}
        onChange={handleChange}
        options={paymentTypes}
        name="paymentType"
        value={values.paymentType}
        error={touched.paymentType && errors.paymentType}
        style={{ marginBottom: '12px' }}
      />
      <DatePicker
        fullWidth
        format="dd.MM.yyyy"
        style={{ marginBottom: '12px' }}
        id="time-picker"
        inputVariant="outlined"
        label={<FormattedMessage id="assign_request.deadline" />}
        onChange={(date) => {
          setFieldValue('expiry_date', date);
        }}
        value={values.expiry_date}
        error={touched.expiry_date && errors.expiry_date}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
      />
      <TextField
        onChange={handleChange}
        value={values.amount}
        label={<FormattedMessage id="shared.quantity" />}
        name="amount"
        type="number"
        error={touched.amount && errors.amount}
        variant="outlined"
        fullWidth
        style={{ marginBottom: '12px' }}
      />
      <TextField
        onChange={handleChange}
        value={values.comment}
        label={<FormattedMessage id="inventory.doctor_comment" />}
        name="comment"
        error={touched.comment && errors.comment}
        variant="outlined"
        fullWidth
        style={{ marginBottom: '12px' }}
      />
      {selectedSubcategory === 'exam' && (
        <TextField
          select
          variant="outlined"
          fullWidth
          style={{ marginBottom: '12px' }}
          name="fieldToSaveLink"
          label={intl.formatMessage({ id: 'medical_record.field' })}
          value={values.fieldToSaveLink}
          error={Boolean(touched.fieldToSaveLink && errors.fieldToSaveLink)}
          onChange={handleChange}
        >
          {allFields.map((field) => (
            <MenuItem key={field.name} value={field.name}>
              {intl.formatMessage({ id: field.translationKey })}
            </MenuItem>
          ))}
        </TextField>
      )}
    </MaterialDialog>
  );
};
