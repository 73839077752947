/* eslint-disable no-param-reassign */
import { CaseApi } from 'api/Case';
import { FileApi } from 'api/File';
import { PaymentTypeApi } from 'api/PaymentType';
import { createStore, action, computed, thunk } from 'easy-peasy';
import { DiscountApi } from '../api/Discount';

export default createStore({
  user: { type: '' },
  users: [],
  // patients: [],
  patientsMap: {},
  services: [],
  catalogues: [],
  inventory: [],
  lang: 'es',
  assignedServices: [],
  selectedPatient: {},
  businessHours: [],
  events: [],
  vat: '',
  token: '',
  paymentTypes: [],

  cases: {
    items: [],
    getById: computed([(state) => state, (state, storeState) => storeState], (localState, globalState) => (id) => {
      const caseItem = localState.items.find(({ _id }) => id === _id);
      if (!caseItem) return null;
      const caseMedicalRecords = globalState.medicalRecords.items.filter(({ caseId }) => caseId === id);
      caseItem.medicalRecords = caseMedicalRecords;
      caseItem.files = caseItem.files?.map((file) => ({
        ...file,
        creator: globalState.users.find((user) => user._id === file.createdBy),
      }));
      caseItem.discounts = caseItem.discounts?.map((discount) => ({
        ...discount,
        creator: globalState.users.find((user) => user._id === discount.createdBy),
      }));
      return caseItem;
    }),
    getByPatientId: computed((state) => (pId) => state.items.filter(({ patientId }) => patientId === pId)),
  },

  medicalRecords: {
    items: [],
    getById: computed((state) => (id) => {
      const medicalRecord = state.items.find(({ _id }) => _id === id);
      return medicalRecord || {};
    }),
  },
  addMedicalRecords: action((state, payload) => {
    const uniqueIdMap = state.medicalRecords.items.reduce((acum, record) => {
      acum[record._id] = record;
      return acum;
    }, {});
    if (payload.medicalRecords) {
      payload.medicalRecords.forEach((recordToAdd) => (uniqueIdMap[recordToAdd._id] = recordToAdd));
    }
    state.medicalRecords.items = Object.values(uniqueIdMap);
  }),
  deleteMedicalRecordFromStore: action((state, payload) => {
    state.medicalRecords.items = state.medicalRecords.items.filter(({ _id }) => _id !== payload.id);
  }),
  updateMedicalRecord: action((state, payload) => {
    const indexToUpdate = state.medicalRecords.items.findIndex(({ _id }) => _id === payload.recordId);

    if (indexToUpdate >= 0) {
      state.medicalRecords.items[indexToUpdate] = { ...state.medicalRecords.items[indexToUpdate], ...payload.medicalRecord };
    }
  }),

  addComment: action((state, payload) => {
    const medicalRecordToUpdate = state.medicalRecords.items.find(({ _id }) => _id === payload.recordId);
    if (medicalRecordToUpdate) {
      if (medicalRecordToUpdate.comments?.length) {
        medicalRecordToUpdate.comments.push(payload.comment);
      } else {
        medicalRecordToUpdate.comments = [payload.comment];
      }
    }
  }),

  addCatalogToMedicalRecord: action((state, payload) => {
    const indexToUpdate = state.medicalRecords.items.findIndex(({ _id }) => _id === payload.recordId);

    if (indexToUpdate >= 0) {
      if (state.medicalRecords.items[indexToUpdate].catalog) {
        state.medicalRecords.items[indexToUpdate].catalog.push(payload.catalogItem);
      } else state.medicalRecords.items[indexToUpdate].catalog = [payload.catalogItem];
    }

    const caseIndexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.catalogItem.caseId);

    if (caseIndexToUpdate >= 0) {
      state.cases.items[caseIndexToUpdate].updatedAt = Date.now();
    }
  }),

  addMultipleCatalogToMedicalRecord: action((state, payload) => {
    try {
      const indexToUpdate = state.medicalRecords.items.findIndex(({ _id }) => _id === payload.recordId);

      if (indexToUpdate >= 0) {
        if (state.medicalRecords.items[indexToUpdate].catalog) {
          state.medicalRecords.items[indexToUpdate].catalog.push(...payload.catalogItems);
        } else state.medicalRecords.items[indexToUpdate].catalog = payload.catalogItems;
      }

      const caseId = payload.catalogItems[0]?.caseId;

      const caseIndexToUpdate = state.cases.items.findIndex(({ _id }) => _id === caseId);

      if (caseIndexToUpdate >= 0) {
        state.cases.items[caseIndexToUpdate].updatedAt = Date.now();
      }
    } catch (err) {
      console.log(err);
    }
  }),

  deleteAssignedCatalogItem: action((state, payload) => {
    const indexToUpdate = state.medicalRecords.items.findIndex(({ _id }) => _id === payload.recordId);
    if (indexToUpdate >= 0) {
      if (state.medicalRecords.items[indexToUpdate].catalog) {
        state.medicalRecords.items[indexToUpdate].catalog = state.medicalRecords.items[indexToUpdate].catalog.filter(
          ({ _id }) => _id !== payload.catalogItem._id,
        );
      }
    }
    const caseIndexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.catalogItem.caseId);
    if (caseIndexToUpdate >= 0) {
      state.cases.items[caseIndexToUpdate].updatedAt = Date.now();
    }
  }),

  removeFieldFromRecord: action((state, payload) => {
    const indexToUpdate = state.medicalRecords.items.findIndex(({ _id }) => _id === payload.recordId);

    if (indexToUpdate >= 0) {
      if (state.medicalRecords.items[indexToUpdate].fields) {
        state.medicalRecords.items[indexToUpdate].fields.find((f) => f.name === payload.field).enabled = payload.value;
      }
    }
  }),
  changeCatalogItemStatus: action((state, payload) => {
    const indexToUpdate = state.medicalRecords.items.findIndex(({ _id }) => _id === payload.recordId);
    if (payload.documentLink) {
      state.medicalRecords.items[indexToUpdate][payload.catalogItem.fieldToSaveLink] = `${payload.documentLink} ${payload.doctorJudgement}`;
    }

    if (indexToUpdate >= 0 && state.medicalRecords.items[indexToUpdate].catalog) {
      const catalogIndexToUpdate = state.medicalRecords.items[indexToUpdate].catalog.findIndex(({ _id }) => _id === payload.catalogItem._id);
      if (catalogIndexToUpdate >= 0) {
        state.medicalRecords.items[indexToUpdate].catalog[catalogIndexToUpdate] = payload.catalogItem;
        const caseIndexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.catalogItem.caseId);
        state.cases.items[caseIndexToUpdate].total +=
          (payload.catalogItem.resolved ? 1 : -1) * (payload.catalogItem.amount * payload.catalogItem.precio);
        state.cases.items[caseIndexToUpdate].updatedAt = Date.now();
      }
    }
  }),
  changeCatalogItemPrice: action((state, payload) => {
    const indexToUpdate = state.medicalRecords.items.findIndex(({ _id }) => _id === payload.recordId);

    if (indexToUpdate >= 0 && state.medicalRecords.items[indexToUpdate].catalog) {
      const catalogIndexToUpdate = state.medicalRecords.items[indexToUpdate].catalog.findIndex(({ _id }) => _id === payload.catalogItem._id);
      const caseIndexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.catalogItem.caseId);
      if (catalogIndexToUpdate >= 0) {
        state.medicalRecords.items[indexToUpdate].catalog[catalogIndexToUpdate] = payload.catalogItem;
      }
      if (caseIndexToUpdate >= 0) {
        state.cases.items[caseIndexToUpdate].updatedAt = Date.now();
      }
    }
  }),

  addNewPayment: action((state, payload) => {
    const indexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.caseId);
    if (indexToUpdate >= 0) {
      if (state.cases.items[indexToUpdate].payments) {
        state.cases.items[indexToUpdate].payments.push(payload.payment);
      } else state.cases.items[indexToUpdate].payments = [payload.payment];

      state.cases.items[indexToUpdate].paidTotal += payload.payment.amount;
      state.cases.items[indexToUpdate].updatedAt = Date.now();
    }
  }),

  updatePayment: action((state, payload) => {
    const indexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.caseId);
    if (indexToUpdate >= 0) {
      const oldPaymentId = state.cases.items[indexToUpdate].payments.findIndex((p) => p._id === payload.paymentId);
      if (oldPaymentId >= 0) {
        const sum = payload.payment.amount - state.cases.items[indexToUpdate].payments[oldPaymentId].amount;
        state.cases.items[indexToUpdate].paidTotal += sum;
        state.cases.items[indexToUpdate].updatedAt = Date.now();
        state.cases.items[indexToUpdate].payments[oldPaymentId] = payload.payment;
      }
    }
  }),

  setTokenData: action((state, payload) => {
    state.token = payload;
  }),
  setLang: action((state, payload) => {
    state.lang = payload;
  }),
  setInventoryData: action((state, payload) => {
    state.inventory = payload;
  }),
  setBusinessHours: action((state, payload) => {
    state.businessHours = payload;
  }),
  setEvents: action((state, payload) => {
    state.events = payload;
  }),
  setSelectedPatient: action((state, payload) => {
    state.selectedPatient = payload;
  }),
  setServices: action((state, payload) => {
    state.services = payload;
  }),
  setCatalogues: action((state, payload) => {
    state.catalogues = payload;
  }),
  setVat: action((state, payload) => {
    state.vat = payload;
  }),
  setPatientsData: action((state, payload) => {
    state.patientsMap = payload.reduce(
      (acum, curValue) => ({
        ...acum,
        [curValue._id]: curValue,
      }),
      {},
    );
  }),

  updatePatient: action((state, payload) => {
    if (state.patientsMap?.[payload.patientId]) {
      state.patientsMap[payload.patientId] = { ...state.patientsMap[payload.patientId], ...payload.patient };
    }
  }),

  loadNewPatient: action((state, payload) => {
    if (state.patientsMap?.[payload.patientId]) {
      state.patientsMap[payload.patientId] = { ...state.patientsMap[payload.patientId], ...payload.patient };
    } else {
      state.patientsMap[payload.patientId] = payload.patient;
    }
  }),
  setUserData: action((state, payload) => {
    state.user = payload;
  }),
  setUsersData: action((state, payload) => {
    state.users = payload;
  }),
  setAssignedServices: action((state, payload) => {
    state.assignedServices = payload;
  }),
  setRequests: action((state, payload) => {
    state.requests = payload;
  }),
  removePatient: action((state, payload) => {
    if (state.patientsMap?.[payload.id]) {
      delete state.patientsMap[payload.id];
    }
    // state.patients = state.patients.filter((p) => p._id !== payload.id);
  }),
  toggleAssignedDoctor: action((state, payload) => {
    const patient = state.patientsMap[payload.patientId];

    const isIncludeDoctorWithId = patient.doctors?.find((doctor) => doctor._id === payload.doctorId);

    if (isIncludeDoctorWithId) {
      patient.doctors = patient.doctors.filter((doctor) => doctor._id !== payload.doctorId);
    } else {
      patient.doctors.push(payload.doctor);
    }
  }),

  addNewCases: action((state, payload) => {
    const uniqueIdMap = state.cases.items.reduce((acum, record) => {
      acum[record._id] = record;
      return acum;
    }, {});
    payload.cases.forEach((caseToAdd) => (uniqueIdMap[caseToAdd._id] = caseToAdd));
    state.cases.items = Object.values(uniqueIdMap).sort((a, b) => b.createdAt - a.createdAt);
  }),

  deleteCase: action((state, payload) => {
    state.cases.items = state.cases.items.filter(({ _id }) => _id !== payload.caseId);
  }),

  updateCaseItem: action((state, payload) => {
    const caseIndexToUpdate = state.cases.items.findIndex(({ _id }) => payload.caseItem._id === _id);
    if (caseIndexToUpdate >= 0) state.cases.items[caseIndexToUpdate] = { ...state.cases.items[caseIndexToUpdate], ...payload.caseItem };
  }),

  removeFile: action((state, payload) => {
    const caseToUpdate = state.cases.items.find((caseItem) => caseItem._id === payload.caseId);
    caseToUpdate.files = caseToUpdate.files?.filter((file) => file._id !== payload._id);
  }),

  removeFileThunk: thunk((actions, payload) =>
    FileApi.remove(payload._id)
      .then(({ data }) => {
        if (data.status) {
          actions.removeFile(payload);
        }
      })
      .catch((err) => console.log(err)),
  ),

  addFile: action((state, payload) => {
    const caseToUpdate = state.cases.items.find((caseItem) => caseItem._id === payload.caseId);
    caseToUpdate.files.push(payload);
  }),

  uploadFileThunk: thunk((actions, payload) =>
    FileApi.upload(payload.caseId, payload.file)
      .then(({ data }) => {
        actions.addFile(data);
      })
      .catch((err) => console.log(err)),
  ),

  addDiscount: action((state, payload) => {
    const indexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.caseId);

    if (indexToUpdate < 0) {
      return;
    }

    if (state.cases.items[indexToUpdate].discounts) {
      state.cases.items[indexToUpdate].discounts.push(payload);
    } else {
      state.cases.items[indexToUpdate].discounts = [payload];
    }

    state.cases.items[indexToUpdate].updatedAt = Date.now();
  }),

  addCovidInfo: action((state, payload) => {
    const indexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload._id);

    if (indexToUpdate < 0) {
      return;
    }

    state.cases.items[indexToUpdate].covidInfo = payload.covidInfo;
  }),

  addDiscountThunk: thunk((actions, payload) =>
    DiscountApi.addDiscount(payload).then(({ data }) => {
      actions.addDiscount(data);
    }),
  ),

  addCovidThunk: thunk((actions, payload) =>
    CaseApi.addCovid(payload).then(({ data }) => {
      actions.addCovidInfo(data);
    }),
  ),

  removeCovidThunk: thunk((actions, payload) =>
    CaseApi.removeCovid(payload).then(({ data }) => {
      actions.addCovidInfo(data);
    }),
  ),

  removeDiscount: action((state, payload) => {
    const indexToUpdate = state.cases.items.findIndex(({ _id }) => _id === payload.caseId);

    if (indexToUpdate < 0) {
      return;
    }

    if (state.cases.items[indexToUpdate].discounts) {
      state.cases.items[indexToUpdate].discounts = state.cases.items[indexToUpdate].discounts.filter(({ _id }) => _id !== payload.discountId);
    }

    state.cases.items[indexToUpdate].updatedAt = Date.now();
  }),
  removeDiscountThunk: thunk((actions, payload) =>
    DiscountApi.removeDiscount(payload.discountId).then(() => {
      actions.removeDiscount(payload);
    }),
  ),

  addPaymentTypes: action((state, payload) => {
    state.paymentTypes = payload;
  }),

  updatePaymentType: action((state, payload) => {
    const paymentTypeIndex = state.paymentTypes.findIndex((pt) => pt._id === payload._id);

    if (paymentTypeIndex >= 0) {
      state.paymentTypes[paymentTypeIndex] = payload;
    }
  }),

  getPaymentTypesThunk: thunk((actions) =>
    PaymentTypeApi.getPaymentTypes().then((response) => {
      actions.addPaymentTypes(response.data);
    }),
  ),

  updatePaymentTypeThunk: thunk((actions, payload) =>
    PaymentTypeApi.updatePaymentType(payload).then((response) => {
      actions.updatePaymentType(response.data);
    }),
  ),
});
