import { Table, TableBody, TableContainer, TableRow, TableCell, Paper, Checkbox } from '@material-ui/core';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { CaseApi } from 'api/Case';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslations } from 'utils/useTranslations';
import { showErrorToast } from 'utils/toast';
import { namesMapper, valuesMapper } from './mappers';

const Covid19Result = () => {
  const intl = useTranslations();
  const { covidId, caseId } = useParams<any>();
  const { goBack } = useHistory<any>();
  const [loading, setLoading] = useState(false);
  const [questioneerData, setQuestioneerData] = useState(null);

  useEffect(() => {
    setLoading(true);
    CaseApi.getCovid({ caseId, covidId })
      .then(
        ({
          data: {
            questionnaire: { id, ...rest },
          },
        }) => setQuestioneerData({ ...rest }),
      )
      .catch((err) => {
        showErrorToast(err.message);
        goBack();
      })
      .finally(() => setLoading(false));
  }, [setLoading, setQuestioneerData]);

  if (loading) {
    return <Loader height="calc(100vh - 64px)" />;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {questioneerData &&
            Object.keys(questioneerData).map((categoryKey) => (
              <React.Fragment key={categoryKey}>
                <TableRow>
                  <TableCell style={{ fontSize: '1.5rem', fontWeight: 600 }} colSpan={typeof questioneerData[categoryKey] !== 'object' ? 1 : 2}>
                    {intl(`covid19.${categoryKey}`)}
                  </TableCell>
                  {typeof questioneerData[categoryKey] !== 'object' && <TableCell>{questioneerData[categoryKey]}</TableCell>}
                </TableRow>
                {Object.entries(questioneerData[categoryKey]).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{intl(namesMapper[categoryKey][key] || key)}</TableCell>
                    <TableCell>
                      {typeof value === 'boolean' ? (
                        <Checkbox checked={value} disabled />
                      ) : (
                        valuesMapper[categoryKey][key] && intl(valuesMapper[categoryKey][key](value) || '-')
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Covid19Result;
