import { chartsEn, chartsEs } from './charts';
import { discountEn, discountEs } from './discount';
import { paymentEn, paymentEs } from './payment';

/* eslint-disable max-len */
export default {
  en: {
    'shared.add': 'Add',
    'shared.delete': 'Delete',
    'shared.cancel': 'Cancel',
    'shared.close': 'Close',
    'shared.quantity': 'Quantity',
    'shared.name': 'Name',
    'shared.date': 'Date',
    'shared.code': 'Code',
    'shared.description': 'Description',
    'shared.price': 'Price',
    'shared.subtotal': 'Total Parcial',
    'shared.company': 'Company',
    'shared.addedBy': 'Added by',
    'shared.status': 'Status',
    'shared.resolvedBy': 'Resolved by',
    'shared.voucherNumber': 'Voucher number',
    'shared.confirm': 'Confirm',
    'shared.edit': 'Edit',

    'auth.signin': 'Login',
    'auth.password': 'Password',
    'auth.forgot_password': 'Forgot password?',
    'auth.dont_have_an_account': "Don't have an account? Sign Up",
    'auth.already_have_an_account': 'Already have an account? Sign in',
    'auth.reset': 'Reset',
    'auth.sign_up': 'Sign up',
    'auth.add_admin': 'Add new user',
    calendar: 'Calendar',
    'calendar.from': 'From',
    'calendar.to': 'To',
    'calendar.apply': 'Apply',
    'calendar.monday': 'Monday',
    'calendar.tuesday': 'Tuesday',
    'calendar.wednesday': 'Wednesday',
    'calendar.thursday': 'Thursday',
    'calendar.friday': 'Friday',
    'calendar.saturday': 'Saturday',
    'calendar.sunday': 'Sunday',
    'calendar.show_picker': 'Show Availability Time Picker',
    'calendar.hide_picker': 'Hide Availability Time Picker',
    'calendar.edit': 'Edit',
    'calendar.cancel': 'Cancel',
    'calendar.delete': 'Delete',
    'calendar.add': 'Add',
    'calendar.save': 'Save',
    'calendar.one_day': 'One day',
    'calendar.in_range': 'In range',
    'doctor.title': 'Title',
    'doctor.title.none': 'None',
    'doctor.title.lic': 'Lic.',
    'doctor.title.ing': 'Ing.',
    'doctor.title.ph_d': 'Ph.D.',
    'doctor.title.dr': 'Dr.',
    'doctor.title.mtro': 'Mtro.',
    'doctor.title.mtr': 'Mtr.',
    'doctor.title.sr_sra': 'Sr. / Sra.',
    'doctor.role': 'Job position',
    'doctor.collegiate_number': 'Collegiate Number',
    'doctor.skills': 'Skills',
    inventory: 'Inventory',
    'inventory.add_new_item': 'Add new item',
    'inventory.sku_description': 'Code',
    'inventory.inventory_name': 'Inventory Name',
    'inventory.name': 'Name',
    'inventory.date': 'Date',
    'inventory.price': 'Price',
    'inventory.cost': 'Cost',
    'inventory.quantity': 'Quantity',
    'inventory.total_cost': 'Total cost',
    'inventory.description': 'Description',
    'inventory.provide_info': 'To add your inventory item, please provide information inputs below',
    'inventory.add': 'Add',
    'inventory.add_service': 'Add service',
    'inventory.add_exam': 'Add exam',
    'inventory.add_inventory': 'Add inventory',
    'inventory.cancel': 'Cancel',
    'inventory.status': 'Status',
    'inventory.type': 'Type',
    'inventory.link': 'Link',
    'inventory.doctor_comment': 'Physician comment',
    'inventory.field_to_save_link': 'Field to save link',
    'inventory.expert_judge': 'Expert judgement',
    'inventory.find_by_sku': 'Find by Code',
    'inventory.find_by_patient': 'Find by patient',
    'inventory.details': 'Inventory details',
    'calendar.close': 'Close',
    'navbar.profile': 'Profile',
    'navbar.medicals': 'Staff',
    'navbar.patient_record': 'Patient Records',
    'navbar.calendar': 'Calendar',
    'navbar.services': 'Services',
    'navbar.inventory': 'Inventory',
    'navbar.requests': 'Requests',
    'navbar.admin_accounts': 'Patient Bills',
    'navbar.assigned_services': 'Patient Bills',
    'navbar.paymentType': 'Payment Types',
    'patient.bills': 'Patient Bills',
    'patient.totalAmount': 'Total amount',
    'patient.totalAmountPaid': 'Total amount paid',
    'patient.totalAmountToPay': 'Total amount to pay',
    'patient.accountNumber': 'Account Number',
    'patient.trsType': 'Transaction type',
    'patient.amount': 'Amount',
    'patient.registered': 'Registered by',
    'patient.addNewPayment': 'Add new payment',
    'patient.checkout': 'Check out',

    position: 'Position',
    'position.none': 'None',
    'position.doctor': 'Physician',
    'position.pharmacist': 'Pharmacist',
    'position.nurse': 'Nurse',
    'position.patient': 'Patient',
    'position.technician_radiology': 'Technician (Radiology)',
    'position.technician_labs': 'Technician (Laboratory)',
    'position.technition': 'Technician',
    'position.admin': 'Webmaster',
    'position.staff': 'Manager',
    'position.find_specialist': 'Find staff member',
    'position.find_patient': 'Find patient',
    'position.find_request': 'Find request',
    'position.find_service': 'Find service',
    'position.find_inventory': 'Find inventory',
    'position.find_catalog': 'Find catalogue',
    profile: 'Profile',
    'profile.edit_profile': 'Edit profile',
    'profile.view_profile': 'View profile',
    'record.name': 'Name',
    'record.author': 'Author',
    'record.date': 'Date',
    'record.add_new': 'Add new medical record',
    'request.add_new_item': 'Add new exam',
    request: 'Request',
    'service.payment_status': 'Payment status',
    services: 'Services',
    'services.exams': 'Exams',
    'services.add_new_item': 'Add New Service',
    'services.sku_description': 'Code',
    'services.sku': 'Code',
    'services.name': 'Name',
    'services.price': 'Price',
    'services.title': 'Title',
    'services.type': 'Type',
    'services.description': 'Description',
    'services.medida': 'Presentation',
    'services.status': 'Status',
    'services.provide_info': 'To add your service, please provide information inputs below',
    'services.add': 'Add',
    'services.cancel': 'Cancel',
    'services.service.name': 'Service name',
    'services.doctor_comment': 'Physician comment',
    'services.deadline_date': 'Date',
    'services.company': 'Company',
    exams: 'Exams',
    'exam.sku_description': 'Code',
    'exam.sku': 'Code',
    'exam.name': 'Name',
    'exam.price': 'Price',
    'exam.description': 'Description',
    'exam.status': 'Status',
    'exam.add_new_exam': 'Add new exam',
    medicaments: 'Medicaments',
    'medicament.sku_description': 'Code',
    'medicament.sku': 'Code',
    'medicament.name': 'Name',
    'medicament.price': 'Price',
    'medicament.quantity': 'Quantity',
    'medicament.total_cost': 'Total cost',
    'medicament.status': 'Status',
    'medicament.add_new_item': 'Add new medicament',
    supplies: 'Supplies',
    'supply.sku_description': 'Code',
    'supply.sku': 'Code',
    'supply.name': 'Name',
    'supply.price': 'Price',
    'supply.quantity': 'Quantity',
    'supply.total_cost': 'Total cost',
    'supply.status': 'Status',
    'supply.add_new_item': 'Add new supply',
    'user.email_adress': 'Email Address',
    'user.phone': 'Phone',
    'user.first_name': 'First Name',
    'user.name': 'Name',
    'user.last_name': 'Last Name',
    'user.role': 'Role',
    'user.search': 'Search',
    'user.payment_type': 'Method of Payment',
    'user.view': 'View',
    'user.profile': 'Profile',
    'user.accounts': 'Bills',
    'user.medical_records': 'Medical Records',
    'user.medical_record': 'Medical Record',
    'user.assign_doctor': 'Assign Physician',
    'user.remove': 'Remove',
    'user.add_comment': 'Add comment',
    'user.observation': 'Comments',
    'user.nurse_observation': 'Nurse comments',

    'user.leave_comment': 'Leave a comment',
    'users.confirm_removing': 'Confirm removing',
    'user.not_found': 'User not found',
    'VAT.percent': 'VAT',
    'vat.change_value': 'Change VAT',
    'home.log_in': 'Log in',
    'auth.back_to_login': 'Back to sign in',
    'auth.restore_link_sent': 'Link was send to your email allowing to setup your password',
    'service.status.paid': 'Paid',
    'service.status.unpaid': 'Unpaid',

    'calendar.Lunes': 'Monday',
    'calendar.Martes': 'Tuesday',
    'calendar.Miercoles': 'Wednesday',
    'calendar.Jueves': 'Thursday',
    'calendar.Viernes': 'Friday',
    'calendar.Sabado': 'Saturday',
    'calendar.Domingo': 'Sunday',
    'calendar.new_event': 'Add new event',
    'calendar.provide_event_info': 'To add your event to calendar, please provide information in input below.',
    'calendar.provide_event_label': 'Event information',

    'medical_record.man': 'Man',
    'medical_record.woman': 'Woman',
    'medical_records.medical_records': 'Medical records',
    'medical_records.dialog_title': 'Add new medical record',
    'medical_records.record_name': 'Record name',
    'medical_records.orderSummary': 'Order summary',

    'medicalRecord.author': 'Author',
    'medicalRecord.viewMedRecord': 'View medical record',
    'medicalRecord.addNewRecord': 'Add new record',

    'patient.download_info': 'Download patient info',
    'patient_dialog.assign_doctor': 'Assign physician',
    'patient_dialog.doctor': 'Physician',
    'patient_dialog.assign': 'Assign',
    'patient.self_pay': 'Self pay',
    'patient.social_security': 'Social security',

    'exam.mark_as_done': 'Done',
    'exam.close_request': 'Close request',
    'exam.document_link': 'Document link',
    'exam.expert_judgement': 'Expert judgement',

    'service_item_type.service': 'Service',
    'service_item_type.surgery': 'Surgery',
    'service_item_type.laboratory_exam': 'Laboratory Exam',
    'service_item_type.radiological_exam': 'Radiological Exam',
    'service_item_type.procedure': 'Procedure',
    'service_item_type.hospitality': 'Hospitality',

    'accounts_table.exam_dialog_info': 'Exam info',
    'accounts_table.exam_dialog_close': 'Close',
    'accounts_table.medicament_dialog_info': 'Medicament info',
    'accounts_table.medicament_dialog_close': 'Close',
    'accounts_table.supply_dialog_info': 'Supply info',
    'accounts_table.supply_dialog_close': 'Close',

    'assign_inventory.title': 'Select inventory',
    'assign_inventory.info': 'Select inventory',
    'assign_inventory.quantity': 'Quantity',
    'assign_inventory.paymentType': 'Method of Payment',
    'assign_inventory.cash': 'Cash',
    'assign_inventory.insurance': 'Insurance',
    'assign_inventory.credit_card': 'Credit Card',
    'assign_request.title': 'Select exam',
    'assign_request.exam': 'Exam',
    'assign_request.quantity': 'Method of Payment',
    'assign_request.paymentType': 'Method of Payment',
    'assign_request.deadline': 'Date',
    'assign_request.comment': 'Comment',
    'assign_service.title': 'Select service',
    'assign_service.service': 'Service',
    'assign_service.paymentType': 'Method of Payment',

    'navbar.logout': 'Log out',
    'patient.case_ID': 'Patient ID',

    'medical_record.restore_fields': 'Restore fields',
    'medical_record.sign': 'Sign',
    'medical_record.signed_by': 'Signed by',

    'medical_record.field': 'Field',

    'medical_record.laboratory_exams': 'Laboratory Exams',
    'medical_record.radiological_exams': 'Radiological Exams',
    'medical_record.clinical_treatment': 'Clinical Treatment',
    'medical_record.surgical_treatment': 'Surgical Treatment',
    'medical_record.procedures_in_treatment': 'Procedures in Treatment',
    'medical_record.vital_signs': 'Vital Signs',
    'medical_record.personal_medical_history': 'Personal Medical History',
    'medical_record.family_medical_history': 'Family Medical History',
    'medical_record.symptoms': 'Symptoms',
    'medical_record.signs': 'Signs',
    'medical_record.gender': 'Gender',
    'medical_record.age': 'Age',
    'medical_record.address': 'Address',
    'medical_record.recent_trips': 'Recent Trips',
    'medical_record.weight': 'Weight',
    'medical_record.height': 'Height',
    'medical_record.body_temperature': 'Body Temperature',
    'medical_record.heart_rate': 'Heart Rate',
    'medical_record.bpm': 'Breaths per Minute',
    'medical_record.blood_pressure': 'Blood Pressure',
    'medical_record.oxygen_saturation': 'Oxygen Saturation',
    'medical_record.sodium_blood_test': 'Sodium Blood Test',
    'medical_record.potassium_blood_test': 'Potassium Blood Test',
    'medical_record.chloride_blood_test': 'Chloride Blood Test',
    'medical_record.bicarbonate_blood_test': 'Bicarbonate Blood Test',
    'medical_record.blood_urea_nitrogen_test': 'Blood Urea Nitrogen Test',
    'medical_record.glucose_blood_test': 'Glucose Blood Test',
    'medical_record.platelet_blood_count': 'Platelet Blood Count',
    'medical_record.creatinine_blood_test': 'Creatinine Blood Test',
    'medical_record.white_blood_cell_count': 'White Blood Cell Count',
    'medical_record.hematocrit_blood_test': 'Hematocrit Blood Test',
    'medical_record.liver_function_tests': 'Liver Function Tests',
    'medical_record.kidney_function_tests': 'Kidney Function Tests',
    'medical_record.special_request_tests': 'Special Request Tests',
    'medical_record.ultrasound_results': 'Ultrasound Results',
    'medical_record.x_rays_results': 'X-Rays Results',
    'medical_record.ct_scan_results': 'CT-Scan Results',
    'medical_record.mri_results': 'MRI Results',
    'medical_record.pet/ct_results': 'PET/CT Results',
    'medical_record.diagnostic': 'Diagnostic',
    'medical_record.days_in_treatment': 'Days in Treatment',
    'medical_record.medication_for_treatment': 'Medication for Treatment',
    'medical_record.medicaments_control': 'Medicaments Control',
    'medical_record.laboratoryExamLink': 'Laboratory exam link',
    'medical_record.radiologyExamLink': 'Radiology exam link',
    'medical_records.laboratoryExamLink': 'Laboratory exam link',
    'medical_records.radiologyExamLink': 'Radiology exam link',
    'medical_record.detailed_info': 'Detailed Info',
    'medical_record.general_info': 'General Info',
    'medical_record.date_name': 'Date (Document Number)',

    'signos_vitales_alt.date': 'Date',
    'signos_vitales_alt.hour': 'Hour',
    'signos_vitales_alt.p/a': 'P/Arterial',
    'signos_vitales_alt.f/c': 'F/C',
    'signos_vitales_alt.breathing': 'Breathing',
    'signos_vitales_alt.temperature': 'Temperature',
    'signos_vitales_alt.observations': 'Observations',
    'signos_vitales_alt.signature': 'Signature',

    'control_de_medicamentos_alt.initialDate': 'Initial date',
    'control_de_medicamentos_alt.medicine': 'Medicine',
    'control_de_medicamentos_alt.hour': 'Hour',
    'control_de_medicamentos_alt.day': 'Day',
    'control_de_medicamentos_alt.bed': 'Bed',
    'control_de_medicamentos_alt.room': 'Room',

    'inventory_type.medicine': 'Medicine',
    'inventory_type.supplies': 'Supplies',
    'inventory_type.supply': 'Supply',
    'inventory_type.service': 'Service',
    'inventory_type.services': 'Services',
    'inventory_type.exam': 'Exam',
    'inventory_type.inventory': 'Inventory',

    'patient_modal.add_new_patient': 'Add new patient',
    'doctor_modal.add_new_medical': 'Add new medical specialist',
    'doctor_modal.add_new_admin': 'Add new admin',
    catalogue: 'catalogue',
    'catalogue.add_new_item': 'Add new catalogue',
    'catalogue.medicamentos': 'Medicaments',
    'catalog.inventory': 'Catalogue',
    'catalogue.sku': 'Code',
    'catalogue.name': 'Catalog name',
    'catalogue.price': 'Price',
    'catalogue.type': 'Type',
    'catalogue.category': 'Category',
    'catalogue.cancel': 'Cancel',
    'catalogue.add': 'Add',
    'catalogue.provide_info': 'To add your catalogue item, please provide information inputs below',
    'catalogue.compania': 'Company',
    'catalogue.codigo': 'Code',
    'catalogue.descripcion': 'Description',
    'catalogue.disponible': 'Available',
    'catalogue.bodega': 'Warehouse Number',
    'catalogue.companyNumber': 'Company number',
    'catalogue.nombreBodega': 'Warehouse',
    'catalogue.precio': 'Price',
    'catalogue.medida': 'Presentation',
    'catalogue.uso': 'Usage',
    'catalogue.principioActivo': 'Active principle',
    'catalogue.uploadFile': 'Upload File',
    'catalogue.status': 'Status',
    'catalogue.status.active': 'Active',
    'catalogue.status.notActive': 'Not Active',
    'catalog_item_category.inventory': 'Inventory',
    'catalog_item_category.services': 'Services',
    'catalogue.subcategory': 'Subcategory',
    'catalogue.price_category': 'Price category',

    'subcategories.medicine': 'Medicine',
    'subcategories.service': 'Service',
    'subcategories.supply': 'Supply',
    'subcategories.exam': 'Exam',
    'categories.inventory': 'Inventory',
    'categories.services': 'Services',

    'payment_type.self_pay': 'Self pay',
    'payment_type.social_security': 'Social security',
    'payment_type.insurance': 'Insurance',
    'payment_type.coex': 'COEX',

    'remove_user_popup.remove_confirm_msg': 'Are you sure that you want to remove the {name} account?',
    patient_bills_sssigned_services: 'Assigned Services',
    'error.user_not_found': 'User not found',
    'error.not_valid_credentials': 'Not valid credentials',
    'error.user_not_confirmed': 'User not confirmed',
    'error.user_already_exists': 'User already exists',
    'error.server_unavailable': 'Server unavailable',
    'error.incorrect_symbols': 'Incorrect symbols',
    'error.incorrect_numbers': 'Min - 0, max - 100',
    'error.user-exists': 'User already exists',

    'detailed.company': 'Company',
    'detailed.codigo': 'Code',
    'detailed.descripcion': 'Description',
    'detailed.disponiblecompany': 'Available company',
    'detailed.bodega': 'Store',
    'detailed.nombreBodega': 'Store number',
    'detailed.precio': 'Price',
    'detailed.medida': 'Measure',
    'detailed.expiryDate': 'Expiry date',
    'detailed.paid': 'Paid',
    'detailed.resolved': 'Resolved',
    'detailed.paymentType': 'Payment type',
    'detailed.category': 'Category',
    'detailed.subcategory': 'Subcategory',
    'detailed.documentLink': 'Document link',
    'detailed.doctorJudgement': "Doctor's judgement",
    'detailed.quantity': 'Quantity',

    'forgot_password.user_not_found': 'User not found',
    'user.cases': 'Cases',
    'patient.case': 'Case',
    'view.case': 'View case',
    'case.admission_date': 'Date of receipt',
    'case.discharge_date': 'Discharge date',
    'case.medical_records': 'Medical Records',
    'case.estimate': 'Evaluation',
    'case.total_price': 'Account Price',
    'case.total_payment': 'Total payment',
    'case.total_price_paid': 'Total Price paid',
    'case.total_price_pending': 'Total Price to pay',
    'case.cancel': 'Cancel',
    'case.add': 'Add',
    'case.addNewCase': 'Add new case',
    'case.name': 'Case name',
    'case.budget': 'Case budget',
    'case.totalAccount': 'Account total',
    'case.totalAccountPaid': 'Total Account Paid',
    'case.totalDiscounts': 'Total discounts',
    'case.amountToPay': 'Amount to pay',
    'case.view': 'View case',
    'case.budgetOption.severe': 'Severe',
    'case.budgetOption.mild': 'Mild',
    'case.budgetOption.moderate': 'Moderate',
    'case.noCases': 'There are no available cases, create one.',
    'case.invoiceNumber': 'Invoice number',
    'case.firstDocument': 'Document 1 Confirmed',
    'case.secondDocument': 'Document 2 Confirmed',
    'case.thirdDocument': 'Document 3 Confirmed',
    'case.editRecord': 'Edit record',
    'case.edit': 'Edit',

    'case.date_name': 'Date (Document Number)',

    'case.download_all': 'Download all',
    'case.files': 'Files',
    'case.file_size_err': 'File size must be less than 20MB',
    'case.file_format_err': 'Only pdf, png and jpeg formats allowed',
    'case.no_file_err': 'No file provided',

    'patient.pending': 'Pending',
    'remove.label': 'Remove',
    'remove.text': 'Are you sure that you want to remove this item? This action is irreversible',
    'medical_records.medicalEvolutionNote': 'Medical Evolution Note',
    'medical_record.medicalEvolutionNote': 'Medical Evolution Note',

    'covid19.Yes': 'Yes',
    'covid19.No': 'No',
    "covid19.No / I don't know / I can't remember": "No / I don't know / I can't remember",
    "covid19.I don't know / can't remember": "I don't know / can't remember",
    'covid19.Next': 'Next',
    'covid19.Back': 'Back',
    'covid19.None': 'None',
    'covid19.Submit': 'Submit',
    'covid19.Required': 'Required',
    'covid19.Please enter a valid email address': 'Please enter a valid email address',
    'covid19.There was an error submitting the form.': 'There was an error submitting the form.',
    'covid19.Hi, Welcome to Codi': 'Hi, Welcome to Codi',
    'covid19.Please select from the options below:': 'Please select from the options below:',
    'covid19.Answer Questionnaire': 'Answer Questionnaire',
    'covid19.Watch Video Instructions': 'Watch Video Instructions',
    'covid19.Upload Test Image': 'Upload Test Image',
    'covid19.General Information ~ 10 min to complete quiz': 'General Information ~ 10 min to complete quiz',
    'covid19.What sex were you assigned at birth?': 'What sex were you assigned at birth?',
    'covid19.Male': 'Male',
    'covid19.Female': 'Female',
    'covid19.Other / Prefer not to say': 'Other / Prefer not to say',
    'covid19.What is your age group?': 'What is your age group?',
    'covid19.I prefer not to say': 'I prefer not to say',
    'covid19.How is your health?': 'How is your health?',
    'covid19.I am very fit and rarely ill': 'I am very fit and rarely ill',
    'covid19.I am fitter and healthier than average': 'I am fitter and healthier than average',
    'covid19.I am less fit and healthy than average': 'I am less fit and healthy than average',
    "covid19.I'm not very healthy at all": "I'm not very healthy at all",
    'covid19.Which of the following do you have/apply to you?': 'Which of the following do you have/apply to you?',
    'covid19.None of the following': 'None of the following',
    'covid19.Mild heart condition': 'Mild heart condition',
    'covid19.Chronic heart condition': 'Chronic heart condition',
    'covid19.Mild lung problem like mild asthma': 'Mild lung problem like mild asthma',
    'covid19.Chronic lung problems': 'Chronic lung problems',
    'covid19.Mild or borderline Diabetes': 'Mild or borderline Diabetes',
    'covid19.Chronic Diabetes': 'Chronic Diabetes',
    'covid19.Recent (last 5 years) Cancer treatment with chemo': 'Recent (last 5 years) Cancer treatment with chemo',
    'covid19.Past Cancer treatment (over 5 years ago) with chemo': 'Past Cancer treatment (over 5 years ago) with chemo',
    'covid19.Reduced kidney function': 'Reduced kidney function',
    'covid19.Chronic liver disease': 'Chronic liver disease',
    'covid19.I take immunosuppressant drugs': 'I take immunosuppressant drugs',
    'covid19.I am pregnant': 'I am pregnant',
    'covid19.Did you get a flu shot in the past year?': 'Did you get a flu shot in the past year?',
    'covid19.Location and Travel ~ 8 min to complete quiz': 'Location and Travel ~ 8 min to complete quiz',
    'covid19.Where are you living right now?': 'Where are you living right now?',
    'covid19.Please enter which state you live in.': 'Please enter which state you live in.',
    'covid19.Please enter only the first three letters or numbers of your postcode/zipcode.':
      'Please enter only the first three letters or numbers of your postcode/zipcode.',
    'covid19.Have you travelled to any other countries since January 1st 2020?': 'Have you travelled to any other countries since January 1st 2020?',
    'covid19.Yes, before they were in "lockdown"': 'Yes, before they were in "lockdown"',
    'covid19.Yes, while they were in "lockdown"': 'Yes, while they were in "lockdown"',
    'covid19.Yes, after they came out of "lockdown"': 'Yes, after they came out of "lockdown"',
    'covid19.Please enter exactly 3 characters': 'Please enter exactly 3 characters',
    'covid19.Take Photo Page': 'Take Photo Page',
    'covid19.Take Photo': 'Take Photo',
    'covid19.Travel Continued ~ 7 min to complete quiz': 'Travel Continued ~ 7 min to complete quiz',
    'covid19.Which countries have you visited since January 1st 2020? (If you went to multiple countries please pick the one where you think you could have caught COVID-19 or had the highest risk of catching it)':
      'Which countries have you visited since January 1st 2020? (If you went to multiple countries please pick the one where you think you could have caught COVID-19 or had the highest risk of catching it)',
    'covid19.What date did you enter that country?': 'What date did you enter that country?',
    'covid19.What date did you leave that country?': 'What date did you leave that country?',
    'covid19.Exposure risk ~ 7 min to complete quiz': 'Exposure risk ~ 7 min to complete quiz',
    "covid19.Did you go to work during your country's lockdown?": "Did you go to work during your country's lockdown?",
    'covid19.Yes, but I interacted with few people per day': 'Yes, but I interacted with few people per day',
    'covid19.Yes, and I interacted with many people per day': 'Yes, and I interacted with many people per day',
    'covid19.Yes, and I interacted with many people/patients per day in a healthcare setting.':
      'Yes, and I interacted with many people/patients per day in a healthcare setting.',
    'covid19.What did you to avoid catching COVID-19 outside your home?': 'What did you to avoid catching COVID-19 outside your home?',
    'covid19.I always kept 6 ft/2 m distance from people.': 'I always kept 6 ft/2 m distance from people.',
    'covid19.I always wore a mask/face covering.': 'I always wore a mask/face covering.',
    'covid19.I never spent more than 30 minutes indoors/in vehicles with others.':
      'I never spent more than 30 minutes indoors/in vehicles with others.',
    'covid19.I frequently washed my hands and rarely touched my face.': 'I frequently washed my hands and rarely touched my face.',
    'covid19.Outside your home did you spend time with anyone who had or soon after developed...?':
      'Outside your home did you spend time with anyone who had or soon after developed...?',
    'covid19.Confirmed COVID-19 (Positive diagnosis)': 'Confirmed COVID-19 (Positive diagnosis)',
    'covid19.Possible COVID-19 symptoms': 'Possible COVID-19 symptoms',
    'covid19.None of the above': 'None of the above',
    'covid19.How many people do you live with? (Please type a number, e.g. 2)': 'How many people do you live with? (Please type a number, e.g. 2)',
    'covid19.Do you think anyone in your home might have had COVID-19?': 'Do you think anyone in your home might have had COVID-19?',
    'covid19.Yes, possibly': 'Yes, possibly',
    'covid19.Yes, definitely (Positive test result)': 'Yes, definitely (Positive test result)',
    'covid19.Ill Housemates ~ 6 min to complete quiz': 'Ill Housemates ~ 6 min to complete quiz',
    'covid19.How many people who you live with have had possible COVID-19 symptoms? Please type a number, e.g. 2':
      'How many people who you live with have had possible COVID-19 symptoms? Please type a number, e.g. 2',
    'covid19.When did the first person in your house become ill?': 'When did the first person in your house become ill?',
    'covid19.Were you ever ill? ~ 6 min to complete quiz': 'Were you ever ill? ~ 6 min to complete quiz',
    "covid19.Have you felt ill at all since January 2020? (If you say no, you won't be asked about symptoms)":
      "Have you felt ill at all since January 2020? (If you say no, you won't be asked about symptoms)",
    "covid19.No, I'm fine. I haven't felt ill at all since January 2020": "No, I'm fine. I haven't felt ill at all since January 2020",
    "covid19.I'm beginning to feel ill": "I'm beginning to feel ill",
    "covid19.I'm now feeling quite ill": "I'm now feeling quite ill",
    "covid19.I'm feeling very ill": "I'm feeling very ill",
    "covid19.I'm still ill but feeling better": "I'm still ill but feeling better",
    'covid19.I was recovering, but now feeling worse again': 'I was recovering, but now feeling worse again',
    'covid19.Yes, but I feel fully recovered': 'Yes, but I feel fully recovered',
    'covid19.Your Symptoms ~ 4 min to complete quiz': 'Your Symptoms ~ 4 min to complete quiz',
    'covid19.Which of the following symptoms did/do you have?': 'Which of the following symptoms did/do you have?',
    'covid19.No symptoms': 'No symptoms',
    'covid19.Really tired': 'Really tired',
    'covid19.A headache': 'A headache',
    'covid19.Achy joints and/or muscles': 'Achy joints and/or muscles',
    'covid19.A sore throat': 'A sore throat',
    'covid19.Congested nose': 'Congested nose',
    'covid19.Runny nose': 'Runny nose',
    'covid19.A dry cough': 'A dry cough',
    'covid19.A cough with sputum': 'A cough with sputum',
    'covid19.Sneezing': 'Sneezing',
    'covid19.Fever': 'Fever',
    'covid19.Chills': 'Chills',
    'covid19.Loss of sense of taste/smell': 'Loss of sense of taste/smell',
    'covid19.Short of breath': 'Short of breath',
    'covid19.Stomach disturbance/diarrhoea': 'Stomach disturbance/diarrhoea',
    'covid19.Vomiting/nausea': 'Vomiting/nausea',
    'covid19.Skin rash': 'Skin rash',
    'covid19.How hard did you, or do you find it to breathe? (0 = mild shortness of breath, 4 = severe shortness of breath)':
      'How hard did you, or do you find it to breathe? (0 = mild shortness of breath, 4 = severe shortness of breath)',
    'covid19.How bad did you, or do you feel overall? (0 = only slightly ill, 4 = seriously ill/went to the hospital)':
      'How bad did you, or do you feel overall? (0 = only slightly ill, 4 = seriously ill/went to the hospital)',
    'covid19.How anxious were/are you about COVID19? (0 = a little anxious, 4 = very anxious)':
      'How anxious were/are you about COVID19? (0 = a little anxious, 4 = very anxious)',
    'covid19.When did you first experience symptoms?': 'When did you first experience symptoms?',
    'covid19.How long did you feel ill/have symptoms for?': 'How long did you feel ill/have symptoms for?',
    'covid19.A few days or less': 'A few days or less',
    'covid19.Less than one week': 'Less than one week',
    'covid19.One to two weeks': 'One to two weeks',
    'covid19.Two to three weeks': 'Two to three weeks',
    'covid19.More than three weeks': 'More than three weeks',
    "covid19.I'm still ill!": "I'm still ill!",
    'covid19.Did/Do you think you were infected with Coronavirus / COVID-19?': 'Did/Do you think you were infected with Coronavirus / COVID-19?',
    'covid19.No way': 'No way',
    'covid19.A small chance (~25% sure)': 'A small chance (~25% sure)',
    'covid19.A reasonable chance (~50% sure)': 'A reasonable chance (~50% sure)',
    'covid19.Pretty sure (~75% sure)': 'Pretty sure (~75% sure)',
    'covid19.Absolutely convinced (~90%+ sure)': 'Absolutely convinced (~90%+ sure)',
    'covid19.Yes, 100%: I tested positive': 'Yes, 100%: I tested positive',
    'covid19.Yes, 100%: I was treated in hospital': 'Yes, 100%: I was treated in hospital',
    'covid19.Yes, 100%: I was treated in intensive care': 'Yes, 100%: I was treated in intensive care',
    'covid19.COVID testing ~ 3 min to complete quiz': 'COVID testing ~ 3 min to complete quiz',
    'covid19.Have you done a lateral flow antibody test to check if you have/had COVID-19?':
      'Have you done a lateral flow antibody test to check if you have/had COVID-19?',
    "covid19.No, I've not done a lateral flow antibody test, or any other COVID tests.":
      "No, I've not done a lateral flow antibody test, or any other COVID tests.",
    "covid19.No, but I've done a different COVID test.": "No, but I've done a different COVID test.",
    'covid19.Yes, and I tested positive': 'Yes, and I tested positive',
    'covid19.Yes, but I tested negative': 'Yes, but I tested negative',
    "covid19.Yes, but I don't know the result": "Yes, but I don't know the result",
    'covid19.What date did you do the test?': 'What date did you do the test?',
    'covid19.What kind of antibody test did you do to see if you have/had COVID-19?':
      'What kind of antibody test did you do to see if you have/had COVID-19?',
    'covid19.Rapid Antibody blood test (Lateral flow immunoassay)': 'Rapid Antibody blood test (Lateral flow immunoassay)',
    'covid19.Sent blood for laboratory antibody test': 'Sent blood for laboratory antibody test',
    'covid19.Antibody test ~ 2 min to complete quiz': 'Antibody test ~ 2 min to complete quiz',
    'covid19.What did your test result look like?': 'What did your test result look like?',
    'covid19.C only': 'C only',
    'covid19.C and IgG': 'C and IgG',
    'covid19.C and IgM': 'C and IgM',
    'covid19.C, IgM and IgG': 'C, IgM and IgG',
    'covid19.IgG only (no C)': 'IgG only (no C)',
    'covid19.IgM only (no C)': 'IgM only (no C)',
    'covid19.IgG and IgM only (no C)': 'IgG and IgM only (no C)',
    'covid19.None, no lines': 'None, no lines',
    'covid19.Please upload a photo of the antibody test taken from directly above, with even lighting so that the test cassette fills the screen (like the one below). ':
      'Please upload a photo of the antibody test taken from directly above, with even lighting so that the test cassette fills the screen (like the one below). ',
    'covid19.Other tests ~ 1 min to complete quiz': 'Other tests ~ 1 min to complete quiz',
    'covid19.Did you send blood for a laboratory antibody test?': 'Did you send blood for a laboratory antibody test?',
    'covid19.Have you done a Sputum/Nasal/Throat swab for PCR?': 'Have you done a Sputum/Nasal/Throat swab for PCR?',
    'covid19.Have you done a viral Antigen test?': 'Have you done a viral Antigen test?',
    'covid19.Have you had an X-ray/CT scans of test?': 'Have you had an X-ray/CT scans of test?',
    "covid19.Have you had a Doctor's diagnosis?": "Have you had a Doctor's diagnosis?",
    'covid19.Negative result': 'Negative result',
    'covid19.Positive result': 'Positive result',
    'covid19.Unknown result': 'Unknown result',
    'covid19.Final question ~ 30 sec to complete quiz': 'Final question ~ 30 sec to complete quiz',
    'covid19.Would you be interested in knowing about any studies, testing programmes, or trials, to help confirm if you have fully recovered, to donate plasma, or if you may be immune to Corona Virus?':
      'Would you be interested in knowing about any studies, testing programmes, or trials, to help confirm if you have fully recovered, to donate plasma, or if you may be immune to Corona Virus?',
    'covid19.Interested, send me more information when you hear about it': 'Interested, send me more information when you hear about it',
    'covid19.Interested, but no information please': 'Interested, but no information please',
    'covid19.No, not interested': 'No, not interested',
    'covid19.Please leave your email address below': 'Please leave your email address below',
    'covid19.Thank You!': 'Thank You!',
    'covid19.You will receive your results by email shortly.': 'You will receive your results by email shortly.',
    'covid19.Region?': 'Region',
    'covid19.generalInformation': 'General Information',
    'covid19.locationAndTravel': 'Location And Travel',
    'covid19.travelContinued': 'Travel Continued',
    'covid19.exposureRisk': 'Exposure Risk',
    'covid19.illHousemates': 'Ill Housemates',
    'covid19.wereYouIll': 'Were You Ill',
    'covid19.yourSymptoms': 'Your Symptoms',
    'covid19.antibodyTest': 'Antibody Test',
    'covid19.covidTesting': 'Covid Testing',
    'covid19.otherTests': 'Other Tests',
    'covid19.emailConsent': 'Email Consent',
    'covid19.email': 'Email',
    'covid19.stdDiv': 'Std Div',
    'covid19.mean': 'Mean',
    'assigned_catalog.incorrectFormat': 'Incorrect format',
    'assigned_catalog.uploadCsv': 'Upload Csv',
    'assigned_catalog.successfullyAssigned': 'Successfully asigned',
    'assigned_catalog.notAssigned': 'Not asigned',

    paymentTypes: 'Payment Types',
    'paymentTypes.paymentType': 'Payment Type',
    'paymentTypes.name': 'Name',
    'paymentTypes.namePlaceholder': 'Payment type ...',
    'paymentTypes.value': 'Value',
    'paymentTypes.editPaymentType': 'Edit payment type',
    'paymentTypes.modifyName': 'You can modify payment type name',

    ...discountEn,
    ...paymentEn,
    ...chartsEn,
  },
  es: {
    'shared.delete': 'Eliminar',
    'shared.add': 'Agregar',
    'shared.cancel': 'Cancelar',
    'shared.edit': 'Editar',
    'shared.close': 'Cerrar',
    'shared.quantity': 'Cantidad',
    'shared.name': 'Nombre',
    'shared.date': 'Fecha',
    'shared.code': 'Codigo',
    'shared.confirm': 'Confirmar',
    'shared.description': 'Descripcion',
    'shared.price': 'Precio',
    'shared.subtotal': 'Partial Total',
    'shared.company': 'Compañía',
    'shared.addedBy': 'Agregar por',
    'shared.status': 'Estado',
    'shared.resolvedBy': 'Marcado por',
    'shared.voucherNumber': 'Numero de Vale',

    'auth.signin': 'Ingresa',
    'auth.password': 'Contraseña',
    'auth.forgot_password': '¿Haz olvidado tu contraseña?',
    'auth.dont_have_an_account': '¿Aun no tienes cuenta? Registrate',
    'auth.already_have_an_account': '¿Ya tienes cuenta? Ingresa',
    'auth.reset': 'Reajusta tu Cuenta',
    'auth.sign_up': 'Regístrate',
    'auth.add_admin': 'Crea un Nuevo Usuario',

    calendar: 'Calendario',
    'calendar.from': 'Desde',
    'calendar.to': 'Hasta',
    'calendar.apply': 'Aplicar',
    'calendar.monday': 'Lunes',
    'calendar.tuesday': 'Martes',
    'calendar.wednesday': 'Miercoles',
    'calendar.thursday': 'Jueves',
    'calendar.friday': 'Viernes',
    'calendar.saturday': 'Sabado',
    'calendar.sunday': 'Domingo',
    'calendar.show_picker': 'Muestra Calendario de Disponibilidad',
    'calendar.hide_picker': 'Esconde Calendario de Disponibilidad',
    'calendar.edit': 'Editar',
    'calendar.cancel': 'Cancelar',
    'calendar.delete': 'Eliminar',
    'calendar.add': 'Agregar',
    'calendar.save': 'Salvar',
    'calendar.one_day': 'Un día',
    'calendar.in_range': 'En el rango',
    'doctor.title': 'Título',
    'doctor.title.none': 'N/A',
    'doctor.title.lic': 'Lic.',
    'doctor.title.ing': 'Ing.',
    'doctor.title.ph_d': 'Ph.D.',
    'doctor.title.dr': 'Dr.',
    'doctor.title.mtro': 'Mtro.',
    'doctor.title.mtr': 'Mtr.',
    'doctor.title.sr_sra': 'Sr. / Sra.',
    'doctor.role': 'Posición Laboral',
    'doctor.collegiate_number': 'Numero de Colegiado',
    'doctor.skills': 'Habilidades',
    inventory: 'Inventario',
    'inventory.add_new_item': 'Agrega Inventario',
    'inventory.sku_description': 'Codigo',
    'inventory.inventory_name': 'Nombre',
    'inventory.name': 'Nombre',
    'inventory.date': 'Fecha',
    'inventory.price': 'Precio',
    'inventory.cost': 'Costo',
    'inventory.quantity': 'Cantidad',
    'inventory.total_cost': 'Coste total',
    'inventory.description': 'Nombre',
    'inventory.provide_info': 'Para agregar iventario, favor de llenar este formulario',
    'inventory.add': 'Agregar',
    'inventory.add_service': 'Agrega Servicio',
    'inventory.add_exam': 'Agrega Examen',
    'inventory.add_inventory': 'Agrega Inventario',
    'inventory.cancel': 'Cancelar',
    'inventory.status': 'Estado',
    'inventory.type': 'Tipo',
    'inventory.link': 'Enlazar',
    'inventory.doctor_comment': 'Observaciones médico',
    'inventory.field_to_save_link': 'Campo para guardar enlace',
    'inventory.expert_judge': 'Opinión de expertos',
    'inventory.find_by_sku': 'Buscar por Codigo',
    'inventory.find_by_patient': 'Encuentra por paciente',
    'inventory.details': 'Detalles del inventario',
    'calendar.close': 'Cerrar',

    'navbar.profile': 'Perfil',
    'navbar.medicals': 'Equipo Medico',
    'navbar.patient_record': 'Registros de Pacientes',
    'navbar.calendar': 'Calendario',
    'navbar.services': 'Servicios',
    'navbar.inventory': 'Inventario',
    'navbar.requests': 'Solicitudes',
    'navbar.admin_accounts': 'Cuentas de pacientes',
    'navbar.assigned_services': 'Cuentas de pacientes',
    'navbar.paymentType': 'Formas de Pago',

    'patient.bills': 'Cuentas de Patiente',
    'patient.totalAmount': 'Total de cuenta',
    'patient.totalAmountPaid': 'Total de cuenta pagado',
    'patient.totalAmountToPay': 'Total de cuenta pendiente',

    'patient.accountNumber': 'No. Transaccion',
    'patient.trsType': 'Tipo de Transaccion',
    'patient.amount': 'Monto',
    'patient.registered': 'Registrado Por',
    'patient.addNewPayment': 'Agregar Saldo a Cuenta',
    'patient.checkout': 'Revisa',

    position: 'Tipo de Cuenta',
    'position.none': 'N/A',
    'position.doctor': 'Medico',
    'position.pharmacist': 'Farmaceuta',
    'position.nurse': 'Enfermera',
    'position.patient': 'Paciente',
    'position.technician_radiology': 'Tecnico de Radiologia',
    'position.technician_labs': 'Tecnico de Laboratorio',
    'position.technition': 'Tecnico',
    'position.admin': 'Webmaster',
    'position.staff': 'Encargado',
    'position.find_specialist': 'Encuentra miembro del staff',
    'position.find_patient': 'Encuentra a paciente',
    'position.find_request': 'Buscar solicitud',
    'position.find_service': 'Encuentra servicio',
    'position.find_inventory': 'Encuentra inventario',
    'position.find_catalog': 'Buscar catalogo',
    profile: 'Perfil',
    'profile.edit_profile': 'Editar Perfil',
    'profile.view_profile': 'Ver Perfil',
    'record.name': 'Nombre',
    'record.author': 'Autor',
    'record.date': 'Fecha',
    'record.add_new': 'Agregar nuevo record medico',
    'request.add_new_item': 'Agregar nuevo examen',
    request: 'Solicitud',
    services: 'Servicios',
    'services.exams': 'Exámenes',
    'services.add_new_item': 'Agregar Nuevo Servicio',
    'services.sku_description': 'Codigo',
    'services.sku': 'Codigo',
    'services.name': 'Nombre',
    'services.price': 'Precio',
    'services.title': 'Nombre',
    'services.description': 'Descripcion',
    'services.medida': 'Presentación',
    'services.status': 'Estado',
    'services.provide_info': 'Para agregar un nuevo servicio, favor de llenar este formulario',
    'services.add': 'Agregar',
    'services.cancel': 'Cancelar',
    'services.service.name': 'Nombre del Servicio',
    'services.type': 'Tipo',
    'services.doctor_comment': 'Comentario del doctor',
    'services.deadline_date': 'Fecha límite',
    'services.company': 'Compañía',
    exams: 'Exámenes',
    'exam.sku_description': 'Codigo',
    'exam.sku': 'Codigo',
    'exam.name': 'Nombre',
    'exam.price': 'Precio',
    'exam.description': 'Descripcion',
    'exam.status': 'Estado',
    'exam.add_new_exam': 'Agregar Nuevo Examen',
    medicaments: 'Medicamentos',
    'medicament.sku_description': 'Codigo',
    'medicament.sku': 'Codigo',
    'medicament.name': 'Nombre',
    'medicament.price': 'Precio',
    'medicament.quantity': 'Cantidad',
    'medicament.total_cost': 'Coste total',
    'medicament.status': 'Estado',
    'medicament.add_new_item': 'Agregar Nuevo Medicamento',
    supplies: 'Suministros',
    'supply.sku_description': 'Codigo',
    'supply.sku': 'Codigo',
    'supply.name': 'Nombre',
    'supply.price': 'Precio',
    'supply.quantity': 'Cantidad',
    'supply.total_cost': 'Coste total',
    'supply.status': 'Estado',
    'supply.add_new_item': 'Agregar Nuevo Suministros',
    'user.email_adress': 'Correo Electrónico',
    'user.phone': 'Telefono',
    'user.first_name': 'Nombre',
    'user.name': 'Nombre',
    'user.last_name': 'Apellido',
    'user.role': 'El rol',
    'user.search': 'Buscar',
    'user.payment_type': 'Forma de Pago',
    'user.view': 'Ver',
    'user.profile': 'Perfil',
    'user.assign_doctor': 'Asignarle Medico',
    'user.remove': 'Eliminar',
    'user.accounts': 'Cuentas',
    'user.medical_records': 'Registros Médicos',
    'user.medical_record': 'Historial médico',
    'user.add_comment': 'Agregar Nota',
    'user.observation': 'Ordenes Medicas',
    'user.nurse_observation': 'Notas de Enfermeria',

    'user.leave_comment': 'Deja un comentario',
    'users.confirm_removing': 'Confirmar la eliminación',
    'user.not_found': 'Usuario no encontrado',
    'VAT.percent': 'VAT',
    'vat.change_value': 'Cambiar VAT',
    'home.log_in': 'Ingresa',
    'auth.back_to_login': 'Atrás',
    'auth.restore_link_sent': 'El enlace se envió a su correo electrónico permitiendo configurar su contraseña',
    'service.status.paid': 'Pagado',
    'service.status.unpaid': 'No pagado',
    'service.payment_status': 'Estado de pago',
    'calendar.Lunes': 'Lunes',
    'calendar.Martes': 'Martes',
    'calendar.Miercoles': 'Miercoles',
    'calendar.Jueves': 'Jueves',
    'calendar.Viernes': 'Viernes',
    'calendar.Sabado': 'Sabado',
    'calendar.Domingo': 'Domingo',
    'calendar.new_event': 'Agregar nuevo evento',
    'calendar.provide_event_info': 'Para agregar su evento al calendario, proporcione información en la entrada a continuación.',
    'calendar.provide_event_label': 'Información del Evento',
    'medical_record.man': 'Hombre',
    'medical_record.woman': 'Mujer',
    'medical_records.medical_records': 'Record Medico',
    'medical_records.dialog_title': 'Agregar record medico nuevo',
    'medical_records.record_name': 'Descripción de Record Medico',
    'medical_records.orderSummary': 'Resumen de órdenes',
    'medicalRecord.author': 'Autor',
    'medicalRecord.viewMedRecord': 'Ver récord médico',
    'medicalRecord.addNewRecord': 'Agregar nuevo record medico',

    'patient.download_info': 'Download patient info',
    'patient_dialog.assign_doctor': 'Asignar Medico',
    'patient_dialog.doctor': 'Medico',
    'patient_dialog.assign': 'Asignar',
    'patient.self_pay': 'Pago por Cuenta Propia',
    'patient.social_security': 'Seguridad Social',

    'exam.mark_as_done': 'Hecho',
    'exam.close_request': 'Cerrar solicitud',
    'exam.document_link': 'Enlace de documento',
    'exam.expert_judgement': 'Opinión de expertos',

    'service_item_type.service': 'Servicio',
    'service_item_type.surgery': 'Cirugía',
    'service_item_type.laboratory_exam': 'Examen de laboratorio',
    'service_item_type.radiological_exam': 'Examen de Radiologia',
    'service_item_type.procedure': 'Procedimiento',
    'service_item_type.hospitality': 'Hospitalidad',

    'accounts_table.exam_dialog_info': 'Información del examen',
    'accounts_table.exam_dialog_close': 'Cerrar',
    'accounts_table.medicament_dialog_info': 'Información de medicamentos',
    'accounts_table.medicament_dialog_close': 'Cerrar',
    'accounts_table.supply_dialog_info': 'Información de suministro',
    'accounts_table.supply_dialog_close': 'Cerrar',

    'assign_inventory.title': 'Seleccionar inventario',
    'assign_inventory.info': 'Seleccionar inventario',
    'assign_inventory.quantity': 'Cantidad',
    'assign_inventory.paymentType': 'Método de pago',
    'assign_inventory.cash': 'Efectivo',
    'assign_inventory.insurance': 'Seguro',
    'assign_inventory.credit_card': 'Tarjeta de crédito',
    'assign_request.title': 'Seleccionar examen',
    'assign_request.exam': 'Examen',
    'assign_request.quantity': 'Método de pago',
    'assign_request.paymentType': 'Método de pago',
    'assign_request.deadline': 'Fecha',
    'assign_request.comment': 'Comentario',
    'assign_service.title': 'Seleccionar servicio',
    'assign_service.service': 'Servicio',
    'assign_service.paymentType': 'Método de pago',

    'navbar.logout': 'Cerrar sesión',
    'patient.case_ID': 'ID del Caso',

    'medical_record.restore_fields': 'Restaurar campos',
    'medical_record.sign': 'Firmar',
    'medical_record.signed_by': 'Firmado por',

    'medical_record.field': 'Campo',

    'medical_record.laboratory_exams': 'Examenes de Laboratorio',
    'medical_record.radiological_exams': 'Examenes de Radiologia',
    'medical_record.clinical_treatment': 'Tratamiento Clinico',
    'medical_record.surgical_treatment': 'Tratamiento Quirúrgico',
    'medical_record.procedures_in_treatment': 'Procedimientos en Tratamiento',

    'medical_record.vital_signs': 'Signos vitales',
    'medical_record.personal_medical_history': 'Historia Medica Personal',
    'medical_record.family_medical_history': 'Historia Medica Familiar',
    'medical_record.symptoms': 'Sintomas',
    'medical_record.signs': 'Signos',
    'medical_record.gender': 'Genero',
    'medical_record.age': 'Edad',
    'medical_record.address': 'Direccion de Domicilio',
    'medical_record.recent_trips': 'Viajes Recientes',
    'medical_record.weight': 'Peso',
    'medical_record.height': 'Altura',
    'medical_record.body_temperature': 'Temperatura Corporal',
    'medical_record.heart_rate': 'Frecuencia Cardiaca',
    'medical_record.bpm': 'Respiraciones por Minuto',
    'medical_record.blood_pressure': 'Presion Sanguinea',
    'medical_record.oxygen_saturation': 'Saturacion de Oxigeno',
    'medical_record.sodium_blood_test': 'Examen de Sodio en la Sangre',
    'medical_record.potassium_blood_test': 'Examen de Potasio en la Sangre',
    'medical_record.chloride_blood_test': 'Examen de Cloruro en la Sangre',
    'medical_record.bicarbonate_blood_test': 'Examen de CO2 en la Sangre',
    'medical_record.blood_urea_nitrogen_test': 'Examen de Nitrogeno de Urea en la Sangre',
    'medical_record.glucose_blood_test': 'Examen de Glucosa en la Sangre',
    'medical_record.platelet_blood_count': 'Conteo de Plaquetas en la Sangre',
    'medical_record.creatinine_blood_test': 'Examen de Creatinina en la Sangre',
    'medical_record.white_blood_cell_count': 'Conteo de Glóbulos Blancos',
    'medical_record.hematocrit_blood_test': 'Analisis de Hematocrito',
    'medical_record.liver_function_tests': 'Examenes de Funcionalidad Hepatica',
    'medical_record.kidney_function_tests': 'Examenes de Funcionalidad Renal',
    'medical_record.special_request_tests': 'Examenes Especiales',
    'medical_record.ultrasound_results': 'Resultados de Ultrasonido',
    'medical_record.x_rays_results': 'Resultados de Rayos-X',
    'medical_record.ct_scan_results': 'Resultados de Tomografia',
    'medical_record.mri_results': 'Resultados de Resonancia Magnetica',
    'medical_record.pet/ct_results': 'Resultados de PET/CT',
    'medical_record.diagnostic': 'Diagnostico',
    'medical_record.days_in_treatment': 'Dias de Tratamiento',
    'medical_record.medication_for_treatment': 'Medicamentos para Tratamiento',
    'medical_record.medicaments_control': 'Control de Medicamentos',
    'medical_record.laboratoryExamLink': 'Link a Examenes de Laboratorio',
    'medical_record.radiologyExamLink': 'Link a Examenes de Radiologia',
    'medical_records.laboratoryExamLink': 'Link a Examenes de Laboratorio',
    'medical_records.radiologyExamLink': 'Link a Examenes de Radiologia',
    'medical_record.detailed_info': 'Información detallada',
    'medical_record.general_info': 'Información general',

    'medical_record.date_name': 'Fecha (Nombre del Documento)',
    'signos_vitales_alt.date': 'Fecha',
    'signos_vitales_alt.hour': 'Hora',
    'signos_vitales_alt.p/a': 'P/Arterial',
    'signos_vitales_alt.f/c': 'F/C',
    'signos_vitales_alt.breathing': 'Respiracion',
    'signos_vitales_alt.temperature': 'Temperatura',
    'signos_vitales_alt.observations': 'Observaciones',
    'signos_vitales_alt.signature': 'Firma',

    'control_de_medicamentos_alt.initialDate': 'Fecha Inicial',
    'control_de_medicamentos_alt.medicine': 'Medicamento',
    'control_de_medicamentos_alt.hour': 'Hora',
    'control_de_medicamentos_alt.day': 'Día',
    'control_de_medicamentos_alt.bed': 'Cama',
    'control_de_medicamentos_alt.room': 'Sala',

    'inventory_type.medicine': 'Medicina',
    'inventory_type.supplies': 'Suministros',
    'inventory_type.supply': 'Suministro',
    'inventory_type.service': 'Servicio',
    'inventory_type.services': 'Servicios',
    'inventory_type.exam': 'Examen',
    'inventory_type.inventory': 'Inventario',
    'patient_modal.add_new_patient': 'Añadir nuevo paciente',
    'doctor_modal.add_new_medical': 'Asigna a nuevo medico',
    'doctor_modal.add_new_admin': 'Asigna a nuevo webmaster',
    catalogue: 'catálogo',
    'catalogue.sku': 'Codigo',
    'catalog.inventory': 'Catálogo',
    'catalogue.name': 'Nombre del catálogo',
    'catalogue.add_new_item': 'Agregar nuevo catálogo',
    'catalogue.medicamentos': 'Medicamentos',
    'catalogue.price': 'Precio',
    'catalogue.type': 'Tipo',
    'catalogue.category': 'Categoría',
    'catalogue.cancel': 'Cancelar',
    'catalogue.add': 'Agregar',
    'catalogue.provide_info': 'Para agregar su artículo de catálogo, favor de llenar este formulario',
    'catalogue.compania': 'Compañía',
    'catalogue.codigo': 'Codigo',
    'catalogue.descripcion': 'Descripción',
    'catalogue.disponible': 'Cantidad',
    'catalogue.bodega': 'Bodega',
    'catalogue.companyNumber': 'Numero de compañia',
    'catalogue.nombreBodega': 'Nombre Bodega',
    'catalogue.precio': 'Precio',
    'catalogue.medida': 'Presentacion',
    'catalogue.uso': 'Uso',
    'catalogue.principioActivo': 'Principio activo',
    'catalogue.uploadFile': 'Subir archivo',
    'catalogue.status': 'Estado',
    'catalogue.status.active': 'Activo',
    'catalogue.status.notActive': 'No activo',
    'catalog_item_category.inventory': 'Inventario',
    'catalog_item_category.services': 'Servicios',
    'catalogue.subcategory': 'Subcategoría',
    'subcategories.medicine': 'Medicina',
    'subcategories.service': 'Servicio',
    'subcategories.supply': 'Suministro',
    'subcategories.exam': 'Examen',
    'categories.inventory': 'Inventario',
    'categories.services': 'Servicios',
    'catalogue.price_category': 'Categoría de precio',
    'payment_type.self_pay': 'Pago por Cuenta Propia',
    'payment_type.social_security': 'Seguridad Social',
    'payment_type.insurance': 'Seguros',
    'payment_type.coex': 'COEX',

    'remove_user_popup.remove_confirm_msg': '¿Estás seguro de que quieres eliminar la cuenta de {name}?',
    patient_bills_sssigned_services: 'Servicios Asignados',

    'error.user_not_found': 'Usuario no encontrado',
    'error.not_valid_credentials': 'Credenciales no validas',
    'error.user_not_confirmed': 'Usuario no confirmado',
    'error.user_already_exists': 'El usuario ya existe',
    'error.server_unavailable': 'Servidor no disponible',
    'error.incorrect_symbols': 'Símbolos incorrectos',
    'error.incorrect_numbers': 'Mínimo - 0, máximo - 100',
    'error.user-exists': 'El usuario ya existe',

    'detailed.company': 'Compañía',
    'detailed.codigo': 'Codigo',
    'detailed.descripcion': 'Descripcion',
    'detailed.disponiblecompany': 'Disponible compañía',
    'detailed.bodega': 'Bodega',
    'detailed.nombreBodega': 'Nombre bodega',
    'detailed.precio': 'Precio',
    'detailed.medida': 'Medida',
    'detailed.expiryDate': 'Fecha de caducidad',
    'detailed.paid': 'Pagado',
    'detailed.resolved': 'Resuelto',
    'detailed.paymentType': 'Tipo de pago',
    'detailed.category': 'Categoría',
    'detailed.subcategory': 'Subcategoría',
    'detailed.documentLink': 'Enlace de documento',
    'detailed.doctorJudgement': 'Juicio del doctor',
    'detailed.quantity': 'Cantidad',
    'forgot_password.user_not_found': 'Usuario no encontrado',
    'user.cases': 'Casos',
    'patient.case': 'Caso',
    'view.case': 'Ver caso',
    'case.admission_date': 'Fecha de Ingreso',
    'case.discharge_date': 'Fecha de Egreso',
    'case.estimate': 'Circunstancias para Tratamiento',
    'case.total_price': 'Total de la Cuenta',
    'case.total_payment': 'Pago total',
    'case.total_price_paid': 'Total de Cuenta Pagado',
    'case.total_price_pending': 'Total de Cuenta Pendiente',
    'case.medical_records': 'Registros Médicos',
    'case.cancel': 'Cancelar',
    'case.add': 'Añadir',
    'case.addNewCase': 'Agregar nuevo caso',
    'case.name': 'Nombre del caso',
    'case.budget': 'Presupuesto del caso',
    'case.totalAccount': 'Total de la Cuenta',
    'case.totalDiscounts': 'Total descuentos',
    'case.totalAccountPaid': 'Total de la Cuenta Pagado',
    'case.amountToPay': 'Total de la Cuenta Pendiente',
    'case.view': 'View case',
    'case.budgetOption.severe': 'Severo',
    'case.budgetOption.mild': 'Leve',
    'case.budgetOption.moderate': 'Moderado',
    'case.noCases': 'No hay casos disponibles, cree uno.',
    'case.invoiceNumber': 'Número de factura',
    'case.firstDocument': 'Documento 1 Firmado',
    'case.secondDocument': 'Documento 2 Firmado',
    'case.thirdDocument': 'Documento 3 Firmado',
    'case.editRecord': 'Editar registro',
    'case.edit': 'Editar',

    'case.date_name': 'Fecha (Nombre del Documento)',

    'case.download_all': 'Descargar todo',
    'case.files': 'Archivos',
    'case.file_size_err': 'El tamaño del archivo debe ser inferior a 20 MB.',
    'case.file_format_err': 'Solo se permiten los formatos pdf, png y jpeg',
    'case.no_file_err': 'No se proporcionó ningún archivo',

    'patient.pending': 'Pendiente',
    'remove.label': 'Eliminar',
    'remove.text': '¿Está seguro de que desea eliminar este elemento? Esta acción es irreversible',
    'medical_records.medicalEvolutionNote': 'Nota de Evolución Medica',
    'medical_record.medicalEvolutionNote': 'Nota de Evolución Medica',

    'covid19.Yes': 'Si',
    'covid19.No': 'No hizo',
    "covid19.No / I don't know / I can't remember": 'No hizo / No se / No recuerdo',
    "covid19.I don't know / can't remember": 'No se / no recuerdo',
    'covid19.Next': 'Próximo',
    'covid19.Back': 'Atras',
    'covid19.None': 'Ninguno',
    'covid19.Submit': 'Enviar',
    'covid19.Required': 'Requerido',
    'covid19.Please enter a valid email address': 'Porfavor, ingrese una dirección de correo Valida',
    'covid19.There was an error submitting the form.': 'There was an error submitting the form.',
    'covid19.Hi, Welcome to Codi': 'Hola, Bienvenidos a Codi',
    'covid19.Please select from the options below:': 'Seleccione entre las siguientes opciones:',
    'covid19.Answer Questionnaire': 'Responder Cuestionario',
    'covid19.Watch Video Instructions': 'Ver instrucciones en video',
    'covid19.Upload Test Image': 'Subir imagen de prueba',
    'covid19.General Information ~ 10 min to complete quiz': 'Información general',
    'covid19.What sex were you assigned at birth?': '¿Qué sexo le asignaron al nacer?',
    'covid19.Male': 'Masculino',
    'covid19.Female': 'Femenino',
    'covid19.Other / Prefer not to say': 'Otro / Prefiero no decirlo',
    'covid19.What is your age group?': '¿Cuál es tu grupo de edad?',
    'covid19.I prefer not to say': 'Prefiero no decirlo',
    'covid19.How is your health?': '¿Cómo está tu salud?',
    'covid19.I am very fit and rarely ill': 'Estoy muy en forma y rara vez me enfermo',
    'covid19.I am fitter and healthier than average': 'Estoy más en forma y más saludable que el promedio',
    'covid19.I am less fit and healthy than average': 'Estoy menos en forma y saludable que el promedio',
    "covid19.I'm not very healthy at all": 'No soy muy saludable',
    'covid19.Which of the following do you have/apply to you?': '¿Cuál de los siguientes tiene / aplica para usted?',
    'covid19.None of the following': 'Ninguno de los siguientes',
    'covid19.Mild heart condition': 'Condición cardíaca leve',
    'covid19.Chronic heart condition': 'Enfermedad cardíaca crónica',
    'covid19.Mild lung problem like mild asthma': 'Problema pulmonar leve como asma leve',
    'covid19.Chronic lung problems': 'Problemas pulmonares crónicos',
    'covid19.Mild or borderline Diabetes': 'Diabetes leve o limítrofe',
    'covid19.Chronic Diabetes': 'Diabetes Crónica',
    'covid19.Recent (last 5 years) Cancer treatment with chemo': 'Tratamiento reciente (últimos 5 años) del cáncer con quimioterapia',
    'covid19.Past Cancer treatment (over 5 years ago) with chemo': 'Tratamiento contra el cáncer en el pasado (hace más de 5 años) con quimioterapia',
    'covid19.Reduced kidney function': 'Función renal reducida',
    'covid19.Chronic liver disease': 'Enfermedad cronica del higado',
    'covid19.I take immunosuppressant drugs': 'Tomo medicamentos inmunosupresores',
    'covid19.I am pregnant': 'Estoy embarazada',
    'covid19.Did you get a flu shot in the past year?': '¿Recibiste una vacuna contra la influenza el año pasado?',
    'covid19.Location and Travel ~ 8 min to complete quiz': 'Ubicación y viaje',
    'covid19.Where are you living right now?': '¿Dónde vives ahora?',
    'covid19.Please enter which state you live in.': 'Please enter which state you live in.',
    'covid19.Please enter only the first three letters or numbers of your postcode/zipcode.':
      'Ingrese solo las primeras tres letras o números de su código postal.',
    'covid19.Have you travelled to any other countries since January 1st 2020?': '¿Has viajado a otros países desde el 1 de enero de 2020?',
    'covid19.Yes, before they were in "lockdown"': 'Sí, antes de que estuvieran en "cierre de emergencia"',
    'covid19.Yes, while they were in "lockdown"': 'Sí, mientras estaban en "cierre de emergencia"',
    'covid19.Yes, after they came out of "lockdown"': 'Sí, después de que salieron del "cierre de emergencia"',
    'covid19.Please enter exactly 3 characters': 'Por favor, introduzca exactamente 3 caracteres',
    'covid19.Take Photo Page': 'Página de Toma de Foto',
    'covid19.Take Photo': 'Tomar foto',
    'covid19.Travel Continued ~ 7 min to complete quiz': 'Viaje continuo',
    'covid19.Which countries have you visited since January 1st 2020? (If you went to multiple countries please pick the one where you think you could have caught COVID-19 or had the highest risk of catching it)':
      '¿Qué países has visitado desde el 1 de enero de 2020? (Si fuiste a varios países, elige el que creas que pudiste haber capturado COVID-19 o que tuviste el mayor riesgo de contraerlo)',
    'covid19.What date did you enter that country?': '¿En qué fecha entraste a ese país?',
    'covid19.What date did you leave that country?': '¿En qué fecha te fuiste de ese país?',
    'covid19.Exposure risk ~ 7 min to complete quiz': 'Riesgo de exposición',
    "covid19.Did you go to work during your country's lockdown?": '¿Fuiste a trabajar durante el encierro de tu país?',
    'covid19.Yes, but I interacted with few people per day': 'Sí, pero interactué con pocas personas por día.',
    'covid19.Yes, and I interacted with many people per day': 'Sí, e interactué con muchas personas por día.',
    'covid19.Yes, and I interacted with many people/patients per day in a healthcare setting.':
      'Sí, e interactué con muchas personas / pacientes por día en un entorno de atención médica.',
    'covid19.What did you to avoid catching COVID-19 outside your home?': '¿Qué hizo para evitar contagiarse de COVID-19 fuera de su hogar?',
    'covid19.I always kept 6 ft/2 m distance from people.': 'Siempre mantuve una distancia de 2 m de la gente.',
    'covid19.I always wore a mask/face covering.': 'Siempre usé una máscara / cubierta facial.',
    'covid19.I never spent more than 30 minutes indoors/in vehicles with others.':
      'Nunca pasé más de 30 minutos en interiores / en vehículos con otras personas.',
    'covid19.I frequently washed my hands and rarely touched my face.': 'Frecuentemente me lavo las manos y rara vez me toco la cara.',
    'covid19.Outside your home did you spend time with anyone who had or soon after developed...?':
      'Fuera de su casa, ¿pasó tiempo con alguien que se había desarrollado o poco después ...?',
    'covid19.Confirmed COVID-19 (Positive diagnosis)': 'COVID-19 confirmado (diagnóstico positivo)',
    'covid19.Possible COVID-19 symptoms': 'Posibles síntomas de COVID-19',
    'covid19.None of the above': 'Ninguno de las anteriores',
    'covid19.How many people do you live with? (Please type a number, e.g. 2)': '¿Con cuántas personas vives? (Escriba un número, p.ej. 2)',
    'covid19.Do you think anyone in your home might have had COVID-19?': '¿Crees que alguien en tu casa podría haber tenido COVID-19?',
    'covid19.Yes, possibly': 'Sí, posiblemente',
    'covid19.Yes, definitely (Positive test result)': 'Sí, definitivamente (resultado positivo de la prueba)',
    'covid19.Ill Housemates ~ 6 min to complete quiz': 'Compañeros de vivienda enfermos',
    'covid19.How many people who you live with have had possible COVID-19 symptoms? Please type a number, e.g. 2':
      '¿Cuántas personas con las que vive han tenido posibles síntomas de COVID-19? Por favor escriba un número, p.ej. 2',
    'covid19.When did the first person in your house become ill?': '¿Cuándo se enfermó la primera persona en su casa?',
    'covid19.Were you ever ill? ~ 6 min to complete quiz': '¿Alguna vez estuviste enfermo?',
    "covid19.Have you felt ill at all since January 2020? (If you say no, you won't be asked about symptoms)":
      '¿Te has sentido mal desde enero de 2020? (Si dice que no, no se le preguntará acerca de los síntomas)',
    "covid19.No, I'm fine. I haven't felt ill at all since January 2020": 'No, estoy bien. No me he sentido mal desde enero de 2020',
    "covid19.I'm beginning to feel ill": 'Estoy empezando a sentirme enfermo',
    "covid19.I'm now feeling quite ill": 'Ahora me siento bastante enfermo',
    "covid19.I'm feeling very ill": 'Me siento muy enfermo',
    "covid19.I'm still ill but feeling better": 'Todavía estoy enfermo pero me siento mejor',
    'covid19.I was recovering, but now feeling worse again': 'Me estaba recuperando, pero ahora me siento enfermo otra vez',
    'covid19.Yes, but I feel fully recovered': 'Sí, pero me siento completamente recuperado.',
    'covid19.Your Symptoms ~ 4 min to complete quiz': 'Sus síntomas',
    'covid19.Which of the following symptoms did/do you have?': '¿Cuál de los siguientes síntomas tenía / tiene?',
    'covid19.No symptoms': 'Sin síntomas',
    'covid19.Really tired': 'Realmente cansado',
    'covid19.A headache': 'Un dolor de cabeza',
    'covid19.Achy joints and/or muscles': 'Dolor en las articulaciones y / o músculos',
    'covid19.A sore throat': 'Dolor de garganta',
    'covid19.Congested nose': 'Nariz congestionada',
    'covid19.Runny nose': 'Mocos en la Nariz',
    'covid19.A dry cough': 'Tos seca',
    'covid19.A cough with sputum': 'Tos con esputo',
    'covid19.Sneezing': 'Estornudos',
    'covid19.Fever': 'Fiebre',
    'covid19.Chills': 'Escalofrios',
    'covid19.Loss of sense of taste/smell': 'Pérdida del sentido del gusto / olfato.',
    'covid19.Short of breath': 'Dificultad al respirar',
    'covid19.Stomach disturbance/diarrhoea': 'Malestar estomacal / diarrea',
    'covid19.Vomiting/nausea': 'Vómitos / náuseas',
    'covid19.Skin rash': 'Ronchas o Alergias en la Piel',
    'covid19.How hard did you, or do you find it to breathe? (0 = mild shortness of breath, 4 = severe shortness of breath)':
      '¿Qué tanto te costó o te resulta respirar? (0 = dificultad para respirar leve, 4 = dificultad para respirar severa)',
    'covid19.How bad did you, or do you feel overall? (0 = only slightly ill, 4 = seriously ill/went to the hospital)':
      '¿Qué tan mal te sentiste o te sentiste en general? (0 = solo levemente enfermo, 4 = gravemente enfermo / fue al hospital)',
    'covid19.How anxious were/are you about COVID19? (0 = a little anxious, 4 = very anxious)':
      '¿Qué tan ansioso estaba / estás por COVID19? (0 = un poco ansioso, 4 = muy ansioso)',
    'covid19.When did you first experience symptoms?': '¿Cuándo experimentaste los primeros síntomas?',
    'covid19.How long did you feel ill/have symptoms for?': '¿Durante cuánto tiempo se sintió enfermo / tuvo síntomas?',
    'covid19.A few days or less': 'Unos días o menos',
    'covid19.Less than one week': 'Menos de una semana',
    'covid19.One to two weeks': 'Una o dos semanas',
    'covid19.Two to three weeks': 'Dos o tres semanas',
    'covid19.More than three weeks': 'Más de tres semanas',
    "covid19.I'm still ill!": 'Todavía estoy enfermo!',
    'covid19.Did/Do you think you were infected with Coronavirus / COVID-19?': '¿Creía que estaba infectado con Coronavirus / COVID-19?',
    'covid19.No way': 'De ninguna manera',
    'covid19.A small chance (~25% sure)': 'Una pequeña posibilidad (~ 25% seguro)',
    'covid19.A reasonable chance (~50% sure)': 'Una oportunidad razonable (~ 50% seguro)',
    'covid19.Pretty sure (~75% sure)': 'Bastante seguro (~ 75% seguro)',
    'covid19.Absolutely convinced (~90%+ sure)': 'Absolutamente convencido (~ 90% + seguro)',
    'covid19.Yes, 100%: I tested positive': 'Sí, 100%: he dado positivo',
    'covid19.Yes, 100%: I was treated in hospital': 'Sí, 100%: me trataron en el hospital',
    'covid19.Yes, 100%: I was treated in intensive care': 'Sí, 100%: me trataron en cuidados intensivos',
    'covid19.COVID testing ~ 3 min to complete quiz': 'Prueba del COVID',
    'covid19.Have you done a lateral flow antibody test to check if you have/had COVID-19?':
      '¿Se ha hecho una prueba de anticuerpos para verificar si tiene / tuvo COVID-19?',
    "covid19.No, I've not done a lateral flow antibody test, or any other COVID tests.": 'No, no he hecho una prueba de anticuerpos.',
    "covid19.No, but I've done a different COVID test.": 'No, pero hice un tipo diferente de prueba',
    'covid19.Yes, and I tested positive': 'Sí, y salí positivo',
    'covid19.Yes, but I tested negative': 'Sí, pero obtuve un resultado negativo.',
    "covid19.Yes, but I don't know the result": 'Sí, pero no sé el resultado.',
    'covid19.What date did you do the test?': '¿En qué fecha hiciste la prueba?',
    'covid19.What kind of antibody test did you do to see if you have/had COVID-19?':
      '¿Qué tipo de prueba de anticuerpos realizó para ver si tenía / tuvo COVID-19?',
    'covid19.Rapid Antibody blood test (Lateral flow immunoassay)': 'Análisis rápido de sangre de anticuerpos (inmunoensayo de flujo lateral)',
    'covid19.Sent blood for laboratory antibody test': 'Envío de sangre para análisis de anticuerpos de laboratorio.',
    'covid19.Antibody test ~ 2 min to complete quiz': 'Prueba de anticuerpos',
    'covid19.What did your test result look like?': '¿Cómo se veía el resultado de su prueba?',
    'covid19.C only': 'Solo C',
    'covid19.C and IgG': 'C e IgG',
    'covid19.C and IgM': 'C e IgM',
    'covid19.C, IgM and IgG': 'C, IgM e IgG',
    'covid19.IgG only (no C)': 'Solo IgG (sin C)',
    'covid19.IgM only (no C)': 'Solo IgM (sin C)',
    'covid19.IgG and IgM only (no C)': 'IgG e IgM solamente (sin C)',
    'covid19.None, no lines': 'Ninguno, sin líneas',
    'covid19.Please upload a photo of the antibody test taken from directly above, with even lighting so that the test cassette fills the screen (like the one below). ':
      'Cargue una foto de la prueba de anticuerpos tomada directamente desde arriba, con una iluminación uniforme para que el casete de prueba llene la pantalla (como la siguiente).',
    'covid19.Other tests ~ 1 min to complete quiz': 'Otras pruebas',
    'covid19.Did you send blood for a laboratory antibody test?': '¿Envió sangre para una prueba de laboratorio de anticuerpos?',
    'covid19.Have you done a Sputum/Nasal/Throat swab for PCR?': '¿Ha realizado una muestra de esputo / nasal / garganta para PCR?',
    'covid19.Have you done a viral Antigen test?': '¿Has hecho una prueba de antígeno viral?',
    'covid19.Have you had an X-ray/CT scans of test?': '¿Le han hecho una prueba de rayos X / CT?',
    "covid19.Have you had a Doctor's diagnosis?": '¿Has tenido un diagnóstico médico?',
    'covid19.Negative result': 'Resultado negativo',
    'covid19.Positive result': 'Resultado positivo',
    'covid19.Unknown result': 'Resultado desconocido',
    'covid19.Final question ~ 30 sec to complete quiz': 'Ultima Pregunta',
    'covid19.Would you be interested in knowing about any studies, testing programmes, or trials, to help confirm if you have fully recovered, to donate plasma, or if you may be immune to Corona Virus?':
      '¿Le interesaría saber acerca de algún estudio, programa de prueba o ensayo para ayudar a confirmar si se ha recuperado por completo, para donar plasma o si es inmune al Coronavirus?',
    'covid19.Interested, send me more information when you hear about it': 'Interesado, envíeme más información cuando se entere',
    'covid19.Interested, but no information please': 'Interesado, pero no me manden información por favor',
    'covid19.No, not interested': 'No, no estoy interesado',
    'covid19.Please leave your email address below': 'Por favor deje su dirección de correo electrónico a continuación',
    'covid19.Thank You!': 'Thank You!',
    'covid19.You will receive your results by email shortly.': 'You will receive your results by email shortly.',
    'covid19.Region?': 'Región',
    'covid19.generalInformation': 'Información General',
    'covid19.locationAndTravel': 'Ubicación y Viajes',
    'covid19.travelContinued': 'Continuación del viaje',
    'covid19.exposureRisk': 'Riesgo de exposición',
    'covid19.illHousemates': 'Enfermas',
    'covid19.wereYouIll': 'Estabas enfermo',
    'covid19.yourSymptoms': 'Tus síntomas',
    'covid19.antibodyTest': 'Prueba de anticuerpos',
    'covid19.covidTesting': 'Prueba de covid',
    'covid19.otherTests': 'Otras pruebas',
    'covid19.emailConsent': 'Consentimiento por correo electrónico',
    'covid19.email': 'Email',
    'covid19.stdDiv': 'Std Div',
    'covid19.mean': 'Mean',
    'assigned_catalog.incorrectFormat': 'Formato incorrecto',
    'assigned_catalog.uploadCsv': 'Subir Csv',
    'assigned_catalog.successfullyAssigned': 'Asignado con éxito',
    'assigned_catalog.notAssigned': 'No asignado',

    paymentTypes: 'Formas de Pago',
    'paymentTypes.paymentType': 'Forma de Pago',
    'paymentTypes.name': 'Nombre',
    'paymentTypes.namePlaceholder': 'Forma de Pago ...',
    'paymentTypes.value': 'Valor',
    'paymentTypes.editPaymentType': 'Editar Forma de Pago',
    'paymentTypes.modifyName': 'Puede modificar la forma de pago',

    ...discountEs,
    ...paymentEs,
    ...chartsEs,
  },
};
