import React, { useCallback, useState } from 'react';
import { Typography, Button, Box, Divider } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useStoreState } from 'easy-peasy';
import useCaseFetch from 'api/Case';
import { useTranslations } from 'utils/useTranslations';
import { NewMedicalRecordModal } from 'components/Modals/NewMedicalRecordModal';
import usePermissions from 'utils/usePermissions';
import { RemoveConfirmModal } from 'components/Modals/RemoveConfirmationModal';
import Files from 'components/Files';
import Covid from 'components/Covid/Covid';
import { CaseListItem } from '../PatientCases/types';
import { ParamType } from './types';
import PatientCaseListItem from '../PatientCases/PatientCaseListItem';
import { useStyles } from './styles';
import MedicalRecordListItem from '../MedicalRecordListItem/MedicalRecordListItem';

const PatientCase = () => {
  const intl = useTranslations();
  const { push } = useHistory();
  const styles = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const toggleModal = () => setOpen((old) => !old);
  const { patient, caseId } = useParams<ParamType>();
  const { deleteCase } = useCaseFetch();
  const { canCrudCasesAndRecords } = usePermissions();

  const caseItem: CaseListItem = useStoreState((state) => state.cases.getById(caseId));

  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleCaseDelete = async () => {
    try {
      setDeleteIsLoading(true);
      await deleteCase(caseId, patient);
      setDeleteIsLoading(false);
    } catch (err) {
      setDeleteIsLoading(false);
    }
    setConfirmationModal(false);
  };

  const onGoToCovid = useCallback(() => push(`/user/patient_records/${patient}/cases/${caseId}/covid19`), [push]);

  return caseItem ? (
    <Box>
      <Box position="relative" className={styles.medRecordsHeader}>
        <Box position="absolute" right="0">
          <Button onClick={onGoToCovid} color="primary" variant="contained">
            Covid 19
          </Button>
        </Box>
        <PatientCaseListItem deleteIsLoading={deleteIsLoading} onDelete={() => setConfirmationModal(true)} caseItem={caseItem} />
      </Box>

      <Box mt="3rem">
        <Files files={caseItem.files} />
      </Box>

      <Box mt="3rem">
        <Covid covidInfo={caseItem.covidInfo} />
      </Box>

      <Box className={styles.medRecordsContainer}>
        <Box>
          <Typography className={styles.caseHeader}>{intl('medical_records.medical_records')}</Typography>
        </Box>
        <Box>
          {canCrudCasesAndRecords(patient) && (
            <Button className={clsx(styles.addButtonContainer, styles.caseBtnHeaderText)} onClick={toggleModal} variant="contained" color="primary">
              {intl('medical_records.dialog_title')}
            </Button>
          )}
        </Box>
      </Box>
      <Divider />

      {caseItem.medicalRecords.map((item: any) => (
        <MedicalRecordListItem key={item._id} medicalRecord={item} />
      ))}

      <NewMedicalRecordModal open={open} onClose={toggleModal} />
      <RemoveConfirmModal
        label={intl('remove.label')}
        text={intl('remove.text')}
        open={confirmationModal}
        onSubmit={handleCaseDelete}
        loading={deleteIsLoading}
        onClose={() => setConfirmationModal(false)}
      />
    </Box>
  ) : (
    <></>
  );
};

export default PatientCase;
