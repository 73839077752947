import Highcharts from 'highcharts';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getFillColor = () => {
  const randomColor = getRandomColor();
  return {
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [0, randomColor],
        [1, Highcharts.color(randomColor).setOpacity(0).get('rgba')],
      ],
    },
  };
};
