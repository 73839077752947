import { TextField } from '@material-ui/core';
import MaterialDialog from 'components/Dialog';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslations } from 'utils/useTranslations';
import catalogueFetch from 'api/Catalogue';
import * as yup from 'yup';
import { useStoreActions } from 'easy-peasy';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';

interface Props {
  open: boolean;
  onClose: () => void;
  catalogItemId: string | null;
  medicalRecordId: string | null;
}

const ResolveRequest = ({ onClose, open, catalogItemId, medicalRecordId }: Props) => {
  const changeCatalogResolveStatus: any = useStoreActions((state) => state.changeCatalogResolveStatus);
  const { updateAssignedCatalogItem } = useMedicalRecordFetch();

  const changePatientRecordByClosingExam = async ({
    assignedCatalogItemId,
    medicalRecordId,
    documentLink,
    doctorJudgement,
  }: {
    assignedCatalogItemId: string;
    medicalRecordId: string;
    documentLink: string;
    doctorJudgement: string;
  }) => {
    await updateAssignedCatalogItem({ id: assignedCatalogItemId, isResolved: true, medicalRecordId, documentLink, doctorJudgement });
    // const changedStatusRequest = await catalogueFetch.changeStatus(localStorage.getItem('accessToken'), {
    //   id: assignedCatalogItemId,
    //   documentLink,
    //   doctorJudgement,
    // });
    // console.log(changedStatusRequest);
    // changeCatalogResolveStatus({ recordId: medicalRecordId, catalogItemId: assignedCatalogItemId });
    onClose();
  };

  const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      documentLink: '',
      doctorJudgement: '',
    },
    validationSchema: yup.object({
      documentLink: yup.string().required(),
      doctorJudgement: yup.string().required(),
    }),
    onSubmit(formValues) {
      changePatientRecordByClosingExam({ assignedCatalogItemId: catalogItemId, medicalRecordId, ...formValues });
    },
  });

  useEffect(() => {
    resetForm();
  }, [open]);

  const intl = useTranslations();

  const actions = [
    {
      name: intl('calendar.cancel'),
      onClick: onClose,
      color: 'secondary',
    },
    {
      name: intl('exam.mark_as_done'),
      onClick: handleSubmit,
      color: 'primary',
    },
  ];

  return (
    <MaterialDialog opened={open} title={intl('exam.close_request')} actions={actions} onClose={onClose}>
      <TextField
        fullWidth
        error={Boolean(touched.documentLink && errors.documentLink)}
        value={values.documentLink}
        label={intl('exam.document_link')}
        onChange={handleChange}
        name="documentLink"
        margin="dense"
      />
      <TextField
        fullWidth
        value={values.doctorJudgement}
        error={Boolean(touched.doctorJudgement && errors.doctorJudgement)}
        label={intl('exam.expert_judgement')}
        onChange={handleChange}
        name="doctorJudgement"
        margin="dense"
      />
    </MaterialDialog>
  );
};

export default ResolveRequest;
