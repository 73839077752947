const getDayFromNumber = number => {
  const days = {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miercoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sabado',
  }
  return days[number]
}

const getNumberFromDays = day => {
  const days = {
    Domingo: 0,
    Lunes: 1,
    Martes: 2,
    Miercoles: 3,
    Jueves: 4,
    Viernes: 5,
    Sabado: 6,
  }
  return days[day]
}

const getHoursAndMinutes = date => {
  let minutes = new Date(date).getMinutes()
  let hours = new Date(date).getHours()
  if (minutes < 10) minutes = `0${minutes}`
  if (hours < 10) hours = `0${hours}`
  return `${hours}:${minutes}`
}

export const parseForDatePickers = businessHours => {
  if (businessHours) {
    const parsedHours = businessHours.map(el => ({
      daysOfWeek: getDayFromNumber(el.daysOfWeek[0]),
      startTime: new Date(`January 1, 1970 ${el.startTime}:00`),
      endTime: new Date(`January 1, 1970 ${el.endTime}:00`),
    }))
    return parsedHours
  }
  return []
}

export const parseForSend = hoursFromDatePickers => {
  const parsedHours = hoursFromDatePickers.map(el => ({
    daysOfWeek: [getNumberFromDays(el.daysOfWeek)],
    startTime: getHoursAndMinutes(el.startTime),
    endTime: getHoursAndMinutes(el.endTime),
  }))

  return parsedHours
}
