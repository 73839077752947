import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, TextField } from '@material-ui/core';
import { useStoreActions } from 'easy-peasy';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { PaymentType, UpdatePaymentType } from 'types/paymentTypes';
import { useTranslations } from 'utils/useTranslations';
import { useModalStyles } from './style';

interface PaymentTypeEditModalProps {
  open: boolean;
  onClose: () => void;
  initialValues: PaymentType;
}

const PaymentTypeEditModal: FC<PaymentTypeEditModalProps> = ({ open, onClose, initialValues }) => {
  const intl = useTranslations();
  const classes = useModalStyles();

  const [isLoading, setIsLoading] = useState(false);
  const updatePaymentType = useStoreActions((actions) => actions.updatePaymentTypeThunk) as UpdatePaymentType;

  const { values, handleChange, handleSubmit, resetForm } = useFormik<PaymentType>({
    initialValues,
    enableReinitialize: true,
    onSubmit(formValue) {
      setIsLoading(true);
      updatePaymentType(formValue)
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    },
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  return (
    <Dialog onClose={onClose} open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{intl('paymentTypes.editPaymentType')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{intl('paymentTypes.modifyName')}</DialogContentText>
          <TextField
            name="name"
            value={values.name}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            label={intl('paymentTypes.name')}
            placeholder={intl('paymentTypes.namePlaceholder')}
          />
          <TextField
            name="value"
            value={values.value}
            onChange={handleChange}
            className={classes.textField}
            variant="outlined"
            fullWidth
            label={intl('paymentTypes.value')}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={onClose} color="secondary">
            {intl('shared.close')}
          </Button>
          <Button disabled={isLoading} type="submit" color="primary">
            {intl('shared.edit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PaymentTypeEditModal;
