import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import { useTranslations } from 'utils/useTranslations';
import { useParams } from 'react-router-dom';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import * as yup from 'yup';

interface Props {
  open: boolean;
  onClose: () => void;
}
interface ParamType {
  caseId: string;
}

interface INewCaseForm {
  name: Date;
}

const initValues: INewCaseForm = {
  name: null,
};

export const NewMedicalRecordModal = ({ open, onClose }: Props) => {
  const { create } = useMedicalRecordFetch();
  const { caseId } = useParams<ParamType>();
  const [loading, setLoading] = useState(false);
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: initValues,
    validationSchema: yup.object().shape({
      name: yup.string().required(),
    }),
    async onSubmit(formValues, formContext) {
      try {
        setLoading(true);
        await create(caseId, {
          name: formValues.name.toISOString(),
        });
        setLoading(false);
        formContext.resetForm();
        onClose();
      } catch (err) {
        setLoading(false);
      }
    },
  });
  const intl = useTranslations();
  const handleNameChange = (date: Date) => {
    setFieldValue('name', date);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{intl('medicalRecord.addNewRecord')}</DialogTitle>

      <DialogContent>
        <DatePicker
          fullWidth
          margin="normal"
          format="dd.MM.yyyy"
          id="time-picker"
          onChange={handleNameChange}
          inputVariant="outlined"
          name="name"
          label={intl('medical_record.date_name')}
          error={touched.name && Boolean(errors.name)}
          value={values.name}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="secondary">
          {intl('shared.cancel')}
        </Button>
        <Button disabled={loading} color="primary" onClick={() => handleSubmit()}>
          {intl('shared.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
