import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { NewCaseModal } from 'components/Modals/NewCaseModal';
import useCaseFetch from 'api/Case';
import { useStoreState } from 'easy-peasy';
import { useTranslations } from 'utils/useTranslations';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import usePermissions from 'utils/usePermissions';

import PatientCaseListItem from './PatientCaseListItem';
import { CaseListItem } from './types';
import { useStyles } from './styles';

interface ParamType {
  patient: string;
}

const PatientCases = () => {
  const classes = useStyles({});
  const intl = useTranslations();
  const [open, setModalOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setModalOpen((old) => !old);
  };
  const { canCrudCasesAndRecords } = usePermissions();
  const { getCases } = useCaseFetch();
  const { patient } = useParams<ParamType>();
  const [loading, setLoading] = useState(false);
  const cases: CaseListItem[] = useStoreState((state) => state.cases.getByPatientId(patient));

  const loadCases = async () => {
    try {
      if (!cases.length) {
        setLoading(true);
      }
      await getCases(patient);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadCases();
  }, []);

  // const canCreate = getPermissions(type, [UserTypeEnum.doctor, UserTypeEnum.admin, UserTypeEnum.staff]);

  return (
    <Box>
      {loading ? (
        <Loader height="calc(100vh - 350px)" />
      ) : (
        <>
          <Box className={classes.addButtonContainer}>
            {Boolean(cases.length) && canCrudCasesAndRecords(patient) && (
              <Button variant="contained" onClick={toggleModal} color="primary">
                {intl('case.addNewCase')}
              </Button>
            )}
          </Box>
          {cases.length ? (
            <Box>
              {cases.map((caseItem) => (
                <PatientCaseListItem caseItem={caseItem} />
              ))}
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 350px)">
              <Typography variant="h5" align="center">
                {intl('case.noCases')}
              </Typography>
              {canCrudCasesAndRecords(patient) && (
                <Box mt={4}>
                  <Button variant="contained" onClick={toggleModal} color="primary">
                    {intl('case.addNewCase')}
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {canCrudCasesAndRecords(patient) && <NewCaseModal open={open} onClose={toggleModal} />}
        </>
      )}
    </Box>
  );
};

export default PatientCases;
