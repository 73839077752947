import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_HOST
// 'http://dev.hospitalprivadopeten-api.geniusee.space'

export default {
  async signUp(token, formData) {
    try {
      const { data } = await axios.post('/auth/sign_up', formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return data
    } catch (err) {
      return { error: err.response }
    }
  },

  async registerAdmin(token, formData) {
    try {
      const { data } = await axios.post('/auth/sign_up', formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return data
    } catch (err) {
      return { error: err.response }
    }
  },

  async setPassword(formData) {
    try {
      const { data } = await axios.post('/auth/set_password', formData)
      return data
    } catch (err) {
      throw err
    }
  },

  async signIn(formData) {
    try {
      const { data } = await axios.post('/auth/login', formData)
      return data
    } catch (err) {
      return { error: err.response }
    }
  },

  async resetPassword(formData) {
    try {
      const { data } = await axios.post(
        '/auth/restore_password_send_email',
        formData
      )
      return data
    } catch (err) {
      return { error: err.response }
    }
  },
}
