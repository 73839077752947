import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

interface Props {
  height: string;
}

const Loader = ({ height = '' }: Props) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height={height || 'calc(100vh - 350px)'}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
