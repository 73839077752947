import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslations } from 'utils/useTranslations';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { floatWithTwoDigits } from 'utils/floatWithTwoDigits';
import MaterialSelect from 'components/Select';
import { useStoreActions } from 'easy-peasy';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues?: INewDiscountForm;
}

interface ParamType {
  caseId: string;
}

interface INewDiscountForm {
  amount: number | null;
  discountType: string;
}

const initValues: INewDiscountForm = {
  amount: null,
  discountType: '',
};

const useStyles = makeStyles({
  textField: {
    marginBottom: '1rem',
  },
  container: {
    width: '600px',
  },
});

export const AddDiscountModal = ({ open, onClose, initialValues }: Props) => {
  const classes = useStyles();
  const addDiscountThunk: any = useStoreActions((actions) => actions.addDiscountThunk);

  const { caseId } = useParams<ParamType>();
  const [loading, setLoading] = useState(false);

  const { values, handleSubmit, handleChange, touched, errors } = useFormik({
    initialValues: initialValues || initValues,
    onSubmit(formValues, formContext) {
      setLoading(true);
      addDiscountThunk({ ...formValues, caseId })
        .then(() => {
          formContext.resetForm();
          onClose();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      amount: yup
        .number()
        // eslint-disable-next-line
        .test('float with 2 points', 'Amount must be in propriate format', function () {
          // eslint-disable-next-line
          return floatWithTwoDigits(this.parent.amount);
        })
        .required(),
      discountType: yup.string().required(),
    }),
  });
  const intl = useTranslations();

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{intl('discount.addDiscount')}</DialogTitle>

        <DialogContent>
          <TextField
            fullWidth
            name="amount"
            variant="outlined"
            type="number"
            className={classes.textField}
            value={values.amount}
            error={Boolean(touched.amount && errors.amount)}
            onChange={handleChange}
            label={intl('patient.amount')}
          />

          <MaterialSelect
            withoutNone
            options={[
              { name: intl('discount.number'), value: 'number' },
              { name: intl('discount.percentage'), value: 'percentage' },
            ]}
            className={classes.textField}
            onChange={handleChange}
            value={values.discountType}
            error={Boolean(touched.discountType && errors.discountType)}
            name="discountType"
            title={intl('discount.discountType')}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={onClose} color="secondary">
            {intl('shared.cancel')}
          </Button>
          <Button disabled={loading} color="primary" type="submit">
            {intl('shared.add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
