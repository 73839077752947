import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(12),
  },
  titleName: {
    [theme.breakpoints.down('sm')]: { fontSize: 20 },
  },
  input: {
    marginBottom: 20,
    maxWidth: '100%',
  },
}))

export default useStyles
