import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonCont: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appBar: {
    width: '80%',
    background: 'inherit',
    boxShadow: 'none',
    marginBottom: 30,
  },
  tabs: {
    background: 'inherit',
    border: 0,
    borderRadius: 3,
    height: 48,
    padding: '0 30px',
    color: 'white',
    paddingLeft: 0,
  },
  button: {
    '&:hover': {
      border: 'none',
    },
  },
  tab: {
    '&:hover': {
      border: 0,
      outline: 'none',
    },
    '&:focus': {
      border: 0,
      outline: 'none',
    },
  },
  link: {
    textDecoration: 'none',
  },
  search: {
    width: '40%',
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  vatContainer: {
    maxWidth: '40%',
  },
  containerForEditMode: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  barWithStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tableContainer: {
    position: 'relative',
  },
}));

export default useStyles;
