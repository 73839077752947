type InputType = {
  name: string;
  translationKey: string;
  multiline?: boolean;
};
export const vitalSigns: InputType[] = [
  { name: 'personalMedicalHistory', translationKey: 'medical_record.personal_medical_history', multiline: true },
  { name: 'familiarMedicalHistory', translationKey: 'medical_record.family_medical_history', multiline: true },
  { name: 'symptoms', translationKey: 'medical_record.symptoms' },
  { name: 'signs', translationKey: 'medical_record.signs' },
  { name: 'age', translationKey: 'medical_record.age' },
  { name: 'homeAdress', translationKey: 'medical_record.address' },
  { name: 'recentTrips', translationKey: 'medical_record.recent_trips' },
  { name: 'weight', translationKey: 'medical_record.weight' },
  { name: 'height', translationKey: 'medical_record.height' },
  { name: 'temperatura', translationKey: 'medical_record.body_temperature' },
  { name: 'pulse', translationKey: 'medical_record.heart_rate' },
  { name: 'breathesPerMinute', translationKey: 'medical_record.bpm' },
  { name: 'pressure', translationKey: 'medical_record.blood_pressure' },
  { name: 'oxygenSaturation', translationKey: 'medical_record.oxygen_saturation' },
];

export const laboratoryExams: InputType[] = [
  { name: 'Na', translationKey: 'medical_record.sodium_blood_test' },
  { name: 'K', translationKey: 'medical_record.potassium_blood_test' },
  { name: 'Cl', translationKey: 'medical_record.chloride_blood_test' },
  { name: 'WBC', translationKey: 'medical_record.white_blood_cell_count' },
  { name: 'HCT', translationKey: 'medical_record.hematocrit_blood_test' },
  { name: 'bakingSoda', translationKey: 'medical_record.bicarbonate_blood_test' },
  { name: 'BUN', translationKey: 'medical_record.blood_urea_nitrogen_test' },
  { name: 'glukose', translationKey: 'medical_record.glucose_blood_test' },
  { name: 'platelets', translationKey: 'medical_record.platelet_blood_count' },
  { name: 'creatinine', translationKey: 'medical_record.creatinine_blood_test' },
  { name: 'hepaticFunctionality', translationKey: 'medical_record.liver_function_tests' },
  { name: 'renalFunctionality', translationKey: 'medical_record.kidney_function_tests' },
  { name: 'specials', translationKey: 'medical_record.special_request_tests' },
];

export const radiologicalExams: InputType[] = [
  { name: 'ultrasound', translationKey: 'medical_record.ultrasound_results' },
  { name: 'xRays', translationKey: 'medical_record.x_rays_results' },
  { name: 'tomography', translationKey: 'medical_record.ct_scan_results' },
  { name: 'resonancia', translationKey: 'medical_record.mri_results' },
];

export const diagnosis: InputType[] = [{ name: 'diagnosis', translationKey: 'medical_record.diagnostic', multiline: true }];

export const clinicalTreatment: InputType[] = [
  { name: 'treatmentDays', translationKey: 'medical_record.days_in_treatment' },
  { name: 'treatmentMedications', translationKey: 'medical_record.medication_for_treatment', multiline: true },
];

export const surgicalTreatment: InputType[] = [
  { name: 'surgicalTreatmentDays', translationKey: 'medical_record.days_in_treatment' },
  { name: 'treatmentProcedures', translationKey: 'medical_record.procedures_in_treatment', multiline: true },
];

export const signosVitalesAlt = [
  { name: 'date', translationKey: 'signos_vitales_alt.date', md: 2, sm: 6, xs: 12 },
  { name: 'hour', translationKey: 'signos_vitales_alt.hour', md: 1, sm: 6, xs: 12 },
  { name: 'p/a', translationKey: 'signos_vitales_alt.p/a', md: 1, sm: 6, xs: 12 },
  { name: 'f/c', translationKey: 'signos_vitales_alt.f/c', md: 1, sm: 6, xs: 12 },
  { name: 'breathing', translationKey: 'signos_vitales_alt.breathing', md: 2, sm: 6, xs: 12 },
  { name: 'temperature', translationKey: 'signos_vitales_alt.temperature', md: 2, sm: 6, xs: 12 },
  { name: 'observations', translationKey: 'signos_vitales_alt.observations', md: 2, sm: 6, xs: 12 },
  { name: 'signature', translationKey: 'signos_vitales_alt.signature', md: 1, sm: 6, xs: 12 },
];

export const controlDeMedicamentos = [
  { name: 'initialDate', translationKey: 'control_de_medicamentos_alt.initialDate', md: 2, sm: 6, xs: 12 },
  { name: 'medicine', translationKey: 'control_de_medicamentos_alt.medicine', md: 2, sm: 6, xs: 12 },
  { name: 'hour', translationKey: 'control_de_medicamentos_alt.hour', md: 2, sm: 6, xs: 12 },
  { name: 'day', translationKey: 'control_de_medicamentos_alt.day', md: 2, sm: 6, xs: 12 },
  { name: 'bed', translationKey: 'control_de_medicamentos_alt.bed', md: 2, sm: 6, xs: 12 },
  { name: 'room', translationKey: 'control_de_medicamentos_alt.room', md: 2, sm: 6, xs: 12 },
];

export const signosVitalesAltForPdf = [
  { name: 'date', translation: 'Fecha' },
  { name: 'hour', translation: 'Hora' },
  { name: 'p/a', translation: 'P/Arterial' },
  { name: 'f/c', translation: 'F/C' },
  { name: 'breathing', translation: 'Respiracion' },
  { name: 'temperature', translation: 'Temperatura' },
  { name: 'observations', translation: 'Observaciones' },
  { name: 'signature', translation: 'Firma' },
];

export const controlDeMedicamentosForPdf = [
  { name: 'initialDate', translation: 'Fecha Inicial' },
  { name: 'medicine', translation: 'Medicamento' },
  { name: 'hour', translation: 'Hora' },
  { name: 'day', translation: 'Día' },
  { name: 'bed', translation: 'Cama' },
  { name: 'room', translation: 'Sala' },
];
