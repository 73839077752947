import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Tabs,
  Tab,
  AppBar,
  TextField,
  Box,
  Button,
  InputAdornment,
  Hidden,
  MenuItem,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import { Edit, Done } from '@material-ui/icons';

import { FormattedMessage } from 'react-intl';

import { useStoreActions, useStoreState } from 'easy-peasy';
import usePermissions from 'utils/usePermissions';
import { useTranslations } from 'utils/useTranslations';
import { debounce } from 'lodash';

import cataloguesFetch from 'api/Catalogue';

import { TableWithPagination } from 'components/TableWithPagination';
import getPermissions from 'utils/getPermissions';
import useStyles from './style';
import { CatalogueItem, MobileCatalogueItem } from './CatalogueItem';

import { CatalogueAddModal } from './CatalogueAddModal';
import CatalogueViewMoreModal from './CatalogueViewMoreModal';

const thCells = [
  {
    name: 'catalogue.codigo',
  },
  {
    name: 'catalogue.descripcion',
  },
  {
    name: 'catalogue.disponible',
  },
  {
    name: 'catalogue.precio',
  },
  { name: ' ' },
];

const doctorCells = [
  {
    name: 'catalogue.codigo',
  },
  {
    name: 'catalogue.descripcion',
  },
  {
    name: 'catalogue.disponible',
  },
  { name: ' ' },
];

const validateNumber = (string) => {
  if (!/[0-9]+/.test(string)) {
    return {
      success: false,
      message: <FormattedMessage id="error.incorrect_symbols" description="Incorrect symbols" />,
    };
  }

  if (+string > 100 || +string < 0) {
    return {
      success: false,
      message: <FormattedMessage id="error.incorrect_numbers" description="Incorrect numbers" />,
    };
  }

  return { success: true };
};

export default function Catalogue() {
  const { accessToken } = JSON.parse(localStorage.getItem('AUTH'));
  // const catalogues = useStoreState((state) => state.catalogues);
  const user = useStoreState((state) => state.user);
  const style = useStyles();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { canCrudCatalogAndVat } = usePermissions();
  // const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const intl = useTranslations();
  const vatValue = useStoreState((state) => state.vat);
  const setVat = useStoreActions((actions) => actions.setVat);
  const [newVatValue, setNewVatValue] = useState(vatValue);
  const [vatError, setVatError] = useState(false);
  const [editVatMode, setEditVatMode] = useState(false);
  const [currentCatalogItem, setCurrentCatalogItem] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);

  const [catalogLoading, setCatalogLoading] = useState(false);

  useEffect(() => {
    setNewVatValue(vatValue);
  }, [vatValue]);

  const toggleDialog = () => {
    setDialog((old) => !old);
  };

  const handleEditClick = (catalogItem) => {
    setCurrentCatalogItem(catalogItem);
    toggleDialog();
  };

  const handleMoreClick = (catalogItem) => {
    setCurrentCatalogItem(catalogItem);
    setViewDialog(true);
  };

  const sendVat = async () => {
    const result = validateNumber(newVatValue);
    if (result.success) {
      const token = localStorage.getItem('accessToken');
      const response = await cataloguesFetch.editVatPercent(token, {
        vatPercent: +newVatValue,
      });
      setVat(response.percent);
      setEditVatMode(false);
      return true;
    }
    setVatError(result.message);
    return false;
  };

  const makeEditable = () => {
    setEditVatMode(true);
  };

  const handleVatChange = (e) => {
    setVatError(false);
    setNewVatValue(e.target.value);
  };

  const [catalogPagination, setCatalogPagination] = useState({
    search: '',
    page: 0,
    rowsPerPage: 10,
    data: [],
    count: 0,
    status: 'TRUE',
  });

  const handleStatusChange = (newStatus) => {
    setCatalogPagination((prevState) => ({ ...prevState, status: newStatus, data: [] }));
  };

  const handleChangePage = (_, newPage) => {
    setCatalogPagination((prevState) => ({ ...prevState, page: newPage }));
  };
  const handleChangeRowsPerPage = (e) => {
    setCatalogPagination((prevState) => ({ ...prevState, rowsPerPage: e.target.value, page: 0 }));
  };

  const handleShowMore = () => {
    setCatalogPagination((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  const loadCatalog = async () => {
    try {
      setCatalogLoading(true);
      const catalog = await cataloguesFetch.getCatalogue(accessToken, {
        limit: catalogPagination.rowsPerPage,
        skip: catalogPagination.page * catalogPagination.rowsPerPage,
        query: catalogPagination.search,
        status: catalogPagination.status,
      });
      if (isSmUp) {
        setCatalogPagination((prevState) => ({ ...prevState, data: catalog.catalog, count: catalog.count }));
      } else {
        setCatalogPagination((prevState) => ({ ...prevState, data: [...prevState.data, ...catalog.catalog], count: catalog.count }));
      }
      setCatalogLoading(false);
    } catch (err) {
      setCatalogLoading(false);
    }
  };

  const deleteCatalogue = async (catalogue) => {
    try {
      await cataloguesFetch.removeCatalogue(accessToken, catalogue._id);
      // const newCataloguesArray = catalogToShow.filter((el) => el._id !== catalogue._id);
      if (isSmUp) {
        loadCatalog();
      } else {
        setCatalogPagination((prevState) => ({ ...prevState, data: [], count: 0 }));
        loadCatalog();
      }
      // setCatalogues(newCataloguesArray);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchChange = useCallback(
    debounce((value) => {
      setCatalogPagination((prevState) => ({ ...prevState, search: value, data: [], page: 0 }));
    }, 1000),
    [],
  );

  const handleCatalogUpdateAction = (newCatalog) => {
    if (currentCatalogItem && currentCatalogItem._id === newCatalog._id) {
      const updatedCatalog = catalogPagination.data.map((cItem) => (cItem._id === newCatalog._id ? newCatalog : cItem));

      setCatalogPagination((prevState) => ({ ...prevState, data: updatedCatalog }));
    } else {
      setCatalogPagination((prevState) => ({
        ...prevState,
        data: [newCatalog, ...prevState.data.filter((_, i) => i !== prevState.data.length - 1)],
        count: prevState.count + 1,
      }));
    }
    setCurrentCatalogItem(null);
  };

  const handleClose = () => {
    setDialog(false);
    setViewDialog(false);
    setCurrentCatalogItem(null);
  };

  useEffect(() => {
    loadCatalog();
  }, [catalogPagination.rowsPerPage, catalogPagination.page, catalogPagination.search, catalogPagination.status]);

  return (
    <Container component="main">
      <Box className={style.buttonCont}>
        <FormattedMessage id="position.find_catalog" description="Search">
          {(msg) => (
            <TextField
              className={style.search}
              variant="outlined"
              margin="normal"
              label={<FormattedMessage id="user.search" description="Search" />}
              placeholder={msg}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                handleSearchChange(e.target.value);
              }}
            />
          )}
        </FormattedMessage>
        {canCrudCatalogAndVat && (
          <Box className={style.containerForEditMode}>
            <TextField
              fullWidth
              className={style.vatContainer}
              variant="outlined"
              error={vatError}
              disabled={!editVatMode}
              type="number"
              label={<FormattedMessage id="VAT.percent" description="VAT" />}
              value={newVatValue}
              onChange={handleVatChange}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              helperText={vatError}
            />
            <Button onClick={editVatMode ? sendVat : makeEditable}>{editVatMode ? <Done /> : <Edit />}</Button>
          </Box>
        )}

        {canCrudCatalogAndVat && (
          <Button onClick={toggleDialog} className={style.button} variant="contained" color="primary" style={{ marginBottom: 14 }}>
            <FormattedMessage id="catalogue.add_new_item" description="Add new catalogue" />
          </Button>
        )}
      </Box>
      <Box className={style.barWithStatus}>
        <AppBar className={style.appBar} position="static" color="primary">
          <Tabs
            className={style.tabs}
            value={0}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#3f51b5',
              },
            }}
          >
            <Tab className={style.tab} label={<FormattedMessage id="catalogue" description="Catalogue" />} />
          </Tabs>
        </AppBar>
        {canCrudCatalogAndVat && (
          <Box width="200px">
            <TextField
              variant="outlined"
              fullWidth
              select
              label="Estado"
              value={catalogPagination.status}
              name="catalogStatus"
              onChange={(e) => handleStatusChange(e.target.value)}
            >
              <MenuItem value={null}>N/A</MenuItem>
              <MenuItem value="TRUE">{intl('catalogue.status.active')}</MenuItem>
              <MenuItem value="FALSO">{intl('catalogue.status.notActive')}</MenuItem>
            </TextField>
          </Box>
        )}
      </Box>
      <Hidden xsDown>
        <TableWithPagination
          tableContainerProps={{ className: style.tableContainer }}
          thCells={getPermissions(user.type, ['admin', 'staff', 'pharmacist']) ? thCells : doctorCells}
          paginationProps={{
            count: catalogPagination.count,
            page: catalogPagination.page,
            rowsPerPage: catalogPagination.rowsPerPage,
            handleChangePage,
            handleChangeRowsPerPage,
          }}
          loading={catalogLoading}
        >
          {catalogPagination.data.map((catalogueItem) => (
            <CatalogueItem
              userType={user.type}
              onMore={handleMoreClick}
              onDelete={() => deleteCatalogue(catalogueItem)}
              onEdit={() => handleEditClick(catalogueItem)}
              catalogue={catalogueItem}
              vatPerCent={newVatValue}
            />
          ))}
        </TableWithPagination>
      </Hidden>
      <Hidden smUp>
        {catalogPagination.data.map((catalogueItem) => (
          <MobileCatalogueItem
            userType={user.type}
            onDelete={() => deleteCatalogue(catalogueItem)}
            onEdit={() => handleEditClick(catalogueItem)}
            catalogue={catalogueItem}
            onMore={handleMoreClick}
            vatPerCent={newVatValue}
          />
        ))}
        {catalogLoading && (
          <Box display="flex" width="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
        {catalogPagination.data.length < catalogPagination.count && (
          <Button variant="outlined" color="primary" onClick={handleShowMore}>
            Show more
          </Button>
        )}
      </Hidden>
      <CatalogueAddModal
        onCatalogUpdateAction={handleCatalogUpdateAction}
        open={dialog}
        handleClose={handleClose}
        currentCatalogItem={currentCatalogItem}
        toggle={toggleDialog}
      />
      <CatalogueViewMoreModal vatPerCent={newVatValue} open={viewDialog} onClose={handleClose} catalogItem={currentCatalogItem} />
    </Container>
  );
}
