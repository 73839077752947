import React, { useMemo } from 'react';
import { Box, Card, CardContent, Divider, IconButton, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { Delete, Edit } from '@material-ui/icons';

export default function Comments({ comments, onRemoveComment = null, onEditComment = null }) {
  const user = useStoreState((state) => state.user);
  const { pathname } = useLocation();
  const isOld = useMemo(() => pathname.includes('old'), [pathname]);
  return (
    <Box>
      {comments.map((comment) => (
        <Box mt={2} key={comment.createdAt}>
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography style={{ fontSize: 18, fontWeight: 600 }}>{comment.comment}</Typography>
                {!isOld && user.type === 'admin' && (
                  <Box>
                    <IconButton onClick={() => onEditComment('comment', comment)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => onRemoveComment?.(comment.createdAt)}>
                      <Delete />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Divider />
              <Typography>{format(comment.createdAt, 'dd.MM.yyyy')}</Typography>
              <Link to={user._id === comment.userId ? '/user/profile' : `/user/medical_professionals/profile/${comment.userId}`}>
                <Typography style={{ fontWeight: 'bold' }}>{comment.commentedBy}</Typography>
              </Link>
              <small>{comment.userType}</small>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  );
}
