import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslations } from 'utils/useTranslations';
import useCaseFetch from 'api/Case';
import { useParams } from 'react-router-dom';
import { BudgetTypes } from 'types/cases';

interface Props {
  open: boolean;
  onClose: () => void;
}
interface ParamType {
  patient: string;
}

interface INewCaseForm {
  budget: string;
  name: Date;
  firstDoc: boolean;
  secondDoc: boolean;
}

const initValues: INewCaseForm = {
  budget: '',
  name: null,
  firstDoc: false,
  secondDoc: false,
};

const budgetOptions = [BudgetTypes.MILD, BudgetTypes.MODERATE, BudgetTypes.SEVERE];

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 200,
  },
}));

export const NewCaseModal = ({ open, onClose }: Props) => {
  const { create } = useCaseFetch();
  const { patient } = useParams<ParamType>();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { values, setFieldValue, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object({
      name: Yup.date().required(),
      budget: Yup.string(),
    }),
    async onSubmit(formValues, formContext) {
      try {
        const data: { name: string; budget?: string } = {
          name: formValues.name.toISOString(),
        };
        if (formValues.budget) {
          data.budget = formValues.budget;
        }
        setLoading(true);
        const response = await create(patient, data);
        // console.log(response);
        formContext.resetForm();
        setLoading(false);
        onClose();
      } catch (err) {
        setLoading(false);
      }
    },
  });
  const intl = useTranslations();
  const handleNameChange = (date: Date) => {
    setFieldValue('name', date);
  };
  return (
    <Dialog open={open} onClose={loading ? () => null : onClose}>
      <DialogTitle>{intl('case.addNewCase')}</DialogTitle>

      <DialogContent>
        <KeyboardDatePicker
          fullWidth
          margin="normal"
          format="dd.MM.yyyy"
          id="time-picker"
          onChange={handleNameChange}
          name="name"
          className={classes.input}
          error={Boolean(touched.name && errors.name)}
          label={intl('case.date_name')}
          value={values.name}
        />
        <TextField
          select
          fullWidth
          className={classes.input}
          name="budget"
          label={intl('case.estimate')}
          value={values.budget}
          error={Boolean(touched.budget && errors.budget)}
          onChange={handleChange}
        >
          {budgetOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {intl(`case.budgetOption.${option}`)}
            </MenuItem>
          ))}
        </TextField>
        <Box display="flex">
          <FormControlLabel
            color="primary"
            onChange={handleChange}
            checked={values.firstDoc}
            control={<Checkbox checked={values.firstDoc} color="primary" name="firstDoc" />}
            label={intl('case.firstDocument')}
          />
          <FormControlLabel
            color="primary"
            onChange={handleChange}
            checked={values.secondDoc}
            control={<Checkbox checked={values.secondDoc} color="primary" name="secondDoc" />}
            label={intl('case.thirdDocument')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="secondary">
          {intl('case.cancel')}
        </Button>
        <Button disabled={loading || !(values.secondDoc && values.firstDoc)} color="primary" onClick={() => handleSubmit()}>
          {intl('case.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
