import axiosClient from 'api';

export class DiscountApi {
  static addDiscount({ caseId, discountType, amount }) {
    return axiosClient.post('discount', { caseId, discountType, amount });
  }

  static removeDiscount(id: string) {
    return axiosClient.delete(`discount/${id}`);
  }
}
