import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Button, Box } from '@material-ui/core';
import { useTranslations } from 'utils/useTranslations';
import clsx from 'clsx';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';

import { format, isValid } from 'date-fns';
import { AddPaymentModal, INewPaymentModal } from 'components/Modals/AddPaymentModal';
import { roundTo2 } from 'utils/roundTo2';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { BillingPdf } from 'components/BillingPdf/BillingPdf';
import { BillingDetailedPdf } from 'components/BillingPdf/BillingDetailedPdf';

import { AddDiscountModal } from 'components/Modals/AddDiscountModal';
import { convertNumber } from 'utils/formatters';
import BillBySubCategoryPdf from 'components/BillingPdf/BillBySubCategoryPdf';
import PaymentTable from './paymentsTable/PaymentsTable';
import { CaseListItem, Payment } from '../PatientCases/types';
import { useStyles } from './styles';
import DiscountTable from './discountTable/DiscountTable';
import { countTotals } from './PatientBill.utils';

interface INewDiscountModal {
  open: boolean;
  discount: any | null;
}

interface INewDiscountModal {
  open: boolean;
  discount: any | null;
}

const PatientBill = () => {
  const intl = useTranslations();
  const styles = useStyles();
  const { caseId } = useParams<{ caseId: string; patient: string }>();

  const caseItem: CaseListItem = useStoreState((state) => state.cases.getById(caseId));
  const patients: any = useStoreState((state) => state.patientsMap);

  const patient = patients?.[caseItem?.patientId];

  const [addNewPaymentModal, setAddNewPaymentModal] = useState<INewPaymentModal>({
    open: false,
    payment: null,
  });

  const [addNewDiscountModal, setAddNewDiscountModal] = useState<INewDiscountModal>({
    open: false,
    discount: null,
  });

  const [pdfLoading, setPdfLoading] = useState(true);

  const onClose = () => setAddNewPaymentModal((oldPaymentModal) => ({ ...oldPaymentModal, open: false }));

  const onOpen = (payment?: Payment) => setAddNewPaymentModal((oldPaymentModal) => ({ ...oldPaymentModal, open: true, payment }));

  const onOpenDiscountModal = (discount?: Payment) => setAddNewDiscountModal((oldDiscountModal) => ({ ...oldDiscountModal, open: true, discount }));
  const onCloseDiscountModal = () => setAddNewDiscountModal({ open: false, discount: null });

  useEffect(() => {
    setPdfLoading(true);
    if (caseItem) {
      setTimeout(() => {
        setPdfLoading(false);
      }, 500);
    }
  }, [caseItem]);

  const showDiscountsBlock = useMemo(() => !!caseItem?.discounts?.length, [caseItem?.discounts]);

  const showPaymentsBlock = useMemo(() => !!caseItem?.payments?.length, [caseItem?.payments]);

  const { totalLeftToPay, totalToPay } = useMemo(() => countTotals(caseItem?.total, caseItem?.paidTotal, caseItem?.discounts), [caseItem]);

  const formattedTotals = useMemo(
    () => ({
      totalToPay: convertNumber(roundTo2(totalToPay)),
      totalLeftToPay: convertNumber(roundTo2(totalLeftToPay)),
      paidTotal: convertNumber(roundTo2(caseItem?.paidTotal)),
    }),
    [totalLeftToPay, totalToPay],
  );

  return (
    caseItem && (
      <>
        <Box className={styles.sectionHeader}>
          <Typography className={styles.sectionHeaderBold} variant="h5">
            {intl('patient.bills')} {isValid(new Date(caseItem.name)) ? format(new Date(caseItem.name), 'dd.MM.yyyy') : caseItem.name}
          </Typography>
        </Box>
        <Box className={clsx(styles.sectionHeader)}>
          <Typography className={styles.sectionSubHeader} variant="h5">
            {intl('patient.totalAmount')}: {formattedTotals.totalToPay}
          </Typography>
          {!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (
            <>
              {pdfLoading ? (
                <>Cargando documento ...</>
              ) : (
                <Box>
                  <Box mb="16px">
                    <PDFDownloadLink fileName={caseItem._id} document={<BillingPdf patient={patient} caseItem={caseItem} />}>
                      {({ loading }) => (
                        <Button fullWidth variant="contained" color="primary">
                          {loading ? 'Cargando documento ...' : '¡Descargar ahora!'}
                        </Button>
                      )}
                    </PDFDownloadLink>
                  </Box>
                  <Box mb="16px">
                    <PDFDownloadLink fileName={caseItem._id} document={<BillingDetailedPdf patient={patient} caseItem={caseItem} />}>
                      {({ loading }) => (
                        <Button fullWidth variant="contained" color="primary">
                          {loading ? 'Cargando documento ...' : 'Descargar pdf resumido ahora !'}
                        </Button>
                      )}
                    </PDFDownloadLink>
                  </Box>
                  <Box mb="16px">
                    <PDFDownloadLink
                      fileName={caseItem._id}
                      document={<BillBySubCategoryPdf withMedicalRecords patient={patient} caseItem={caseItem} />}
                    >
                      {({ loading }) => (
                        <Button fullWidth variant="contained" color="primary">
                          {loading ? 'Cargando documento ...' : 'Descargar Resumen por Record Medico'}
                        </Button>
                      )}
                    </PDFDownloadLink>
                  </Box>
                  <Box>
                    <PDFDownloadLink fileName={caseItem._id} document={<BillBySubCategoryPdf patient={patient} caseItem={caseItem} />}>
                      {({ loading }) => (
                        <Button fullWidth variant="contained" color="primary">
                          {loading ? 'Cargando documento ...' : 'Descargar Resumen'}
                        </Button>
                      )}
                    </PDFDownloadLink>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>

        <Box className={clsx(styles.sectionHeader)}>
          <Typography className={styles.sectionSubHeader} variant="h5">
            {intl('patient.totalAmountPaid')}
          </Typography>
          <Box display="flex" alignItems="flex-start">
            <Button className={styles.addPaymentButton} variant="contained" onClick={() => onOpen()} color="primary">
              {intl('patient.addNewPayment')}
            </Button>
            <Button variant="contained" onClick={() => onOpenDiscountModal()} color="primary">
              {intl('discount.addDiscount')}
            </Button>
          </Box>
        </Box>

        {showPaymentsBlock && (
          <Box mt="2em">
            <Typography className={styles.sectionSubHeader} variant="h5">
              {intl('payment.payments')}
            </Typography>
            <PaymentTable payments={caseItem?.payments} total={formattedTotals.paidTotal} />
          </Box>
        )}

        {showDiscountsBlock && (
          <Box mt="2em">
            <Typography className={styles.sectionSubHeader} variant="h5">
              {intl('discount.discounts')}
            </Typography>
            <DiscountTable discounts={caseItem.discounts} />
          </Box>
        )}

        <Box className={clsx(styles.sectionHeader, styles.sectionConent)}>
          <Typography className={styles.sectionSubHeader} variant="h5">
            {intl('patient.totalAmountToPay')}: {formattedTotals.totalLeftToPay}
          </Typography>
        </Box>

        <AddPaymentModal
          initialValues={
            addNewPaymentModal.payment
              ? {
                  paymentNumber: addNewPaymentModal.payment.paymentNumber,
                  trsType: addNewPaymentModal.payment.trsType,
                  amount: addNewPaymentModal.payment.amount,
                }
              : undefined
          }
          paymentId={addNewPaymentModal.payment ? addNewPaymentModal.payment._id : undefined}
          open={addNewPaymentModal.open}
          onClose={onClose}
        />

        <AddDiscountModal initialValues={addNewDiscountModal.discount} open={addNewDiscountModal.open} onClose={onCloseDiscountModal} />
      </>
    )
  );
};

export default PatientBill;
