import React from 'react';
import { useParams } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import MedicalRecordSKUTable from 'components/MedicalRecordSKUTable';

const PatientAccountRecord = () => {
  const { recordId } = useParams<{ recordId: string }>();
  const medicalRecord = useStoreState((state) => state.medicalRecords.getById(recordId));

  return <MedicalRecordSKUTable catalog={medicalRecord.catalog} />;
};
export default PatientAccountRecord;
