/* eslint-disable arrow-body-style */
import { Document, Image, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import React, { memo } from 'react';
import logo from 'assets/images/managemed-logo-2.png';
import prodLogo from 'assets/images/hospitalprivado.png';
import { format, isValid } from 'date-fns';
import { convertDate } from 'utils/formatters';
import { controlDeMedicamentosForPdf, signosVitalesAltForPdf } from 'pages/User/MedicalRecord/constants';

Font.register({ family: 'Roboto', src: 'assets/fonts/roboto/Roboto-Bold.ttf' });

const styles = StyleSheet.create({
  name: {
    marginLeft: 40,
    fontSize: 24,
  },
  medRecordTitle: {
    fontSize: 16,
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 10,
  },
  logo: {
    width: 200,
    marginLeft: 40,
    marginTop: 40,
    marginBottom: 40,
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: 'grey',
    display: 'flex',
    flexDirection: 'row',
  },
  tableCell: {
    borderRightColor: 'grey',
    borderRightWidth: 1,
    minHeight: 25,
    width: 166.6666,
    padding: 5,
  },
  tableCellText: {
    fontSize: 12,
  },
  table: {
    borderTopColor: 'grey',
    borderLeftColor: 'grey',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    marginLeft: 40,
    width: 500,
  },
  emptyState: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 12,
  },

  sectionTitle: {
    fontSize: 18,
    marginBottom: 15,
    marginTop: 15,
    marginLeft: 40,
    fontWeight: 500,
  },
  block: {
    // borderWidth: 1,
    // borderColor: 'grey',
    marginLeft: 40,
    marginTop: 15,
    marginBottom: 15,
    padding: 5,
    width: 500,

    // padding: 5,
  },

  blockTitle: {
    fontSize: 18,
    marginLeft: 40,
    marginBottom: 0,
    marginTop: 15,
    fontWeight: 500,
  },
  blockItem: {
    // padding: 5,
    // marginBottom: 1,
    // marginBottom: 15,
    // display: 'flex',
    // flexDirection: 'row',
    marginBottom: 12,
  },
  blockItemText: {
    fontSize: 12,
  },
  blockItemTitleText: {
    fontSize: 14,
    fontFamily: 'Roboto',
    letterSpacing: 0.5,
    // fontWeight: 700,
  },
  comment: {
    marginLeft: 40,
    borderColor: 'grey',
    borderWidth: 1,
    borderRadius: 5,
    width: 500,
    padding: 10,
    marginBottom: 15,
  },
  page: {
    padding: 20,
  },
  smallCommentText: {
    fontSize: 10,
  },
  commentText: {
    fontSize: 12,
  },
  commentTitle: {
    fontSize: 14,
    fontWeight: 600,
  },

  signField: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    width: 100,
    position: 'absolute',
    bottom: 40,
    left: 40,
  },
  signText: { position: 'absolute', bottom: 25, left: 40, fontSize: 8 },
  recordVitalesFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  recordVitalesFlexItem: {
    width: 100,
    marginBottom: 10,
  },
});

const columns = [
  { name: 'codigo', translationKey: 'Codigo' },
  { name: 'descripcion', translationKey: 'Descripcion' },
  { name: 'amount', translationKey: 'Cantidad' },
];

const EmptySkusState = () => (
  <View style={styles.emptyState}>
    <Text>No se han agregado skus</Text>
  </View>
);

const SkuSubcategoryTable = ({ catalog, title }) => {
  return (
    <View>
      <Text style={styles.sectionTitle}>{title}</Text>
      {catalog.length ? (
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {columns.map((field) => (
              <View style={styles.tableCell} key={field.name}>
                <Text style={styles.tableCellText}>{field.translationKey}</Text>
              </View>
            ))}
          </View>
          {catalog.map((row) => (
            <View style={styles.tableRow} key={row._id}>
              {columns.map(({ name }) => (
                <View style={styles.tableCell} key={name}>
                  <Text style={styles.tableCellText}>{row[name]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      ) : (
        <EmptySkusState />
      )}
    </View>
  );
};

const SkuTable = ({ catalogList }: any) => {
  const medicaments = catalogList.filter((row) => row.subcategory === 'medicine');
  const services = catalogList.filter((row) => row.subcategory === 'service');
  const supplies = catalogList.filter((row) => row.subcategory === 'supply');
  const exams = catalogList.filter((row) => row.subcategory === 'exam');

  return (
    <View>
      {catalogList.length ? (
        <>
          <SkuSubcategoryTable catalog={exams} title="Exámenes" />
          <SkuSubcategoryTable catalog={services} title="Servicios" />
          <SkuSubcategoryTable catalog={medicaments} title="Medicamentos" />
          <SkuSubcategoryTable catalog={supplies} title="Suministros" />
        </>
      ) : (
        <EmptySkusState />
      )}
    </View>
  );
};

const RecordSimpleField = ({ data = [], label = '' }: any) => {
  return (
    <View wrap={false}>
      <Text style={styles.sectionTitle}>{label}</Text>

      {data?.map((dataItem) => (
        <View style={styles.comment} key={dataItem.createdAt}>
          <Text style={styles.commentText}>{dataItem.text}</Text>
          <Text style={styles.smallCommentText}>{convertDate(dataItem.createdAt)}</Text>
          <Text style={styles.smallCommentText}>{dataItem.createdBy}</Text>
          <Text style={styles.smallCommentText}>{dataItem.userType}</Text>
        </View>
      ))}
    </View>
  );
};

const RecordComplexField = ({ data = [], label = '', mapper = [] }: any) => {
  return (
    <View wrap={false}>
      <Text style={styles.sectionTitle}>{label}</Text>

      {data?.map((dataItem) => (
        <View style={styles.comment} key={dataItem.createdAt}>
          <View style={styles.recordVitalesFlex}>
            {mapper.map(({ name, translation }) => (
              <View style={styles.recordVitalesFlexItem}>
                <Text style={styles.commentTitle}>{translation}</Text>
                <Text style={styles.commentText}>{dataItem[name]}</Text>
              </View>
            ))}
          </View>
          <Text style={styles.smallCommentText}>{convertDate(dataItem.createdAt)}</Text>
          <Text style={styles.smallCommentText}>{dataItem.createdBy}</Text>
          <Text style={styles.smallCommentText}>{dataItem.userType}</Text>
        </View>
      ))}
    </View>
  );
};

const RecordComments = ({ comments }: any) => {
  return (
    <View wrap={false}>
      <Text style={styles.sectionTitle}>Notas</Text>

      {Array.isArray(comments) ? (
        comments?.map((comment) => (
          <View style={styles.comment} key={comment.createdAt}>
            <Text style={styles.commentText}>{comment.comment}</Text>
            <Text style={styles.smallCommentText}>{format(comment.createdAt, 'dd.MM.yyyy')}</Text>
            <Text style={styles.smallCommentText}>{comment.commentedBy}</Text>
          </View>
        ))
      ) : (
        <View />
      )}
    </View>
  );
};

const RecordInfo = ({ medicalRecord }: any) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
      <RecordSimpleField data={medicalRecord.medicalEvolutionNote} label="Nota de Evolución Medica" />
      <RecordSimpleField data={medicalRecord.orderSummaryAlt} label="Resumen de órdenes" />
      <RecordComments
        comments={medicalRecord.comments
          ?.filter((comment) => comment.isNurse)
          .slice()
          .reverse()}
      />
      <RecordComplexField mapper={signosVitalesAltForPdf} data={medicalRecord.signosVitalesAlt} label="Signos vitales" />
      <RecordComplexField mapper={controlDeMedicamentosForPdf} data={medicalRecord.controlDeMedicamentosAlt} label="Control de Medicamentos" />
      <RecordSimpleField data={medicalRecord.laboratoryExamLink} label="Link a Examenes de Laboratorio" />
      <RecordSimpleField data={medicalRecord.radiologyExamLink} label="Link a Examenes de Radiologia" />
    </View>
  );
};

const MedicalRecordItem = ({ medicalRecord }: any) => {
  return (
    <View>
      <Text style={styles.medRecordTitle}>
        Record Medico {isValid(new Date(medicalRecord.name)) ? format(new Date(medicalRecord.name), 'dd.MM.yyyy') : medicalRecord.name}
      </Text>
      <RecordInfo medicalRecord={medicalRecord} />
      {medicalRecord?.catalog?.length ? <SkuTable catalogList={medicalRecord.catalog} /> : <></>}
    </View>
  );
};

export const MedicalRecordPdfAlt = memo(({ medicalRecord, patient }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={process.env.REACT_APP_ENV === 'master' ? prodLogo : logo} />

        <View>
          <Text style={styles.name}>
            {patient.firstName} {patient.lastName}
          </Text>
        </View>

        <MedicalRecordItem medicalRecord={medicalRecord} />

        {/* <View> */}
        <Text style={styles.signField} />
        <Text style={styles.signText}>(Firma del doctor)</Text>
        {/* </View> */}
      </Page>
    </Document>
  );
});
