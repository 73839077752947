import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  menuItem: {
    color: 'black',
    textDecoration: 'none',
    textAlign: 'center',
  },
  link: {
    color: 'black',
    textAlign: 'center',
    textDecoration: 'none',
  },
}));
