import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  title: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: { marginBottom: '12px' },
  },
  titleName: {
    [theme.breakpoints.down('sm')]: { fontSize: 20 },
  },
  input: {
    marginBottom: 20,
    '&:first-child': {
      marginTop: 20,
    },
  },
}));

export default useStyles;
