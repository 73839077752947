export enum UserTypeEnum {
  doctor = 'doctor',
  technician_labs = 'technician_labs',
  technician_radiology = 'technician_radiology',
  nurse = 'nurse',
  pharmacist = 'pharmacist',
  staff = 'staff',
  admin = 'admin',
  patient = 'patient',
}
