import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableRow, TableCell, Box } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { convertNumber } from 'utils/formatters';
import usePermissions from 'utils/usePermissions';
import { useTranslations } from 'utils/useTranslations';
import { defaultFields, restrictedFieldNames } from './constants';

type Props = {
  open: boolean;
  onClose: () => void;
  catalogItem: any;
  vatPerCent: number;
};

const CatalogueViewMoreModal = ({ open, onClose, catalogItem, vatPerCent }: Props) => {
  const intl = useTranslations();
  const { canCrudCatalogAndVat } = usePermissions();

  const [imageRnd, setImageRnd] = useState(Math.random());

  useEffect(() => {
    setImageRnd(Math.random());
  }, [open]);

  const price = convertNumber(Math.round(catalogItem?.precio * (1 + vatPerCent / 100) * 100) / 100);
  const fieldsData = useMemo(
    () => ({
      ...catalogItem,
      price,
      category: catalogItem?.category ? intl(`categories.${catalogItem?.category}`) : 'N/A',
      subcategory: catalogItem?.subcategory ? intl(`subcategories.${catalogItem?.subcategory}`) : 'N/A',
    }),
    [price, catalogItem],
  );

  const fields = useMemo(
    () => (canCrudCatalogAndVat ? defaultFields : defaultFields.filter((field) => !restrictedFieldNames.includes(field.nameInModel))),
    [canCrudCatalogAndVat],
  );

  return (
    <Dialog open={open} onClose={onClose} style={{ width: '100%' }}>
      <DialogTitle>{intl('catalogue.add_new_item')}</DialogTitle>
      <DialogContent>
        <Table>
          {fields.map(({ nameInModel, translationKey }) => (
            <TableRow key={nameInModel}>
              <TableCell>{intl(translationKey)}</TableCell>
              <TableCell>{fieldsData[nameInModel] || '---'}</TableCell>
            </TableRow>
          ))}
        </Table>
        {fieldsData.image && (
          <Box overflow="hidden" display="flex" height="200px" alignItems="center" justifyContent="center">
            <img style={{ height: 'inherit', maxWidth: '100%' }} alt="SKU" src={`${fieldsData.image}?${imageRnd}`} />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {intl('shared.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CatalogueViewMoreModal;
