import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Tabs,
  Tab,
  AppBar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Hidden,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { KeyboardDatePicker } from '@material-ui/pickers';
import catalogueFetch from 'api/Catalogue';
import { debounce } from 'lodash';

import usePermissions from 'utils/usePermissions';
import { Chart } from 'components/Chart';
import useStyles from './style';
import AccountsItem from './AccountsItem';
import AccountsItemMobile from './AccountsItemMobile';

export default function AdminsAccounts() {
  const style = useStyles();

  const [tab, setTab] = useState(0);

  const [patientNameText, setPatientNameText] = useState('');
  const [skuNumberText, setSkuNumberText] = useState('');

  const [skuNumber, setSkuNumber] = useState('');
  const [patientName, setPatientName] = useState('');

  const [isInRange, setIsInRange] = useState('day');
  const [dateFrom, setDateFrom] = useState(new Date().setHours(0, 0, 0, 0));
  const [dateTo, setDateTo] = useState(new Date().setHours(23, 59, 59, 999));
  const [servicesToView, setServicesToView] = useState([]);

  const { canViewPrices } = usePermissions();

  const [assignedCatalogPagination, setAssignedCatalogPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    count: 0,
  });

  const handleChangePage = (_, newPage) => {
    setAssignedCatalogPagination((prevState) => ({ ...prevState, page: newPage }));
  };
  const handlePatientNameChange = useCallback(
    debounce((value) => {
      handleChangePage(null, 0);
      setPatientName(value);
    }, 1000),
    [],
  );

  const handleSkuNumberChange = useCallback(
    debounce((value) => {
      handleChangePage(null, 0);
      setSkuNumber(value);
    }, 1000),
    [],
  );

  const handleChangeRowsPerPage = (e) => {
    setAssignedCatalogPagination((prevState) => ({ ...prevState, rowsPerPage: e.target.value, page: 0 }));
  };

  useEffect(() => {
    (async () => {
      const assignedServices = await catalogueFetch.getAllAssigned(localStorage.getItem('accessToken'), {
        dateTo: new Date(dateTo),
        dateFrom: new Date(dateFrom),
        patientQuery: patientName,
        skuQuery: skuNumber,
        limit: assignedCatalogPagination.rowsPerPage,
        skip: assignedCatalogPagination.page * assignedCatalogPagination.rowsPerPage,
      });
      setServicesToView(assignedServices.items || []);
      setAssignedCatalogPagination((prevState) => ({ ...prevState, count: assignedServices.count || 0 }));
    })();
  }, [dateFrom, dateTo, isInRange, patientName, skuNumber, assignedCatalogPagination.page, assignedCatalogPagination.rowsPerPage]);

  return (
    <Container component="main" className={style.container}>
      <AppBar className={style.appBar} position="static" color="primary">
        <Tabs
          className={style.tabs}
          value={tab}
          textColor="primary"
          onChange={(_, value) => setTab(value)}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#3f51b5',
            },
          }}
        >
          <Tab value={0} className={style.tab} label={<FormattedMessage id="patient_bills_sssigned_services" description="Assigned Services" />} />
          <Tab value={1} className={style.tab} label={<FormattedMessage id="charts.chart" description="Charts" />} />
        </Tabs>
      </AppBar>
      {tab === 0 && (
        <>
          <Box className={style.buttonCont} display="flex" alignItems="flex-start" flexWrap="wrap">
            <Box display="flex" flexDirection="column" className={style.dateAndRangeMobile} width="230px">
              <FormattedMessage id="inventory.find_by_sku">
                {(msg) => (
                  <TextField
                    className={style.search}
                    margin="normal"
                    fullWidth
                    label={<FormattedMessage id="inventory.sku_description" />}
                    value={skuNumberText}
                    onChange={(e) => {
                      setSkuNumberText(e.target.value);
                      handleSkuNumberChange(e.target.value);
                    }}
                    placeholder={msg}
                  />
                )}
              </FormattedMessage>
              <FormattedMessage id="inventory.find_by_patient">
                {(msg) => (
                  <TextField
                    className={style.search}
                    margin="normal"
                    fullWidth
                    label={<FormattedMessage id="position.patient" />}
                    value={patientNameText}
                    onChange={(e) => {
                      setPatientNameText(e.target.value);
                      handlePatientNameChange(e.target.value);
                    }}
                    placeholder={msg}
                  />
                )}
              </FormattedMessage>
            </Box>
            <Box>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={isInRange}
                onChange={(e) => {
                  if (e.target.value === 'day') {
                    setDateTo(new Date(dateFrom).setHours(23, 59, 59, 999));
                    setDateFrom(new Date(dateFrom).setHours(0, 0, 0, 0));
                  }
                  setIsInRange(e.target.value);
                }}
              >
                <FormControlLabel value="day" control={<Radio />} label={<FormattedMessage id="calendar.one_day" />} />
                <FormControlLabel value="range" control={<Radio />} label={<FormattedMessage id="calendar.in_range" />} />
              </RadioGroup>
            </Box>

            <Box display="flex" flexDirection="column">
              <KeyboardDatePicker
                margin="normal"
                format="dd.MM.yyyy"
                id="time-picker"
                label={<FormattedMessage id="calendar.from" description="From" />}
                value={dateFrom}
                onChange={(date) => {
                  if (isInRange === 'day') {
                    setDateTo(new Date(date).setHours(23, 59, 59, 999));
                  }
                  handleChangePage(null, 0);
                  setDateFrom(new Date(date).setHours(0, 0, 0, 0));
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
              {isInRange === 'range' && (
                <KeyboardDatePicker
                  margin="normal"
                  format="dd.MM.yyyy"
                  id="time-picker"
                  label={<FormattedMessage id="calendar.to" description="To" />}
                  value={dateTo}
                  onChange={(date) => {
                    handleChangePage(null, 0);
                    setDateTo(new Date(date).setHours(23, 59, 59, 999));
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              )}
            </Box>
          </Box>
          <Hidden xsDown>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="inventory.sku_description" description="Sku" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="inventory.name" description="Name" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="inventory.type" description="Type" />
                    </TableCell>
                    {canViewPrices && (
                      <>
                        <TableCell>
                          <FormattedMessage id="inventory.price" description="Price" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="inventory.quantity" description="Quantity" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id="inventory.total_cost" description="Total cost" />
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <FormattedMessage id="position.patient" description="Patient" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="record.date" description="Date" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="inventory.status" description="Status" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {servicesToView?.map?.((item) => (
                    <AccountsItem key={item._id} item={item} />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[1, 2, 3, 10, 15, 20]}
                component="div"
                count={assignedCatalogPagination.count}
                rowsPerPage={assignedCatalogPagination.rowsPerPage}
                page={assignedCatalogPagination.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Hidden>
          <Hidden smUp className={style.mobileContainer}>
            {servicesToView.map((item) => (
              <AccountsItemMobile item={item} />
            ))}
          </Hidden>
        </>
      )}

      {tab === 1 && <Chart />}
    </Container>
  );
}
