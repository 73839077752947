import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Box, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStoreState, useStoreActions } from 'easy-peasy';
import usePermissions from 'utils/usePermissions';
import { useTranslations } from 'utils/useTranslations';
import userFetch from '../../../api/User';
import omit from '../../../utils/omit';

import useStyles from './style';

import MaterialSelect from '../../../components/Select';
import { Edit, Save } from '@material-ui/icons';

const MedicalProfessionalProfile = ({ user, match }) => {
  const style = useStyles();
  const users = useStoreState((state) => state.users);
  const setUsersData = useStoreActions((actions) => actions.setUsersData);
  const setUserData = useStoreActions((actions) => actions.setUserData);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editIsLoading, setEditIsLoading] = useState(false);
  const intl = useTranslations();
  const { canCrudSpecialist } = usePermissions();

  const getMedicalSpecialist = () => {
    if (match.params.medicalSpecialist) {
      const medicalSpecialist = users.filter((u) => u._id === match.params.medicalSpecialist)[0];

      if (medicalSpecialist) {
        setCurrentUserData({
          ...medicalSpecialist,
        });
      }
    }
  };

  const handleUserDataChange = (e) => {
    setCurrentUserData({
      ...currentUserData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getMedicalSpecialist();
  }, [match.params.medicalSpecialist, users]);

  const requiredFields = {
    doctor: ['firstName', 'lastName', 'phone', 'title', 'role', 'collegiateNumber', 'skills'],
    admin: ['firstName', 'lastName', 'phone'],
    staff: ['firstName', 'lastName', 'phone'],
    nurse: ['firstName', 'lastName', 'phone', 'role', 'title'],
    technician_labs: ['firstName', 'lastName', 'phone', 'role', 'title'],
    technician_radiology: ['firstName', 'lastName', 'phone', 'role', 'title'],
    patient: ['firstName', 'lastName', 'phone', 'paymentType'],
    pharmacist: ['firstName', 'lastName', 'phone', 'role', 'title'],
  };

  const userRoleOptions = [
    { value: 'lic', name: intl('doctor.title.lic') },
    { value: 'ing', name: intl('doctor.title.ing') },
    { value: 'ph_d', name: intl('doctor.title.ph_d') },
    { value: 'dr', name: intl('doctor.title.dr') },
    { value: 'mtro', name: intl('doctor.title.mtro') },
    { value: 'mtr', name: intl('doctor.title.mtr') },
    { value: 'sr_sra', name: intl('doctor.title.sr_sra') },
  ];
  const onEditPress = async () => {
    if (editMode) {
      try {
        const currentUserDataOmmited = {
          ...omit(currentUserData, requiredFields[currentUserData.type]),
          userId: match.params.medicalSpecialist,
          type: currentUserData.type,
        };
        const accessToken = localStorage.getItem('accessToken');
        console.log(currentUserDataOmmited);
        setEditIsLoading(true);
        const userProfile = await userFetch.editUserProfile(accessToken, currentUserDataOmmited);
        setEditIsLoading(false);
        const updatedUserList = [...users];
        const index = updatedUserList.findIndex((user) => user._id === currentUserData._id);
        updatedUserList[index] = currentUserData;
        setUsersData(updatedUserList);
        setEditMode(false);
      } catch (err) {
        setEditMode(true);
        setEditIsLoading(false);
      }
    } else {
      setEditMode(true);
    }
  };

  const renderAdditionPositionFields = (user) => {
    switch (user.type) {
      case 'doctor':
      case 'nurse':
      case 'pharmacist':
      case 'technician_labs':
      case 'technician_radiology': {
        return (
          <>
            <MaterialSelect
              disabled={!editMode}
              options={userRoleOptions}
              value={user.title}
              className={style.input}
              name="title"
              onChange={handleUserDataChange}
              title={<FormattedMessage id="doctor.title" description="Title" />}
            />
            <TextField
              variant="outlined"
              name="role"
              label={<FormattedMessage id="doctor.role" />}
              className={style.input}
              value={user.role}
              InputLabelProps={{ shrink: true }}
              disabled={!editMode}
              fullWidth
              onChange={handleUserDataChange}
            />

            {user.type === 'doctor' && (
              <>
                <TextField
                  variant="outlined"
                  name="collegiateNumber"
                  label={<FormattedMessage id="doctor.collegiate_number" />}
                  className={style.input}
                  value={user.collegiateNumber}
                  InputLabelProps={{ shrink: true }}
                  disabled={!editMode}
                  fullWidth
                  onChange={handleUserDataChange}
                />
                <TextField
                  variant="outlined"
                  name="skills"
                  label={<FormattedMessage id="doctor.skills" />}
                  className={style.input}
                  value={user.skills}
                  InputLabelProps={{ shrink: true }}
                  disabled={!editMode}
                  fullWidth
                  onChange={handleUserDataChange}
                />
              </>
            )}
          </>
        );
      }

      default:
        return null;
    }
  };

  return currentUserData ? (
    <Container component="main">
      <Box className={style.title} display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="h4" variant="h4" className={style.titleName}>
          <FormattedMessage id="profile" description="Profile" />
        </Typography>

        {canCrudSpecialist && (
          <Button disabled={editIsLoading} className={style.button} onClick={onEditPress} variant="contained" color="primary">
            {editMode ? <Save /> : <Edit />}
          </Button>
        )}
      </Box>
      {/* <Divider /> */}

      <Box className={style.paper}>
        <TextField
          variant="outlined"
          name="type"
          label={<FormattedMessage id="user.role" />}
          className={style.input}
          value={currentUserData.type ? intl(`position.${currentUserData.type}`) : '---'}
          InputLabelProps={{ shrink: true }}
          disabled
          fullWidth
          onChange={handleUserDataChange}
        />
        <TextField
          variant="outlined"
          name="firstName"
          label={<FormattedMessage id="user.first_name" />}
          className={style.input}
          value={currentUserData.firstName}
          InputLabelProps={{ shrink: true }}
          disabled={!editMode}
          fullWidth
          onChange={handleUserDataChange}
        />
        <TextField
          variant="outlined"
          name="lastName"
          label={<FormattedMessage id="user.last_name" />}
          className={style.input}
          value={currentUserData.lastName}
          InputLabelProps={{ shrink: true }}
          disabled={!editMode}
          fullWidth
          onChange={handleUserDataChange}
        />
        <TextField
          variant="outlined"
          name="email"
          label={<FormattedMessage id="user.email_adress" />}
          className={style.input}
          value={currentUserData.email}
          InputLabelProps={{ shrink: true }}
          disabled
          fullWidth
        />
        <TextField
          variant="outlined"
          name="phone"
          label={<FormattedMessage id="user.phone" />}
          className={style.input}
          value={currentUserData.phone}
          InputLabelProps={{ shrink: true }}
          disabled={!editMode}
          fullWidth
          onChange={handleUserDataChange}
        />
        {/* <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <FormattedMessage id="user.role" description="User Role" />
              </TableCell>
              <TableCell>{intl(`position.${currentUserData.type}`)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="user.first_name" description="First Name" />
              </TableCell>
              <TableCellEditContainer edit={editMode} onEdit={handleUserDataChange} value={currentUserData.firstName} name="firstName" />
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="user.last_name" description="Last Name" />
              </TableCell>

              <TableCellEditContainer edit={editMode} onEdit={handleUserDataChange} value={currentUserData.lastName} name="lastName" />
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="user.email_adress" description="Email" />
              </TableCell>
              <TableCell>{currentUserData.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="user.phone" description="Phone" />
              </TableCell>

              <TableCellEditContainer edit={editMode} onEdit={handleUserDataChange} value={currentUserData.phone} name="phone" />
            </TableRow> */}
        {renderAdditionPositionFields(currentUserData)}
        {/* </TableBody>
        </Table> */}
      </Box>
    </Container>
  ) : (
    <></>
  );
};

export default MedicalProfessionalProfile;
