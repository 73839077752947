import React, { useState } from 'react';
import { Typography, Button, Box, TableBody, Table } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';

import { useTranslations } from 'utils/useTranslations';

import { AssignCatalogItemModal } from 'components/AssignCatalogItem';

import usePermissions from 'utils/usePermissions';
import { roundTo2 } from 'utils/roundTo2';
import { ChangePriceModal } from 'components/Modals/ChangePriceModal';
import ResolveRequest from 'components/Modals/ResolveRequest';
import { showErrorToast } from 'utils/toast';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import MedicalRecordSKUTableHead from './MedicalRecordSKUTableHead';
import MedicalRecordSKUTableRow from './MedicalRecordSKUTableRow';
import { useStyles } from './styles';

const CSV_MIME = ['text/csv', 'application/vnd.ms-excel']

const MedicalRecordSKUTable = ({ catalog, isSignedAndEditable = true }: { catalog: any; isSignedAndEditable?: boolean }) => {
  const intl = useTranslations();
  const styles = useStyles();
  const { patient, recordId } = useParams<{ patient: string; recordId: string }>();
  const [modalOpened, setModalOpened] = useState(false);
  const [changePriceModalOpened, setChangePriceModalOpened] = useState(false);
  const [selectedSkuItem, setSelectedSkuItem] = useState<any>({});
  const [csvUploading, setCsvUploading] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);
  const { canAddSkuInBills, canAddSkuInMedicalRecord, canCheckmarkExamSkuInBills, canCheckmarkSkuInBills, canCheckoutAddAndViewPaymentsInBills } =
    usePermissions();
  const { uploadCsvItems } = useMedicalRecordFetch();
  const location = useLocation();

  const [resolveModalInfo, setResolveModalInfo] = useState({
    open: false,
    catalogItemId: null,
    medicalRecordId: null,
  });

  const canBeResolved = location.pathname.includes('accounts');

  const toggleModal = () => setModalOpened((old) => !old);
  const handleModalOpen = (subcategory) => () => {
    setSelectedSubcategory(subcategory);
    toggleModal();
  };

  const toggleChangePriceModal = () => setChangePriceModalOpened((old) => !old);
  const handleChangePriceModalOpen = (skuItem) => () => {
    setSelectedSkuItem(skuItem);
    toggleChangePriceModal();
  };

  const exams = catalog?.filter((cItem: any) => cItem.subcategory === 'exam') || [];
  const supplies = catalog?.filter((cItem: any) => cItem.subcategory === 'supply') || [];
  const services = catalog?.filter((cItem: any) => cItem.subcategory === 'service') || [];
  const medicaments = catalog?.filter((cItem: any) => cItem.subcategory === 'medicine') || [];

  const calculateTotal = (arr: any[] = []) => roundTo2(arr.reduce((sum, sItem) => sum + sItem.precio * sItem.amount, 0));

  const handleResolve = ({ catalogItemId, medicalRecordId }: { catalogItemId: string; medicalRecordId: string }) => {
    setResolveModalInfo({ open: true, catalogItemId, medicalRecordId });
  };
  const handleResolveClose = () => {
    setResolveModalInfo({ open: false, catalogItemId: null, medicalRecordId: null });
  };

  const canAddSku = canBeResolved ? canAddSkuInBills : canAddSkuInMedicalRecord(patient);

  const onCsvUpload = (e) => {
    const file = e.target.files[0];

    if (!CSV_MIME.includes(file.type)) {
      showErrorToast(intl('assigned_catalog.incorrectFormat'));
      return;
    }

    setCsvUploading(true);
    uploadCsvItems({
      recordId,
      file,
    }).finally(() => setCsvUploading(false));
  };
  return (
    <>
      <Button className={styles.uploadCsvButton} disabled={csvUploading} variant="contained" color="primary" component="label">
        {intl('assigned_catalog.uploadCsv')}
        <input accept="text/csv" type="file" onChange={onCsvUpload} hidden />
      </Button>
      {/* Services */}
      <Box className={styles.sectionHeader}>
        <Typography className={styles.sectionHeaderBold} variant="h5">
          {intl('services')}
        </Typography>
        {canAddSku && (
          <Button disabled={!isSignedAndEditable} onClick={handleModalOpen('service')} variant="contained" color="primary">
            {intl('services.add_new_item')}
          </Button>
        )}
      </Box>
      <Table>
        <MedicalRecordSKUTableHead canBeResolved={canBeResolved && canCheckmarkSkuInBills} />
        <TableBody>
          {services &&
            services.map((serviceItem) => (
              <MedicalRecordSKUTableRow
                onChangePrice={handleChangePriceModalOpen(serviceItem)}
                canBeResolved={canBeResolved && canCheckmarkSkuInBills}
                row={serviceItem}
                key={serviceItem._id}
                isSignedAndEditable={isSignedAndEditable}
              />
            ))}
        </TableBody>
      </Table>
      {canBeResolved && services.length > 0 && canCheckoutAddAndViewPaymentsInBills && (
        <Box mt={2}>
          <Typography variant="h6">
            {intl('shared.subtotal')}: {calculateTotal(services)}
          </Typography>
        </Box>
      )}
      {/* Exams */}
      <Box className={styles.sectionHeader}>
        <Typography className={styles.sectionHeaderBold} variant="h5">
          {intl('exams')}
        </Typography>
        {canAddSku && (
          <Button disabled={!isSignedAndEditable} onClick={handleModalOpen('exam')} variant="contained" color="primary">
            {intl('exam.add_new_exam')}
          </Button>
        )}
      </Box>
      <Table>
        <MedicalRecordSKUTableHead canBeResolved={canBeResolved && (canCheckmarkExamSkuInBills || canCheckmarkSkuInBills)} />
        <TableBody>
          {exams &&
            exams.map((examItem) => (
              <MedicalRecordSKUTableRow
                canBeResolved={canBeResolved && (canCheckmarkExamSkuInBills || canCheckmarkSkuInBills)}
                row={examItem}
                key={examItem._id}
                isSignedAndEditable={isSignedAndEditable}
                onChangePrice={handleChangePriceModalOpen(examItem)}
                onResolveRequest={handleResolve}
              />
            ))}
        </TableBody>
      </Table>
      {canBeResolved && exams.length > 0 && canCheckoutAddAndViewPaymentsInBills && (
        <Box mt={2}>
          <Typography variant="h6">
            {intl('shared.subtotal')}: {calculateTotal(exams)}
          </Typography>
        </Box>
      )}
      {/* Medicaments */}
      <Box className={styles.sectionHeader}>
        <Typography className={styles.sectionHeaderBold} variant="h5">
          {intl('medicaments')}
        </Typography>
        {canAddSku && (
          <Button disabled={!isSignedAndEditable} onClick={handleModalOpen('medicine')} variant="contained" color="primary">
            {intl('medicament.add_new_item')}
          </Button>
        )}
      </Box>
      <Table>
        <MedicalRecordSKUTableHead canBeResolved={canBeResolved && canCheckmarkSkuInBills} />
        <TableBody>
          {medicaments &&
            medicaments.map((medicamentItem) => (
              <MedicalRecordSKUTableRow
                canBeResolved={canBeResolved && canCheckmarkSkuInBills}
                row={medicamentItem}
                onChangePrice={handleChangePriceModalOpen(medicamentItem)}
                key={medicamentItem._id}
                isSignedAndEditable={isSignedAndEditable}
              />
            ))}
        </TableBody>
      </Table>
      {canBeResolved && medicaments.length > 0 && canCheckoutAddAndViewPaymentsInBills && (
        <Box mt={2}>
          <Typography variant="h6">
            {intl('shared.subtotal')}: {calculateTotal(medicaments)}
          </Typography>
        </Box>
      )}
      {/* Supplies */}
      <Box className={styles.sectionHeader}>
        <Typography className={styles.sectionHeaderBold} variant="h5">
          {intl('supplies')}
        </Typography>
        {canAddSku && (
          <Button disabled={!isSignedAndEditable} onClick={handleModalOpen('supply')} variant="contained" color="primary">
            {intl('supply.add_new_item')}
          </Button>
        )}
      </Box>
      <Table>
        <MedicalRecordSKUTableHead canBeResolved={canBeResolved && canCheckmarkSkuInBills} />
        <TableBody>
          {supplies &&
            supplies.map((supplyItem) => (
              <MedicalRecordSKUTableRow
                canBeResolved={canBeResolved && canCheckmarkSkuInBills}
                onChangePrice={handleChangePriceModalOpen(supplyItem)}
                row={supplyItem}
                key={supplyItem._id}
                isSignedAndEditable={isSignedAndEditable}
              />
            ))}
        </TableBody>
      </Table>
      {canBeResolved && canCheckoutAddAndViewPaymentsInBills && supplies.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6">
            {intl('shared.subtotal')}: {calculateTotal(supplies)}
          </Typography>
        </Box>
      )}

      <AssignCatalogItemModal toggle={toggleModal} open={modalOpened} selectedSubcategory={selectedSubcategory} />
      <ChangePriceModal onClose={toggleChangePriceModal} open={changePriceModalOpened} skuItem={selectedSkuItem} />
      <ResolveRequest
        onClose={handleResolveClose}
        open={resolveModalInfo.open}
        catalogItemId={resolveModalInfo.catalogItemId}
        medicalRecordId={resolveModalInfo.medicalRecordId}
      />
    </>
  );
};

export default MedicalRecordSKUTable;
