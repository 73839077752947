import React from 'react';
import { ListItem, Box, Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import usePermissions from 'utils/usePermissions';
import { roundTo2 } from '../../../utils/roundTo2';

export default function ServiceItem({ item }) {
  const { canViewPrices } = usePermissions();
  return (
    <Box>
      <Divider />
      <ListItem>
        <FormattedMessage id="inventory.sku_description" description="Sku" />: {item.codigo}
      </ListItem>
      <ListItem>
        <FormattedMessage id="inventory.name" description="Name" />: {item.descripcion || item.title}
      </ListItem>
      <ListItem>
        <FormattedMessage id="inventory.type" description="Type" />: {item.subcategory}
      </ListItem>
      {canViewPrices && (
        <>
          <ListItem>
            <FormattedMessage id="inventory.price" description="Price" />: {roundTo2(item.precio)}
          </ListItem>
          <ListItem>
            <FormattedMessage id="inventory.quantity" description="Quantity" />: {item.amount}
          </ListItem>
          <ListItem>
            <FormattedMessage id="inventory.total_cost" description="Total cost" />: {roundTo2(roundTo2(item.precio) * item.amount)}
          </ListItem>
        </>
      )}
      <ListItem>
        <FormattedMessage id="position.patient" description="Patient" />: {item.patient && item.patient.firstName}{' '}
        {item.patient && item.patient.lastName}
      </ListItem>
      <ListItem>
        <FormattedMessage id="record.date" description="Date" />: {format(new Date(item.createdAt), 'dd.MM.yyyy')}
      </ListItem>
      <ListItem>
        <FormattedMessage id="inventory.status" description="Status" />:{' '}
        {item.paid ? <FormattedMessage id="service.status.paid" /> : <FormattedMessage id="service.status.unpaid" />}
      </ListItem>
    </Box>
  );
}
