import axiosClient from 'api';
import { AxiosResponse } from 'axios';

interface IChartsRequest {
  dateFrom?: Date | string;
  dateTo?: Date | string;
  codigo?: Array<{ codigo: number; categoria: number }>;
  patients?: Array<string>;
}

export class ChartsApi {
  static getCharts(data?: IChartsRequest) {
    return axiosClient.post<IChartsRequest, AxiosResponse<any>>('assigned_catalog/charts', data);
  }

  static getPatients() {
    return axiosClient.get<any, AxiosResponse<any>>('user/patients/light');
  }

  static getCatalog() {
    return axiosClient.get<any, AxiosResponse<any>>('catalog/light', { params: { limit: Number.MAX_SAFE_INTEGER, skip: 0 } });
  }
}
