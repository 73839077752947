import React, { useCallback } from 'react';
import { Drawer as MaterialDrawer, MenuItem, Box } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DrawerProps } from './types';
import { useStyles } from './styles';

const Drawer = ({ links, userType, drawerOpened, onClose, logOut, ...props }: DrawerProps) => {
  const styles = useStyles();
  const intl = useIntl();

  const handleLogOut = useCallback(() => {
    logOut();
    onClose();
  }, [logOut, onClose]);

  return (
    <MaterialDrawer open={drawerOpened} anchor="right" onClose={onClose} {...props}>
      <Box width="250px" height="100vh" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          {links.map(
            (link) =>
              link.permissions.includes(userType) && (
                <Link key={link.link} className={styles.link} to={link.link}>
                  <MenuItem onClick={onClose} className={styles.menuItem}>
                    {intl.formatMessage({ id: link.name })}
                  </MenuItem>
                </Link>
              ),
          )}
        </Box>
        <Box>
          <MenuItem onClick={handleLogOut} className={styles.menuItem}>
            <FormattedMessage id="navbar.logout" />
          </MenuItem>
        </Box>
      </Box>
    </MaterialDrawer>
  );
};

export default Drawer;
