import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { useTranslations } from 'utils/useTranslations';
import usePermissions from 'utils/usePermissions';
import { convertNumber } from 'utils/formatters';
import { roundTo2 } from '../../../utils/roundTo2';

export default function ServiceItem({ item }) {
  const intl = useTranslations();
  const { canViewPrices } = usePermissions();
  const price = convertNumber(item.precio);
  const totalPrice = convertNumber(roundTo2(roundTo2(item.precio) * item.amount));

  return (
    <TableRow key={item._id}>
      <TableCell>{item.codigo}</TableCell>
      <TableCell>{item.descripcion || item.title}</TableCell>
      <TableCell>{intl(`subcategories.${item.subcategory}`)}</TableCell>
      {canViewPrices && (
        <>
          <TableCell>{price}</TableCell>
          <TableCell>{item.amount}</TableCell>
          <TableCell>{totalPrice}</TableCell>
        </>
      )}
      <TableCell>
        {item.patient && item.patient.firstName} {item.patient && item.patient.lastName}
      </TableCell>
      <TableCell>{format(new Date(item.createdAt), 'dd.MM.yyyy')}</TableCell>
      <TableCell>{item.paid ? <FormattedMessage id="service.status.paid" /> : <FormattedMessage id="service.status.unpaid" />}</TableCell>
    </TableRow>
  );
}
