import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslations } from 'utils/useTranslations';

import { vitalSigns, laboratoryExams, radiologicalExams, diagnosis, clinicalTreatment, surgicalTreatment } from 'pages/User/MedicalRecord/constants';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import { useParams } from 'react-router-dom';

export const allFields = [...vitalSigns, ...laboratoryExams, ...radiologicalExams, ...diagnosis, ...clinicalTreatment, ...surgicalTreatment];

interface Props {
  open: boolean;
  onClose: () => void;
  fields: any[];
}

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 200,
  },
}));

export const RestoreMedicalRecord = ({ open, onClose, fields = [] }: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { toggleField } = useMedicalRecordFetch();
  const { recordId } = useParams<{ recordId: string }>();

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: { field: '' },
    validationSchema: Yup.object({
      field: Yup.string().required(),
    }),
    async onSubmit(formValues, formContext) {
      toggleField(recordId, formValues.field, true);
      formContext.resetForm();
      setLoading(false);
      onClose();
    },
  });
  const intl = useTranslations();

  return (
    <Dialog open={open} onClose={loading ? () => null : onClose}>
      <DialogTitle>{intl('medical_record.restore_fields')}</DialogTitle>
      <DialogContent>
        <TextField
          select
          fullWidth
          className={classes.input}
          name="field"
          label={intl('medical_record.field')}
          value={values.field}
          error={Boolean(touched.field && errors.field)}
          onChange={handleChange}
        >
          {allFields
            .filter((field) => fields.find((f) => f.name === field.name && !f.enabled))
            .map((field) => (
              <MenuItem key={field.name} value={field.name}>
                {intl(field.translationKey)}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="secondary">
          {intl('shared.cancel')}
        </Button>
        <Button disabled={loading} color="primary" onClick={() => handleSubmit()}>
          {intl('shared.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
