import React from 'react';
import { MenuItem, FormControl, Box, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export default function MaterialSelect({
  title,
  name,
  onChange,
  value,
  error = false,
  disabled = false,
  options = [],
  style = {},
  className = '',
  variant = 'outlined',
  withoutNone = false,
}) {
  return (
    <FormControl style={style} className={className} variant={variant} fullWidth>
      <TextField label={title} select disabled={disabled} name={name} variant={variant} onChange={onChange} value={value} error={error}>
        {!withoutNone && (
          <MenuItem fontStyle="italic" value="">
            <Box fontStyle="italic">
              <FormattedMessage id="position.none" description="None" />
            </Box>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}
