import Axios, { AxiosResponse } from 'axios';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import axiosClient from 'api';

interface ICreateCase {
  budget?: string;
  name: string;
}

interface IUseCaseFetch {
  create: (patientId: string, caseData: ICreateCase) => Promise<AxiosResponse<any>>;
  getCases: (patientId: string) => Promise<AxiosResponse<any>>;
  getCase: (caseId: string, patientId: string) => Promise<AxiosResponse<any>>;
  deleteCase: (caseId: string, patientId: string) => Promise<AxiosResponse<any>>;
  addPayment: (data: { caseId: string; amount: number; paymentNumber: string }) => Promise<AxiosResponse<any>>;
  updatePayment: (data: { caseId: string; paymentId: string; amount: number; paymentNumber: string }) => Promise<AxiosResponse<any>>;
  checkOut: (data: { caseId: string }) => Promise<AxiosResponse<any>>;
  updateCaseInvoice: (data: { caseId: string; invoiceNumber: string }) => Promise<AxiosResponse<any>>;
  updateCaseDocument: (data: { caseId: string; documentStatus: boolean }) => Promise<AxiosResponse<any>>;
}

export class CaseApi {
  static addCovid({ caseId, covidInfo }) {
    return axiosClient.post(`case/${caseId}/covid`, covidInfo);
  }

  static removeCovid({ caseId, covidId }) {
    return axiosClient.delete(`case/${caseId}/covid/${covidId}`);
  }

  static getCovid({ caseId, covidId }) {
    return axiosClient.get(`case/${caseId}/covid/${covidId}`);
  }
}

const useCaseFetch = (): IUseCaseFetch => {
  const baseUrl = process.env.REACT_APP_HOST;
  const token = useStoreState((state) => state.token);
  const { push } = useHistory();
  const addNewCases: any = useStoreActions((actions) => actions.addNewCases);
  const deleteCaseFromStore: any = useStoreActions((actions) => actions.deleteCase);
  const addMedicalRecords: any = useStoreActions((actions) => actions.addMedicalRecords);
  const addNewPayment: any = useStoreActions((actions) => actions.addNewPayment);
  const updatePaymentStore: any = useStoreActions((actions) => actions.updatePayment);

  const updateCaseItem: any = useStoreActions((actions) => actions.updateCaseItem);

  const create = async (patientId: string, caseData: ICreateCase) => {
    const body = {
      patientId,
      caseData,
    };
    const newCase = await Axios.post(`${baseUrl}/case`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    addNewCases({ cases: [newCase.data] });
    return newCase;
  };

  const getCases = async (patientId: string) => {
    const patientCases = await Axios.get(`${baseUrl}/case?patientId=${patientId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    addNewCases({ cases: patientCases.data.items });
    return patientCases;
  };

  const getCase = async (caseId: string, patientId: string) => {
    const caseItem = await Axios.get(`${baseUrl}/case/${caseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { medicalRecords, ...caseData } = caseItem.data;
    addNewCases({ cases: [caseData] });
    addMedicalRecords({ medicalRecords });
    console.log(caseItem);
    return caseItem;
  };

  const deleteCase = async (caseId: string, patientId: string) => {
    const caseItem = await Axios.delete(`${baseUrl}/case/${caseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    push(`/user/patient_records/${patientId}/cases`);
    deleteCaseFromStore({ caseId });
    return caseItem;
  };

  const addPayment = async ({
    caseId,
    amount,
    paymentNumber,
    trsType,
  }: {
    caseId: string;
    amount: number;
    paymentNumber: string;
    trsType: string;
  }) => {
    const newPayment = await Axios.post(
      `${baseUrl}/case/${caseId}/add_payment`,
      { amount, paymentNumber, trsType },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    addNewPayment({ caseId, payment: newPayment.data });
    return newPayment;
  };

  const updatePayment = async ({
    caseId,
    paymentId,
    amount,
    paymentNumber,
    trsType,
  }: {
    caseId: string;
    paymentId: string;
    amount: number;
    paymentNumber: string;
    trsType: string;
  }) => {
    const newPayment = await Axios.post(
      `${baseUrl}/case/update_payment`,
      { _id: paymentId, amount, paymentNumber, trsType },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    updatePaymentStore({ caseId, paymentId, payment: newPayment.data });
    console.log(newPayment);
    return newPayment;
  };

  const checkOut = async ({ caseId }: { caseId: string }) => {
    const caseItem = await Axios.post(`${baseUrl}/case/${caseId}/checkout`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    updateCaseItem({ caseId, caseItem: caseItem.data });
    return caseItem;
  };

  const updateCaseInvoice = async ({ caseId, invoiceNumber }: { caseId: string; invoiceNumber: string }) => {
    const caseItem = await Axios.put(
      `${baseUrl}/case`,
      {
        caseData: {
          invoiceNumber,
        },
        caseId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    console.log(caseItem);

    updateCaseItem({ caseId, caseItem: caseItem.data });
    return caseItem;
  };

  const updateCaseDocument = async ({ caseId, documentStatus }: { caseId: string; documentStatus: boolean }) => {
    const caseItem = await Axios.put(
      `${baseUrl}/case`,
      {
        caseData: {
          documentStatus,
        },
        caseId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    updateCaseItem({ caseId, caseItem: caseItem.data });
    return caseItem;
  };

  return { create, getCases, getCase, deleteCase, addPayment, checkOut, updatePayment, updateCaseInvoice, updateCaseDocument };
};

export default useCaseFetch;
