import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Box, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { useTranslations } from 'utils/useTranslations';
import userFetch from '../../../api/User';
import omit from '../../../utils/omit';

import useStyles from './style';
import MaterialSelect from '../../../components/Select';

const Profile = () => {
  const style = useStyles();
  const currentUserFromStore = useStoreState((state) => state.user);
  const intl = useTranslations();
  const users = useStoreState((state) => state.users);
  const setUsersData = useStoreActions((actions) => actions.setUsersData);
  const setUserData = useStoreActions((actions) => actions.setUserData);
  const [currentUserData, setCurrentUserData] = useState(currentUserFromStore);
  const [editMode, setEditMode] = useState(false);
  const [editIsLoading, setEditIsLoading] = useState(false);
  useEffect(() => {
    setCurrentUserData(currentUserFromStore);
  }, [currentUserFromStore]);

  const handleUserDataChange = (e) => {
    setCurrentUserData({
      ...currentUserData,
      [e.target.name]: e.target.value,
    });
  };

  const requiredFields = {
    doctor: ['firstName', 'lastName', 'phone', 'title', 'role', 'collegiateNumber'],
    admin: ['firstName', 'lastName', 'phone'],
    staff: ['firstName', 'lastName', 'phone'],
    nurse: ['firstName', 'lastName', 'phone', 'role', 'title'],
    technician_labs: ['firstName', 'lastName', 'phone', 'role', 'title'],
    technician_radiology: ['firstName', 'lastName', 'phone', 'role', 'title'],
    patient: ['firstName', 'lastName', 'phone', 'paymentType'],
    pharmacist: ['firstName', 'lastName', 'phone', 'role', 'title'],
  };

  const userTitleOptions = [
    {
      value: 'lic',
      name: <FormattedMessage id="doctor.title.lic" description="Lic." />,
    },
    {
      value: 'ing',
      name: <FormattedMessage id="doctor.title.ing" description="Ing." />,
    },
    {
      value: 'ph_d',
      name: <FormattedMessage id="doctor.title.ph_d" description="Ph.D." />,
    },
    {
      value: 'dr',
      name: <FormattedMessage id="doctor.title.dr" description="Dr." />,
    },
    {
      value: 'mtro',
      name: <FormattedMessage id="doctor.title.mtro" description="Mtro." />,
    },
    {
      value: 'mtr',
      name: <FormattedMessage id="doctor.title.mtr" description="Mtr." />,
    },
    {
      value: 'sr_sra',
      name: <FormattedMessage id="doctor.title.sr_sra" description="Sr. / Sra." />,
    },
  ];
  const onEditPress = async () => {
    if (editMode) {
      try {
        setEditIsLoading(true);
        const currentUserDataOmmited = omit(currentUserData, requiredFields[currentUserData.type]);
        const accessToken = localStorage.getItem('accessToken');
        await userFetch.editUserProfile(accessToken, currentUserDataOmmited);
        const updatedUserList = [...users];
        const index = updatedUserList.findIndex((user) => user._id === currentUserData._id);
        updatedUserList[index] = currentUserData;
        setUsersData(updatedUserList);
        setUserData(currentUserData);
        setEditMode(false);
        setEditIsLoading(false);
      } catch (err) {
        setEditMode(true);
        setEditIsLoading(false);
      }
    } else {
      setEditMode(true);
    }
  };

  const renderAdditionPositionFields = (user) => {
    switch (user.type) {
      case 'doctor':
      case 'nurse':
      case 'technician_labs':
      case 'pharmacist':
      case 'technician_radiology': {
        return (
          <>
            <MaterialSelect
              disabled={!editMode}
              options={userTitleOptions}
              value={user.title}
              className={style.input}
              name="title"
              onChange={handleUserDataChange}
              title={<FormattedMessage id="doctor.title" description="Title" />}
            />

            <TextField
              variant="outlined"
              name="role"
              label={<FormattedMessage id="doctor.role" description="Role" />}
              className={style.input}
              value={currentUserData.role}
              onChange={handleUserDataChange}
              disabled={!editMode}
            />

            {user.type === 'doctor' && (
              <TextField
                variant="outlined"
                className={style.input}
                name="collegiateNumber"
                label={<FormattedMessage id="doctor.collegiate_number" description="Collegiate Number" />}
                value={currentUserData.collegiateNumber}
                onChange={handleUserDataChange}
                disabled={!editMode}
              />
            )}
          </>
        );
      }
      // We do not allow patients to log in anymore, so this needs to be removed
      case 'patient': {
        return (
          <>
            <TextField
              multiline
              disabled
              label={<FormattedMessage id="user.doctors" description="Doctors" />}
              className={style.input}
              value={user.doctors.map((doctor) => `${doctor.name} ${doctor.lastName}`).join('\n')}
            />

            <MaterialSelect
              disabled={!editMode}
              options={[
                { value: 'self_pay', name: 'Self pay' },
                { value: 'social_security', name: 'Social security' },
              ]}
              className={style.input}
              value={user.paymentType}
              name="paymentType"
              onChange={handleUserDataChange}
              title={intl('paymentTypes.paymentType')}
            />
          </>
        );
      }

      default:
        return null;
    }
  };

  return (
    <Container component="main">
      <Box className={style.title} display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="h4" variant="h4" className={style.titleName}>
          <FormattedMessage id="profile" description="Profile" />
        </Typography>
        <Box display="flex" flexDirection="column">
          <Button disabled={editIsLoading} className={style.button} onClick={onEditPress} variant="contained" color="primary">
            {editMode ? (
              <>
                <FormattedMessage id="calendar.save" description="Save" /> <FormattedMessage id="user.profile" description="Profile" />
              </>
            ) : (
              <FormattedMessage id="profile.edit_profile" description="Edit profile" />
            )}
          </Button>
        </Box>
      </Box>

      <Box className={style.paper}>
        <TextField
          variant="outlined"
          label={<FormattedMessage id="user.role" description="User Role" />}
          value={intl(`position.${currentUserData.type}`)}
          className={style.input}
          disabled
        />

        <TextField
          variant="outlined"
          name="firstName"
          label={<FormattedMessage id="user.first_name" description="First Name" />}
          value={currentUserData.firstName}
          onChange={handleUserDataChange}
          className={style.input}
          disabled={!editMode}
        />

        <TextField
          variant="outlined"
          name="lastName"
          label={<FormattedMessage id="user.last_name" description="Last Name" />}
          value={currentUserData.lastName}
          onChange={handleUserDataChange}
          className={style.input}
          disabled={!editMode}
        />

        <TextField
          variant="outlined"
          name="email"
          label={<FormattedMessage id="user.email_adress" description="Email" />}
          value={currentUserData.email}
          className={style.input}
          disabled
        />

        <TextField
          variant="outlined"
          name="phone"
          label={<FormattedMessage id="user.phone" description="Phone" />}
          value={currentUserData.phone}
          onChange={handleUserDataChange}
          disabled={!editMode}
          className={style.input}
        />

        {renderAdditionPositionFields(currentUserData)}
      </Box>
    </Container>
  );
};

export default Profile;
