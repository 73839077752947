import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  Input,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import { useTranslations } from 'utils/useTranslations';

export const FormController = ({ control, name, label, value, hasOwnLabel, onChange, options, error, touched }) => {
  const intl = useTranslations();

  if (control === 'input') {
    const LabelType = hasOwnLabel ? FormLabel : InputLabel;
    return (
      <FormControl fullWidth error={touched && error} id={name} component="fieldset">
        <LabelType>{intl(label)}</LabelType>
        <Input name={name} value={value} onChange={onChange} />
      </FormControl>
    );
  }

  if (control === 'radio') {
    return (
      <FormControl error={touched && error} id={name} component="fieldset">
        <FormLabel component="legend">{intl(label)}</FormLabel>
        <RadioGroup aria-label={name} name={name} value={value} onChange={onChange}>
          {options.map(({ label: optionLabel, value: optionValue }) => (
            <FormControlLabel key={optionLabel} value={optionValue} control={<Radio />} label={intl(optionLabel)} />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  if (control === 'select') {
    const LabelType = hasOwnLabel ? FormLabel : InputLabel;
    return (
      <FormControl error={touched && error} id={name} fullWidth>
        <LabelType>{intl(label)}</LabelType>
        <Select value={value} name={name} onChange={onChange}>
          {options.map(({ label: optionLabel, value: optionValue }) => (
            <MenuItem key={optionLabel} value={optionValue}>
              {intl(optionLabel)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (control === 'checkbox') {
    const onCheckboxChange = (e) =>
      onChange({
        target: { name, value: value.includes(e.target.name) ? value.filter((v) => v !== e.target.name) : [...(value || []), e.target.name] },
      });

    return (
      <FormControl error={touched && error} id={name} component="fieldset">
        <FormLabel component="legend">{intl(label)}</FormLabel>
        <FormGroup>
          {options.map(({ label: optionLabel, value: optionValue }) => (
            <FormControlLabel
              key={optionLabel}
              name={optionValue}
              control={<Checkbox checked={value?.includes(optionValue)} onChange={onCheckboxChange} name={optionValue} />}
              label={intl(optionLabel)}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }

  if (control === 'date') {
    const onDateChange = (date) => onChange({ target: { value: date, name } });
    return (
      <KeyboardDatePicker
        error={touched && error}
        id={name}
        fullWidth
        margin="normal"
        format="dd.MM.yyyy"
        value={value || null}
        onChange={onDateChange}
        inputVariant="outlined"
        name={name}
        label={intl(label)}
      />
    );
  }

  return <TextField />;
};
