import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslations } from 'utils/useTranslations';
import { useParams } from 'react-router-dom';
import useCaseFetch from 'api/Case';
import * as yup from 'yup';
import { floatWithTwoDigits } from 'utils/floatWithTwoDigits';
import { Payment } from 'pages/User/PatientCases/types';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues?: INewCaseForm;
  paymentId?: string;
}
interface ParamType {
  caseId: string;
}

export interface INewPaymentModal {
  open: boolean;
  payment: Payment | null;
}

interface INewCaseForm {
  amount: number | null;
  paymentNumber: string;
  trsType: string;
}

const initValues: INewCaseForm = {
  amount: null,
  paymentNumber: '',
  trsType: '',
};

const useStyles = makeStyles({
  textField: {
    marginBottom: '1rem',
  },
});

export const AddPaymentModal = ({ open, onClose, initialValues, paymentId }: Props) => {
  const classes = useStyles();
  const { addPayment, updatePayment } = useCaseFetch();
  const { caseId } = useParams<ParamType>();
  const [loading, setLoading] = useState(false);
  const { values, handleSubmit, handleChange, touched, errors } = useFormik({
    initialValues: initialValues || initValues,
    async onSubmit(formValues, formContext) {
      try {
        setLoading(true);
        if (paymentId) {
          updatePayment({ paymentId, caseId, ...formValues });
        } else {
          await addPayment({ ...formValues, caseId });
        }
        formContext.resetForm();
        onClose();
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      amount: yup
        .number()
        // eslint-disable-next-line
        .test('float with 2 points', 'Amount must be in propriate format', function () {
          // eslint-disable-next-line
          return floatWithTwoDigits(this.parent.amount);
        })
        .required(),
      paymentNumber: yup.string(),
      trsType: yup.string(),
    }),
  });
  const intl = useTranslations();

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{intl('patient.addNewPayment')}</DialogTitle>

        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            className={classes.textField}
            name="amount"
            type="number"
            value={values.amount}
            error={Boolean(touched.amount && errors.amount)}
            onChange={handleChange}
            label={intl('patient.amount')}
          />
          <TextField
            variant="outlined"
            fullWidth
            name="trsType"
            className={classes.textField}
            value={values.trsType}
            error={Boolean(touched.trsType && errors.trsType)}
            onChange={handleChange}
            label={intl('patient.trsType')}
          />
          <TextField
            variant="outlined"
            fullWidth
            name="paymentNumber"
            className={classes.textField}
            value={values.paymentNumber}
            error={Boolean(touched.paymentNumber && errors.paymentNumber)}
            onChange={handleChange}
            label={intl('patient.accountNumber')}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={onClose} color="secondary">
            {intl('shared.cancel')}
          </Button>
          <Button disabled={loading} type="submit" color="primary">
            {intl('shared.add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
