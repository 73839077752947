import React, { useCallback, useMemo, useState } from 'react';
import { TableRow, TableCell, Button, Divider, List, ListItem } from '@material-ui/core';

import { Delete, Edit, MoreVert } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import usePermissions from 'utils/usePermissions';
import { convertNumber } from 'utils/formatters';

export function CatalogueItem({ catalogue, onDelete, onEdit, onMore, vatPerCent }) {
  const { canCrudCatalogAndVat } = usePermissions();
  const [catalogIsDeleting, setCatalogIsDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      setCatalogIsDeleting(true);
      await onDelete();
      setCatalogIsDeleting(false);
    } catch (err) {
      setCatalogIsDeleting(false);
    }
  };

  const price = useMemo(() => convertNumber(Math.round(catalogue.precio * (1 + vatPerCent / 100) * 100) / 100), [catalogue, vatPerCent]);

  const handleMoreClick = useCallback(() => {
    onMore(catalogue);
  }, [onMore, catalogue]);

  return (
    <TableRow>
      <TableCell>{catalogue.codigo}</TableCell>
      <TableCell>{catalogue.descripcion}</TableCell>
      <TableCell>{catalogue.disponible}</TableCell>
      {canCrudCatalogAndVat && <TableCell>{price}</TableCell>}
      <TableCell align="right">
        {canCrudCatalogAndVat && (
          <>
            <Button disabled={catalogIsDeleting}>
              <Delete onClick={handleDelete} />
            </Button>
            <Button>
              <Edit onClick={onEdit} />
            </Button>
          </>
        )}
        <Button>
          <MoreVert onClick={handleMoreClick} />
        </Button>
      </TableCell>
    </TableRow>
  );
}

export function MobileCatalogueItem({ catalogue, onDelete, onEdit, onMore, vatPerCent }) {
  const price = useMemo(() => convertNumber(Math.round(catalogue.precio * (1 + vatPerCent / 100) * 100) / 100), [catalogue, vatPerCent]);

  const { canCrudCatalogAndVat } = usePermissions();

  const handleMoreClick = useCallback(() => {
    onMore(catalogue);
  }, [onMore, catalogue]);

  return (
    <List style={{ marginTop: '16px' }}>
      <Divider />
      <ListItem>
        <FormattedMessage id="catalogue.codigo" description="Codigo" />:{catalogue.codigo || '---'}
      </ListItem>
      <ListItem>
        <FormattedMessage id="catalogue.descripcion" description="Descripcion" />: {catalogue.descripcion || '---'}
      </ListItem>
      <ListItem>
        <FormattedMessage id="catalogue.disponible" description="Disponible" />: {catalogue.disponible || '---'}
      </ListItem>

      {canCrudCatalogAndVat && (
        <ListItem>
          <FormattedMessage id="catalogue.precio" description="Precio" />: {price}
        </ListItem>
      )}

      <ListItem align="right">
        {canCrudCatalogAndVat && (
          <>
            <Button>
              <Delete onClick={onDelete} />
            </Button>
            <Button>
              <Edit onClick={onEdit} />
            </Button>
          </>
        )}
        <Button>
          <MoreVert onClick={handleMoreClick} />
        </Button>
      </ListItem>
    </List>
  );
}
