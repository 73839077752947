import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useTranslations } from 'utils/useTranslations';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  text: string;
  loading?: boolean;
  label: Component | string;
}

export const RemoveConfirmModal = ({ open, onSubmit, loading, label, onClose, text }: Props) => {
  const intl = useTranslations();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="secondary">
          {intl('shared.cancel')}
        </Button>
        <Button disabled={loading} color="primary" onClick={onSubmit}>
          {intl('shared.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
