import React, { useCallback, useMemo, useState } from 'react';
import { TableRow, TableCell, IconButton, FormControl, Checkbox, Hidden } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import useMedicalRecordFetch from 'api/MedicalRecordsFetch';
import Delete from '@material-ui/icons/Delete';
import { useStoreState } from 'easy-peasy';
import { UserTypeEnum } from 'types';
import getPermissions from 'utils/getPermissions';
import { roundTo2 } from 'utils/roundTo2';
import usePermissions from 'utils/usePermissions';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useTranslations } from 'utils/useTranslations';
import { MoreVert } from '@material-ui/icons';
import { DetailAssignedInfo } from 'components/DetailAssignedInfo';
import { convertNumber } from 'utils/formatters';
import { useStyles } from './styles';

// eslint-disable-next-line
const MedicalRecordSKUTableRow = ({ row, canBeResolved = false, onChangePrice, onResolveRequest = (data) => {}, isSignedAndEditable }) => {
  const styles = useStyles();
  const intl = useTranslations();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const user = useStoreState((state) => state.user);
  const { patient } = useParams<{ patient: string }>();
  const { updateAssignedCatalogItem, deleteCatalogItem } = useMedicalRecordFetch();
  const { canViewPrices, canCloseRequests, canDeleteSkusInMedicalRecord } = usePermissions();

  const [openModal, setOpenModal] = useState(false);

  const handleStatusChange = useCallback(async () => {
    try {
      setStatusLoading(true);
      await updateAssignedCatalogItem({ id: row._id, isResolved: !row.resolved, medicalRecordId: row.medicalRecordId });
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  }, [row]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);
      await deleteCatalogItem({ catalogItem: row, recordId: row.medicalRecordId });
      setDeleteLoading(false);
    } catch (err) {
      setDeleteLoading(false);
    }
  }, [row]);

  const canUnresolve = getPermissions(user.type, [UserTypeEnum.admin, UserTypeEnum.staff]);

  const canDelete = useMemo(() => canDeleteSkusInMedicalRecord(patient), [canDeleteSkusInMedicalRecord, patient]);

  const canChangePrice = getPermissions(user.type, [UserTypeEnum.admin, UserTypeEnum.staff]);

  const isRequest = row.subcategory === 'exam' && canCloseRequests;

  const { price, totalPrice } = useMemo(
    () => ({
      price: convertNumber(roundTo2(row.precio)),
      totalPrice: convertNumber(roundTo2(row.precio * row.amount)),
    }),
    [row],
  );

  return (
    <>
      <Hidden smDown>
        <TableRow>
          <TableCell className={styles.tableText}>{row.codigo}</TableCell>
          <TableCell className={styles.tableText}>{row.descripcion}</TableCell>
          {canViewPrices && <TableCell className={styles.tableText}>{price}</TableCell>}
          <TableCell className={styles.tableText}>{row.amount}</TableCell>
          {canViewPrices && <TableCell className={styles.tableText}>{totalPrice}</TableCell>}
          <TableCell className={styles.tableText}>{row.compania}</TableCell>
          <TableCell className={styles.tableText}>
            <Link to={`/user/medical_professionals/profile/${canBeResolved ? row.resolvedBy?._id : row.createdBy?._id}`}>
              {canBeResolved ? row.resolvedBy?.firstName : row.createdBy?.firstName}{' '}
              {canBeResolved ? row.resolvedBy?.lastName : row.createdBy?.lastName}
            </Link>
          </TableCell>
          {canBeResolved && <TableCell className={styles.tableText}>{row.resolvedAt}</TableCell>}
          {isRequest ? (
            <TableCell className={styles.tableText}>
              <FormControl
                disabled={row.resolved || statusLoading}
                checked={row.resolved}
                component={Checkbox}
                onClick={() => onResolveRequest({ catalogItemId: row._id, medicalRecordId: row.medicalRecordId })}
              />
            </TableCell>
          ) : (
            canBeResolved && (
              <TableCell className={styles.tableText}>
                <FormControl
                  disabled={(!canUnresolve && row.resolved) || statusLoading}
                  checked={row.resolved}
                  component={Checkbox}
                  onClick={handleStatusChange}
                />
              </TableCell>
            )
          )}

          <TableCell>
            {canDelete && (
              <IconButton disabled={deleteLoading || !isSignedAndEditable} onClick={handleDelete} color="secondary">
                <Delete />
              </IconButton>
            )}

            {canBeResolved && onChangePrice && canChangePrice && (
              <IconButton onClick={onChangePrice} color="primary">
                <EditOutlinedIcon />
              </IconButton>
            )}
          </TableCell>
          <TableCell>
            <IconButton onClick={() => setOpenModal(true)}>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      </Hidden>
      <Hidden mdUp>
        <TableRow className={styles.smDividerTop}>
          <TableCell>{intl('shared.code')}</TableCell>
          <TableCell className={styles.tableText}>{row.codigo}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{intl('shared.description')}</TableCell>
          <TableCell className={styles.tableText}>{row.descripcion}</TableCell>
        </TableRow>
        {canViewPrices && (
          <TableRow>
            <TableCell>{intl('shared.price')}</TableCell>
            <TableCell className={styles.tableText}>{price}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>{intl('shared.quantity')}</TableCell>
          <TableCell className={styles.tableText}>{row.amount}</TableCell>
        </TableRow>
        {canViewPrices && (
          <TableRow>
            <TableCell>{intl('shared.subtotal')}</TableCell>
            <TableCell className={styles.tableText}>{totalPrice}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>{intl('shared.company')}</TableCell>
          <TableCell className={styles.tableText}>{row.compania}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{canBeResolved ? intl('shared.resolvedBy') : intl('shared.addedBy')}</TableCell>
          <TableCell className={styles.tableText}>
            <Link to={`/user/medical_professionals/profile/${canBeResolved ? row.resolvedBy?._id : row.createdBy?._id}`}>
              {canBeResolved ? row.resolvedBy?.firstName : row.createdBy?.firstName}{' '}
              {canBeResolved ? row.resolvedBy?.lastName : row.createdBy?.lastName}
            </Link>
          </TableCell>
        </TableRow>
        {canBeResolved && (
          <TableRow>
            <TableCell>{intl('shared.voucherNumber')}</TableCell>
            <TableCell className={styles.tableText}>{row.resolvedAt}</TableCell>
          </TableRow>
        )}
        {canBeResolved && (
          <TableRow>
            <TableCell>{intl('shared.status')}</TableCell>
            {isRequest ? (
              <TableCell className={styles.tableText}>
                <FormControl
                  disabled={row.resolved || statusLoading}
                  checked={row.resolved}
                  component={Checkbox}
                  onClick={() => onResolveRequest({ catalogItemId: row._id, medicalRecordId: row.medicalRecordId })}
                />
              </TableCell>
            ) : (
              canBeResolved && (
                <TableCell className={styles.tableText}>
                  <FormControl
                    disabled={(!canUnresolve && row.resolved) || statusLoading}
                    checked={row.resolved}
                    component={Checkbox}
                    onClick={handleStatusChange}
                  />
                </TableCell>
              )
            )}
          </TableRow>
        )}
        <TableRow className={styles.smDividerBottom}>
          <TableCell>
            {canDelete && (
              <IconButton disabled={deleteLoading || !isSignedAndEditable} onClick={handleDelete} color="secondary">
                <Delete />
              </IconButton>
            )}
          </TableCell>
          <TableCell>
            {canBeResolved && onChangePrice && canChangePrice && (
              <IconButton onClick={onChangePrice} color="primary">
                <EditOutlinedIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell>
            <IconButton onClick={() => setOpenModal(true)}>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      </Hidden>

      <DetailAssignedInfo catalogItem={row} open={openModal} toggle={() => setOpenModal((old) => !old)} />
    </>
  );
};

export default MedicalRecordSKUTableRow;
