import React, { useMemo, useState } from 'react';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Edit, Save, Delete, RemoveCircleOutline } from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usePermissions from 'utils/usePermissions';

import { useTranslations } from 'utils/useTranslations';
import MaterialSelect from '../../../components/Select';

import userFetch from '../../../api/User';

import useStyles from './style';
import MaterialDialog from '../../../components/Dialog';
import removeFromObject from '../../../utils/removeFromObject';

export const PatientProfile = () => {
  const style = useStyles();
  const intl = useTranslations();
  const { patient: patientId } = useParams();
  const history = useHistory();
  const users = useStoreState((state) => state.users);
  const patients = useStoreState((state) => state.patientsMap);
  const removePatient = useStoreActions((actions) => actions.removePatient);
  const toggleAssignedDoctor = useStoreActions((actions) => actions.toggleAssignedDoctor);
  const updatePatient = useStoreActions((actions) => actions.updatePatient);
  const paymentTypes = useStoreState((state) => state.paymentTypes);

  const { canEditPatientsInfo, canDeletePatient } = usePermissions();

  const [isDisabled, setDisabled] = useState(true);
  const [isDialogOpened, setDialog] = useState(false);

  const [patientProfileActionIsLoading, setPatientProfileActionIsLoading] = useState(false);
  const [patientDoctorActionIsLoading, setPatientDoctorActionIsLoading] = useState(false);

  const toggleDisabled = () => setDisabled((old) => !old);

  const toggleDialog = () => setDialog((old) => !old);

  const patient = patients?.[patientId] || {};

  const doctors = users.filter((u) => u.type === 'doctor');

  const onSubmit = async (values) => {
    try {
      const updatedPatient = removeFromObject(
        {
          ...patient,
          ...values,
          userId: patientId,
        },
        ['doctors', 'medicalRecords', 'status', '_id', 'doctorId', 'createdAt', 'paid'],
      );
      setPatientProfileActionIsLoading(true);
      const res = await userFetch.editUserProfile(localStorage.getItem('accessToken'), updatedPatient);
      setPatientProfileActionIsLoading(false);

      updatePatient({ patient: res.data, patientId });
      toggleDisabled();
    } catch (err) {
      setPatientProfileActionIsLoading(false);
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      _id: patientId || patient._id,
      firstName: patient.firstName || '',
      lastName: patient.lastName || '',
      phone: `${patient.phone}` || '',
      paymentType: patient.paymentType || '',
      doctors: patient.doctors || [],
      email: patient.email,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      email: Yup.string().email(),
      phone: Yup.string().required(),
      paymentType: Yup.string().required(),
    }),
    enableReinitialize: true,
    onSubmit,
  });

  const onDeleteProfilePress = async () => {
    try {
      setPatientProfileActionIsLoading(true);

      await userFetch.deleteProfile(localStorage.getItem('accessToken'), {
        userId: patientId,
        type: 'patient',
      });
      setPatientProfileActionIsLoading(false);
      history.push('/user/patient_records');
      removePatient({ id: patientId });
    } catch (err) {
      setPatientProfileActionIsLoading(false);
    }
  };

  const [doctor, setDoctor] = useState('');
  const [doctorError, setDoctorError] = useState(false);

  const dialogActions = [
    {
      name: <FormattedMessage id="accounts_table.exam_dialog_close" />,
      onClick: toggleDialog,
      color: 'primary',
    },
  ];
  const handleDoctorChange = (e) => {
    setDoctor(e.target.value);
    setDoctorError(false);
  };
  const assignDoctor = async () => {
    if (!doctor) {
      setDoctorError(true);
      return;
    }
    try {
      setPatientDoctorActionIsLoading(true);
      await userFetch.assignDoctor(localStorage.getItem('accessToken'), {
        doctorId: doctor._id,
        patientId,
      });
      setPatientDoctorActionIsLoading(false);

      toggleAssignedDoctor({
        patientId,
        doctorId: doctor._id,
        doctor,
      });
      setDoctor('');
    } catch (err) {
      setPatientDoctorActionIsLoading(false);
    }
  };

  const usAssignDoctor = async (clickedDoctor) => {
    try {
      setPatientDoctorActionIsLoading(true);
      await userFetch.unassignDoctor(localStorage.getItem('accessToken'), {
        doctorId: clickedDoctor._id,
        patientId,
      });
      toggleAssignedDoctor({
        doctorId: clickedDoctor._id,
        patientId,
      });
      setPatientDoctorActionIsLoading(false);
    } catch (err) {
      setPatientDoctorActionIsLoading(false);
    }
  };

  const paymentStatus = useMemo(() => {
    switch (patient.paid) {
      case true: {
        return intl('service.status.paid');
      }
      case false: {
        return intl('patient.pending');
      }
      default: {
        return 'NULL';
      }
    }
  }, [patient.paid]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="24px">
        <Box>
          <Typography component="h4" variant="h4">
            <FormattedMessage id="position.patient" description="Patient" /> <FormattedMessage id="user.profile" description="Profile" />
          </Typography>
          <Typography>
            {intl('service.payment_status')}: {paymentStatus}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {canEditPatientsInfo && (
            <Button
              disabled={patientProfileActionIsLoading}
              className={style.button}
              onClick={isDisabled ? toggleDisabled : handleSubmit}
              variant="contained"
              color="primary"
            >
              {isDisabled ? <Edit /> : <Save />}
            </Button>
          )}
          {canDeletePatient && (
            <Button
              disabled={patientProfileActionIsLoading}
              style={{ marginTop: 10 }}
              className={style.button}
              onClick={onDeleteProfilePress}
              variant="contained"
              color="secondary"
            >
              <Delete />
            </Button>
          )}
        </Box>
      </Box>
      <TextField
        variant="outlined"
        name="_id"
        label={<FormattedMessage id="patient.case_ID" />}
        className={style.input}
        value={values._id}
        InputLabelProps={{ shrink: true }}
        disabled
        fullWidth
        onChange={handleChange}
      />

      <TextField
        variant="outlined"
        name="firstName"
        label={<FormattedMessage id="user.first_name" description="First name" />}
        className={style.input}
        value={values.firstName}
        fullWidth
        error={touched.firstName && errors.firstName}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <TextField
        variant="outlined"
        name="lastName"
        label={<FormattedMessage id="user.last_name" description="Last name" />}
        className={style.input}
        value={values.lastName}
        onChange={handleChange}
        error={touched.lastName && errors.lastName}
        fullWidth
        disabled={isDisabled}
      />

      <TextField
        variant="outlined"
        name="email"
        label={<FormattedMessage id="user.email_adress" description="Email adress" />}
        className={style.input}
        value={values.email ?? ''}
        onChange={handleChange}
        error={touched.email && errors.email}
        fullWidth
        disabled={isDisabled}
      />

      <TextField
        variant="outlined"
        name="phone"
        label={<FormattedMessage id="user.phone" description="Phone" />}
        className={style.input}
        value={values.phone}
        onChange={handleChange}
        fullWidth
        error={touched.phone && errors.phone}
        disabled={isDisabled}
      />
      <MaterialSelect
        className={style.input}
        value={values.paymentType}
        title={<FormattedMessage id="user.payment_type" description="Payment type" />}
        disabled={isDisabled}
        options={paymentTypes}
        name="paymentType"
        error={touched.paymentType && errors.paymentType}
        onChange={handleChange}
      />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TextField
          value={patient.doctors?.map((d) => `${d.firstName} ${d.lastName}`).join(', ') || ''}
          variant="outlined"
          disabled
          fullWidth
          label={<FormattedMessage id="position.doctor" />}
          className={style.input}
        />
        {canEditPatientsInfo && (
          <Button className={style.button} onClick={toggleDialog} variant="contained" color="primary" disabled={isDisabled}>
            <FormattedMessage id="user.assign_doctor" description="Doctors" />
          </Button>
        )}
      </Box>

      {canEditPatientsInfo && (
        <MaterialDialog
          title={<FormattedMessage id="patient_dialog.assign_doctor" />}
          opened={isDialogOpened}
          onClose={toggleDialog}
          actions={dialogActions}
        >
          <p>
            {patient.doctors?.map((d) => (
              <Box display="flex" justifyContent="space-between">
                <p>
                  {d.firstName} {d.lastName}
                </p>
                <Button disabled={patientDoctorActionIsLoading} onClick={() => usAssignDoctor(d)}>
                  <RemoveCircleOutline />
                </Button>
              </Box>
            ))}
          </p>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <MaterialSelect
              title={<FormattedMessage id="patient_dialog.doctor" />}
              value={doctor}
              name="doctor"
              error={doctorError}
              onChange={handleDoctorChange}
              options={doctors.map((d) => ({
                value: d,
                name: `${d.firstName || ''} ${d.lastName || ''}`,
              }))}
            />
            <Box ml={4} height="100%">
              <Button disabled={patientDoctorActionIsLoading} onClick={assignDoctor} variant="contained" color="primary">
                <FormattedMessage id="patient_dialog.assign" />
              </Button>
            </Box>
          </Box>
        </MaterialDialog>
      )}
    </Box>
  );
};
