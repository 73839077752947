import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
  },
}));

export const useModalStyles = makeStyles({
  textField: {
    marginTop: 12,
  },
});
