import { TableCell, TableHead, Hidden } from '@material-ui/core';
import React from 'react';
import usePermissions from 'utils/usePermissions';
import { useTranslations } from 'utils/useTranslations';

import { useStyles } from './styles';

const MedicalRecordSKUTableHead = ({ canBeResolved = false }) => {
  const intl = useTranslations();
  const { canViewPrices } = usePermissions();
  const classes = useStyles();
  return (
    <Hidden smDown>
      <TableHead>
        <TableCell className={classes.tableHeader}>{intl('shared.code')}</TableCell>
        <TableCell className={classes.tableHeader}>{intl('shared.description')}</TableCell>
        {canViewPrices && <TableCell className={classes.tableHeader}>{intl('shared.price')}</TableCell>}
        <TableCell className={classes.tableHeader}>{intl('shared.quantity')}</TableCell>
        {canViewPrices && <TableCell className={classes.tableHeader}>{intl('shared.subtotal')}</TableCell>}
        <TableCell className={classes.tableHeader}>{intl('shared.company')}</TableCell>
        <TableCell className={classes.tableHeader}>{canBeResolved ? intl('shared.resolvedBy') : intl('shared.addedBy')}</TableCell>
        {canBeResolved && <TableCell className={classes.tableHeader}>{intl('shared.voucherNumber')}</TableCell>}
        {canBeResolved && <TableCell className={classes.tableHeader}>{intl('shared.status')}</TableCell>}
        <TableCell />
        <TableCell />
      </TableHead>
    </Hidden>
  );
};

export default MedicalRecordSKUTableHead;
