import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Hidden, makeStyles } from '@material-ui/core';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FormattedMessage } from 'react-intl';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import enLocale from '@fullcalendar/core/locales/en-gb';
import esLocale from '@fullcalendar/core/locales/es';

import BusinessTimePicker from './BusinessTimePicker';
import calendarFetch from '../../../api/Calendar';
import businessHoursInit from './calendarDataInit';

// import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import getPermissions from '../../../utils/getPermissions';

const locale = {
  en: enLocale,
  es: esLocale,
};

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    '& .fc': {
      minHeight: 375,
    },
  },
}));

export default function Calendar({ location, ...props }) {
  const accessToken = localStorage.getItem('accessToken');
  const classes = useStyles();
  const user = useStoreState((state) => state.user);
  const users = useStoreState((state) => state.users);
  const lang = useStoreState((state) => state.lang);
  const [medicalProfessional, setMedicalProfessional] = useState();
  const businessHoursFromStore = useStoreState((state) => state.businessHours);
  const eventsFromStore = useStoreState((state) => state.events);
  const setEventsInStore = useStoreActions((actions) => actions.setEvents);
  const setBusinessHoursInStore = useStoreActions((actions) => actions.setBusinessHours);
  const [open, setOpen] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [buttonName, setButtonName] = useState('add');
  const [availabilityTimeView, setAvailabilityTimeView] = useState(false);
  const [businessHours, setBusinessHours] = useState(businessHoursInit);
  const [calendarActionIsLoading, setCalendarActionIsLoading] = useState(false);

  const getBusinessHoursAndEvents = async () => {
    // if admin tries to get doctors calendar
    if (props.match.params.doctorId && users.length) {
      const userAccount = users.find((u) => u._id === props.match.params.doctorId);
      const formData = { userId: userAccount._id, type: userAccount.type };
      const eventsList = await calendarFetch.getEvents(accessToken, formData);
      const formattedEvents = eventsList.map((el) => ({ ...el, id: el._id }));
      setMedicalProfessional(userAccount);
      setEventsInStore(formattedEvents);
      const businessHoursList = await calendarFetch.getBusinessHours(accessToken, formData);
      setBusinessHours(businessHoursList);
    } else {
      // if doctor tries to get his calendar
      setMedicalProfessional(user);
      setBusinessHours(businessHoursFromStore);
    }
  };

  useEffect(() => {
    getBusinessHoursAndEvents();
  }, [users, businessHoursFromStore]);

  const handleSelect = ({ start, end }) => {
    setOpen(true);
    setCurrentEvent({ start, end });
  };

  const handleEvent = ({ event: { start, end, title, id } }) => {
    setOpen(true);
    setCurrentEvent({ start, end, title, id });
    setButtonName('edit');
  };

  const handleClose = () => {
    setCurrentEvent({});
    setOpen(false);
    setButtonName('add');
    setTitleError(false);
  };

  const handleEventTitle = (e) => {
    setTitleError(false);
    setCurrentEvent({
      ...currentEvent,
      title: e.target.value,
    });
  };

  const createEvent = async () => {
    if (currentEvent.title) {
      try {
        const userId = medicalProfessional._id;
        const type = medicalProfessional.type;
        const formData = { ...currentEvent };
        if (getPermissions(user.type, ['admin', 'staff'])) {
          formData.type = type;
          formData.userId = userId;
        }
        setCalendarActionIsLoading(true);
        const createdEvent = await calendarFetch.addEvent(accessToken, formData);
        setEventsInStore([...eventsFromStore, { ...createdEvent, id: createdEvent._id }]);
        setCurrentEvent({});
        setCalendarActionIsLoading(false);
        setOpen(false);
      } catch (err) {
        setTitleError(true);
        setCalendarActionIsLoading(false);
      }
    } else {
      setTitleError(true);
    }
  };

  const getEvents = () => eventsFromStore.map((evt) => ({ ...evt, id: evt.id }));

  const editEvent = async () => {
    const position = eventsFromStore.map((event) => event.id).indexOf(currentEvent.id);
    const userId = medicalProfessional._id;
    const type = medicalProfessional.type;
    console.log(position);
    console.log(currentEvent);
    if (position !== -1 && currentEvent.title) {
      try {
        const newEventArray = [...eventsFromStore];
        const formData = {
          ...currentEvent,
          eventId: currentEvent.id,
        };
        if (getPermissions(user.type, ['admin', 'staff'])) {
          formData.type = type;
          formData.userId = userId;
        }
        delete formData.start;
        delete formData.end;
        delete formData.id;
        setCalendarActionIsLoading(true);
        const editedEvent = await calendarFetch.editEvent(accessToken, formData);
        newEventArray[position] = { ...editedEvent, id: editedEvent._id };
        setCurrentEvent({});
        setCalendarActionIsLoading(false);
        setOpen(false);
        setButtonName('add');
        setEventsInStore(newEventArray);
      } catch (err) {
        setCalendarActionIsLoading(false);
        setTitleError(true);
      }
    } else {
      setTitleError(true);
    }
  };

  const deleteEvent = async () => {
    const userId = medicalProfessional._id;
    const type = medicalProfessional.type;
    console.log(currentEvent);
    const formData = {
      eventId: currentEvent.id,
    };
    if (getPermissions(user.type, ['admin', 'staff'])) {
      formData.type = type;
      formData.userId = userId;
    }
    try {
      setCalendarActionIsLoading(true);
      const deletedEvent = await calendarFetch.deleteEvent(accessToken, formData);
      setCalendarActionIsLoading(false);
      console.log('eventsFromStore', eventsFromStore);
      console.log('currentEvent', currentEvent);
      setEventsInStore(eventsFromStore.filter((event) => event.id !== currentEvent.id));
      setButtonName('add');
      setTitleError(false);
      setOpen(false);
    } catch (err) {
      setCalendarActionIsLoading(false);
      throw err;
    }
  };

  const onAvailabilityTimeChange = async (hours) => {
    try {
      const userId = medicalProfessional._id;
      const type = medicalProfessional.type;

      const formData = getPermissions(user.type, ['admin', 'staff'])
        ? {
            businessHours: hours,
            userId,
            type,
          }
        : { businessHours: hours };
      const response = await calendarFetch.setBusinessHours(accessToken, formData);
      setBusinessHours(hours);
      setBusinessHoursInStore(hours);
    } catch (err) {
      throw err;
    }
  };

  return (
    <Box>
      <Hidden xsDown>
        <Box
          style={{
            width: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 100,
          }}
        >
          <Button
            style={{ marginBottom: 50, width: '300px' }}
            variant="contained"
            color="primary"
            onClick={() => setAvailabilityTimeView(!availabilityTimeView)}
          >
            {availabilityTimeView ? (
              <FormattedMessage id="calendar.hide_picker" description="Hide Picker" />
            ) : (
              <FormattedMessage id="calendar.show_picker" description="Show picker" />
            )}
          </Button>
          {availabilityTimeView && <BusinessTimePicker onApply={onAvailabilityTimeChange} businessHours={businessHours} />}
          <FullCalendar
            initialView="timeGridWeek"
            headerToolbar={{
              left: 'title',
              center: 'dayGridMonth timeGridWeek timeGridDay',
              right: 'today prev,next',
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            selectable
            locale={locale[lang]}
            select={handleSelect}
            event
            events={getEvents()}
            eventClick={handleEvent}
            businessHours={businessHours}
            selectConstraint={businessHours}
            scrollTime="06:00:00"
          />
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box
          className={classes.calendarContainer}
          style={{
            width: '92vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 100,
            fontSize: 10,
            fontWeight: 400,
          }}
        >
          <Button style={{ marginBottom: 30 }} variant="contained" color="primary" onClick={() => setAvailabilityTimeView(!availabilityTimeView)}>
            {availabilityTimeView ? (
              <FormattedMessage id="calendar.hide_picker" description="Hide Picker" />
            ) : (
              <FormattedMessage id="calendar.show_picker" description="Show picker" />
            )}
          </Button>
          {availabilityTimeView && <BusinessTimePicker onApply={onAvailabilityTimeChange} businessHours={businessHours} />}
          <FullCalendar
            headerToolbar={{
              top: 'title',
              right: 'prev,next',
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridDay"
            selectable
            locale={locale[lang]}
            select={handleSelect}
            event
            events={getEvents()}
            eventClick={handleEvent}
            businessHours={businessHours}
            selectConstraint={businessHours}
            scrollTime="06:00:00"
          />
        </Box>
      </Hidden>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="calendar.new_event" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="calendar.provide_event_info" />
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={<FormattedMessage id="calendar.provide_event_label" />}
            type="text"
            fullWidth
            error={titleError}
            value={currentEvent.title || ''}
            onChange={handleEventTitle}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={calendarActionIsLoading} onClick={deleteEvent} color="secondary">
            <FormattedMessage id="calendar.delete" description="Delete" />
          </Button>
          <Button disabled={calendarActionIsLoading} onClick={handleClose} color="primary">
            <FormattedMessage id="calendar.cancel" description="Cancel" />
          </Button>
          {buttonName === 'add' ? (
            <Button disabled={calendarActionIsLoading} onClick={createEvent} color="primary">
              <FormattedMessage id="calendar.add" description="Add" />
            </Button>
          ) : (
            <Button disabled={calendarActionIsLoading} onClick={editEvent} color="primary">
              <FormattedMessage id="calendar.edit" description="Edit" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
