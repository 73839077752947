import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Delete, GetApp } from '@material-ui/icons';
import { FileApi } from 'api/File';
import { FileItemType } from 'pages/User/PatientCases/types';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { convertDate } from 'utils/formatters';
import { saveAs } from 'file-saver';
import clsx from 'clsx';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ThunkType } from 'store/type';
import { Link } from 'react-router-dom';

interface FileItemProps {
  file: FileItemType;
}

const useStyles = makeStyles((theme) => ({
  fileLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  disabled: {
    color: '#eee',
    pointerEvents: 'none',
    textDecoration: 'none',
    cursor: 'not-allowed',
  },
  small: {
    fontSize: 14,
  },
}));

const FileItem: FC<FileItemProps> = ({ file }) => {
  const classes = useStyles();

  const { type } = useStoreState(({ user }) => user);
  const isRemoveAllowed = useMemo(() => ['admin', 'staff'].includes(type), [type]);

  const removeFile = useStoreActions(({ removeFileThunk }) => removeFileThunk as ThunkType);

  const [loading, setLoading] = useState({
    view: false,
    download: false,
    delete: false,
  });

  const nameFormatted = `${file.creator.firstName || ''} ${file.creator.lastName || ''}`;
  const createdAtFormatted = useMemo(() => convertDate(file.createdAt, 'dd.MM.yyyy HH:mm'), [file.createdAt]);

  const onLinkClick = useCallback(async () => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, view: true }));
      const { data } = await FileApi.getDownloadLink(file._id);
      if (data.downloadLink) {
        window.open(data.downloadLink, '_blank');
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, view: false }));
    }
  }, [file]);

  const onDownloadClick = useCallback(async () => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, download: true }));
      const { data } = await FileApi.downloadSingleFile(file._id);
      if (data) {
        saveAs(new Blob([data]), file.fileName);
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, download: false }));
    }
  }, [file]);

  const onDeleteClick = useCallback(async () => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, delete: true }));
      await removeFile(file);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, delete: false }));
    }
  }, [file, setLoading, removeFile]);

  return (
    <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography className={clsx(classes.fileLink, loading.view && classes.disabled)} onClick={onLinkClick}>
          {file.fileName}
        </Typography>
        <Typography>
          <Link className={classes.small} to={`/users/${file.createdBy}`}>
            {nameFormatted}
          </Link>
          <Typography component="span" className={classes.small}>
            {createdAtFormatted}
          </Typography>
        </Typography>
      </Box>

      <Box>
        <IconButton disabled={loading.download} onClick={onDownloadClick}>
          <GetApp />
        </IconButton>
        {isRemoveAllowed && (
          <IconButton disabled={loading.delete} onClick={onDeleteClick} color="secondary">
            <Delete />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default FileItem;
