import React, { useState, FC } from 'react';
import clsx from 'clsx';
import { AppBar, Tabs, Tab, Box, Hidden, IconButton } from '@material-ui/core';
import { MenuOutlined } from '@material-ui/icons';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStoreState } from 'easy-peasy';

import hospitalPrivado from '../../assets/images/hospitalprivado.png';
import managemedLogo from '../../assets/images/managemed-logo-2.png';

import Drawer from '../Drawer';

import { useStyles } from './styles';
import { PERMISSION_TABS } from './constants';

const NavBar: FC = () => {
  const user = useStoreState((state) => state.user);

  const intl = useIntl();
  const { push } = useHistory();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpened((drawer) => !drawer);
  };

  const mStyles = useStyles({});

  const logOut = () => {
    localStorage.removeItem('AUTH');
    localStorage.removeItem('accessToken');
    setIsLoggedOut(true);
    push('/');
  };

  return (
    <AppBar className={mStyles.appBar}>
      <img
        src={process.env.REACT_APP_ENV === 'master' ? hospitalPrivado : managemedLogo}
        className={mStyles.hospitalPrivado}
        alt="Hospital Privado"
      />
      <Hidden smDown>
        <Tabs
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            className: mStyles.tabIndicarorStyle,
          }}
        >
          {PERMISSION_TABS.map(
            (link) =>
              link.permissions.includes(user.type) && (
                <Link key={link.link} className={mStyles.link} to={link.link}>
                  <Tab value={link.link} className={mStyles.btn} label={intl.formatMessage({ id: link.name })} />
                </Link>
              ),
          )}
        </Tabs>
        {isLoggedOut && <Redirect to="/" />}
        <Box display="flex">
          <Tab value="logout" label={<FormattedMessage id="navbar.logout" />} className={clsx(mStyles.btn, mStyles.mobileLogout)} onClick={logOut} />
        </Box>
      </Hidden>
      <Hidden mdUp>
        <IconButton onClick={toggleDrawer}>
          <MenuOutlined className={mStyles.burger} />
        </IconButton>
      </Hidden>

      <Drawer links={PERMISSION_TABS} userType={user.type} drawerOpened={drawerOpened} onClose={toggleDrawer} logOut={logOut} />
    </AppBar>
  );
};

export default NavBar;
