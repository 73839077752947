/* eslint-disable max-len */
import { convertDate } from 'utils/formatters';

export const valuesMapper = {
  generalInformation: {
    sex: (value) => {
      const sex = {
        0: 'covid19.Male',
        1: 'covid19.Female',
        2: 'covid19.Other / Prefer not to say',
      };
      return sex[value];
    },
    age: (value) => value,
    health: (value) => {
      const health = {
        0: 'covid19.I am very fit and rarely ill',
        1: 'covid19.I am fitter and healthier than average',
        2: 'covid19.I am less fit and healthy than average',
        3: "covid19.I'm not very healthy at all",
      };
      return health[value];
    },
  },
  locationAndTravel: {
    country: (value) => value,
    region: (value) => value,
    postcode: (value) => value,
    travelledToOtherCountries: (value) => {
      const travelled = {
        0: 'covid19.No',
        1: 'covid19.Yes, before they were in "lockdown"',
        2: 'covid19.Yes, while they were in "lockdown"',
        3: 'covid19.Yes, after they came out of "lockdown"',
      };
      return travelled[value];
    },
  },
  travelContinued: {
    highestRiskCountry: (value) => value,
    dateEnteredCountry: (value) => convertDate(value),
    dateLeftCountry: (value) => convertDate(value),
  },
  exposureRisk: {
    didWork: (value) => {
      const didWork = {
        0: 'covid19.No',
        1: 'covid19.Yes, but I interacted with few people per day',
        2: 'covid19.Yes, and I interacted with many people per day',
        3: 'covid19.Yes, and I interacted with many people/patients per day in a healthcare setting.',
      };
      return didWork[value];
    },
    contactWithCovid19: (value) => {
      const contactWithCovid19 = {
        0: 'covid19.No',
        1: 'covid19.Possible COVID-19 symptoms',
        2: 'covid19.Confirmed COVID-19 (Positive diagnosis)',
      };
      return contactWithCovid19[value];
    },
    amountPeopleLiveWith: (value) => value,
    householdIllness: (value) => {
      const householdIllness = {
        0: 'covid19.No',
        1: 'covid19.Yes, possibly',
        2: 'covid19.Yes, definitely (Positive test result)',
      };
      return householdIllness[value];
    },
  },
  illHousemates: {
    numberOfIllInHousehold: (value) => value,
    firstHouseholdIllnessDate: (value) => convertDate(value),
  },
  wereYouIll: {
    hasFeltIll: (value) => {
      const hasFeltIll = {
        0: "covid19.No, I'm fine. I haven't felt ill at all since January 2020",
        1: "covid19.I'm beginning to feel ill",
        2: "covid19.I'm now feeling quite ill",
        3: "covid19.I'm feeling very ill",
        4: "covid19.I'm still ill but feeling better",
        5: 'covid19.I was recovering, but now feeling worse again',
        6: 'covid19.Yes, but I feel fully recovered',
      };
      return hasFeltIll[value];
    },
  },
  yourSymptoms: {
    howShortOfBreath: (value) => value,
    howBadDidTheyFeel: (value) => value,
    howAnxious: (value) => value,
    firstSymptoms: (value) => convertDate(value),
    lengthOfTimeOfSymptoms: (value) => {
      const lengthOfTimeOfSymptoms = {
        0: 'covid19.A few days or less',
        1: 'covid19.Less than one week',
        2: 'covid19.One to two weeks',
        3: 'covid19.Two to three weeks',
        4: 'covid19.More than three weeks',
        5: "covid19.I'm still ill!",
      };
      return lengthOfTimeOfSymptoms[value];
    },
    wereTheyInfected: (value) => {
      const wereTheyInfected = {
        0: 'covid19.No way',
        1: 'covid19.A small chance (~25% sure)',
        2: 'covid19.A reasonable chance (~50% sure)',
        3: 'covid19.Pretty sure (~75% sure)',
        4: 'covid19.Absolutely convinced (~90%+ sure)',
        5: 'covid19.Yes, 100%: I tested positive',
        6: 'covid19.Yes, 100%: I was treated in hospital',
        7: 'covid19.Yes, 100%: I was treated in intensive care',
      };
      return wereTheyInfected[value];
    },
  },
  antibodyTest: {
    testOutcome: (value) => {
      const testOutcome = {
        0: 'covid19.C only',
        1: 'covid19.C and IgG',
        2: 'covid19.C and IgM',
        3: 'covid19.C, IgM and IgG',
        4: 'covid19.IgG only (no C)',
        5: 'covid19.IgM only (no C)',
        6: 'covid19.IgG and IgM only (no C)',
        7: 'covid19.None, no lines',
        8: 'covid19.None of the above',
      };
      return testOutcome[value];
    },
    testImageSrc: (value) => value,
    testImageURL: (value) => value,
  },
  covidTesting: {
    antibodyTest: (value) => {
      const antibodyTest = {
        0: "covid19.No, I've not done a lateral flow antibody test, or any other COVID tests.",
        1: "covid19.No, but I've done a different COVID test.",
        2: 'covid19.Yes, and I tested positive',
        3: 'covid19.Yes, but I tested negative',
        4: "covid19.Yes, but I don't know the result",
      };
      return antibodyTest[value];
    },
    dateOfTest: (value) => convertDate(value),
  },
  otherTests: {
    labTest: (value) => value,
    swabTest: (value) => value,
    antigenTest: (value) => value,
    xrayTest: (value) => value,
    diagnosedByDoctor: (value) => value,
  },
  emailConsent: {
    email: (value) => value,
  },
  stdDiv: (value) => value,
  mean: (value) => value,
};

export const namesMapper = {
  generalInformation: {
    sex: 'covid19.What sex were you assigned at birth?',
    age: 'covid19.What is your age group?',
    health: 'covid19.How is your health?',
    mildHeartCondition: 'covid19.Mild heart condition',
    chronicHeartCondition: 'covid19.Chronic heart condition',
    mildLungCondition: 'covid19.Mild lung problem like mild asthma',
    chronicLungCondition: 'covid19.Chronic lung problems',
    mildDiabetes: 'covid19.Mild or borderline Diabetes',
    chronicDiabetes: 'covid19.Chronic Diabetes',
    recentCancer: 'covid19.Recent (last 5 years) Cancer treatment with chemo',
    pastCancer: 'covid19.Past Cancer treatment (over 5 years ago) with chemo',
    reducedKidneyFunction: 'covid19.Reduced kidney function',
    chronicLiverDisease: 'covid19.Chronic liver disease',
    onImmunosuppressant: 'covid19.I take immunosuppressant drugs',
    pregnant: 'covid19.I am pregnant',
    fluVaccine: 'covid19.Did you get a flu shot in the past year?',
  },
  locationAndTravel: {
    country: 'covid19.Where are you living right now?',
    region: 'covid19.Region?',
    postcode: 'covid19.Please enter only the first three letters or numbers of your postcode/zipcode.',
    travelledToOtherCountries: 'covid19.Have you travelled to any other countries since January 1st 2020?',
  },
  travelContinued: {
    highestRiskCountry:
      'covid19.Which countries have you visited since January 1st 2020? (If you went to multiple countries please pick the one where you think you could have caught COVID-19 or had the highest risk of catching it)',
    dateEnteredCountry: 'covid19.What date did you enter that country?',
    dateLeftCountry: 'covid19.What date did you leave that country?',
  },
  exposureRisk: {
    didWork: "covid19.Did you go to work during your country's lockdown?",
    keepDistanceFromOthers: 'covid19.I always kept 6 ft/2 m distance from people.',
    mask: 'covid19.I always wore a mask/face covering.',
    timeInVehicles: 'covid19.I never spent more than 30 minutes indoors/in vehicles with others.',
    washedHands: 'covid19.I frequently washed my hands and rarely touched my face.',
    contactWithCovid19: 'covid19.Outside your home did you spend time with anyone who had or soon after developed...?',
    amountPeopleLiveWith: 'covid19.How many people do you live with? (Please type a number, e.g. 2)',
    householdIllness: 'covid19.Do you think anyone in your home might have had COVID-19?',
  },
  illHousemates: {
    numberOfIllInHousehold: 'covid19.How many people who you live with have had possible COVID-19 symptoms? Please type a number, e.g. 2',
    firstHouseholdIllnessDate: 'covid19.When did the first person in your house become ill?',
  },
  wereYouIll: {
    hasFeltIll: "covid19.Have you felt ill at all since January 2020? (If you say no, you won't be asked about symptoms)",
  },
  yourSymptoms: {
    headaches: 'covid19.A headache',
    achyJointsMuscles: 'covid19.Achy joints and/or muscles',
    chills: 'covid19.Chills',
    congestedNose: 'covid19.Congested nose',
    dryCough: 'covid19.A dry cough',
    coughWithSputum: 'covid19.A cough with sputum',
    fever: 'covid19.Fever',
    lossTasteSmell: 'covid19.Loss of sense of taste/smell',
    fatigue: 'covid19.Really tired',
    runnyNose: 'covid19.Runny nose',
    shortnessOfBreath: 'covid19.Short of breath',
    skinRash: 'covid19.Skin rash',
    sneezing: 'covid19.Sneezing',
    soreThroat: 'covid19.A sore throat',
    stomachUpsetDiarrhoea: 'covid19.Stomach disturbance/diarrhoea',
    nausea: 'covid19.Vomiting/nausea',
    howShortOfBreath: 'covid19.How hard did you, or do you find it to breathe? (0 = mild shortness of breath, 4 = severe shortness of breath)',
    howBadDidTheyFeel: 'covid19.How bad did you, or do you feel overall? (0 = only slightly ill, 4 = seriously ill/went to the hospital)',
    howAnxious: 'covid19.How anxious were/are you about COVID19? (0 = a little anxious, 4 = very anxious)',
    firstSymptoms: 'covid19.When did you first experience symptoms?',
    lengthOfTimeOfSymptoms: 'covid19.How long did you feel ill/have symptoms for?',
    wereTheyInfected: 'covid19.Did/Do you think you were infected with Coronavirus / COVID-19?',
  },
  antibodyTest: {
    testOutcome: 'covid19.What did your test result look like?',
  },
  covidTesting: {
    antibodyTest: 'covid19.Have you done a lateral flow antibody test to check if you have/had COVID-19?',
    dateOfTest: 'covid19.What date did you do the test?',
  },
  otherTests: {},
  emailConsent: {
    email: 'covid19.email',
    emailPermission:
      'covid19.Would you be interested in knowing about any studies, testing programmes, or trials, to help confirm if you have fully recovered, to donate plasma, or if you may be immune to Corona Virus?',
  },
};
