const omit = (obj, requiredKeys) => {
  const clone = { ...obj }

  Object.keys(clone).forEach((el) => {
    if (!requiredKeys.includes(el)) {
      delete clone[el]
    }
  })

  return clone
}

export default omit
