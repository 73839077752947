import React, { useCallback, useEffect, useState } from 'react';
import { Container, Tabs, Tab, AppBar, TextField, Box, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useTranslations } from 'utils/useTranslations';
import UniTable from 'components/UniTable';

import userFetch from 'api/User';
import { debounce } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useStyles from './style';
import AddPatientModal from '../../../components/AddPatientModal';

export default function PatientRecords() {
  const style = useStyles();
  const [modalOpened, setModalOpened] = useState(false);
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [searchText, setSearchText] = useState('');

  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const getPaymentTypes = useStoreActions((actions) => actions.getPaymentTypesThunk);
  const paymentTypes = useStoreState((state) => state.paymentTypes);

  const [patientsLoading, setPatientsLoading] = useState(false);
  const [patientsPagination, setPatientsPagination] = useState({
    search: '',
    page: 0,
    rowsPerPage: 10,
    data: [],
    count: 0,
  });

  const handleChangePage = (_, newPage) => {
    setPatientsPagination((prevState) => ({ ...prevState, page: newPage }));
  };
  const handleChangeRowsPerPage = (e) => {
    setPatientsPagination((prevState) => ({ ...prevState, rowsPerPage: e.target.value, page: 0 }));
  };

  const loadPatients = async () => {
    const patients = await userFetch.getPatients({
      limit: patientsPagination.rowsPerPage,
      skip: patientsPagination.page * patientsPagination.rowsPerPage,
      query: patientsPagination.search,
    });
    setPatientsPagination((prevState) => ({ ...prevState, data: patients.patients, count: patients.count }));
  };

  const handleSearchChange = useCallback(
    debounce((search) => {
      setPatientsPagination((prevState) => ({ ...prevState, search, data: [], page: 0 }));
    }, 1000),
    [],
  );

  useEffect(() => {
    setPatientsLoading(true);
    getPaymentTypes()
      .then(() => loadPatients())
      .finally(() => setPatientsLoading(false));
  }, [patientsPagination.rowsPerPage, patientsPagination.page, patientsPagination.search]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const openModal = (modal) => {
    setModalOpened(modal);
  };
  const closeModal = () => setModalOpened(false);
  const intl = useTranslations();

  const columns = [
    { name: 'firstName', title: intl('user.first_name') },
    { name: 'lastName', title: intl('user.last_name') },
    { name: 'phone', title: intl('user.phone') },
    {
      name: 'doctors',
      title: intl('position.doctor'),
      valueGetter: (doctors) => doctors.map(({ firstName, lastName }) => `${firstName} ${lastName}`),
    },
    {
      name: 'paymentType',
      title: intl('assign_service.paymentType'),
      valueGetter: (paymentType) => paymentTypes.find((pt) => pt.value === paymentType)?.name || paymentType,
    },
  ];

  const smColumns = [
    {
      name: '',
      title: intl('user.name'),
      valueGetter: ({ lastName, firstName }) => `${firstName || ''} ${lastName || ''}`,
      headerClassName: style.nameCell,
      bodyClassName: style.nameCell,
    },

    { name: 'phone', title: intl('user.phone'), headerClassName: style.phoneCell, bodyClassName: style.phoneCell },
  ];

  const onClickAction = ({ _id }) => {
    history.push(`/user/patient_records/${_id}`);
  };

  const handleSuccessPatientCreation = (newPatient) => {
    setPatientsPagination((prevState) => ({
      ...prevState,
      data: [newPatient, ...prevState.data.filter((_, i) => i !== prevState.data.length - 1)],
      count: prevState.count + 1,
    }));
  };

  return (
    <Container component="main">
      <Box className={style.buttonCont} display="flex" flexWrap="wrap">
        <FormattedMessage id="position.find_patient" description="Search">
          {(msg) => (
            <TextField
              className={style.search}
              margin="normal"
              label={<FormattedMessage id="user.search" description="Search" />}
              placeholder={msg}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                handleSearchChange(e.target.value);
              }}
            />
          )}
        </FormattedMessage>

        <Button className={style.button} variant="contained" color="primary" onClick={() => openModal('patient')}>
          <FormattedMessage id="patient_modal.add_new_patient" description="Add new patient" />
        </Button>
      </Box>

      <AppBar className={style.appBar} position="static" color="primary">
        <Tabs
          className={style.tabs}
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#3f51b5',
            },
          }}
        >
          <Tab className={style.tab} value={0} label={<FormattedMessage id="position.patient" description="Patients" />} />
        </Tabs>
      </AppBar>

      <UniTable
        className={style.table}
        columns={matchesSmUp ? columns : smColumns}
        rows={patientsPagination.data}
        onRowClick={onClickAction}
        keyMapper="_id"
        loading={patientsLoading}
        paginationProps={{
          count: patientsPagination.count,
          page: patientsPagination.page,
          rowsPerPage: patientsPagination.rowsPerPage,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
      />

      <AddPatientModal open={modalOpened === 'patient'} handleClose={closeModal} onSuccess={handleSuccessPatientCreation} />
    </Container>
  );
}
