import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  addPaymentButton: {
    marginRight: '2em',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1em',
    },
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    // paddingRight: 60,
  },

  sectionHeaderBold: {
    fontWeight: 700,
  },

  sectionConent: {
    justifyContent: 'left',
  },
  sectionSubHeader: {
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  column: {
    // width: '25%',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      width: '33%',
      padding: 4,
    },
  },
  // tableHeader: {
  //   textAlign: 'center',
  //   fontWeight: 700,
  // },
  // textConent: {
  //   paddingLeft: 100,
  // },
  // tableText: {
  //   textAlign: 'center',
  // },
}));
