import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import hospitalPrivado from '../../assets/images/hospitalprivado.png';
import managemedLogo from '../../assets/images/managemed-logo-2.png';

export default function Home() {
  const { push } = useHistory();
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      push('/user');
    }
  }, []);
  return (
    <Box display="flex" alignItems="center" height="100vh" flexDirection="column" justifyContent="center" marginTop="-100">
      <img src={process.env.REACT_APP_ENV === 'master' ? hospitalPrivado : managemedLogo} style={{ height: 100 }} alt="Hospital Privado" />
      <Link to="/auth/sign_in" style={{ textDecoration: 'none', marginTop: 50 }}>
        <Button variant="contained" color="primary">
          <FormattedMessage id="home.log_in" />
        </Button>
      </Link>
    </Box>
  );
}
