import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

export default function MaterialDialog({ opened, onClose, title, actions, children }) {
  return (
    <Dialog open={opened} onClose={onClose} fullWidth aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {actions.map((action) => (
          <Button onClick={action.onClick} disabled={action.disabled} color={action.color}>
            {action.name}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}
